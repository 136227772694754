import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useLocation, Link, useNavigate } from "react-router-dom";
import "./index.css";
import {
  contentFontsize_14,
  contentFontSize_18,
  HeaderFontSize_36_xs_25,
} from "../Typography";
import { ReactComponent as FacebookIcon } from "../utils/icons/fb.svg";
import { ReactComponent as LinkedInIcon } from "../utils/icons/linkedin.svg";
import { ReactComponent as InstagramIcon } from "../utils/icons/insta.svg";
import { ReactComponent as TwitterIcon } from "../utils/icons/X.svg";

const Navbar = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const location = useLocation();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const getTextStyle = (path) => {
    if (path === "/" && location.pathname === "/") {
      return { fontWeight: 550, color: "rgba(185, 126, 18, 1)" };
    }

    if (location.pathname.startsWith(path) && path !== "/") {
      return { fontWeight: 550, color: "rgba(185, 126, 18, 1)" };
    }

    return { fontWeight: 500, color: "black" };
  };
  const nav = useNavigate();
  const handleIconClick = () => {
    nav("/");
  };
  const handlefbClick = () => {
    window.open("https://www.facebook.com/axiakairos", "_blank");
  };
  const handleXclick = () => {
    window.open("https://x.com/axia_kairos", "_blank");
  };
  const handleLinkedinClick = () => {
    window.open("https://www.linkedin.com/company/axiakairos", "_blank");
  };
  const handleInstaClick = () => {
    window.open("https://www.instagram.com/axiakairos", "_blank");
  };
  return (
    <>
      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          justifyContent: "flex-end",
          alignItems: "center",
          marginRight: "53px",
        }}
        className="mail"
      >
        <Typography fontSize={contentFontsize_14}>
          <a href="mailto:reach@axiakairos.com" style={{textDecoration:"none", color:"black"}}>reach@axiakairos.com</a>
        </Typography>
        <IconButton size="small" color="inherit">
          <FacebookIcon fontSize="small" onClick={() => handlefbClick()} />
        </IconButton>
        <IconButton size="small" color="inherit">
          <LinkedInIcon
            fontSize="small"
            onClick={() => handleLinkedinClick()}
          />
        </IconButton>
        <IconButton size="small" color="inherit">
          <TwitterIcon fontSize="small" onClick={() => handleXclick()} />
        </IconButton>
        <IconButton size="small" color="inherit">
          <InstagramIcon fontSize="small" onClick={() => handleInstaClick()} />
        </IconButton>
      </Box>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        className="navbar-main"
        sx={{ background: "white" }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              cursor: "pointer",
            }}
            onClick={handleIconClick}
          >
            <Typography
              fontSize={HeaderFontSize_36_xs_25}
              style={{ fontWeight: "700", lineHeight: 1 }}
            >
              <span>AXIA</span>
            </Typography>
            <Typography
              fontSize={HeaderFontSize_36_xs_25}
              style={{ fontWeight: "700", lineHeight: 1 }}
            >
              <span className="kairos">
                K<span className="color-ai">AI</span>ROS
              </span>
            </Typography>
          </div>
          {/* <div style={{ display: "flex", flexDirection: "column",justifyContent:"space-between",gap:"25px" }}> */}

          <Box sx={{ display: { xs: "none", md: "flex" }, marginTop: "2%" }}>
            <Typography
              sx={{
                mx: 2,
                cursor: "pointer",
                ...getTextStyle("/"),
                textDecoration: "none",
              }}
              fontSize={contentFontSize_18}
              component={Link}
              to="/"
            >
              Home
            </Typography>
            <Typography
              sx={{
                mx: 2,
                cursor: "pointer",
                ...getTextStyle("/about-us"),
                textDecoration: "none",
              }}
              fontSize={contentFontSize_18}
              component={Link}
              to="/about-us"
            >
              About Us
            </Typography>
            <Typography
              sx={{
                mx: 2,
                cursor: "pointer",
                ...getTextStyle("/services"),
                textDecoration: "none",
              }}
              fontSize={contentFontSize_18}
              component={Link}
              to="/services"
            >
              Services
            </Typography>
            <Typography
              sx={{
                mx: 2,
                cursor: "pointer",
                ...getTextStyle("/industries"),
                textDecoration: "none",
              }}
              fontSize={contentFontSize_18}
              component={Link}
              to="/industries"
            >
              Industries
            </Typography>
            <Typography
              sx={{
                mx: 2,
                cursor: "pointer",
                ...getTextStyle("/blogs"),
                textDecoration: "none",
              }}
              fontSize={contentFontSize_18}
              component={Link}
              to="/blogs"
            >
              Blogs
            </Typography>
            <Typography
              sx={{
                mx: 2,
                cursor: "pointer",
                ...getTextStyle("/careers"),
                textDecoration: "none",
              }}
              fontSize={contentFontSize_18}
              component={Link}
              to="/careers"
            >
              Careers
            </Typography>
            <Typography
              sx={{
                mx: 2,
                cursor: "pointer",
                ...getTextStyle("/contact"),
                textDecoration: "none",
              }}
              fontSize={contentFontSize_18}
              component={Link}
              to="/contact"
            >
              Contact
            </Typography>
          </Box>
          {/* </div> */}
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            aria-label="menu"
            sx={{ display: { xs: "flex", md: "none" } }}
            onClick={handleMenuOpen}
          >
            <MenuIcon />
          </IconButton>

          <Menu
            anchorEl={anchorEl}
            open={isMenuOpen}
            onClose={handleMenuClose}
            sx={{ display: { xs: "block", md: "none" } }}
          >
            <MenuItem
              onClick={handleMenuClose}
              component={Link}
              to="/"
              sx={{ ...getTextStyle("/"), textDecoration: "none" }}
            >
              Home
            </MenuItem>
            <MenuItem
              onClick={handleMenuClose}
              component={Link}
              to="/about-us"
              sx={{ ...getTextStyle("/about-us"), textDecoration: "none" }}
            >
              About Us
            </MenuItem>
            <MenuItem
              onClick={handleMenuClose}
              component={Link}
              to="/services"
              sx={{ ...getTextStyle("/services"), textDecoration: "none" }}
            >
              Services
            </MenuItem>
            <MenuItem
              onClick={handleMenuClose}
              component={Link}
              to="/industries"
              sx={{ ...getTextStyle("/industries"), textDecoration: "none" }}
            >
              Industries
            </MenuItem>
            <MenuItem
              onClick={handleMenuClose}
              component={Link}
              to="/blogs"
              sx={{ ...getTextStyle("/blogs"), textDecoration: "none" }}
            >
              Blogs
            </MenuItem>
            <MenuItem
              onClick={handleMenuClose}
              component={Link}
              to="/careers"
              sx={{ ...getTextStyle("/careers"), textDecoration: "none" }}
            >
              Careers
            </MenuItem>
            <MenuItem
              onClick={handleMenuClose}
              component={Link}
              to="/contact"
              sx={{ ...getTextStyle("/contact"), textDecoration: "none" }}
            >
              Contact
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Navbar;