import { Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { contentFontSize_20, contentFontSize_50 } from "../../Typography";
import Common from "../../utils/images/CareerSub.png";

function CareerImage() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <div
      className="bg-img"
      style={{
        backgroundImage: `url(${Common})`,
        backgroundSize: "cover",
        position: "relative",
        color: "white",
        padding: "20px",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="blog-text1">
        {matches ? (
          <Typography
            fontSize={contentFontSize_50}
            fontWeight="700"
            sx={{
              marginBottom: "10px",
              color: "#FFD700",
              marginTop: smmatch ? "0px" : matches ? "50px" : "160px",
              padding: matches ? "10px 0px 10px 40px" : "0px",
              textAlign: matches ? "center" : null,
            }}
          >
            A Culture of Innovation
          </Typography>
        ) : (
          <Typography
            fontSize={contentFontSize_50}
            fontWeight="700"
            sx={{
              marginBottom: "10px",
              color: "#FFD700",
              marginTop: smmatch ? "0px" : matches ? "50px" : "160px",
              padding: matches ? "10px 0px 10px 40px" : "0px",
              textAlign: matches ? "center" : null,
            }}
          >
            A Culture of Innovation
          </Typography>
        )}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: matches && "center",
          padding: smmatch ? "0px" : "10px 40px",
          flexDirection: lgmatch ? "column" : "row",
        }}
      >
        <div style={{ width: "70%" }}>
          <Typography
            //fontFamily="Roboto"
            fontWeight="400"
            fontSize={contentFontSize_20}
            style={{
              textAlign: matches ? "center" : "left",
              lineHeight: "1.6",
              marginBottom: "20px",
              color: "#FFFFFF",
            }}
          >
            Our inclusive work environment fosters collaboration, creativity,
            and continuous growth. Team members are encouraged to think outside
            the box, explore new ideas, and contribute to meaningful projects
            that make an impact.
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default CareerImage;
