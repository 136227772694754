import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import Navbar from "../Navbar";
import Footer from "../Footer/Footer";
import "./Privacy.css";

const termsData = [
  {
    section: "1. Information We Collect",
    content: [
      "We collect various types of information to provide you with a seamless and personalized experience. The types of information we may collect include:",
      {
        subsection: "A. Personal Information",
        content:
          "This includes your name, email address, phone number, company name, job title, and any other information you voluntarily provide when submitting forms, signing up for our services, or engaging with us via email.",
      },
      {
        subsection: "B. Usage Data",
        content:
          "We collect data on how you access and use our website, such as IP addresses, browser type, operating system, referring URLs, and the pages you visit. This helps us understand how visitors interact with our site and improve the user experience.",
      },
      {
        subsection: "C. Cookies and Tracking Technologies",
        content:
          "We use cookies and similar technologies (e.g., web beacons, pixels) to collect information about your activity on our website. This allows us to enhance your user experience and tailor our content and advertisements. You can control cookies through your browser settings.",
      },
    ],
  },
  {
    section: "2. How We Use Your Information",
    content: [
      "To provide and improve our services: To personalize content, enhance user experience, and provide you with the most relevant information and updates.",
      "To communicate with you: We may use your contact details to send you newsletters, promotional offers, and other marketing materials, with your consent.",
      "To process applications: If you apply for a job or submit an RFP, we will use your data to evaluate your application and contact you regarding job opportunities or business proposals.",
      "To monitor website performance: To analyze trends and user behavior, helping us improve the website and resolve issues.",
      "To comply with legal obligations: To adhere to applicable laws and regulations or to protect our legal rights.",
    ],
  },
  {
    section: "3. How We Protect Your Information",
    content:
      "We take reasonable steps to protect the personal information we collect from unauthorized access, disclosure, alteration, and destruction. We use industry-standard security measures such as encryption, firewalls, and secure servers to safeguard your data. However, no method of transmission over the Internet or electronic storage is 100% secure, so while we strive to protect your personal information, we cannot guarantee its absolute security.",
  },
  {
    section: "4. Sharing Your Information",
    content: [
      "We do not sell, rent, or trade your personal information to third parties. However, we may share your information with trusted third-party service providers who help us operate our website and provide services to you. These parties are obligated to protect your data and use it only for the purposes specified by Axia Kairos.",
      "We may also disclose your information if required by law or to protect the rights, property, or safety of Axia Kairos, our users, or others.",
    ],
  },
  {
    section: "5. Your Rights",
    content: [
      "Accessing your data: You can request to view or obtain a copy of the personal data we hold about you.",
      "Correcting your data: You can request that we correct or update any inaccurate or incomplete personal data.",
      "Deleting your data: You can request the deletion of your personal data, subject to certain legal exceptions.",
      "Opting out of communications: You can unsubscribe from marketing communications at any time by clicking the 'unsubscribe' link in our emails or contacting us directly.",
    ],
  },
  {
    section: "6. Third-Party Links",
    content:
      "Our website may contain links to third-party websites. Please note that this Privacy Policy only applies to Axia Kairos and does not extend to other websites we link to. We encourage you to review the privacy policies of those third-party sites to understand how they collect, use, and protect your information.",
  },
  {
    section: "7. Children’s Privacy",
    content:
      "Axia Kairos does not knowingly collect personal information from children under the age of 13. If we become aware that we have inadvertently collected such information, we will take steps to delete it as quickly as possible.",
  },
  {
    section: "8. Updates to This Privacy Policy",
    content:
      "We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. When we make changes, we will update the 'Effective Date' at the top of the policy. We encourage you to review this page periodically to stay informed about how we are protecting your information.",
  },
  {
    section: "9. Contact Us",
    content: {
      Email: "reach@axiakairos.com",
      Phone: "+1 437 702 2997",
      Address: "34 Minowan Miikan Lane, Toronto, ON, M6J0G3, Canada",
    },
  },
];

const Privacypolicy = () => {
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <React.Fragment>
      <Navbar />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        <Grid item xs={11}>
          <h1 className="mainHeading">Privacy Policy</h1>
        </Grid>
        <Grid item xs={10}>
          {termsData.map((section, index) => (
            <Box key={index} mb={4} sx={{ marginTop: "20px" }}>
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  fontSize: {
                    xs: "18px",
                    sm: "20px",
                    md: "22px",
                  },
                }}
                className="termsTitle"
              >
                {section.section}
              </Typography>
              {Array.isArray(section.content) ? (
                section.content.map((item, i) =>
                  typeof item === "string" ? (
                    <Typography
                      key={i}
                      paragraph
                      className="description"
                      sx={{
                        fontSize: {
                          xs: "13px",
                          sm: "16px",
                          md: "20px",
                        },
                      }}
                    >
                      {item}
                    </Typography>
                  ) : (
                    <Box key={i} mb={2}>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          fontSize: {
                            xs: "14px",
                            sm: "16px",
                            md: "18px",
                          },
                          textAlign: "left",
                        }}
                      >
                        {item.subsection}
                      </Typography>
                      <Typography
                        className="description"
                        sx={{
                          fontSize: {
                            xs: "13px",
                            sm: "16px",
                            md: "20px",
                          },
                        }}
                      >
                        {item.content}
                      </Typography>
                    </Box>
                  )
                )
              ) : (
                <Typography
                  paragraph
                  className="description"
                  sx={{
                    fontSize: {
                      xs: "13px",
                      sm: "16px",
                      md: "20px",
                    },
                  }}
                >
                  {typeof section.content === "object"
                    ? Object.entries(section.content).map(([key, value]) => (
                        <div key={key}>
                          <strong>{key}: </strong>
                          {value}
                        </div>
                      ))
                    : section.content}
                </Typography>
              )}
            </Box>
          ))}
        </Grid>
        <Grid item xs={10}>
          <Typography
            paragraph
            className="description"
            sx={{
              fontSize: {
                xs: "13px",
                sm: "16px",
                md: "20px",
              },
            }}
          >
            This privacy policy template outlines the key aspects of data
            collection, usage, and protection practices while ensuring
            transparency and compliance. Feel free to make adjustments based on
            your specific business operations or legal advice.
          </Typography>
        </Grid>
      </Grid>
      <Footer />
    </React.Fragment>
  );
};

export default Privacypolicy;
