//Help Page
export const HelpCall = { xs: 16, sm: 16, md: 17, lg: 18, xl: 18 };

export const HelpSupport = { xs: 13, sm: 14, md: 15, lg: 16, xl: 16 };

export const getHelp = { xs: 36, sm: 37, md: 38, lg: 39, xl: 40 };

//Profile Page
export const ProfileFont = { xs: 16, sm: 17, md: 18, lg: 19, xl: 20 };
export const ProfileNameFont = { xs: 20, sm: 21, md: 22, lg: 23, xl: 24 };

//Profile Update Page
export const ProfileUpdateButton = { xs: 12, sm: 13, md: 14, lg: 16, xl: 18 };

//HeaderFontsize
export const HeaderFontSize_52 = { xs: 25, sm: 30, md: 42, lg: 48, xl: 52 };
export const HeaderFontSize_50 = { xs: 27, sm: 28, md: 30, lg: 45, xl: 50 };
export const HeaderFontSize_50_xs_33 = {
  xs: 29,
  sm: 44,
  md: 46,
  lg: 48,
  xl: 50,
};
export const HeaderFontSize_48 = { xs: 40, sm: 42, md: 44, lg: 46, xl: 48 };
export const HeaderFontSize_46 = { xs: 39, sm: 41, md: 42, lg: 44, xl: 46 };
export const HeaderFontSize_45 = { xs: 38, sm: 40, md: 41, lg: 43, xl: 45 };
export const HeaderFontSize_42 = { xs: 20, sm: 25, md: 30, lg: 35, xl: 42 };
export const HeaderFontSize_40 = { xs: 36, sm: 37, md: 38, lg: 39, xl: 40 };
export const HeaderFontSize_40_xs_20 = {
  xs: 30,
  sm: 33,
  md: 36,
  lg: 38,
  xl: 40,
};
export const HeaderfontSize_30 = { xs: 20, sm: 23, md: 26, lg: 28, xl: 30 };
export const HeaderFontsize_36 = { xs: 32, sm: 33, md: 34, lg: 35, xl: 36 };
export const HeaderFontSize_36_xs_25 = {
  xs: 25,
  sm: 28,
  md: 32,
  lg: 34,
  xl: 36,
};
export const HeaderFontSize_24 = { xs: 20, sm: 21, md: 22, lg: 23, xl: 24 };
export const HeaderFontSize_24_xs_17 = {
  xs: 17,
  sm: 18,
  md: 20,
  lg: 23,
  xl: 24,
};
export const HeaderFontSize_25 = { xs: 17, sm: 19, md: 20, lg: 23, xl: 25 };

//HeaderNavigationFontSize
export const HeaderNavigationFontSize_22 = {
  xs: 17,
  sm: 18,
  md: 19,
  lg: 20,
  xl: 22,
};
export const HeaderNavigationFontSize_20 = {
  xs: 16,
  sm: 17,
  md: 18,
  lg: 19,
  xl: 20,
};
export const HeaderNavigationFontSize_12 = {
  xs: 9,
  sm: 9,
  md: 10,
  lg: 11,
  xl: 12,
};
export const HeaderNavigationFontSize_20_xs_13 = {
  xs: 13,
  sm: 15,
  md: 16,
  lg: 18,
  xl: 20,
};

//contentFontsize
export const contentFontSize_22 = { xs: 15, sm: 18, md: 19, lg: 20, xl: 22 };
export const contentFontSize_20 = { xs: 17, sm: 18, md: 18, lg: 19, xl: 20 };
export const contentFontSize_20_xs_17 = {
  xs: 15,
  sm: 16,
  md: 17,
  lg: 18,
  xl: 20,
};
export const contentFontSize_20_xs_18 = {
  xs: 15,
  sm: 16,
  md: 17,
  lg: 18,
  xl: 18,
};
export const contentFontSize_18 = { xs: 15, sm: 16, md: 17, lg: 18, xl: 18 };
export const contentFontSize_16 = { xs: 13, sm: 14, md: 15, lg: 16, xl: 16 };
export const contentFontSize_16_xs_10 = {
  xs: 10,
  sm: 12,
  md: 14,
  lg: 15,
  xl: 16,
};
export const contentFontSize_15 = { xs: 10, sm: 11, md: 12, lg: 14, xl: 15 };
export const contentFontSize_18_xs_13 = {
  xs: 13,
  sm: 14,
  md: 16,
  lg: 17,
  xl: 18,
};
export const contentFontsize_14 = { xs: 12, sm: 12, md: 13, lg: 13, xl: 14 };
export const contentFontsize_14_xs_10 = {
  xs: 10,
  sm: 11,
  md: 12,
  lg: 13,
  xl: 14,
};
export const contentFontsize_18_xs_11 = {
  xs: 11,
  sm: 13,
  md: 16,
  lg: 17,
  xl: 18,
};
export const contentFontsize_30 = { xs: 22, sm: 22, md: 22, lg: 30, xl: 30 };
export const contentFontsize_30_xs_20 = {
  xs: 18,
  sm: 18,
  md: 18,
  lg: 28,
  xl: 30,
};
export const contentFontsize_28 = { xs: 22, sm: 22, md: 22, lg: 28, xl: 28 };
export const contentFontsize_36 = { xs: 22, sm: 28, md: 30, lg: 36, xl: 36 };
export const contentFontsize_24 = { xs: 20, sm: 22, md: 22, lg: 24, xl: 24 };
export const contentFontsize_17 = { xs: 15, sm: 15, md: 16, lg: 17, xl: 17 };
export const contentFontsize_17_xs_12 = {
  xs: 12,
  sm: 13,
  md: 15,
  lg: 16,
  xl: 17,
};
export const contentFontSize_48 = { xs: 25, sm: 28, md: 30, lg: 35, xl: 48 };
export const contentFontSize_50 = { xs: 30, sm: 35, md: 40, lg: 45, xl: 50 };
export const contentFontsize_40 = { xs: 30, sm: 33, md: 35, lg: 38, xl: 40 };
export const contentFontSize_36 = { xs: 28, sm: 30, md: 32, lg: 34, xl: 36 };
export const contentFontsize_64 = { xs: 40, sm: 45, md: 50, lg: 55, xl: 64 };
export const contentFontsize_96 = { xs: 70, sm: 80, md: 85, lg: 90, xl: 96 };
export const contentFontsize_55 = { xs: 40, sm: 42, md: 48, lg: 50, xl: 55 };
//ButtonFontSize
export const ButtonFontSize_16 = { xs: 13, sm: 14, md: 15, lg: 16, xl: 16 };
export const ButtonFontSize_18 = { xs: 12, sm: 13, md: 14, lg: 16, xl: 18 };
export const ButtonFontSize_18_xs_10 = {
  xs: 10,
  sm: 13,
  md: 14,
  lg: 16,
  xl: 18,
};
export const ButtonFontSize_17 = { xs: 11, sm: 13, md: 14, lg: 15, xl: 17 };
export const ButtonFontSize_16_xs_11 = {
  xs: 11,
  sm: 13,
  md: 14,
  lg: 15,
  xl: 16,
};
