import React from "react";
import Navbar from "../Navbar";
import IndustriesHero from "./IndustriesMain/IndustriesHero";
import IndustriesCard from "./IndustriesMain/IndustriesCard";
import Footer from "../Footer/Footer";

function Industries() {
  return (
    <>
      <Navbar />
      <IndustriesHero />
      <Footer />
    </>
  );
}

export default Industries;
