import React from "react";
import Navbar from "../Navbar";
import ContactUs from "./ContactUs/ContactUs";
import FAQ from "./ContactUs/FAQ";
import ContactImage from "./ContactUs/ContactImage";
import ContactFaq from "./ContactUs/ContactFaq";
import Footer from "../Footer/Footer";

function Contact() {
  return (
    <>
      <Navbar />
      <ContactImage />
      <ContactUs />
      <FAQ />
      <ContactFaq />
      <Footer/>
    </>
  );
}

export default Contact;
