import React from "react";
import "./FourthPage.css";
import { Card, Typography, useMediaQuery, useTheme } from "@mui/material";
import {
  contentFontSize_15,
  contentFontSize_18,
  contentFontSize_20,
  contentFontSize_22,
  contentFontsize_24,
  contentFontsize_55,
} from "../../Typography";
function FourthPage() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <div>
      <div className="main-fourth">
        <Typography
          fontSize={contentFontsize_55}
          fontWeight="700"
          fontFamily="Roboto"
        >
          Beyond Business
        </Typography>
      </div>
      <div className="main-fourth-sub">
        <Typography
          fontWeight="500"
          fontSize={contentFontSize_20}
          fontFamily="Roboto"
        >
          Our Commitment to Impact
        </Typography>
      </div>
      <div className="main-fourth-content">
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          fontFamily="Roboto"
          sx={{ textAlign: "center", color: "#6C7282" }}
        >
          At Axia Kairos, we view technology as a catalyst for positive change.
          Our commitment extends beyond business success to creating value that
          impacts all areas of life.
        </Typography>
      </div>
      <div className="secnd-sec-4">
        <div className="flex-card-in-4">
          <Card
            className="about-card-4"
            sx={{ borderRadius: "15px", background: "#B97E12" }}
          >
            <div className="about-content-card-4">
              <Typography
                fontWeight="700"
                fontSize={contentFontsize_24}
                fontFamily="Roboto"
                className="about-card-title-4"
              >
                Empowering Growth
              </Typography>
              <Typography
                fontWeight="400"
                fontSize={contentFontSize_18}
                fontFamily="Roboto"
                lineHeight="31px"
                className="about-content-1-4"
              >
                We help businesses scale sustainably, ensuring long-term success
                and profitability.
              </Typography>
            </div>
          </Card>
          <Card
            className="about-card-4"
            sx={{ borderRadius: "15px", background: "#B97E12" }}
          >
            <div className="about-content-card-4">
              <Typography
                fontWeight="700"
                fontSize={contentFontsize_24}
                fontFamily="Roboto"
                className="about-card-title-4"
              >
                Creating Meaningful Connections
              </Typography>
              <Typography
                fontWeight="400"
                fontSize={contentFontSize_18}
                fontFamily="Roboto"
                lineHeight="31px"
                className="about-content-1-4"
              >
                Our solutions enhance the customer experience, fostering
                stronger, more connected relationships.
              </Typography>
            </div>
          </Card>
          <Card
            className="about-card-4"
            sx={{ borderRadius: "15px", background: "#B97E12" }}
          >
            <div className="about-content-card-4">
              <Typography
                fontWeight="700"
                fontSize={contentFontsize_24}
                fontFamily="Roboto"
                className="about-card-title-4"
              >
                Driving Social Impact
              </Typography>
              <Typography
                fontWeight="400"
                fontSize={contentFontSize_18}
                fontFamily="Roboto"
                lineHeight="31px"
                className="about-content-1-4"
              >
                We believe in using technology to make a tangible difference,
                improving communities and society at large
              </Typography>
            </div>
          </Card>
          <Card
            className="about-card-4"
            sx={{ borderRadius: "15px", background: "#B97E12" }}
          >
            <div className="about-content-card-4">
              <Typography
                fontWeight="700"
                fontSize={contentFontsize_24}
                fontFamily="Roboto"
                className="about-card-title-4"
              >
                Building a Better Tomorrow
              </Typography>
              <Typography
                fontWeight="400"
                fontSize={contentFontSize_18}
                fontFamily="Roboto"
                lineHeight="31px"
                className="about-content-1-4"
              >
                Our innovations are designed to drive both business growth and
                positive global change, with a focus on responsibility,
                sustainability, and inclusion.
              </Typography>
            </div>
          </Card>
        </div>
      </div>
      <div>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          fontFamily="Roboto"
          className="end-content"
          sx={{ marginBottom: "60px" }}
        >
          At Axia Kairos, we aim to deliver results that matter—helping you grow
          while making a lasting, positive impact on the world around you.
        </Typography>
      </div>
    </div>
  );
}

export default FourthPage;
