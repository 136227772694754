import React from "react";
import "./SecondPage.css";
import {
  contentFontSize_15,
  contentFontSize_16,
  contentFontSize_18,
  contentFontsize_24,
  contentFontsize_96,
  HeaderFontSize_50_xs_33,
} from "../../Typography";
import { Typography } from "@mui/material";
import Line from "../../utils/images/Line.jpg"

function SecondPage() {
  const data = [
    {
      id: "01",
      title: "Pioneers in Transformation",
      content:
        "We are at the forefront of leveraging digital technologies to drive meaningful change. Whether it’s adopting cloud solutions, integrating AI, or enhancing user experiences, we help businesses turn ideas into reality as a trusted business IT solutions provider.",
    },
    {
      id: "02",
      title: "Personalized for You",
      content:
        "No two businesses are the same, and neither are our solutions. We tailor every strategy, platform, and process to your unique goals and challenges, ensuring maximum impact.",
    },
    {
      id: "03",
      title: "Built for Long-Term Success",
      content:
        "Our solutions aren’t just designed for today—they’re crafted to evolve with you, ensuring lasting value in an ever-changing landscape.",
    },
    {
      id: "04",
      title: "Driven by Expertise",
      content:
        "Our team of seasoned professionals brings together global experience, industry knowledge, and a passion for innovation to deliver results that matter.",
    },
  ];

  return (
    <div className="about-us-second">
      <div className="main-heading-about-us">
        <Typography
          sx={{ fontWeight: "700", fontFamily: "Roboto", marginTop: "50px" }}
          fontSize={HeaderFontSize_50_xs_33}
        >
          Why Axia Kairos?
        </Typography>
      </div>
      <div className="about-us-div">
        {data.map((item) => (
          <div className="about-us-card" key={item.id}>
            <Typography
              fontSize={contentFontsize_96}
              fontFamily="Roboto"
              fontWeight="100"
              sx={{ color: "#EBB81A", lineHeight: "98px",}}
            >
              {item.id}
            </Typography>
            <Typography
              sx={{
                color: "#ECECEC",
                textAlign: "left",
                lineHeight: "31px",
                marginBottom: "10px",
              }}
              fontSize={contentFontsize_24}
              fontFamily="Roboto"
            >
              {item.title}
            </Typography>
            <Typography
              sx={{
                color: "#ECECEC",
                textAlign: "left",
                lineHeight: "31px",
                fontWeight:"400"
              }}
              fontSize={contentFontSize_18}
              fontFamily="Roboto"
            >
              {item.content}
            </Typography>
            {/* <div className="imge-section-about-us">
              <img src={Line}/>
            </div> */}
          </div>
        ))}
      </div>
    </div>
  );
}

export default SecondPage;
