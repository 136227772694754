import { Typography } from "@mui/material";
import React, { useState } from "react";
import {
  contentFontSize_16,
  contentFontSize_20,
  HeaderFontSize_40,
  HeaderFontSize_40_xs_20,
} from "../../Typography";
import "./FAQ.css";
import Plus from "../../utils/icons/PlusIcon.svg";
import Less from "../../utils/icons/LessIcon.svg";

function FAQ() {
  const [openIndexes, setOpenIndexes] = useState([]);

  const faqData = [
    {
      question:
        "How can I request a consultation for digital transformation services?",
      answer: {
        main: "There are two easy ways to request a consultation:",
        sub: [
          "Fill out our convenient contact form above.",
          "Contact us directly at reach@axiakairos.com",
        ],
      },
    },
    {
      question: "Which industries does Axia Kairos serve?",
      answer: {
        main: "We serve various industries through tailored digital transformation solutions.",
      },
    },
    {
      question: "How quickly can I expect a response from Axia Kairos?",
      answer: {
        main: "We prioritize prompt communication.",
      },
    },
  ];

  const toggleSection = (index) => {
    setOpenIndexes((prev) => {
      if (prev.includes(index)) {
        // Close the section if already open
        return prev.filter((item) => item !== index);
      } else {
        // Open the section
        return [...prev, index];
      }
    });
  };

  return (
    <div className="faq-screen">
      <div className="faq-content">
        <Typography
          fontSize={HeaderFontSize_40_xs_20}
          style={{ fontWeight: "600" }}
        >
          FAQs: Your Questions Answered
        </Typography>
        <Typography fontSize={contentFontSize_16} style={{ fontWeight: "600" }}>
          Looking for quick answers?
          <span style={{ fontWeight: "500" }}>
            We've compiled a list of frequently asked questions to help you:
          </span>
        </Typography>
      </div>
      <div className="faq-questions">
        {faqData.map((item, index) => (
          <div key={index} className="faq-question-item">
            {/* Question */}
            <div className="faq-question">
              <Typography
                fontSize={contentFontSize_20}
                style={{ fontWeight: "600" }}
              >
                Q: {item.question}
              </Typography>
              <img
                src={openIndexes.includes(index) ? Less : Plus}
                className="icon-pointer"
                onClick={() => toggleSection(index)}
                alt="Toggle Icon"
              />
            </div>

            {/* Main Answer and Sub-Answers */}
            {openIndexes?.includes(index) && (
              <div className="faq-answer">
                {/* Main Answer */}
                <Typography
                  fontSize={contentFontSize_20}
                  style={{ fontWeight: "500", marginBottom: "8px" }}
                >
                  A: {item?.answer?.main}
                </Typography>

                {/* Sub-Answers as a Numbered List */}
                <Typography
                  fontSize={contentFontSize_20}
                  style={{ fontWeight: "400" }}
                >
                  {item?.answer?.sub && (
                    <ol>
                      {item.answer.sub.map((subAnswer, i) => (
                        <li key={i}>{subAnswer}</li>
                      ))}
                    </ol>
                  )}
                </Typography>
              </div>
            )}
            {index !== faqData.length - 1 && (
              <div className="border-bottom"></div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default FAQ;
