import React, { useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import {
  contentFontSize_16,
  contentFontSize_18,
  contentFontSize_20,
  contentFontsize_24,
  contentFontsize_28,
  contentFontSize_50,
  contentFontsize_96,
  HeaderFontSize_50_xs_33,
} from "../../Typography";
import Common from "../../utils/images/Common.jpg";
import "./DigitalTransformation.css";
import Navbar from "../../Navbar";
import ECommerceS from "../../utils/images/ECommerceS.png";
import WhiteArrow from "../../utils/icons/WhiteArrow.svg"
import { useNavigate } from "react-router-dom";
import Footer from "../../Footer/Footer";
function ECommerce() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
    const nav = useNavigate()
  function handleService(){
      nav("/services")
  }
  const cards = [
    {
      title: "Increased Sales & Conversion Rates",
      description:
        "Our customized e-commerce solutions are designed to improve the user experience and simplify the buying process, driving higher sales and conversions. ",
    },
    {
      title: "Scalable & Flexible Solutions",
      description:
        "As your business grows, so does your e-commerce platform. We build scalable solutions that can handle increased traffic, more products, and larger customer bases without compromising performance.  ",
    },
    {
      title: "Improved Customer Experience",
      description:
        "We focus on creating intuitive, easy-to-navigate platforms that enhance the shopping experience, building customer loyalty and encouraging repeat business.",
    },
    {
      title: "Comprehensive Analytics & Insights",
      description:
        "Our e-commerce solutions provide advanced reporting and analytics and reporting tools that help you track your performance, optimize your strategy, and make data-driven decisions to grow your business. ",
    },
  ];
  const data = [
    {
      id: "01",
      title: "Pioneers in Transformation",
      content:
        "We are at the forefront of leveraging digital technologies to drive meaningful change. Whether it’s adopting cloud solutions, integrating AI, or enhancing user experiences, we help businesses turn ideas into reality as a trusted business IT solutions provider.",
    },
    {
      id: "02",
      title: "Personalized for You",
      content:
        "No two businesses are the same, and neither are our solutions. We tailor every strategy, platform, and process to your unique goals and challenges, ensuring maximum impact.",
    },
    {
      id: "03",
      title: "Built for Long-Term Success",
      content:
        "Our solutions aren’t just designed for today—they’re crafted to evolve with you, ensuring lasting value in an ever-changing landscape.",
    },
    {
      id: "04",
      title: "Driven by Expertise",
      content:
        "Our team of seasoned professionals brings together global experience, industry knowledge, and a passion for innovation to deliver results that matter.",
    },
  ];

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));
  const dataService = [
    {
      title: "Consultation & Needs Assessment",

      description:
        "We begin by understanding your business, target audience, and e-commerce goals. Our experts assess your current systems and define the scope of the project, ensuring that the e-commerce solution aligns with your vision.  ",
    },
    {
      title: "E-Commerce Strategy & Design",

      description:
        "We create a customized e-commerce strategy that focuses on user experience, seamless navigation, and conversion optimization. Our design team ensures your platform is visually appealing and intuitive.  ",
    },
    {
      title: "Platform Development & Integration",

      description:
        "We develop your e-commerce platform using the latest technologies, ensuring it’s scalable and secure. We integrate necessary tools such as payment gateways, CRMs, and inventory management systems.  ",
    },
  ];
  const dataService1 = [
    {
      title: "Testing & Quality Assurance ",

      description:
        "Before launch, we rigorously test your platform across different devices and browsers to ensure a seamless shopping experience. We check for bugs, payment processing issues, and security vulnerabilities.  ",
    },
    {
      title: "Launch & Post-Launch Support",

      description:
        "We ensure a smooth deployment of your e-commerce platform and provide ongoing support post-launch to resolve any issues and provide updates as needed. ",
    },
  ];
  function handleContact(){
    nav("/contact")
  }
  return (
    <div>
      <Navbar />
      <div
        className="bg-img-1"
         style={{
                  backgroundImage: `url(${ECommerceS})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  //height: "500px",
                  position: "relative",
                  color: "white",
                  padding: smmatch ? "0px" : "20px", // Add spacing for responsiveness
                  backgroundRepeat: "no-repeat",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: smmatch ? "center" : "flex-start", // Center for small screens
                }}
      >
         <div className="back-btn" onClick={handleService}>
                                <img src={WhiteArrow} style={{ marginTop:smmatch ? "18px" : mdmatch && "4px"}} />
                                <span
                                  style={{
                                    marginLeft: "10px",
                                    fontSize: contentFontSize_18,
                                    fontFamily: "Roboto",
                                    marginBottom: "3px",
                                     marginTop:smmatch && "15px"
                                  }}
                                >
                                  Back to Services
                                </span>
                              </div>
        <div className="blog-text1">
          {/* {matches ? ( */}
          <div>
            <Typography
              fontSize={contentFontSize_50}
              fontWeight="700"
              sx={{
                marginBottom: "10px",
                color: "w",
                marginTop: smmatch ? "0px" : matches ? "50px" : "300px",
                padding: matches ? "50px 0px 10px 40px" : "0px",
                textAlign: matches ? "center" : null,
              }}
            >
              E-Commerce Solutions: Build Scalable, Secure, and Engaging Online
              Stores
            </Typography>
            <div>
              <Typography
                fontSize={contentFontSize_20}
                fontWeight="400"
                fontFamily="Roboto"
                sx={{
                  padding: matches ? "10px 0px 10px 40px" : "0px",
                  textAlign: matches ? "center" : null,
                }}
              >
                Empower Your Business with Seamless, High-Performance E-Commerce
                Platforms
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div className="service-sub-content">
        <Typography
          fontFamily="Roboto"
          fontWeight="400"
          fontSize={contentFontSize_18}
          sx={{ textAlign: "justify", color: "#6C7282" }}
        >
          At Axia Kairos, we specialize in delivering fully customized
          e-commerce solutions that drive sales, enhance customer experiences,
          and improve operational efficiency. Whether you need a robust B2B or
          B2C store, our team is dedicated to building customized e-commerce
          solutions for businesses tailored to your specific needs, ensuring
          your business thrives in the digital world.
        </Typography>
        <Typography
          fontFamily="Roboto"
          fontSize={contentFontsize_28}
          fontWeight="700"
          color="#B97E12"
          sx={{ marginTop: "15px" }}
        >
          What We Do and Offer
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Custom E-Commerce Website Development
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          We design and develop fully customized e-commerce websites that
          reflect your brand, products, and business goals. Our websites are
          built to be responsive, user-friendly, and optimized for speed and
          performance, ensuring a seamless shopping experience across all
          devices.
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Shopping Cart Integration
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          We integrate secure and reliable shopping cart solutions that make it
          easy for customers to browse, select, and purchase products. Our carts
          are designed to be intuitive and support a variety of payment
          gateways, ensuring a smooth checkout process.
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Payment Gateway Integration
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Ensure that your online store can securely process transactions with
          our integrated payment solutions. We work with trusted payment
          gateways such as PayPal, Stripe, and others to provide customers with
          a safe and seamless payment experience.
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Product Catalog & Inventory Management
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Manage your product listings, categories, and inventory with ease. We
          implement intuitive inventory management systems that allow you to
          efficiently organize and update your products, track stock levels, and
          ensure your online store runs smoothly.
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Customer Relationship Management (CRM) Integration
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Enhance customer engagement by integrating a CRM system into your
          e-commerce platform. This integration helps you better understand your
          customers, manage relationships, and create personalized marketing
          campaigns that drive sales and customer loyalty.
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          E-Commerce SEO Optimization
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Maximize your online visibility and attract more customers with our
          e-commerce SEO optimization services. We ensure your online store is
          optimized for search engines, increasing your chances of ranking high
          on search results and attracting organic traffic.
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Mobile Commerce (M-Commerce)
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Reach your customers on any device with our mobile-first e-commerce
          solutions. We create mobile-friendly designs and applications that
          allow customers to browse and make purchases seamlessly, whether
          they’re using a smartphone, tablet, or desktop.
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          E-Commerce Analytics & Reporting
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Track your sales, conversions, and customer behavior with powerful
          e-commerce analytics and reporting tools. Our team implements advanced
          reporting systems that provide actionable insights, helping you make
          data-driven decisions to grow your business.
        </Typography>
        <Typography
          fontFamily="Roboto"
          fontSize={contentFontsize_28}
          fontWeight="700"
          color="#B97E12"
          sx={{ marginTop: "15px" }}
        >
          Additional Services in Data Analytics
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Multi-Channel Selling Integration
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Expand your reach by selling across multiple platforms like Amazon,
          eBay, and social media. We integrate your e-commerce store with these
          platforms, enabling you to manage orders and inventory in one place
          while reaching a broader audience.
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Subscription-Based E-Commerce Solutions
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Set up subscription-based models where customers can subscribe to
          receive products on a recurring basis. We design subscription
          platforms that offer flexibility in billing cycles and product
          selections, providing a reliable revenue stream for your business.
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          E-Commerce App Development
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Bring the shopping experience to your customers' fingertips with
          custom e-commerce app development. Whether for iOS or Android, we
          create feature-rich e-commerce apps that provide users with a seamless
          and secure shopping experience on mobile devices.
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          E-Commerce Security Solutions
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Security is a top priority for any online business. We implement
          robust security measures, including SSL certificates, secure payment
          gateways, and fraud protection tools, to ensure your e-commerce store
          is safe and secure from cyber threats.
        </Typography>
        {/* <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          IT Infrastructure Modernization
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          We upgrade your IT environment with cutting-edge tools and solutions.
          Whether it’s on-premise, hybrid, or cloud infrastructure, our services
          ensure your systems are resilient, secure, and future-ready.
        </Typography> */}
      </div>
      <div className="about-us-second">
        <div className="main-heading-about-us">
          <Typography
            sx={{ fontWeight: "700", fontFamily: "Roboto", marginTop: "50px",textAlign:smmatch && "center" }}
            fontSize={HeaderFontSize_50_xs_33}
          >
            Why Choose Axia Kairos?
          </Typography>
        </div>
        <Typography
          fontSize={contentFontSize_20}
          fontWeight="400"
          color="white"
          fontFamily="Roboto"
          sx={{
            paddingLeft: mdmatch ? "10px" : "150px",
            paddingRight: mdmatch ? "10px" : "150px",
            textAlign: "center",
            paddingBottom: "50px",
          }}
        >
          Axia Kairos is a trusted partner for developing customized e-commerce
          solutions that help businesses grow and succeed online. We combine
          design expertise, cutting-edge technologies, and in-depth market
          knowledge to deliver e-commerce platforms that enhance user
          experiences and drive conversions. Whether you're building a new
          e-commerce website or optimizing an existing one, we provide tailored
          solutions that meet the specific needs of your business.
        </Typography>
        <div className="about-us-div">
          {data.map((item) => (
            <div className="about-us-card" key={item.id}>
              <Typography
                fontSize={contentFontsize_96}
                fontFamily="Roboto"
                fontWeight="100"
                sx={{ color: "#EBB81A", lineHeight: "98px" }}
              >
                {item.id}
              </Typography>
              <Typography
                sx={{
                  color: "#ECECEC",
                  textAlign: "left",
                  lineHeight: "31px",
                  marginBottom: "10px",
                }}
                fontSize={contentFontsize_24}
                fontFamily="Roboto"
              >
                {item.title}
              </Typography>
              <Typography
                sx={{
                  color: "#ECECEC",
                  textAlign: "left",
                  lineHeight: "31px",
                  fontWeight: "400",
                }}
                fontSize={contentFontSize_16}
                fontFamily="Roboto"
              >
                {item.content}
              </Typography>
              {/* <div className="imge-section-about-us">
                  <img src={Line}/>
                </div> */}
            </div>
          ))}
        </div>
      </div>
      <div className="third-page-service">
        <div style={{ marginTop: "50px", marginBottom: "50px" }}>
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              textAlign: "center",
            }}
            fontWeight="700"
            fontFamily="Roboto"
            fontSize={contentFontSize_50}
          >
            Our Proven Approach to E-Commerce Solutions
          </Typography>
        </div>
        <div className="secnd-sec">
          <div className="flex-card-in">
            {dataService.map((card, index) => (
              <Card
                className="about-card-service"
                key={index}
                sx={{ borderRadius: "15px", background: "#9F6907" }}
              >
                <div className="about-content-card-service">
                  <Typography
                    fontWeight="700"
                    fontSize={contentFontsize_24}
                    fontFamily="Roboto"
                    className="about-card-title"
                  >
                    {card.title}
                  </Typography>
                  <Typography
                    fontWeight="400"
                    fontSize={contentFontSize_18}
                    fontFamily="Roboto"
                    color="white"
                    className="about-content-1-service"
                  >
                    {card.description}
                  </Typography>
                </div>
              </Card>
            ))}
          </div>
        </div>
        <div className="secnd-sec">
          <div className="flex-card-in-1">
            {dataService1.map((card, index) => (
              <Card
                className="about-card-service-1"
                key={index}
                sx={{
                  borderRadius: "15px",
                  background: "#9F6907",
                  marginTop: "20px",
                }}
              >
                <div className="about-content-card-service">
                  <Typography
                    fontWeight="700"
                    fontSize={contentFontsize_24}
                    fontFamily="Roboto"
                    className="about-card-title"
                  >
                    {card.title}
                  </Typography>
                  <Typography
                    fontWeight="400"
                    fontSize={contentFontSize_18}
                    fontFamily="Roboto"
                    color="white"
                    className="about-content-1-service"
                  >
                    {card.description}
                  </Typography>
                </div>
              </Card>
            ))}
          </div>
        </div>
      </div>
      <div className="main-third">
        <div className="about-us-text1">
          <Typography
            fontFamily="Roboto"
            fontWeight="500"
            fontSize={contentFontSize_50}
            sx={{
              paddingTop: mdmatch ? "50px" : "200px",
              textAlign: matches && "center",
            }}
          >
            How Our E-Commerce Solutions Ensure Long-Term Success
          </Typography>
        </div>

        <div className="third-sub">
          {cards.map((card, index) => (
            <div
              key={index}
              //   ref={(el) => (cardRefs.current[index] = el)}
              data-index={index}
              //   className={`card-wrapper-about-us ${
              //     visibleCards.includes(index.toString()) ? "active" : ""
              //   }`}
              style={{
                display: "flex",
                flexDirection: mdmatch ? null : "row",
                justifyContent: mdmatch ? null : "space-between",
                width: mdmatch ? null : "100%",
              }}
            >
              <div
              // className={`side-item-about-us ${
              //   visibleCards.includes(index.toString()) ? "active" : ""
              // }`}
              ></div>

              <Card
                sx={{
                  borderRight: "5px solid #B97E12",
                  background: "#DFDFDF",
                  width: matches ? "100%" : "90%",
                  // color: "white",
                  transition: "all 0.5s ease",
                  height: mdmatch ? "100%" : "160px",
                  borderRadius: "10px",
                }}
                // className={`card-content ${
                //   visibleCards.includes(index.toString()) ? "active" : ""
                // }`}
              >
                <CardContent>
                  <div
                    className="card-title-about-us"
                    style={{ marginBottom: "10px" }}
                  >
                    <Typography
                      fontWeight="700"
                      fontSize={contentFontSize_20}
                      fontFamily="Roboto"
                      //color="black"
                    >
                      {card.title}
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      fontWeight="400"
                      fontSize={contentFontSize_18}
                      fontFamily="Roboto"
                      className="card-description-about-us"
                    >
                      {card.description}
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            </div>
          ))}
        </div>
      </div>
      <div
        className="bg-img"
        style={{
          backgroundImage: `url(${Common})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          // height: "500px",
          position: "relative",
          color: "white",
          padding: "20px", // Add spacing for responsiveness
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="blog-text1">
          {matches ? (
            <Typography
              fontSize={contentFontSize_50}
              fontWeight="700"
              sx={{
                marginBottom: "10px",
                color: "#FFD700",
                marginTop: smmatch ? "0px" : matches ? "50px" : "160px",
                padding: matches ? "10px 0px 10px 40px" : "0px",
                textAlign: matches ? "center" : null,
              }}
            >
              Build a Powerful E-Commerce Platform That Drives Sales and Growth
            </Typography>
          ) : (
            <Typography
              fontSize={contentFontSize_50}
              fontWeight="700"
              sx={{
                marginBottom: "10px",
                color: "#FFD700",
                marginTop: smmatch ? "0px" : matches ? "50px" : "160px",
                padding: matches ? "10px 0px 10px 40px" : "0px",
                textAlign: matches ? "center" : null,
              }}
            >
              Build a Powerful E-Commerce Platform That Drives Sales and Growth
            </Typography>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: matches && "center",
            padding: smmatch ? "0px" : "10px 40px",
            flexDirection: lgmatch ? "column" : "row",
          }}
        >
          <div style={{ width: "70%" }}>
            <Typography
              //fontFamily="Roboto"
              fontWeight="400"
              fontSize={contentFontSize_20}
              style={{
                textAlign: matches ? "center" : "left",
                lineHeight: "1.6",
                marginBottom: "20px",
                color: "#FFFFFF", // White paragraph text
              }}
            >
              Axia Kairos delivers customized e-commerce solutions that empower
              your business to succeed in the digital marketplace. From
              responsive websites to mobile apps and multi-channel integration,
              we ensure your e-commerce platform is built to grow with your
              business.
            </Typography>
          </div>
          <div style={{ marginTop: "10px" }}>
            <Button
              variant="contained"
              onClick={handleContact}
              sx={{
                backgroundImage:
                  "linear-gradient(90deg, #DFAD17 0%, #795E0C 100%)",
                width: "300px",
                height: "40px",
                borderRadius: "15px",
                // padding: "10px 20px",
                // fontSize: "16px",
                // backgroundColor: "#FFC107",
                // color: "white",
                // border: "none",
                // borderRadius: "5px",
                // cursor: "pointer",
                // fontWeight: "500",
              }}
            >
              <Typography
                //fontFamily="Roboto"
                sx={{ textTransform: "capitalize", padding: "15px" }}
              >
                Get Started Today
              </Typography>
            </Button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ECommerce;
