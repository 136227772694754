import React, { useEffect } from "react";

import "./FirstPage.css";
import { Typography } from "@mui/material";
import { contentFontSize_22, contentFontsize_64 } from "../../Typography";
import About from "../../utils/images/About.jpg";

function FirstPage() {
   useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  return (
    <>
      <div className="about-us-main">
        <div className="about-us-sub">
          <div>
            <Typography
              className="about-us-text"
              fontFamily="Roboto"
              fontWeight="500"
              fontSize={contentFontsize_64}
            >
              About Us
            </Typography>
          </div>
          <div>
            <Typography
              fontFamily="Roboto"
              fontWeight="500"
              fontSize={contentFontSize_22}
            >
              Where Innovation Meets Impact
            </Typography>
          </div>
          <div>
            <Typography sx={{ textAlign: "justify", color: "#6C7282" }}>
              At Axia Kairos, we are shaping the future of businesses by
              redefining what’s possible through technology. As a leading
              digital transformation company, we don’t just create solutions; we
              craft experiences that transform the way organizations operate,
              connect, and grow. By blending cutting-edge innovation with a deep
              understanding of diverse industries, we empower businesses to stay
              ahead in a world driven by digital evolution.
              <br />
              <br />
              Founded with a vision to make technology accessible, meaningful,
              and transformative, Axia Kairos has become a trusted technology
              innovation partner for companies seeking to harness the power of
              digital. From startups to enterprises, we work closely with our
              clients to unlock new opportunities, streamline operations, and
              create a lasting impact.
            </Typography>
          </div>
        </div>
        <div className="about-us-img-container">
          <img src={About} alt="About us" className="about-us-img" />
        </div>
      </div>
    </>
  );
}

export default FirstPage;
