import { Typography, useMediaQuery } from '@mui/material';
import React from 'react'
import { useParams } from 'react-router-dom';
import { contentFontSize_20, contentFontSize_50 } from '../../Typography';
import { useTheme } from '@emotion/react';
import Common from "../../utils/images/Common.jpg";
import "./ServiceInnerPage.css"
import DigitalTransformation from './DigitalTransformation';
function ServiceInnerPage() {
    const { title } = useParams();
    
    //const { title } = useParams();

    // Render different components based on the title
    switch (title.replace(/-/g, " ")) {
      case "digital transformation":
        return <DigitalTransformation />;
    //   case "data analytics":
    //     return <DataAnalytics />;
      default:
        return <div>Service not found</div>;
    }
}

export default ServiceInnerPage