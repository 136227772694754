import React from "react";
import "./Services.css";
import {
  Box,
  Card,
  CardContent,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  contentFontSize_18,
  contentFontSize_20,
  contentFontSize_20_xs_18,
  contentFontSize_22,
  contentFontsize_24,
  contentFontsize_30,
  contentFontsize_30_xs_20,
  HeaderFontSize_50_xs_33,
} from "../../Typography";
import cloud from "../../utils/icons/Cloud.svg";
import digital from "../../utils/icons/digi.svg";
import Business from "../../utils/icons/Businesshome.svg";
import Analytics from "../../utils/icons/Analyticshome.svg";
import Software from "../../utils/icons/softwredevhome.svg";
import Devopshome from "../../utils/icons/Devopshome.svg";
import webdevhome from "../../utils/icons/webdevhome.svg";
import iothome from "../../utils/icons/iothome.svg";
import ecommercehome from "../../utils/icons/ecommercehome.svg";
import CyberSecurityhome from "../../utils/icons/Cybersecurityhome.svg";
import itservicehome from "../../utils/icons/Itservicehome.svg";
import { useNavigate } from "react-router-dom";

function Services() {
  const cardData = [
    {
      title: "Data Analytics & Insights",
      img: Analytics,
      route: "/services/Data-Analytics-Insights",
    },
    {
      title: "Custom Software Development",
      img: Software,
      route: "/services/Custom-Software-Development",
    },
    {
      title: "DevOps & Cloud Engineering",
      img: Devopshome,
      route: "/services/Devops-Cloud-Engineering",
    },
    {
      title: "Mobility & Web Development",
      img: webdevhome,
      route: "/services/Mobility-Web-Development",
    },
  ];

  const cardData1 = [
    {
      title: "E-Commerce Solutions",
      img: ecommercehome,
      route: "/services/E-Commerce-Solutions",
    },
    {
      title: "AI, Blockchain & IoT Integration",
      img: CyberSecurityhome,
      route: "/services/Ai-BlockChain-Iot-Solutions-for-Business-Innovation",
    },
    {
      title: "Cyber Security Services",
      img: iothome,
      route: "/services/CyberSecurity-Services",
    },
    {
      title: "Application Managed IT Services",
      img: itservicehome,
      route: "/services/Application-Managed-IT-Services",
    },
  ];
  const navigate = useNavigate();
  const handleCardClick = (route) => {
    navigate(route);
  };
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const xlscreen = useMediaQuery(theme.breakpoints.up("xl"));
  const matchesmd = useMediaQuery(theme.breakpoints.down("md"));
  const matchesxs = useMediaQuery(theme.breakpoints.down("sm"));
  const matcheslg = useMediaQuery(theme.breakpoints.up("sm"));
  const medium = useMediaQuery(theme.breakpoints.only("md"));
  const isScreen768 = useMediaQuery("(max-width:768px)");

  const containerStyle = isScreen768
    ? {
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gap: "24px 40px",
        width: "97%",
        marginTop: "20px",
      }
    : {
        display: "flex",
        /* flex-wrap: wrap; */
        gap: "16px",
        padding: "25px",
        alignItems: "center",
      };

  const handleDigitalClick = () => {
    navigate("/services/Digital-Transfromation");
  };
  const handleBusinessClick = () => {
    navigate("/services/Business-Process-Automation");
  };
  return (
    <>
      <div className="main-content">
        <div className="padding-content">
          <div className="main-heading">
            <Typography
              sx={{ fontWeight: "700", fontFamily: "Roboto" }}
              fontSize={HeaderFontSize_50_xs_33}
            >
              Our Core Services
            </Typography>
          </div>
          <div className="para-content">
            <Typography
              sx={{
                fontWeight: "400",
                fontFamily: "Roboto",
                textAlign: "center",
              }}
              fontSize={contentFontSize_18}
            >
              Our comprehensive suite of services is designed to drive
              innovation, optimize operations, and elevate
              <span>your business to new heights.</span>
            </Typography>
          </div>

          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              padding: matches || matchesmd ? "0px" : "16px",
              marginTop: "20px",
              gap: "20px",
              alignItems: "center",
              flexDirection: matches ? "column" : "row",
            }}
          >
            {/* First Card: Transform Your Business */}
            <Card
              className="first-card"
              sx={{
                backgroundImage:
                  "linear-gradient(90deg, #FFF885 0%, #999550 100%)",
                color: "black",
                // width: "100%", // Ensure full width
                height: "150px",
                display: "flex",
                padding: "10px",
                borderRadius: "15px",
                boxSizing: "border-box", // Ensure padding is included in width calculation
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 1,
                  padding: matchesmd
                    ? "10px"
                    : matchesxs
                    ? "20px"
                    : "20px 40px 0px 40px",
                  width: "100%", // Stretch content to fill parent
                }}
              >
                <Typography
                  fontSize={contentFontsize_30_xs_20}
                  fontFamily="Roboto"
                  fontWeight="600"
                  textAlign="left"
                >
                  Transform Your Business with Axia Kairos
                </Typography>
              </CardContent>
            </Card>
            {/* Second and Third Cards */}
            {matchesmd ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: matches ? "column" : "row",
                  gap: "20px",
                  width: "100%",
                  justifyContent: "space-between", // Adjust spacing between cards
                  padding: matches || matchesmd ? "0px" : "16px",
                }}
              >
                {/* Second Card */}
                <Card
                  sx={{
                    backgroundColor: "#222222",
                    color: "#FFC107",
                    flex: 1, // Ensure cards share equal width
                    width: xlscreen ? "100%" : matches ? "94%" : "350px",
                    height: "130px",
                    display: "flex",
                    padding: "10px",
                    borderRadius: "15px",
                    position: "relative",
                    "&:hover": {
                      "& .img-icon": {
                        filter:
                          "brightness(0) saturate(100%) invert(76%) sepia(58%) saturate(2336%) hue-rotate(0deg) brightness(102%) contrast(101%)",
                      },
                      "& .card-title": {
                        color: "#ebb81a",
                      },
                    },
                  }}
                  onClick={handleDigitalClick}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      gap: 1,
                      padding: "16px 0px 0px 10px",
                    }}
                  >
                    <div className="icon">
                      <img className="img-icon" src={digital} />
                    </div>
                    <Typography
                      fontSize={contentFontSize_20_xs_18}
                      className="card-title"
                      sx={{
                        fontWeight: "400",
                        fontFamily: "Roboto",
                        textAlign: "left",
                        color: "white",
                        paddingTop: "12px",
                      }}
                    >
                      Digital Transformation
                    </Typography>
                  </CardContent>
                </Card>

                {/* Third Card */}
                <Card
                  sx={{
                    backgroundColor: "#222222",
                    color: "#FFC107",
                    flex: 1, // Ensure cards share equal width
                    height: "130px",
                    width: xlscreen ? "100%" : matches ? "94%" : "350px",
                    display: "flex",
                    padding: "10px",
                    borderRadius: "15px",
                    position: "relative",
                    "&:hover": {
                      "& .img-icon": {
                        filter:
                          "brightness(0) saturate(100%) invert(76%) sepia(58%) saturate(2336%) hue-rotate(0deg) brightness(102%) contrast(101%)",
                      },
                      "& .card-title": {
                        color: "#ebb81a",
                      },
                    },
                  }}
                  onClick={handleBusinessClick}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      gap: 1,
                      padding: "16px 0px 0px 10px",
                    }}
                  >
                    <div className="icon">
                      <img className="img-icon" src={Business} />
                    </div>
                    <Typography
                      fontSize={contentFontSize_20_xs_18}
                      className="card-title"
                      sx={{
                        fontWeight: "400",
                        fontFamily: "Roboto",
                        textAlign: "left",
                        color: "white",
                        paddingTop: "12px",
                      }}
                    >
                      Business Process Automation
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            ) : (
              <>
                <Card
                  sx={{
                    backgroundColor: "#222222",
                    color: "#FFC107",
                    flex: 1, // Ensure cards share equal width
                    height: "130px",
                    display: "flex",
                    padding: "10px",
                    borderRadius: "15px",
                    position: "relative",
                    "&:hover": {
                      "& .img-icon": {
                        filter:
                          "brightness(0) saturate(100%) invert(76%) sepia(58%) saturate(2336%) hue-rotate(0deg) brightness(102%) contrast(101%)",
                      },
                      "& .card-title": {
                        color: "#ebb81a",
                      },
                    },
                  }}
                  onClick={handleDigitalClick}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      gap: 1,
                      padding: "16px 0px 0px 10px",
                    }}
                  >
                    <div className="icon">
                      <img className="img-icon" src={digital} />
                    </div>
                    <Typography
                      fontSize={contentFontSize_20_xs_18}
                      className="card-title"
                      sx={{
                        fontWeight: "400",
                        fontFamily: "Roboto",
                        textAlign: "left",
                        color: "white",
                        paddingTop: "12px",
                      }}
                    >
                      Digital Transformation
                    </Typography>
                  </CardContent>
                </Card>

                {/* Third Card */}
                <Card
                  sx={{
                    backgroundColor: "#222222",
                    color: "#FFC107",
                    flex: 1, // Ensure cards share equal width
                    height: "130px",
                    display: "flex",
                    padding: "10px",
                    borderRadius: "15px",
                    position: "relative",
                    "&:hover": {
                      "& .img-icon": {
                        filter:
                          "brightness(0) saturate(100%) invert(76%) sepia(58%) saturate(2336%) hue-rotate(0deg) brightness(102%) contrast(101%)",
                      },
                      "& .card-title": {
                        color: "#ebb81a",
                      },
                    },
                  }}
                  onClick={handleBusinessClick}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      gap: 1,
                      padding: "16px 0px 0px 10px",
                    }}
                  >
                    <div className="icon">
                      <img className="img-icon" src={Business} />
                    </div>
                    <Typography
                      fontSize={contentFontSize_20_xs_18}
                      className="card-title"
                      sx={{
                        fontWeight: "400",
                        fontFamily: "Roboto",
                        textAlign: "left",
                        color: "white",
                        paddingTop: "12px",
                      }}
                    >
                      Business Process Automation
                    </Typography>
                  </CardContent>
                </Card>
              </>
            )}
          </Box>

          <div
            className={
              window.innerWidth === 768
                ? "card-container"
                : window.innerWidth < 768
                ? "card-container1"
                : "container"
            }
          >
            {cardData.map((item, index) => (
              <Card
                key={index}
                className="sercive-card"
                sx={{
                  backgroundColor: "#222222",
                  color: "#FFC107",
                  width: xlscreen
                    ? "100%"
                    : matches || matchesmd
                    ? "94%"
                    : "350px",
                  height: "130px",
                  display: "flex",
                  padding: "10px",
                  borderRadius: "15px",
                  position: "relative",
                  "&:hover": {
                    "& .img-icon": {
                      filter:
                        "brightness(0) saturate(100%) invert(76%) sepia(58%) saturate(2336%) hue-rotate(0deg) brightness(102%) contrast(101%)",
                    },
                    "& .card-title": {
                      color: "#ebb81a",
                    },
                  },
                }}
                onClick={() => handleCardClick(item.route)}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    gap: 1,
                    padding: "16px 0px 0px 10px",
                  }}
                >
                  <div className="icon">
                    <img className="img-icon" src={item.img} alt={item.title} />
                  </div>
                  <Typography
                    fontSize={contentFontSize_20_xs_18}
                    className="card-title"
                    sx={{
                      fontWeight: "400",
                      fontFamily: "Roboto",
                      textAlign: "left",
                      color: "white",
                      paddingTop: "12px",
                    }}
                  >
                    {item.title}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </div>
          <div
            className={
              window.innerWidth === 768
                ? "card-container"
                : window.innerWidth < 768
                ? "card-container1"
                : "container"
            }
          >
            {cardData1.map((item, index) => (
              <Card
                key={index}
                className="sercive-card"
                sx={{
                  backgroundColor: "#222222",
                  color: "#FFC107",
                  width: xlscreen
                    ? "100%"
                    : matches || matchesmd
                    ? "94%"
                    : "350px",
                  height: "130px",
                  display: "flex",
                  padding: "10px",
                  borderRadius: "15px",
                  position: "relative",
                  "&:hover": {
                    "& .img-icon": {
                      filter:
                        "brightness(0) saturate(100%) invert(76%) sepia(58%) saturate(2336%) hue-rotate(0deg) brightness(102%) contrast(101%)",
                    },
                    "& .card-title": {
                      color: "#ebb81a",
                    },
                  },
                }}
                onClick={() => handleCardClick(item.route)}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    gap: 1,
                    padding: "16px 0px 0px 10px",
                  }}
                >
                  <div className="icon">
                    <img className="img-icon" src={item.img} alt="icon" />
                  </div>
                  <Typography
                    fontSize={contentFontSize_20_xs_18}
                    className="card-title"
                    sx={{
                      fontWeight: "400",
                      fontFamily: "Roboto",
                      textAlign: "left",
                      color: "white",
                      paddingTop: "12px",
                    }}
                  >
                    {item.title}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Services;
