import React from "react";
import "./Approach.css";
import { Button, Typography } from "@mui/material";
import {
  ButtonFontSize_16,
  contentFontSize_18,
  HeaderFontSize_50_xs_33,
  HeaderNavigationFontSize_20,
} from "../../Typography";
import consultation from "../../utils/icons/Consultation.svg";
import Implementation from "../../utils/icons/Implementation.svg";
import Strategy from "../../utils/icons/Strategy.svg";
import Optimization from "../../utils/icons/Optimization.svg";
import Arrow1 from "../../utils/icons/Arrow 1.svg";
import Arrow2 from "../../utils/icons/Arrow 2.svg";
import Arrow3 from "../../utils/icons/Arrow 3.svg";
import Arrow4 from "../../utils/icons/Arrow 4.svg";

function Approach() {
  return (
    <>
      <div className="approach-main">
        <div className="img-approach">
          <div className="heading-approach-col">
            <Typography
              sx={{ fontWeight: "700" }}
              className="approach-heading"
              fontSize={HeaderFontSize_50_xs_33}
            >
              Our Approach
            </Typography>
            <Typography
              fontSize={contentFontSize_18}
              className="subheading-approach"
            >
              At Axia Kairos, our approach to digital transformation is
              <br /> simple yet powerful
            </Typography>
          </div>
        </div>

        <div className="grid-container">
          {/* First Card */}
          {/* <div className="heading-approach-col-iner">
            <Typography
              sx={{ fontWeight: "700" }}
              className="approach-heading"
              fontSize={HeaderFontSize_50_xs_33}
            >
              Our Approach
            </Typography>
            <Typography
              fontSize={contentFontSize_18}
              className="subheading-approach-iner"
            >
              At Axia Kairos, our approach to digital transformation is
              <br /> simple yet powerful
            </Typography>
          </div> */}
          <div className="first-card-sec">
            <div className="img-section">
              <Button
                className="news-button"
                variant="contained"
                sx={{
                  borderRadius: "15px",
                  background:
                    "linear-gradient(90deg, #DFAD17 0%, #795E0C 100%)",
                  color: "#fff",
                  height: "28px",
                  width: "120px",
                }}
              >
                <Typography className="RFP" fontSize={ButtonFontSize_16}>
                  Step 1
                </Typography>
              </Button>
              <img src={consultation} alt="Consultation" />
            </div>
            <div className="inner-content-approach">
              <Typography
                fontSize={HeaderNavigationFontSize_20}
                sx={{ fontWeight: 600 }}
              >
                Consultation
              </Typography>
              <Typography fontSize={contentFontSize_18}>
                We take the time to understand your business, your goals, and
                your challenges.
              </Typography>
            </div>
          </div>

          {/* Arrow 1 */}
          {/* <div className="arrow arrow-right">
            <img src={Arrow4} alt="Arrow pointing to Implementation" />
          </div> */}

          {/* Second Card */}
          <div className="first-card-sec">
            <div className="img-section">
              <Button
                className="news-button"
                variant="contained"
                sx={{
                  borderRadius: "15px",
                  background:
                    "linear-gradient(90deg, #DFAD17 0%, #795E0C 100%)",
                  color: "#fff",
                  height: "28px",
                  width: "120px",
                }}
              >
                <Typography className="RFP" fontSize={ButtonFontSize_16}>
                  Step 4
                </Typography>
              </Button>
              <img
                src={Optimization}
                alt="Implementation"
                width="100px"
                height="100px"
              />
            </div>

            <div className="inner-content-approach">
              <Typography
                fontSize={HeaderNavigationFontSize_20}
                sx={{ fontWeight: 600 }}
              >
                Optimization
              </Typography>
              <Typography fontSize={contentFontSize_18}>
                We continuously monitor, refine, and optimize solutions to
                ensure they continue to deliver maximum value.
              </Typography>
            </div>
          </div>

          {/* Arrow 2 */}
          {/* <div className="arrow arrow-down">
            <img src={Arrow2} alt="Arrow pointing to Strategy" />
          </div> */}

          {/* Third Card */}
          <div className="first-card-sec">
            <div className="img-section">
              <Button
                className="news-button"
                variant="contained"
                sx={{
                  borderRadius: "15px",
                  background:
                    "linear-gradient(90deg, #DFAD17 0%, #795E0C 100%)",
                  color: "#fff",
                  height: "28px",
                  width: "120px",
                }}
              >
                <Typography className="RFP" fontSize={ButtonFontSize_16}>
                  Step 2
                </Typography>
              </Button>
              <img src={Strategy} alt="Strategy" />
            </div>
            <div className="inner-content-approach">
              <Typography
                fontSize={HeaderNavigationFontSize_20}
                sx={{ fontWeight: 600 }}
              >
                Strategy
              </Typography>
              <Typography fontSize={contentFontSize_18}>
                With a clear understanding of your needs, we design a customized
                digital business solution that leverages the best technologies
                for your industry.
              </Typography>
            </div>
          </div>

          {/* Arrow 3 */}
          {/* <div className="arrow arrow-left">
            <img src={Arrow3} alt="Arrow pointing to Optimization" />
          </div> */}

          {/* Fourth Card */}
          <div className="first-card-sec">
            <div className="img-section">
              <Button
                className="news-button"
                variant="contained"
                sx={{
                  borderRadius: "15px",
                  background:
                    "linear-gradient(90deg, #DFAD17 0%, #795E0C 100%)",
                  color: "#fff",
                  height: "28px",
                  width: "120px",
                }}
              >
                <Typography className="RFP" fontSize={ButtonFontSize_16}>
                  Step 3
                </Typography>
              </Button>
              <img src={Implementation} alt="Optimization" />
            </div>
            <div className="inner-content-approach">
              <Typography
                fontSize={HeaderNavigationFontSize_20}
                sx={{ fontWeight: 600 }}
              >
                Implementation
              </Typography>
              <Typography fontSize={contentFontSize_18}>
                From cloud solutions to business automation, we implement the
                right tools and systems for your business.
              </Typography>
            </div>
          </div>

          {/* Arrow 4 */}
          {/* <div className="arrow arrow-up">
            <img src={Arrow1} alt="Arrow pointing to Consultation" />
          </div> */}
        </div>
      </div>
    </>
  );
}

export default Approach;
