import React, { useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import {
  contentFontSize_16,
  contentFontSize_18,
  contentFontSize_20,
  contentFontsize_24,
  contentFontsize_28,
  contentFontSize_50,
  contentFontsize_96,
  HeaderFontSize_50_xs_33,
} from "../../Typography";
import Common from "../../utils/images/Common.jpg";
import BusinessP from "../../utils/images/BusimessP.png";
import "./DigitalTransformation.css";
import Navbar from "../../Navbar";
import Footer from "../../Footer/Footer";
import WhiteArrow from "../../utils/icons/WhiteArrow.svg"
import { useNavigate } from "react-router-dom";
function BusinessProcess() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const cards = [
    {
      title: "Increased Operational Efficiency",
      description:
        "By automating routine tasks, your organization becomes more efficient, allowing your teams to focus on strategic initiatives that drive growth.",
    },
    {
      title: "Cost Reduction",
      description:
        "Our automation solutions reduce the need for manual intervention, lowering operational costs and increasing profitability ",
    },
    {
      title: "Error Reduction",
      description:
        "Automation eliminates human error, ensuring consistent, reliable performance across all business functions.",
    },
    {
      title: "Scalable Solutions",
      description:
        "As your business grows, so can your automation. We provide scalable solutions that can expand with your organization’s needs.",
    },
  ];
  const data = [
    {
      id: "01",
      title: "Pioneers in Transformation",
      content:
        "We are at the forefront of leveraging digital technologies to drive meaningful change. Whether it’s adopting cloud solutions, integrating AI, or enhancing user experiences, we help businesses turn ideas into reality as a trusted business IT solutions provider.",
    },
    {
      id: "02",
      title: "Personalized for You",
      content:
        "No two businesses are the same, and neither are our solutions. We tailor every strategy, platform, and process to your unique goals and challenges, ensuring maximum impact.",
    },
    {
      id: "03",
      title: "Built for Long-Term Success",
      content:
        "Our solutions aren’t just designed for today—they’re crafted to evolve with you, ensuring lasting value in an ever-changing landscape.",
    },
    {
      id: "04",
      title: "Driven by Expertise",
      content:
        "Our team of seasoned professionals brings together global experience, industry knowledge, and a passion for innovation to deliver results that matter.",
    },
  ];

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));
  const dataService = [
    {
      title: "Consultation & Process Assessment",

      description:
        "We begin by understanding your business needs and current processes. Our experts conduct a thorough assessment to identify opportunities for automation. ",
    },
    {
      title: "Design & Strategy",

      description:
        "Based on your unique requirements, we design a comprehensive automation strategy that integrates with your current business systems and processes.",
    },
    {
      title: "Implementation & Integration",

      description:
        "We implement the automation solutions and ensure smooth integration with your existing systems, providing ongoing support to ensure smooth execution.  ",
    },
  ];
  const nav = useNavigate()
  function handleService(){
      nav("/services")
  }
  const dataService1 = [
    {
      title: "Continuous Improvement: ",

      description:
        "Automation is not a one-time task. We continuously monitor and optimize the automated workflows to ensure that they evolve with your business needs and the latest technology. ",
    },
    // {
    //   title: "Optimization & Continuous Monitoring",

    //   description:
    //     "We continuously monitor and refine your analytics strategies, ensuring they evolve with your business and leverage the latest innovations in big data analytics services. ",
    // },
  ];
  function handleContact(){
    nav("/contact")
  }
  return (
    <div>
      <Navbar />
      <div
        className="bg-img-1"
        style={{
          backgroundImage: `url(${BusinessP})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          // height: "500px",
          position: "relative",
          color: "white",
          padding: smmatch ? "0px" : "20px", // Add spacing for responsiveness
          backgroundRepeat: "no-repeat",
        }}
      >
         <div className="back-btn" onClick={handleService}>
                                <img src={WhiteArrow} style={{ marginTop:smmatch ? "18px" : mdmatch && "4px"}} />
                                <span
                                  style={{
                                    marginLeft: "10px",
                                    fontSize: contentFontSize_18,
                                    fontFamily: "Roboto",
                                    marginBottom: "3px",
                                     marginTop:smmatch && "15px"
                                  }}
                                >
                                  Back to Services
                                </span>
                              </div>
        <div className="blog-text1">
          {/* {matches ? ( */}
          <div>
            <Typography
              fontSize={contentFontSize_50}
              fontWeight="700"
              sx={{
                marginBottom: "10px",
                color: "w",
                marginTop: smmatch ? "0px" : matches ? "50px" : "300px",
                padding: matches ? "50px 0px 10px 40px" : "0px",
                textAlign: matches ? "center" : null,
              }}
            >
              Business Process Automation: Streamline Your Operations and Boost
              Efficiency
            </Typography>
            <div>
              <Typography
                fontSize={contentFontSize_20}
                fontWeight="400"
                fontFamily="Roboto"
                sx={{
                  padding: matches ? "10px 0px 10px 40px" : "0px",
                  textAlign: matches ? "center" : null,
                }}
              >
                Automate Routine Tasks to Unlock Greater Productivity
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div className="service-sub-content">
        <Typography
          fontFamily="Roboto"
          fontWeight="400"
          fontSize={contentFontSize_18}
          sx={{ textAlign: "justify", color: "#6C7282" }}
        >
          At Axia Kairos, we help businesses increase efficiency, reduce costs,
          and improve operational workflows through business process automation
          (BPA). By automating manual, time-consuming tasks, we enable your
          organization to focus on innovation and growth. Whether you're looking
          to automate simple workflows or integrate intelligent automation
          across your enterprise, our solutions deliver measurable results.
        </Typography>
        <Typography
          fontFamily="Roboto"
          fontSize={contentFontsize_28}
          fontWeight="700"
          color="#B97E12"
          sx={{ marginTop: "15px" }}
        >
          What We Do and Offer
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Workflow Automation
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Axia Kairos automates complex workflows to ensure that repetitive
          tasks are executed accurately and on time. Our solutions allow your
          teams to focus on high-value activities while reducing operational
          bottlenecks and human errors.
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Robotic Process Automation (RPA)
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Leverage the power of RPA to automate business functions like data
          entry, transaction processing, and customer service. Our RPA solutions
          integrate seamlessly into your existing systems and drive operational
          efficiency across your enterprise.
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Intelligent Document Processing
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Automate document-intensive processes such as data extraction,
          classification, and routing. With intelligent document processing
          (IDP), we turn unstructured data into actionable information that
          integrates directly into your workflows, improving accuracy and speed.
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          AI-Driven Process Automation
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Unlock the power of AI to optimize and automate decision-making
          processes. Our AI-driven solutions enhance your workflows by providing
          real-time insights, predicting outcomes, and automating decisions
          based on data patterns.
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Business Process Integration
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          We ensure seamless integration of your automated processes with
          existing systems. Whether it’s CRM, ERP, or other third-party
          platforms, our solutions enable smooth data exchange and workflow
          automation across all departments.
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Automated Reporting & Analytics
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Automate the creation of reports and analytics with our intelligent
          automation tools. We provide customized dashboards that deliver
          real-time business insights, ensuring that you always have access to
          the data you need for decision-making.
        </Typography>
        {/* <Typography
          fontFamily="Roboto"
          fontSize={contentFontsize_28}
          fontWeight="700"
          color="#B97E12"
          sx={{ marginTop: "15px" }}
        >
          Additional Services in Data Analytics
        </Typography> */}
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Digital Worker Solutions
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Empower your organization with digital workers, or ‘bots’, that can
          take over repetitive tasks, enabling your human workforce to focus on
          strategic initiatives. Our digital workers are designed to perform
          tasks quickly and accurately, ensuring consistent operational
          performance.
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Process Mining & Optimization
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Identify inefficiencies in your existing workflows with process
          mining. We provide a clear understanding of your processes, enabling
          you to optimize them for maximum efficiency and effectiveness.
        </Typography>
        {/* <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          IT Infrastructure Modernization
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          We upgrade your IT environment with cutting-edge tools and solutions.
          Whether it’s on-premise, hybrid, or cloud infrastructure, our services
          ensure your systems are resilient, secure, and future-ready.
        </Typography> */}
      </div>
      <div className="about-us-second">
        <div className="main-heading-about-us">
          <Typography
            sx={{ fontWeight: "700", fontFamily: "Roboto", marginTop: "50px",textAlign:smmatch && "center" }}
            fontSize={HeaderFontSize_50_xs_33}
          >
            Why Choose Axia Kairos?
          </Typography>
        </div>
        <Typography
          fontSize={contentFontSize_20}
          fontWeight="400"
          color="white"
          fontFamily="Roboto"
          sx={{
            paddingLeft: mdmatch ? "10px" : "150px",
            paddingRight: mdmatch ? "10px" : "150px",
            textAlign: "center",
            paddingBottom: "50px",
          }}
        >
          Axia Kairos brings deep expertise in business process automation,
          allowing you to realize significant cost savings and efficiency gains.
          We deliver tailored automation solutions that align with your unique
          business objectives. Our proven track record, coupled with our
          commitment to innovation, ensures that your business will stay ahead
          of the competition by adopting automation technologies that are built
          for the future.
        </Typography>
        <div className="about-us-div">
          {data.map((item) => (
            <div className="about-us-card" key={item.id}>
              <Typography
                fontSize={contentFontsize_96}
                fontFamily="Roboto"
                fontWeight="100"
                sx={{ color: "#EBB81A", lineHeight: "98px" }}
              >
                {item.id}
              </Typography>
              <Typography
                sx={{
                  color: "#ECECEC",
                  textAlign: "left",
                  lineHeight: "31px",
                  marginBottom: "10px",
                }}
                fontSize={contentFontsize_24}
                fontFamily="Roboto"
              >
                {item.title}
              </Typography>
              <Typography
                sx={{
                  color: "#ECECEC",
                  textAlign: "left",
                  lineHeight: "31px",
                  fontWeight: "400",
                }}
                fontSize={contentFontSize_16}
                fontFamily="Roboto"
              >
                {item.content}
              </Typography>
              {/* <div className="imge-section-about-us">
                  <img src={Line}/>
                </div> */}
            </div>
          ))}
        </div>
      </div>
      <div className="third-page-service">
        <div style={{ marginTop: "50px", marginBottom: "50px" }}>
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              textAlign: "center",
            }}
            fontWeight="700"
            fontFamily="Roboto"
            fontSize={contentFontSize_50}
          >
            Our Proven Approach to Business Process Automation
          </Typography>
        </div>
        <div className="secnd-sec">
          <div className="flex-card-in">
            {dataService.map((card, index) => (
              <Card
                className="about-card-service"
                key={index}
                sx={{ borderRadius: "15px", background: "#9F6907" }}
              >
                <div className="about-content-card-service">
                  <Typography
                    fontWeight="700"
                    fontSize={contentFontsize_24}
                    fontFamily="Roboto"
                    className="about-card-title"
                  >
                    {card.title}
                  </Typography>
                  <Typography
                    fontWeight="400"
                    fontSize={contentFontSize_18}
                    fontFamily="Roboto"
                    color="white"
                    className="about-content-1-service"
                  >
                    {card.description}
                  </Typography>
                </div>
              </Card>
            ))}
          </div>
        </div>
        <div className="secnd-sec">
          <div className="flex-card-in-1">
            {dataService1.map((card, index) => (
              <Card
                className="about-card-service-1"
                key={index}
                sx={{
                  borderRadius: "15px",
                  background: "#9F6907",
                  marginTop: "20px",
                }}
              >
                <div className="about-content-card-service">
                  <Typography
                    fontWeight="700"
                    fontSize={contentFontsize_24}
                    fontFamily="Roboto"
                    className="about-card-title"
                  >
                    {card.title}
                  </Typography>
                  <Typography
                    fontWeight="400"
                    fontSize={contentFontSize_18}
                    fontFamily="Roboto"
                    color="white"
                    className="about-content-1-service"
                  >
                    {card.description}
                  </Typography>
                </div>
              </Card>
            ))}
          </div>
        </div>
      </div>
      <div className="main-third">
        <div className="about-us-text1">
          <Typography
            fontFamily="Roboto"
            fontWeight="500"
            fontSize={contentFontSize_50}
            sx={{
              paddingTop: mdmatch ? "50px" : "200px",
              textAlign: matches && "center",
            }}
          >
            How Our Automation Services Ensure Long-Term Success
          </Typography>
        </div>

        <div className="third-sub">
          {cards.map((card, index) => (
            <div
              key={index}
              //   ref={(el) => (cardRefs.current[index] = el)}
              data-index={index}
              //   className={`card-wrapper-about-us ${
              //     visibleCards.includes(index.toString()) ? "active" : ""
              //   }`}
              style={{
                display: "flex",
                flexDirection: mdmatch ? null : "row",
                justifyContent: mdmatch ? null : "space-between",
                width: mdmatch ? null : "100%",
              }}
            >
              <div
              // className={`side-item-about-us ${
              //   visibleCards.includes(index.toString()) ? "active" : ""
              // }`}
              ></div>

              <Card
                sx={{
                  borderRight: "5px solid #B97E12",
                  background: "#DFDFDF",
                  width: matches ? "100%" : "90%",
                  // color: "white",
                  transition: "all 0.5s ease",
                  height: mdmatch ? "100%" : "160px",
                  borderRadius: "10px",
                }}
                // className={`card-content ${
                //   visibleCards.includes(index.toString()) ? "active" : ""
                // }`}
              >
                <CardContent>
                  <div
                    className="card-title-about-us"
                    style={{ marginBottom: "10px" }}
                  >
                    <Typography
                      fontWeight="700"
                      fontSize={contentFontSize_20}
                      fontFamily="Roboto"
                      //color="black"
                    >
                      {card.title}
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      fontWeight="400"
                      fontSize={contentFontSize_18}
                      fontFamily="Roboto"
                      className="card-description-about-us"
                    >
                      {card.description}
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            </div>
          ))}
        </div>
      </div>
      <div
        className="bg-img"
        style={{
          backgroundImage: `url(${Common})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          // height: "500px",
          position: "relative",
          color: "white",
          padding: "20px", // Add spacing for responsiveness
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="blog-text1">
          {matches ? (
            <Typography
              fontSize={contentFontSize_50}
              fontWeight="700"
              sx={{
                marginBottom: "10px",
                color: "#FFD700",
                marginTop: smmatch ? "0px" : matches ? "50px" : "160px",
                padding: matches ? "10px 0px 10px 40px" : "0px",
                textAlign: matches ? "center" : null,
              }}
            >
              Unlock Efficiency and Drive Growth with Business Process
              Automation
            </Typography>
          ) : (
            <Typography
              fontSize={contentFontSize_50}
              fontWeight="700"
              sx={{
                marginBottom: "10px",
                color: "#FFD700",
                marginTop: smmatch ? "0px" : matches ? "50px" : "160px",
                padding: matches ? "10px 0px 10px 40px" : "0px",
                textAlign: matches ? "center" : null,
              }}
            >
              Unlock Efficiency and Drive Growth with Business Process
              Automation
            </Typography>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: matches && "center",
            padding: smmatch ? "0px" : "10px 40px",
            flexDirection: lgmatch ? "column" : "row",
          }}
        >
          <div style={{ width: "70%" }}>
            <Typography
              //fontFamily="Roboto"
              fontWeight="400"
              fontSize={contentFontSize_20}
              style={{
                textAlign: matches ? "center" : "left",
                lineHeight: "1.6",
                marginBottom: "20px",
                color: "#FFFFFF", // White paragraph text
              }}
            >
              Axia Kairos offers tailored business process automation solutions
              that allow your organization to operate more efficiently, reduce
              costs, and improve productivity. Whether you want to automate
              basic tasks or transform entire workflows, we’re here to help.
            </Typography>
          </div>
          <div style={{ marginTop: "10px" }}>
            <Button
              variant="contained"
              onClick={handleContact}
              sx={{
                backgroundImage:
                  "linear-gradient(90deg, #DFAD17 0%, #795E0C 100%)",
                width: "300px",
                height: "40px",
                borderRadius: "15px",
                // padding: "10px 20px",
                // fontSize: "16px",
                // backgroundColor: "#FFC107",
                // color: "white",
                // border: "none",
                // borderRadius: "5px",
                // cursor: "pointer",
                // fontWeight: "500",
              }}
            >
              <Typography
                //fontFamily="Roboto"
                sx={{ textTransform: "capitalize", padding: "15px" }}
              >
                Get Started Today
              </Typography>
            </Button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default BusinessProcess;
