import { Button, Typography } from "@mui/material";
import React from "react";
import "./Welcome.css";
import {
  ButtonFontSize_18,
  contentFontsize_17,
  contentFontSize_18,
  contentFontSize_18_xs_13,
  contentFontSize_20,
  contentFontSize_20_xs_17,
  HeaderFontSize_50,
} from "../../Typography";
import mask from "../../utils/images/Mask group.jpg";
import check from "../../utils/icons/Check.svg";

function Welcome() {
  return (
    <>
      <div style={{ overflow: "hidden", height: "100%" }}>
        <div className="welcome-main-heading">
          {/* <Button
            className="home-button-submit"
            variant="contained"
            sx={{
              borderRadius: "10px",
              background: "linear-gradient(90deg, #DFAD17 0%, #795E0C 100%)",
              color: "#fff",
              ":hover": {
                background: "linear-gradient(90deg, #795E0C 0%, #DFAD17 100%)",
              },
            }}
          >
            <Typography className="RFP" fontSize={ButtonFontSize_18}>
              Submit Your RFP
            </Typography>
          </Button> */}
        </div>
        <div className="welcome-flex">
          <div className="left-section">
            <div className="gapping-content">
              <div className="left-heading">
                <Typography
                  fontSize={HeaderFontSize_50}
                  className="font-welcome-about"
                  style={{ fontWeight: 700 }}
                  sx={{
                    "@media (max-width: 480px)": {
                      lineHeight: 1.3,
                    },
                  }}
                >
                  Welcome to Axia Kairos
                </Typography>
                <div className="sub-heading">
                  <Typography
                    fontSize={contentFontSize_20_xs_17}
                    className="sub-heading-01"
                    style={{ fontWeight: 500, lineHeight: 1.3 }}
                  >
                    Your Trusted IT Service Provider for Digital Transformation
                  </Typography>
                </div>
              </div>
              <div className="welcome-content">
                <Typography fontSize={contentFontSize_18_xs_13}>
                  At Axia Kairos, we specialize in delivering digital
                  transformation solutions that empower businesses to excel in
                  an increasingly digital world. As a premier IT service
                  provider, we offer a comprehensive suite of services designed
                  to streamline your operations, enhance efficiency, and
                  accelerate growth.
                  <br />
                  <br />
                  Our services range from cloud solutions and business process
                  automation to custom software development and cybersecurity
                  services. As a trusted managed IT service provider, we ensure
                  your business is equipped with the latest technology to thrive
                  in today’s competitive landscape.
                  <br />
                  <br />
                  Whether you're looking to migrate to the cloud, implement data
                  analytics for business, or develop AI-driven solutions, Axia
                  Kairos has the expertise to deliver exceptional results and
                  help you stay ahead of the curve.
                </Typography>
              </div>
            </div>
          </div>
          <div className="right-section">
            <div className="right-content">
              <img src={mask} alt="Loading..." className="img-mask" />
            </div>
            <div className="experience-section">
              <div className="inner-experience-content">
                <img src={check} alt="Loading..." />
                <div className="experience-content">
                  <Typography fontSize={contentFontSize_18_xs_13}>
                    Management Consulting
                  </Typography>
                  <Typography fontSize={contentFontSize_18_xs_13}>
                    Experience
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Welcome;
