import React, { useEffect } from "react";
import Business from "./First-Section/Business";
import Welcome from "./Second-Section/Welcome";
import "./index.css";
import Navbar from "../Navbar";
import Choose from "./Fourth-Section/Choose";
import Approach from "./Fifth-Section/Approach";
import Services from "./Third-Section/Services";
import News from "./Sixth-Section/News";
import Blog from "./Seventh-Section/Blog";
import Footer from "../Footer/Footer";
import LastSection from "./Eighth-Section/LastSection";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="home">
      <Navbar />
      <Business />
      <Welcome />
      <Services />
      <Choose />
      <Approach />
      {/* <News /> */}
      <Blog />
      <LastSection/>
      <Footer />
    </div>
  );
}

export default Home;
