import { Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import "./InnerPage.css";
import React, { useEffect } from "react";
import BackArrow from "../utils/icons/BackArrow.svg";
import Blog6 from "../utils/images/Blog6.webp";
import Gradient from "../utils/images/Gradient.jpg";
import Name from "../utils/icons/Name.svg";
import calender from "../utils/icons/calender.svg";
import clock from "../utils/icons/clock.svg";
import Common from "../utils/images/Common.jpg";
import {
  contentFontSize_16,
  contentFontSize_18,
  contentFontSize_20,
  contentFontsize_28,
  contentFontSize_48,
  contentFontSize_50,
} from "../Typography";
import Navbar from "../Navbar";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";
function MobilityBlog() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));
  const nav = useNavigate();

  const handleBackClick = () => {
    nav("/blogs");
  };
  const handleContactClick = () => {
    nav("./contact");
  };

  return (
    <div>
      <>
        <Navbar />
      </>
      <div className="back-btn" onClick={handleBackClick}>
        <img src={BackArrow} style={{marginTop:mdmatch && "4px"}}  />
        <span
          style={{
            marginLeft: "10px",
            fontSize: contentFontSize_18,
            fontFamily: "Roboto",
            marginBottom: "3px",
          }}
        >
          Back to Blogs
        </span>
      </div>
      <div
        className="responsive-container"
        style={{
          backgroundImage: xsmatch ? `url(${Gradient})` : `url(${Blog6})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "500px",
          position: "relative",
          color: "white",
          display: "flex",
          width: "100%",
          //justifyContent: "center",
          alignItems: "flex-end",
          marginTop: "30px",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="blog-text">
          <Typography
            fontSize={contentFontSize_48}
            fontWeight="700"
            marginLeft="0px"
          >
            Why Responsive Design is Essential for Modern Web Development
          </Typography>
        </div>
      </div>
      {/* <div className="blog-section-one">
        <div className="blog-section-sub">
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={Name} alt="Profile" style={{ marginRight: "5px" }} />
            <span
              style={{
                fontFamily: "Roboto",
                fontWeight: "500",
                fontSize: "14px",
              }}
            >
              Shyam Pushkar
            </span>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={calender} alt="calender" style={{ marginRight: "0px" }} />
            <span
              style={{
                marginLeft: "10px",
                fontFamily: "Roboto",
                fontWeight: "500",
                fontSize: "14px",
              }}
            >
              January 21 2024
            </span>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={clock} alt="clock" style={{ marginRight: "0px" }} />
            <span
              style={{
                marginLeft: "10px",
                fontFamily: "Roboto",
                fontWeight: "500",
                fontSize: "14px",
                textAlign: "center",
              }}
            >
              8:25
            </span>
          </div>
        </div>
      </div> */}
      <div className="main">
        <div>
          <Typography
            fontSize={contentFontsize_28}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Introduction
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            sx={{
              textAlign: "justify",
              color: "#6C7282",
              marginTop: "10px",
              //letterSpacing: "1px",
            }}
          >
            In an era where mobile devices account for more than half of all web
            traffic, having a responsive website is no longer optional—it’s
            essential. Responsive web design ensures that your website delivers
            a seamless user experience across all devices, from desktops to
            smartphones. This blog explores the importance of responsive design,
            how it works, and why it’s a critical component of modern web
            development.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontsize_28}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            What is Responsive Web Design?
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            lineHeight="31px"
            sx={{
              textAlign: "justify",
              color: "#6C7282",
              marginTop: "10px",
              //letterSpacing: "1px",
            }}
          >
            Responsive web design is an approach to web development that ensures
            a website's layout and elements adapt seamlessly to different screen
            sizes and resolutions. This adaptability enhances user experience,
            making it easier for visitors to navigate, interact with, and
            consume content on any device.
          </Typography>
        </div>

        <div>
          <Typography
            fontSize={contentFontsize_28}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Benefits of Responsive Web Design
          </Typography>
        </div>
        <Typography
          fontFamily="Roboto"
          fontWeight="700"
          fontSize={contentFontSize_20}
          sx={{ margin: "20px 0 10px" }}
        >
          Enhanced User Experience (UX)
        </Typography>
        <ul>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Benefit:</span>

              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                {" "}
                A responsive website provides an intuitive and consistent
                experience across all devices.
              </Typography>
            </Typography>
          </li>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Example:</span>

              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                {" "}
                A user visiting an e-commerce site on their smartphone can
                browse and purchase products as easily as they would on a
                desktop.
              </Typography>
            </Typography>
          </li>
        </ul>

        <Typography
          fontFamily="Roboto"
          fontWeight="700"
          fontSize={contentFontSize_20}
          sx={{ margin: "0 0 10px" }}
        >
          Better SEO Performance
        </Typography>
        <ul>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Benefit:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                Search engines like Google prioritize mobile-friendly websites
                in search rankings, improving your site’s visibility.
              </Typography>
            </Typography>
          </li>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Example:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                A responsive website ranks higher in search results, driving
                more organic traffic to your business.
              </Typography>
            </Typography>
          </li>
        </ul>
        <Typography
          fontFamily="Roboto"
          fontWeight="700"
          fontSize={contentFontSize_20}
          sx={{ margin: "0 0 10px" }}
        >
          Cost-Effectiveness
        </Typography>
        <ul>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Benefit:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                Maintaining a single responsive site is more cost-effective than
                managing separate versions for desktop and mobile.
              </Typography>
            </Typography>
          </li>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Example:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                A startup can reduce development and maintenance costs by
                adopting a responsive design strategy.
              </Typography>
            </Typography>
          </li>
        </ul>
        <Typography
          fontFamily="Roboto"
          fontWeight="700"
          fontSize={contentFontSize_20}
          sx={{ margin: "0 0 10px" }}
        >
          Increased Conversion Rates
        </Typography>
        <ul>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Benefit:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                A consistent user experience across devices leads to higher
                engagement and conversions.
              </Typography>
            </Typography>
          </li>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Example:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                A responsive landing page can capture leads effectively,
                regardless of the device users are on.
              </Typography>
            </Typography>
          </li>
        </ul>
        <Typography
          fontFamily="Roboto"
          fontWeight="700"
          fontSize={contentFontSize_20}
          sx={{ margin: "0 0 10px" }}
        >
          Future-Proof Design
        </Typography>
        <ul>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Benefit:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                Responsive websites adapt to new devices and screen sizes,
                making them more sustainable in the long run.
              </Typography>
            </Typography>
          </li>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Example:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                As foldable smartphones and other new technologies emerge,
                responsive sites remain functional and user-friendly.
              </Typography>
            </Typography>
          </li>
        </ul>
        <div>
          <Typography
            fontSize={contentFontsize_28}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px", letterSpacing: "1px" }}
          >
            How Responsive Web Design Works
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Fluid Grid Layouts
          </Typography>
        </div>
        <div>
          <ul>
            <li>
              <Typography
                fontSize={contentFontSize_18}
                fontFamily="Roboto"
                fontWeight="400"
                sx={{
                  textAlign: "justify",
                  color: "#6C7282",
                  marginTop: "10px",
                }}
              >
                Layouts are designed using proportional units rather than fixed
                pixels, ensuring elements resize dynamically.
              </Typography>
            </li>
          </ul>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Flexible Images and Media
          </Typography>
        </div>
        <div>
          <ul>
            <li>
              <Typography
                fontSize={contentFontSize_18}
                fontFamily="Roboto"
                fontWeight="400"
                sx={{
                  textAlign: "justify",
                  color: "#6C7282",
                  marginTop: "10px",
                }}
              >
                Images and videos are adjusted automatically to fit the
                dimensions of the user’s screen.
              </Typography>
            </li>
          </ul>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Media Queries
          </Typography>
        </div>
        <div>
          <ul>
            <li>
              <Typography
                fontSize={contentFontSize_18}
                fontFamily="Roboto"
                fontWeight="400"
                sx={{
                  textAlign: "justify",
                  color: "#6C7282",
                  marginTop: "10px",
                }}
              >
                CSS media queries detect the user’s screen size and apply the
                appropriate styles, optimizing the layout for the device.
              </Typography>
            </li>
          </ul>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Mobile-First Approach
          </Typography>
        </div>
        <div>
          <ul>
            <li>
              <Typography
                fontSize={contentFontSize_18}
                fontFamily="Roboto"
                fontWeight="400"
                sx={{
                  textAlign: "justify",
                  color: "#6C7282",
                  marginTop: "10px",
                }}
              >
                Responsive design prioritizes mobile usability, ensuring that
                smaller screens are optimized first, with adjustments made for
                larger devices.
              </Typography>
            </li>
          </ul>
        </div>
        <div>
          <Typography
            fontSize={contentFontsize_28}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Best Practices for Responsive Web Design
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Prioritize Speed:
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            sx={{ textAlign: "justify", color: "#6C7282", marginTop: "10px" }}
          >
            Use compressed images and implement caching to improve loading times
            on all devices.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Design for Touchscreens:
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            sx={{ textAlign: "justify", color: "#6C7282", marginTop: "10px" }}
          >
            Ensure buttons and interactive elements are easy to tap on mobile
            devices.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Test Across Devices:
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            sx={{ textAlign: "justify", color: "#6C7282", marginTop: "10px" }}
          >
            Regularly test your website on various devices and browsers to
            ensure consistent performance.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Keep Content Readable:
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            sx={{ textAlign: "justify", color: "#6C7282", marginTop: "10px" }}
          >
            Use legible font sizes and avoid overcrowding the screen with too
            much information.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontsize_28}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Challenges in Implementing Responsive Design (and How to Overcome
            Them)
          </Typography>
        </div>

        <div style={{ lineHeight: "1.8", marginTop: "20px" }}>
          <div style={{ marginBottom: "20px", marginTop: "20px" }}>
            <Typography
              fontFamily="Roboto"
              fontWeight="700"
              fontSize={contentFontSize_20}
              //   sx={{ margin: "0 0 10px" }}
            >
              Complex Layouts
            </Typography>
            {/* <ul style={{ margin: "0", paddingLeft: "20px" }}>
              <li style={{ color: "#6C7282", textAlign: "justify" }}> */}
            <ul>
              <li>
                <Typography fontSize={contentFontSize_18}>
                  <span className="digital">Solution:</span>

                  <Typography
                    fontSize={contentFontSize_18}
                    color="#6C7282"
                    fontWeight="400"
                    sx={{ margin: "0 0 10px" }}
                  >
                    {" "}
                    Use a modular design approach to simplify complex layouts
                    for smaller screens.
                  </Typography>
                </Typography>
              </li>
            </ul>
            {/* </li>
              <li style={{ color: "#6C7282", textAlign: "justify" }}> */}

            {/* </li>
            </ul> */}
          </div>

          <div style={{ marginBottom: "20px" }}>
            <Typography
              fontFamily="Roboto"
              fontWeight="700"
              fontSize={contentFontSize_20}
              //   sx={{ margin: "0 0 10px" }}
            >
              Loading Speed on Mobile
            </Typography>
            {/* <ul style={{ margin: "0", paddingLeft: "20px" }}>
              <li style={{ color: "#6C7282", textAlign: "justify" }}> */}
            <ul>
              <li>
                <Typography fontSize={contentFontSize_18}>
                  <span className="digital">Solution:</span>

                  <Typography
                    fontSize={contentFontSize_18}
                    color="#6C7282"
                    fontWeight="400"
                    sx={{ margin: "0 0 10px" }}
                  >
                    {" "}
                    Optimize media files and minimize the use of heavy scripts
                    to enhance mobile performance.
                  </Typography>
                </Typography>
              </li>
            </ul>
            {/* </li>
              <li style={{ color: "#6C7282", textAlign: "justify" }}>
                <Typography
                  fontWeight="400"
                  fontFamily="Roboto"
                  fontSize={contentFontSize_16}
                >
                  Align objectives with overall business strategy to ensure
                  measurable impact.
                </Typography>
              </li>
            </ul> */}
          </div>

          <div>
            <Typography
              fontFamily="Roboto"
              fontWeight="700"
              fontSize={contentFontSize_20}
              //   sx={{ margin: "0 0 10px" }}
            >
              Compatibility Issues
            </Typography>
            <ul>
              <li>
                <Typography fontSize={contentFontSize_18}>
                  <span className="digital">Solution:</span>

                  <Typography
                    fontSize={contentFontSize_18}
                    color="#6C7282"
                    fontWeight="400"
                    sx={{ margin: "0 0 10px" }}
                  >
                    {" "}
                    Regularly update your site to ensure compatibility with the
                    latest devices and browsers.
                  </Typography>
                </Typography>
              </li>
            </ul>
          </div>
        </div>

        <div>
          <Typography
            fontFamily="Roboto"
            fontWeight="700"
            fontSize={contentFontsize_28}
          >
            Conclusion
          </Typography>
        </div>
        <Typography
          fontSize={contentFontSize_18}
          color="#6C7282"
          fontWeight="400"
          textAlign="justify"
        >
          Responsive design is not just a trend—it’s a fundamental aspect of
          modern web development. By delivering a seamless user experience,
          improving SEO performance, and adapting to new technologies,
          responsive web design ensures that your website remains relevant and
          effective in an ever-changing digital landscape. At Axia Kairos, we
          specialize in creating responsive websites that are visually stunning,
          highly functional, and optimized for all devices. Let us help you
          build a website that meets the needs of your audience and drives your
          business forward.
        </Typography>
      </div>
      <div
        className="bg-img"
        style={{
          backgroundImage: `url(${Common})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          // height: "500px",
          position: "relative",
          color: "white",
          padding: "20px", // Add spacing for responsiveness
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="blog-text1">
          {matches ? (
            <Typography
              fontSize={contentFontSize_50}
              fontWeight="700"
              sx={{
                marginBottom: "10px",
                color: "#FFD700",
                marginTop: smmatch ? "0px" : matches ? "50px" : "160px",
                padding: matches ? "10px 0px 10px 40px" : "0px",
                textAlign: matches ? "center" : null,
              }}
            >
              Begin Your Digital Transformation Journey with Axia Kairos
            </Typography>
          ) : (
            <Typography
              fontSize={contentFontSize_50}
              fontWeight="700"
              sx={{
                marginBottom: "10px",
                color: "#FFD700",
                marginTop: smmatch ? "0px" : matches ? "50px" : "160px",
                padding: matches ? "10px 0px 10px 40px" : "0px",
                textAlign: matches ? "center" : null,
              }}
            >
              Begin Your Digital Transformation Journey <br /> with Axia Kairos
            </Typography>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: matches && "center",
            padding: smmatch ? "0px" : "10px 40px",
            flexDirection: lgmatch ? "column" : "row",
          }}
        >
          <div style={{ width: "70%" }}>
            <Typography
              fontFamily="Roboto"
              fontWeight="400"
              fontSize={contentFontSize_20}
              style={{
                textAlign: matches ? "center" : "left",
                lineHeight: "1.6",
                marginBottom: "20px",
                color: "#FFFFFF", // White paragraph text
              }}
            >
              Ready to redefine your business with digital transformation? Axia
              Kairos delivers innovative strategies and cutting-edge
              technologies to help your organization adapt and thrive.
            </Typography>
          </div>
          <div style={{ marginTop: "10px" }}>
            <Button
              variant="contained"
              sx={{
                backgroundImage:
                  "linear-gradient(90deg, #DFAD17 0%, #795E0C 100%)",
                width: "300px",
                height: "40px",
                borderRadius: "15px",
                // padding: "10px 20px",
                // fontSize: "16px",
                // backgroundColor: "#FFC107",
                // color: "white",
                // border: "none",
                // borderRadius: "5px",
                // cursor: "pointer",
                // fontWeight: "500",
              }}
              onClick={handleContactClick}
            >
              <Typography
                //fontFamily="Roboto"
                sx={{ textTransform: "capitalize", padding: "15px" }}
              >
                Start Your Transformation Today
              </Typography>
            </Button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default MobilityBlog;
