import { Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import "./InnerPage.css";
import React, { useEffect } from "react";
import BackArrow from "../utils/icons/BackArrow.svg";
import Blog5 from "../utils/images/Blog5.webp";
import Gradient from "../utils/images/Gradient.jpg";
import Name from "../utils/icons/Name.svg";
import calender from "../utils/icons/calender.svg";
import clock from "../utils/icons/clock.svg";
import Common from "../utils/images/Common.jpg";
import {
  contentFontSize_16,
  contentFontSize_18,
  contentFontSize_20,
  contentFontsize_28,
  contentFontSize_48,
  contentFontSize_50,
} from "../Typography";
import Navbar from "../Navbar";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";
function DevopsBlog() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));
  const nav = useNavigate();
  const handleBackClick = () => {
    nav("/blogs");
  };
  const handleContactClick = () => {
    nav("./contact");
  };
  return (
    <div>
      <>
        <Navbar />
      </>
      <div className="back-btn" onClick={handleBackClick}>
        <img src={BackArrow} style={{marginTop:mdmatch && "4px"}}  />
        <span
          style={{
            marginLeft: "10px",
            fontSize: contentFontSize_18,
            fontFamily: "Roboto",
            marginBottom: "3px",
          }}
        >
          Back to Blogs
        </span>
      </div>
      <div
        className="responsive-container"
        style={{
          backgroundImage: xsmatch ? `url(${Gradient})` : `url(${Blog5})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "500px",
          position: "relative",
          color: "white",
          display: "flex",
          width: "100%",
          //justifyContent: "center",
          alignItems: "flex-end",
          marginTop: "30px",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="blog-text">
          <Typography
            fontSize={contentFontSize_48}
            fontWeight="700"
            marginLeft="0px"
          >
            Why DevOps is the Key to Faster, Reliable Software Delivery
          </Typography>
        </div>
      </div>
      {/* <div className="blog-section-one">
        <div className="blog-section-sub">
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={Name} alt="Profile" style={{ marginRight: "5px" }} />
            <span
              style={{
                fontFamily: "Roboto",
                fontWeight: "500",
                fontSize: "14px",
              }}
            >
              Shyam Pushkar
            </span>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={calender} alt="calender" style={{ marginRight: "0px" }} />
            <span
              style={{
                marginLeft: "10px",
                fontFamily: "Roboto",
                fontWeight: "500",
                fontSize: "14px",
              }}
            >
              January 21 2024
            </span>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={clock} alt="clock" style={{ marginRight: "0px" }} />
            <span
              style={{
                marginLeft: "10px",
                fontFamily: "Roboto",
                fontWeight: "500",
                fontSize: "14px",
                textAlign: "center",
              }}
            >
              8:25
            </span>
          </div>
        </div>
      </div> */}
      <div className="main">
        <div>
          <Typography
            fontSize={contentFontsize_28}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Introduction
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            sx={{
              textAlign: "justify",
              color: "#6C7282",
              marginTop: "10px",
              //letterSpacing: "1px",
            }}
          >
            In today’s fast-paced tech-driven world, businesses are under
            constant pressure to deliver software faster, more efficiently, and
            with minimal errors. DevOps, a practice that integrates development
            and operations teams, has emerged as the key to achieving these
            goals. By fostering collaboration and automation, DevOps enables
            faster software delivery, improved quality, and greater agility.
            This blog delves into the core benefits of DevOps and why it’s
            essential for modern businesses.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontsize_28}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            What is DevOps?
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            lineHeight="31px"
            sx={{
              textAlign: "justify",
              color: "#6C7282",
              marginTop: "10px",
              //letterSpacing: "1px",
            }}
          >
            DevOps is a culture, set of practices, and tools that bridge the gap
            between development and operations teams. It focuses on automating
            and streamlining the software delivery lifecycle (SDLC), from
            development to deployment and beyond.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontsize_28}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            The Core Principles of DevOps
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Collaboration and Communication:
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            sx={{ textAlign: "justify", color: "#6C7282", marginTop: "10px" }}
          >
            DevOps fosters a collaborative environment where development and
            operations teams work together seamlessly.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Automation:
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            sx={{ textAlign: "justify", color: "#6C7282", marginTop: "10px" }}
          >
            By automating repetitive tasks like testing and deployment, DevOps
            ensures efficiency and reduces the risk of errors.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Continuous Integration and Continuous Delivery (CI/CD):
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            sx={{ textAlign: "justify", color: "#6C7282", marginTop: "10px" }}
          >
            DevOps relies on CI/CD pipelines to ensure that software is built,
            tested, and deployed rapidly and reliably.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Monitoring and Feedback:
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            sx={{ textAlign: "justify", color: "#6C7282", marginTop: "10px" }}
          >
            Continuous monitoring provides insights into software performance,
            allowing teams to identify and fix issues proactively.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontsize_28}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Why Your Business Needs DevOps
          </Typography>
        </div>
        <Typography
          fontFamily="Roboto"
          fontWeight="700"
          fontSize={contentFontSize_20}
          sx={{ margin: "20px 0 10px" }}
        >
          Tailored to Your Business Needs
        </Typography>
        <ul>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Benefit:</span>

              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                {" "}
                DevOps automates critical processes, reducing the time it takes
                to deliver software updates and new features.
              </Typography>
            </Typography>
          </li>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Example:</span>

              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                {" "}
                A retail company using DevOps can roll out seasonal promotions
                quickly to stay ahead of competitors.
              </Typography>
            </Typography>
          </li>
        </ul>

        <Typography
          fontFamily="Roboto"
          fontWeight="700"
          fontSize={contentFontSize_20}
          sx={{ margin: "0 0 10px" }}
        >
          Improved Software Quality
        </Typography>
        <ul>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Benefit:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                Automated testing and CI/CD pipelines ensure that bugs are
                detected and resolved early in the development cycle.
              </Typography>
            </Typography>
          </li>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Example:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                Healthcare apps with DevOps practices experience fewer bugs,
                ensuring uninterrupted patient care.
              </Typography>
            </Typography>
          </li>
        </ul>
        <Typography
          fontFamily="Roboto"
          fontWeight="700"
          fontSize={contentFontSize_20}
          sx={{ margin: "0 0 10px" }}
        >
          Scalability and Flexibility
        </Typography>
        <ul>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Benefit:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                DevOps ensures that infrastructure can scale dynamically to
                accommodate growing traffic and workloads.
              </Typography>
            </Typography>
          </li>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Example:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                Streaming platforms like Netflix rely on DevOps to handle surges
                in viewership during popular releases.
              </Typography>
            </Typography>
          </li>
        </ul>
        <Typography
          fontFamily="Roboto"
          fontWeight="700"
          fontSize={contentFontSize_20}
          sx={{ margin: "0 0 10px" }}
        >
          Enhanced Security (DevSecOps)
        </Typography>
        <ul>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Benefit:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                DevSecOps integrates security practices into the development
                process, ensuring that vulnerabilities are addressed early.
              </Typography>
            </Typography>
          </li>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Example:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                Financial institutions use DevSecOps to protect sensitive data
                and comply with regulatory requirements.
              </Typography>
            </Typography>
          </li>
        </ul>
        <Typography
          fontFamily="Roboto"
          fontWeight="700"
          fontSize={contentFontSize_20}
          sx={{ margin: "0 0 10px" }}
        >
          Cost Efficiency
        </Typography>
        <ul>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Benefit:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                Automation reduces manual effort and operational overhead,
                saving both time and resources.
              </Typography>
            </Typography>
          </li>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Example:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                Businesses using DevOps spend less on infrastructure maintenance
                by leveraging Infrastructure as Code (IaC).
              </Typography>
            </Typography>
          </li>
        </ul>
        <div>
          <Typography
            fontSize={contentFontsize_28}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px", letterSpacing: "1px" }}
          >
            How to Implement DevOps Successfully
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Start with a Cultural Shift
          </Typography>
        </div>
        <div>
          <ul>
            <li>
              <Typography
                fontSize={contentFontSize_18}
                fontFamily="Roboto"
                fontWeight="400"
                sx={{
                  textAlign: "justify",
                  color: "#6C7282",
                  marginTop: "10px",
                }}
              >
                Encourage collaboration and communication between development,
                operations, and other stakeholders.
              </Typography>
            </li>
          </ul>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Adopt the Right Tools
          </Typography>
        </div>
        <div>
          <ul>
            <li>
              <Typography
                fontSize={contentFontSize_18}
                fontFamily="Roboto"
                fontWeight="400"
                sx={{
                  textAlign: "justify",
                  color: "#6C7282",
                  marginTop: "10px",
                }}
              >
                Use DevOps tools like Jenkins, Kubernetes, Docker, and Terraform
                to automate tasks and manage infrastructure.
              </Typography>
            </li>
          </ul>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Build CI/CD Pipelines
          </Typography>
        </div>
        <div>
          <ul>
            <li>
              <Typography
                fontSize={contentFontSize_18}
                fontFamily="Roboto"
                fontWeight="400"
                sx={{
                  textAlign: "justify",
                  color: "#6C7282",
                  marginTop: "10px",
                }}
              >
                Set up pipelines to automate the build, test, and deployment
                process, ensuring faster and error-free releases.
              </Typography>
            </li>
          </ul>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Embrace Cloud-Native Practices
          </Typography>
        </div>
        <div>
          <ul>
            <li>
              <Typography
                fontSize={contentFontSize_18}
                fontFamily="Roboto"
                fontWeight="400"
                sx={{
                  textAlign: "justify",
                  color: "#6C7282",
                  marginTop: "10px",
                }}
              >
                Leverage cloud platforms to scale applications dynamically and
                reduce infrastructure costs.
              </Typography>
            </li>
          </ul>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Monitor and Improve Continuously
          </Typography>
        </div>
        <div>
          <ul>
            <li>
              <Typography
                fontSize={contentFontSize_18}
                fontFamily="Roboto"
                fontWeight="400"
                sx={{
                  textAlign: "justify",
                  color: "#6C7282",
                  marginTop: "10px",
                }}
              >
                Use monitoring tools to track performance and gather feedback
                for continuous improvement.
              </Typography>
            </li>
          </ul>
        </div>
        <div>
          <Typography
            fontSize={contentFontsize_28}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Common Challenges in DevOps Implementation (and How to Overcome
            Them)
          </Typography>
        </div>

        <div style={{ lineHeight: "1.8", marginTop: "20px" }}>
          <div style={{ marginBottom: "20px", marginTop: "20px" }}>
            <Typography
              fontFamily="Roboto"
              fontWeight="700"
              fontSize={contentFontSize_20}
              //   sx={{ margin: "0 0 10px" }}
            >
              Resistance to Change
            </Typography>
            {/* <ul style={{ margin: "0", paddingLeft: "20px" }}>
              <li style={{ color: "#6C7282", textAlign: "justify" }}> */}
            <ul>
              <li>
                <Typography fontSize={contentFontSize_18}>
                  <span className="digital">Solution:</span>

                  <Typography
                    fontSize={contentFontSize_18}
                    color="#6C7282"
                    fontWeight="400"
                    sx={{ margin: "0 0 10px" }}
                  >
                    {" "}
                    Educate teams on the benefits of DevOps and provide training
                    to ease the transition.
                  </Typography>
                </Typography>
              </li>
            </ul>
            {/* </li>
              <li style={{ color: "#6C7282", textAlign: "justify" }}> */}

            {/* </li>
            </ul> */}
          </div>

          <div style={{ marginBottom: "20px" }}>
            <Typography
              fontFamily="Roboto"
              fontWeight="700"
              fontSize={contentFontSize_20}
              //   sx={{ margin: "0 0 10px" }}
            >
              Tool Overload
            </Typography>
            {/* <ul style={{ margin: "0", paddingLeft: "20px" }}>
              <li style={{ color: "#6C7282", textAlign: "justify" }}> */}
            <ul>
              <li>
                <Typography fontSize={contentFontSize_18}>
                  <span className="digital">Solution:</span>

                  <Typography
                    fontSize={contentFontSize_18}
                    color="#6C7282"
                    fontWeight="400"
                    sx={{ margin: "0 0 10px" }}
                  >
                    {" "}
                    Focus on integrating tools that align with your business
                    goals and workflows.
                  </Typography>
                </Typography>
              </li>
            </ul>
            {/* </li>
              <li style={{ color: "#6C7282", textAlign: "justify" }}>
                <Typography
                  fontWeight="400"
                  fontFamily="Roboto"
                  fontSize={contentFontSize_16}
                >
                  Align objectives with overall business strategy to ensure
                  measurable impact.
                </Typography>
              </li>
            </ul> */}
          </div>

          <div>
            <Typography
              fontFamily="Roboto"
              fontWeight="700"
              fontSize={contentFontSize_20}
              //   sx={{ margin: "0 0 10px" }}
            >
              Security Concerns
            </Typography>
            <ul>
              <li>
                <Typography fontSize={contentFontSize_18}>
                  <span className="digital">Solution:</span>

                  <Typography
                    fontSize={contentFontSize_18}
                    color="#6C7282"
                    fontWeight="400"
                    sx={{ margin: "0 0 10px" }}
                  >
                    {" "}
                    Incorporate DevSecOps practices to ensure that security is
                    embedded throughout the development lifecycle.
                  </Typography>
                </Typography>
              </li>
            </ul>
          </div>
        </div>

        <div>
          <Typography
            fontFamily="Roboto"
            fontWeight="700"
            fontSize={contentFontsize_28}
          >
            Conclusion
          </Typography>
        </div>
        <Typography
          fontSize={contentFontSize_18}
          color="#6C7282"
          fontWeight="400"
          textAlign="justify"
        >
          DevOps is no longer a buzzword—it’s a necessity for businesses looking
          to stay competitive in today’s fast-evolving market. By automating
          processes, improving collaboration, and enhancing software quality,
          DevOps enables businesses to deliver faster, more reliable solutions.
        </Typography>
      </div>
      <div
        className="bg-img"
        style={{
          backgroundImage: `url(${Common})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          // height: "500px",
          position: "relative",
          color: "white",
          padding: "20px", // Add spacing for responsiveness
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="blog-text1">
          {matches ? (
            <Typography
              fontSize={contentFontSize_50}
              fontWeight="700"
              sx={{
                marginBottom: "10px",
                color: "#FFD700",
                marginTop: smmatch ? "0px" : matches ? "50px" : "160px",
                padding: matches ? "10px 0px 10px 40px" : "0px",
                textAlign: matches ? "center" : null,
              }}
            >
              Begin Your Digital Transformation Journey with Axia Kairos
            </Typography>
          ) : (
            <Typography
              fontSize={contentFontSize_50}
              fontWeight="700"
              sx={{
                marginBottom: "10px",
                color: "#FFD700",
                marginTop: smmatch ? "0px" : matches ? "50px" : "160px",
                padding: matches ? "10px 0px 10px 40px" : "0px",
                textAlign: matches ? "center" : null,
              }}
            >
              Begin Your Digital Transformation Journey <br /> with Axia Kairos
            </Typography>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: matches && "center",
            padding: smmatch ? "0px" : "10px 40px",
            flexDirection: lgmatch ? "column" : "row",
          }}
        >
          <div style={{ width: "70%" }}>
            <Typography
              fontFamily="Roboto"
              fontWeight="400"
              fontSize={contentFontSize_20}
              style={{
                textAlign: matches ? "center" : "left",
                lineHeight: "1.6",
                marginBottom: "20px",
                color: "#FFFFFF", // White paragraph text
              }}
            >
              Ready to redefine your business with digital transformation? Axia
              Kairos delivers innovative strategies and cutting-edge
              technologies to help your organization adapt and thrive.
            </Typography>
          </div>
          <div style={{ marginTop: "10px" }}>
            <Button
              variant="contained"
              sx={{
                backgroundImage:
                  "linear-gradient(90deg, #DFAD17 0%, #795E0C 100%)",
                width: "300px",
                height: "40px",
                borderRadius: "15px",
                // padding: "10px 20px",
                // fontSize: "16px",
                // backgroundColor: "#FFC107",
                // color: "white",
                // border: "none",
                // borderRadius: "5px",
                // cursor: "pointer",
                // fontWeight: "500",
              }}
              onClick={handleContactClick}
            >
              <Typography
                //fontFamily="Roboto"
                sx={{ textTransform: "capitalize", padding: "15px" }}
              >
                Start Your Transformation Today
              </Typography>
            </Button>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default DevopsBlog;
