import React, { useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import {
  contentFontSize_15,
  contentFontSize_16,
  contentFontSize_18,
  contentFontSize_20,
  contentFontsize_24,
  contentFontsize_28,
  contentFontSize_50,
  contentFontsize_96,
  HeaderFontSize_50_xs_33,
} from "../../Typography";
import Common from "../../utils/images/Common.jpg";
import "./DigitalTransformation.css";
import Navbar from "../../Navbar";
import CyberSecurityS from "../../utils/images/CyberSecurityS.png";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Footer from "../../Footer/Footer";
import WhiteArrow from "../../utils/icons/WhiteArrow.svg"
import { useNavigate } from "react-router-dom";
function CyberSecurity() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const nav = useNavigate()
  function handleService(){
      nav("/services")
  }
  const cards = [
    {
      title: "Minimized Risk of Breaches",
      description:
        "Our proactive security measures significantly reduce the risk of data breaches and cyberattacks, ensuring your business stays safe",
    },
    {
      title: "Regulatory Compliance",
      description:
        "We help you stay compliant with industry regulations and standards, reducing the risk of penalties and ensuring your business meets all security requirements.",
    },
    {
      title: "Improved Incident Response",
      description:
        "With continuous monitoring and rapid response capabilities, we help you quickly mitigate security threats, minimizing downtime and potential damages.",
    },
    {
      title: "Business Continuity & Trust",
      description:
        "By protecting your systems and data, we ensure business continuity and maintain the trust of your clients and partners, ensuring long-term success.",
    },
  ];
  const data = [
    {
      id: "01",
      title: "Pioneers in Transformation",
      content:
        "We are at the forefront of leveraging digital technologies to drive meaningful change. Whether it’s adopting cloud solutions, integrating AI, or enhancing user experiences, we help businesses turn ideas into reality as a trusted business IT solutions provider.",
    },
    {
      id: "02",
      title: "Personalized for You",
      content:
        "No two businesses are the same, and neither are our solutions. We tailor every strategy, platform, and process to your unique goals and challenges, ensuring maximum impact.",
    },
    {
      id: "03",
      title: "Built for Long-Term Success",
      content:
        "Our solutions aren’t just designed for today—they’re crafted to evolve with you, ensuring lasting value in an ever-changing landscape.",
    },
    {
      id: "04",
      title: "Driven by Expertise",
      content:
        "Our team of seasoned professionals brings together global experience, industry knowledge, and a passion for innovation to deliver results that matter.",
    },
  ];

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));
  const dataService = [
    {
      title: "Consultation & Risk Assessment",

      description:
        "We start by understanding your business, identifying potential vulnerabilities, and conducting a risk assessment to define the right security strategy.  ",
    },
    {
      title: "Security Design & Strategy",

      description:
        "Our experts design a comprehensive cybersecurity strategy, tailored to your specific needs, ensuring robust protection for all aspects of your digital environment. ",
    },
    {
      title: "Implementation & Integration",

      description:
        "We implement advanced security measures and integrate them into your existing infrastructure, ensuring minimal disruption while maximizing protection. ",
    },
  ];
  const dataService1 = [
    {
      title: "Continuous Monitoring & Response",

      description:
        "With our managed services, we monitor your systems 24/7, responding to threats and breaches in real time, ensuring your systems are always protected. ",
    },
    {
      title: "Ongoing Support & Optimization",

      description:
        "We continuously optimize your security infrastructure, keeping you ahead of emerging threats and ensuring your systems remain secure and compliant",
    },
  ];
  function handleContact(){
    nav("/contact")
  }
  return (
    <div>
      <Navbar />
      <div
        className="bg-img-1"
        style={{
          backgroundImage: `url(${CyberSecurityS})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          //height: "500px",
          position: "relative",
          color: "white",

          padding: smmatch ? "0px" : "20px", // Add spacing for responsiveness
          backgroundRepeat: "no-repeat",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: smmatch ? "center" : "flex-start", // Center for small screens
        }}
      >
         <div className="back-btn" onClick={handleService}>
                                <img src={WhiteArrow} style={{ marginTop:smmatch ? "18px" : mdmatch && "4px"}} />
                                <span
                                  style={{
                                    marginLeft: "10px",
                                    fontSize: contentFontSize_18,
                                    fontFamily: "Roboto",
                                    marginBottom: "3px",
                                     marginTop:smmatch && "15px"
                                  }}
                                >
                                  Back to Services
                                </span>
                              </div>
        <div className="blog-text1">
          {/* {matches ? ( */}
          <div>
            <Typography
              fontSize={contentFontSize_50}
              fontWeight="700"
              sx={{
                marginBottom: "10px",
                color: "white",
                marginTop: smmatch ? "0px" : matches ? "50px" : "300px",
                padding: matches ? "50px 0px 10px 40px" : "0px",
                textAlign: matches ? "center" : null,
              }}
            >
              Cyber Security: Protect Your Business with Robust, Tailored
              Solutions
            </Typography>
            <div>
              <Typography
                fontSize={contentFontSize_20}
                fontWeight="400"
                fontFamily="Roboto"
                sx={{
                  padding: matches ? "10px 0px 10px 40px" : "0px",
                  textAlign: matches ? "center" : null,
                }}
              >
                Safeguard Your Data, Systems, and Reputation with Advanced
                Security Measures
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div className="service-sub-content">
        <Typography
          fontFamily="Roboto"
          fontWeight="400"
          fontSize={contentFontSize_18}
          sx={{ textAlign: "justify", color: "#6C7282" }}
        >
          In an increasingly digital world, protecting your business from cyber
          threats is crucial. At Axia Kairos, we provide comprehensive cyber
          security solutions that are customized to your unique needs. Our team
          of experts ensures that your data, applications, and IT infrastructure
          are secure from emerging threats. With advanced security technologies,
          proactive monitoring, and strategic planning, we help you safeguard
          your organization and maintain the trust of your customers.
        </Typography>
        <Typography
          fontFamily="Roboto"
          fontSize={contentFontsize_28}
          fontWeight="700"
          color="#B97E12"
          sx={{ marginTop: "15px" }}
        >
          What We Do and Offer
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Network Security
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          We implement robust network security measures that protect your
          organization from unauthorized access, data breaches, and
          cyberattacks. Our solutions include firewalls, intrusion detection
          systems, and network monitoring to ensure the integrity of your
          network.
        </Typography>
        <ul style={{ paddingLeft: "20px", listStyleType: "disc" }}>
          <li>
            <Typography
              fontFamily="Roboto"
              fontWeight="600"
              fontSize={contentFontSize_20}
            >
              Firewall Configuration & Management:
            </Typography>
            <Typography
              style={{ marginLeft: "20px", padding: "10px" }}
              fontWeight="400"
              fontSize={contentFontSize_18}
              color="#6C7282"
            >
              We configure and manage firewalls to block malicious traffic and
              unauthorized access to your systems, ensuring your network remains
              secure.
            </Typography>
          </li>
          <li>
            <Typography
              fontFamily="Roboto"
              fontWeight="600"
              fontSize={contentFontSize_20}
            >
              Intrusion Detection & Prevention Systems (IDPS):
            </Typography>
            <Typography
              style={{ marginLeft: "20px", padding: "10px" }}
              fontWeight="400"
              fontSize={contentFontSize_18}
              color="#6C7282"
            >
              Our IDPS solutions monitor your network traffic and alert you to
              potential threats, providing real-time response capabilities to
              mitigate attacks.
            </Typography>
          </li>
        </ul>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Application Security
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Securing applications is essential to prevent vulnerabilities that can
          be exploited by hackers. We perform comprehensive security
          assessments, vulnerability testing, and patch management to ensure
          your applications are protected from security flaws.
        </Typography>
        <ul style={{ paddingLeft: "20px", listStyleType: "disc" }}>
          <li>
            <Typography
              fontFamily="Roboto"
              fontWeight="600"
              fontSize={contentFontSize_20}
            >
              Web Application Firewalls (WAF)
            </Typography>
            <Typography
              style={{ marginLeft: "20px", padding: "10px" }}
              fontWeight="400"
              fontSize={contentFontSize_18}
              color="#6C7282"
            >
              We deploy WAFs to protect your web applications from attacks like
              SQL injection, cross-site scripting (XSS), and other
              vulnerabilities.
            </Typography>
          </li>
          <li>
            <Typography
              fontFamily="Roboto"
              fontWeight="600"
              fontSize={contentFontSize_20}
            >
              Security Testing & Code Review
            </Typography>
            <Typography
              style={{ marginLeft: "20px", padding: "10px" }}
              fontWeight="400"
              fontSize={contentFontSize_18}
              color="#6C7282"
            >
              Our experts perform code reviews and security testing to identify
              and address any potential weaknesses in your software
              applications.
            </Typography>
          </li>
        </ul>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Endpoint Security
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Your organization’s endpoints (devices such as laptops, smartphones,
          and tablets) are common targets for cyber threats. We deploy
          comprehensive endpoint security solutions that monitor and protect all
          devices connected to your network.
        </Typography>
        <ul style={{ paddingLeft: "20px", listStyleType: "disc" }}>
          <li>
            <Typography
              fontFamily="Roboto"
              fontWeight="600"
              fontSize={contentFontSize_20}
            >
              Anti-Malware & Antivirus Solutions
            </Typography>
            <Typography
              style={{ marginLeft: "20px", padding: "10px" }}
              fontWeight="400"
              fontSize={contentFontSize_18}
              color="#6C7282"
            >
              We implement the latest anti-malware and antivirus software to
              detect and eliminate malicious threats before they cause harm.
            </Typography>
          </li>
          <li>
            <Typography
              fontFamily="Roboto"
              fontWeight="600"
              fontSize={contentFontSize_20}
            >
              Mobile Device Management (MDM)
            </Typography>
            <Typography
              style={{ marginLeft: "20px", padding: "10px" }}
              fontWeight="400"
              fontSize={contentFontSize_18}
              color="#6C7282"
            >
              Ensure the security of mobile devices by managing their access and
              enforcing security policies, keeping your data safe even when
              employees work remotely.
            </Typography>
          </li>
        </ul>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Data Protection & Encryption
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Protect your business-critical data with encryption and advanced
          protection strategies. We ensure that your sensitive information is
          safe, both at rest and in transit, minimizing the risk of data theft
          and unauthorized access.
        </Typography>
        <ul style={{ paddingLeft: "20px", listStyleType: "disc" }}>
          <li>
            <Typography
              fontFamily="Roboto"
              fontWeight="600"
              fontSize={contentFontSize_20}
            >
              Data Encryption & Backup Solutions
            </Typography>
            <Typography
              style={{ marginLeft: "20px", padding: "10px" }}
              fontWeight="400"
              fontSize={contentFontSize_18}
              color="#6C7282"
            >
              We provide encryption solutions to protect your data at rest and
              during transmission. Additionally, we implement secure backup
              solutions to ensure data availability in case of cyber incidents.
            </Typography>
          </li>
          <li>
            <Typography
              fontFamily="Roboto"
              fontWeight="600"
              fontSize={contentFontSize_20}
            >
              Data Loss Prevention (DLP)
            </Typography>
            <Typography
              style={{ marginLeft: "20px", padding: "10px" }}
              fontWeight="400"
              fontSize={contentFontSize_18}
              color="#6C7282"
            >
              Our DLP systems prevent unauthorized sharing and leakage of
              sensitive data, ensuring that valuable company data stays secure.
            </Typography>
          </li>
        </ul>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Identity & Access Management (IAM)
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Identity and access management solutions are crucial for controlling
          who has access to your systems and data. We provide comprehensive IAM
          strategies that ensure only authorized users can access sensitive
          resources.
        </Typography>
        <ul style={{ paddingLeft: "20px", listStyleType: "disc" }}>
          <li>
            <Typography
              fontFamily="Roboto"
              fontWeight="600"
              fontSize={contentFontSize_20}
            >
              Multi-Factor Authentication (MFA)
            </Typography>
            <Typography
              style={{ marginLeft: "20px", padding: "10px" }}
              fontWeight="400"
              fontSize={contentFontSize_18}
              color="#6C7282"
            >
              We implement MFA to add an extra layer of security, requiring
              multiple forms of verification before granting access to sensitive
              information.
            </Typography>
          </li>
          <li>
            <Typography
              fontFamily="Roboto"
              fontWeight="600"
              fontSize={contentFontSize_20}
            >
              Role-Based Access Control (RBAC)
            </Typography>
            <Typography
              style={{ marginLeft: "20px", padding: "10px" }}
              fontWeight="400"
              fontSize={contentFontSize_18}
              color="#6C7282"
            >
              We define access privileges based on user roles to ensure that
              employees only have access to the information necessary for their
              tasks.
            </Typography>
          </li>
        </ul>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Security Operations Center (SOC) & Managed Detection and Response
          (MDR)
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          With 24/7 monitoring and advanced threat detection capabilities, our
          Security Operations Center (SOC) and Managed Detection and Response
          (MDR) services ensure that your systems are constantly protected from
          evolving cyber threats.
        </Typography>
        <ul style={{ paddingLeft: "20px", listStyleType: "disc" }}>
          <li>
            <Typography
              fontFamily="Roboto"
              fontWeight="600"
              fontSize={contentFontSize_20}
            >
              24/7 Monitoring & Incident Response
            </Typography>
            <Typography
              style={{ marginLeft: "20px", padding: "10px" }}
              fontWeight="400"
              fontSize={contentFontSize_18}
              color="#6C7282"
            >
              Our SOC team monitors your infrastructure around the clock,
              identifying potential security threats and responding in real-time
              to mitigate damage.
            </Typography>
          </li>
          <li>
            <Typography
              fontFamily="Roboto"
              fontWeight="600"
              fontSize={contentFontSize_20}
            >
              Threat Hunting & Forensics
            </Typography>
            <Typography
              style={{ marginLeft: "20px", padding: "10px" }}
              fontWeight="400"
              fontSize={contentFontSize_18}
              color="#6C7282"
            >
              We actively search for signs of cyber threats within your network
              and perform digital forensics to identify the root cause of
              security breaches.
            </Typography>
          </li>
        </ul>

        <Typography
          fontFamily="Roboto"
          fontSize={contentFontsize_28}
          fontWeight="700"
          color="#B97E12"
          sx={{ marginTop: "15px" }}
        >
          Additional Services in Cyber Security
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Cloud Security
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Protect your cloud-based systems and data with comprehensive cloud
          security solutions. We help secure your cloud infrastructure,
          applications, and data, ensuring that your business remains safe while
          leveraging the scalability and flexibility of the cloud.
        </Typography>
        <ul style={{ paddingLeft: "20px", listStyleType: "disc" }}>
          <li>
            <Typography
              fontFamily="Roboto"
              fontWeight="600"
              fontSize={contentFontSize_20}
            >
              Cloud Access Security Brokers (CASBs)
            </Typography>
            <Typography
              style={{ marginLeft: "20px", padding: "10px" }}
              fontWeight="400"
              fontSize={contentFontSize_18}
              color="#6C7282"
            >
              We deploy CASBs to monitor and enforce security policies across
              cloud applications and services, ensuring that your data is
              protected and compliant.
            </Typography>
          </li>
        </ul>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Compliance & Risk Management
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Ensure that your business adheres to industry regulations and
          standards (such as GDPR, HIPAA, PCI DSS) with our compliance and risk
          management services. We help assess your security posture and
          implement the necessary controls to meet compliance requirements.
        </Typography>
        <ul style={{ paddingLeft: "20px", listStyleType: "disc" }}>
          <li>
            <Typography
              fontFamily="Roboto"
              fontWeight="600"
              fontSize={contentFontSize_20}
            >
              Regulatory Compliance Audits
            </Typography>
            <Typography
              style={{ marginLeft: "20px", padding: "10px" }}
              fontWeight="400"
              fontSize={contentFontSize_18}
              color="#6C7282"
            >
              We perform thorough audits to ensure your business complies with
              applicable industry regulations, identifying areas for
              improvement.
            </Typography>
          </li>
          <li>
            <Typography
              fontFamily="Roboto"
              fontWeight="600"
              fontSize={contentFontSize_20}
            >
              Risk Assessment & Mitigation
            </Typography>
            <Typography
              style={{ marginLeft: "20px", padding: "10px" }}
              fontWeight="400"
              fontSize={contentFontSize_18}
              color="#6C7282"
            >
              We conduct risk assessments to identify potential vulnerabilities
              and threats, and develop strategies to mitigate them, reducing
              your overall risk.
            </Typography>
          </li>
        </ul>
      </div>
      <div className="about-us-second">
        <div className="main-heading-about-us">
          <Typography
            sx={{ fontWeight: "700", fontFamily: "Roboto", marginTop: "50px",textAlign: smmatch && "center" }}
            fontSize={HeaderFontSize_50_xs_33}
          >
            Why Choose Axia Kairos?
          </Typography>
        </div>
        <Typography
          fontSize={contentFontSize_20}
          fontWeight="400"
          color="white"
          fontFamily="Roboto"
          sx={{
            paddingLeft: mdmatch ? "10px" : "150px",
            paddingRight: mdmatch ? "10px" : "150px",
            textAlign: "center",
            paddingBottom: "50px",
          }}
        >
          Axia Kairos brings a wealth of expertise in cybersecurity, providing
          businesses with customized solutions to protect their critical assets.
          Our team combines cutting-edge technology with industry best practices
          to secure your infrastructure, applications, and data. With a
          proactive, layered approach to security, we help businesses stay one
          step ahead of cybercriminals while ensuring business continuity,
          compliance, and a safe digital environment.
        </Typography>
        <div className="about-us-div">
          {data.map((item) => (
            <div className="about-us-card" key={item.id}>
              <Typography
                fontSize={contentFontsize_96}
                fontFamily="Roboto"
                fontWeight="100"
                sx={{ color: "#EBB81A", lineHeight: "98px" }}
              >
                {item.id}
              </Typography>
              <Typography
                sx={{
                  color: "#ECECEC",
                  textAlign: "left",
                  lineHeight: "31px",
                  marginBottom: "10px",
                }}
                fontSize={contentFontsize_24}
                fontFamily="Roboto"
              >
                {item.title}
              </Typography>
              <Typography
                sx={{
                  color: "#ECECEC",
                  textAlign: "left",
                  lineHeight: "31px",
                  fontWeight: "400",
                }}
                fontSize={contentFontSize_16}
                fontFamily="Roboto"
              >
                {item.content}
              </Typography>
              {/* <div className="imge-section-about-us">
                <img src={Line}/>
              </div> */}
            </div>
          ))}
        </div>
      </div>
      <div className="third-page-service">
        <div style={{ marginTop: "50px", marginBottom: "50px" }}>
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              textAlign: "center",
            }}
            fontWeight="700"
            fontFamily="Roboto"
            fontSize={contentFontSize_50}
          >
            Our Proven Approach to Cyber Security
          </Typography>
        </div>
        <div className="secnd-sec">
          <div className="flex-card-in">
            {dataService.map((card, index) => (
              <Card
                className="about-card-service"
                key={index}
                sx={{ borderRadius: "15px", background: "#9F6907" }}
              >
                <div className="about-content-card-service">
                  <Typography
                    fontWeight="700"
                    fontSize={contentFontsize_24}
                    fontFamily="Roboto"
                    className="about-card-title"
                  >
                    {card.title}
                  </Typography>
                  <Typography
                    fontWeight="400"
                    fontSize={contentFontSize_18}
                    fontFamily="Roboto"
                    color="white"
                    className="about-content-1-service"
                  >
                    {card.description}
                  </Typography>
                </div>
              </Card>
            ))}
          </div>
        </div>
        <div className="secnd-sec">
          <div className="flex-card-in-1">
            {dataService1.map((card, index) => (
              <Card
                className="about-card-service-1"
                key={index}
                sx={{
                  borderRadius: "15px",
                  background: "#9F6907",
                  marginTop: "20px",
                }}
              >
                <div className="about-content-card-service">
                  <Typography
                    fontWeight="700"
                    fontSize={contentFontsize_24}
                    fontFamily="Roboto"
                    className="about-card-title"
                  >
                    {card.title}
                  </Typography>
                  <Typography
                    fontWeight="400"
                    fontSize={contentFontSize_18}
                    fontFamily="Roboto"
                    color="white"
                    className="about-content-1-service"
                  >
                    {card.description}
                  </Typography>
                </div>
              </Card>
            ))}
          </div>
        </div>
      </div>
      <div className="main-third">
        <div className="about-us-text1">
          <Typography
            fontFamily="Roboto"
            fontWeight="500"
            fontSize={contentFontSize_50}
            sx={{
              paddingTop: mdmatch ? "50px" : "200px",
              textAlign: matches && "center",
            }}
          >
            How Our Cyber Security Solutions Ensure Long-Term Success
          </Typography>
        </div>

        <div className="third-sub">
          {cards.map((card, index) => (
            <div
              key={index}
              //   ref={(el) => (cardRefs.current[index] = el)}
              data-index={index}
              //   className={`card-wrapper-about-us ${
              //     visibleCards.includes(index.toString()) ? "active" : ""
              //   }`}
              style={{
                display: "flex",
                flexDirection: mdmatch ? null : "row",
                justifyContent: mdmatch ? null : "space-between",
                width: mdmatch ? null : "100%",
              }}
            >
              <div
              // className={`side-item-about-us ${
              //   visibleCards.includes(index.toString()) ? "active" : ""
              // }`}
              ></div>

              <Card
                sx={{
                  borderRight: "5px solid #B97E12",
                  background: "#DFDFDF",
                  width: matches ? "100%" : "90%",
                  // color: "white",
                  transition: "all 0.5s ease",
                  height: mdmatch ? "100%" : "160px",
                  borderRadius: "10px",
                }}
                // className={`card-content ${
                //   visibleCards.includes(index.toString()) ? "active" : ""
                // }`}
              >
                <CardContent>
                  <div
                    className="card-title-about-us"
                    style={{ marginBottom: "10px" }}
                  >
                    <Typography
                      fontWeight="700"
                      fontSize={contentFontSize_20}
                      fontFamily="Roboto"
                      //color="black"
                    >
                      {card.title}
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      fontWeight="400"
                      fontSize={contentFontSize_18}
                      fontFamily="Roboto"
                      className="card-description-about-us"
                    >
                      {card.description}
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            </div>
          ))}
        </div>
      </div>
      <div
        className="bg-img"
        style={{
          backgroundImage: `url(${Common})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          // height: "500px",
          position: "relative",
          color: "white",
          padding: "20px", // Add spacing for responsiveness
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="blog-text1">
          {matches ? (
            <Typography
              fontSize={contentFontSize_50}
              fontWeight="700"
              sx={{
                marginBottom: "10px",
                color: "#FFD700",
                marginTop: smmatch ? "0px" : matches ? "50px" : "160px",
                padding: matches ? "10px 0px 10px 40px" : "0px",
                textAlign: matches ? "center" : null,
              }}
            >
              Unlock the Power of AI, Blockchain & IoT for Your Business
            </Typography>
          ) : (
            <Typography
              fontSize={contentFontSize_50}
              fontWeight="700"
              sx={{
                marginBottom: "10px",
                color: "#FFD700",
                marginTop: smmatch ? "0px" : matches ? "50px" : "160px",
                padding: matches ? "10px 0px 10px 40px" : "0px",
                textAlign: matches ? "center" : null,
              }}
            >
              Protect Your Business from Emerging Cyber Threats
            </Typography>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: matches && "center",
            padding: smmatch ? "0px" : "10px 40px",
            flexDirection: lgmatch ? "column" : "row",
          }}
        >
          <div style={{ width: "70%" }}>
            <Typography
              //fontFamily="Roboto"
              fontWeight="400"
              fontSize={contentFontSize_20}
              style={{
                textAlign: matches ? "center" : "left",
                lineHeight: "1.6",
                marginBottom: "20px",
                color: "#FFFFFF", // White paragraph text
              }}
            >
              Axia Kairos provides comprehensive cybersecurity solutions
              designed to safeguard your organization from cyber threats. From
              network security to cloud protection, we offer end-to-end security
              services that protect your business and enable it to thrive in a
              secure environment.
            </Typography>
          </div>
          <div style={{ marginTop: "10px" }}>
            <Button
              variant="contained"
              onClick={handleContact}
              sx={{
                backgroundImage:
                  "linear-gradient(90deg, #DFAD17 0%, #795E0C 100%)",
                width: "300px",
                height: "40px",
                borderRadius: "15px",
                // padding: "10px 20px",
                // fontSize: "16px",
                // backgroundColor: "#FFC107",
                // color: "white",
                // border: "none",
                // borderRadius: "5px",
                // cursor: "pointer",
                // fontWeight: "500",
              }}
            >
              <Typography
                //fontFamily="Roboto"
                sx={{ textTransform: "capitalize", padding: "15px" }}
              >
                Get Started Today
              </Typography>
            </Button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default CyberSecurity;
