import React from "react";
import "./Choose.css";
import { Typography } from "@mui/material";
import {
  contentFontSize_18_xs_13,
  contentFontSize_20_xs_17,
  HeaderFontSize_50,
} from "../../Typography";
import choose from "../../utils/images/choose.jpg";

function Choose() {
  return (
    <>
      <div className="Choose-main">
        <div className="inner-choose">
          <div className="inner-content">
            <Typography
              fontSize={HeaderFontSize_50}
              className="font-welcome-about"
              style={{ fontWeight: 700 }}
              sx={{
                "@media (max-width: 480px)": {
                  lineHeight: 1.3,
                },
              }}
            >
              Why Choose Axia Kairos?
            </Typography>
            <div className="choose-content">
              <Typography
                fontSize={contentFontSize_18_xs_13}
                style={{ lineHeight: 1.8 }}
              >
                At Axia Kairos, we don’t just provide services—we create
                solutions that propel your business forward. Our team of experts
                brings deep industry knowledge, technical expertise, and a
                commitment to delivering results that matter.
              </Typography>
            </div>
          </div>
          <div>
            <img src={choose} alt="Loading..." className="img-choose" />
          </div>
        </div>
        <div className="side-section">
          <div className="side-item">
            <Typography
              fontSize={contentFontSize_20_xs_17}
              className="font-header"
              style={{ fontWeight: 600 }}
            >
              Industry Expertise
            </Typography>
            <Typography
              fontSize={contentFontSize_18_xs_13}
              style={{ lineHeight: 1.6 }}
            >
              With years of experience, we deliver tailored solutions that meet
              your unique needs.
            </Typography>
          </div>
          <div className="side-item">
            <Typography
              fontSize={contentFontSize_20_xs_17}
              className="font-header"
              style={{ fontWeight: 600 }}
            >
              Quality Services
            </Typography>
            <Typography
              fontSize={contentFontSize_18_xs_13}
              style={{ lineHeight: 1.6 }}
            >
              We provide reliable, high-performance services that drive business
              success.
            </Typography>
          </div>
          <div className="side-item">
            <Typography
              fontSize={contentFontSize_20_xs_17}
              className="font-header"
              style={{ fontWeight: 600 }}
            >
              Innovation
            </Typography>
            <Typography
              fontSize={contentFontSize_18_xs_13}
              style={{ lineHeight: 1.6 }}
            >
              We embrace the latest technologies and trends to deliver
              cutting-edge solutions that set you apart from the competition.
            </Typography>
          </div>
          <div className="side-item">
            <Typography
              fontSize={contentFontSize_20_xs_17}
              className="font-header"
              style={{ fontWeight: 600 }}
            >
              Long-Term Partnership
            </Typography>
            <Typography
              fontSize={contentFontSize_18_xs_13}
              style={{ lineHeight: 1.6 }}
            >
              We’re not just a service provider—we’re a partner invested in your
              long-term success.
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
}

export default Choose;
