import { Typography } from "@mui/material";
import React from "react";
import {
  contentFontSize_18,
  contentFontSize_20,
  contentFontsize_64,
} from "../../Typography";
import "./Industries.css";
import IndustriesCard from "./IndustriesCard";

function IndustriesHero(){
  return (
    <>
      <div className="main-industries">
        <div className="service-section">
          <Typography
            fontWeight="500"
            fontSize={contentFontsize_64}
            className="service-section-text"
          >
            Transforming Industries with <br />
            Tailored Digital Solutions
          </Typography>
        </div>
        <div className="service-section">
          <Typography
            fontWeight="500"
            fontSize={contentFontSize_20}
            // className="service-section-sub"
          >
            Empowering Diverse Sectors through Innovation and Technology
          </Typography>
        </div>
        <div className="service-section">
          <Typography
            fontWeight="400"
            fontSize={contentFontSize_18}
            sx={{ marginTop: "20px", color: " #6C7282", textAlign: "justify" }}
            className="service-section-sub"
          >
            Axia Kairos understands that each industry faces distinct challenges
            and opportunities. We provide tailored digital solutions for
            industries to help businesses stay competitive and thrive. With
            extensive industry knowledge, we offer custom IT solutions for
            businesses that are strategically designed to align with your
            specific goals. Whether you're aiming to optimize operations,
            improve customer engagement, or remain ahead of market trends, Axia
            Kairos is your trusted partner in digital transformation for
            industries.
          </Typography>
        </div>
      </div>
      <IndustriesCard/>
    </>
  );
}

export default IndustriesHero;
