import React from "react";
import Workingpeople from "../../utils/images/workingpeople.jpg";
import "./Business.css";
import { Typography } from "@mui/material";
import {
  HeaderFontSize_25,
  HeaderFontsize_36,
  HeaderFontSize_40,
  HeaderFontSize_42,
  HeaderFontSize_45,
  HeaderNavigationFontSize_12,
  HeaderNavigationFontSize_20,
  HeaderNavigationFontSize_22,
} from "../../Typography";
import Arrow from "../../utils/icons/ArrowHome.svg";

function Business() {
  return (
    <>
      <div className="main-div">
        <div>
          <img src={Workingpeople} alt="Loading..." className="working-image" />
        </div>
        <div className="home-heading-section">
          <Typography
            fontSize={HeaderFontSize_42}
            className="main-homefont"
            style={{ fontWeight: 600, lineHeight: 1.2 }}
          >
            Generate Business Value and Accelerated Growth{" "}
            <span className="responsive-break"> </span>
            with<span className="responsive-break-01"> </span> Our Digital
            Transformation Solutions
          </Typography>
          <Typography
            fontSize={HeaderNavigationFontSize_22}
            className="time-to-value"
          >
            Innovate, Transform, Lead
          </Typography>
          <div className="container-01">
            <img src={Arrow} alt="Loading..." className="arrow-home" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Business;
