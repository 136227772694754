import { Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import "./InnerPage.css";
import React, { useEffect } from "react";
import BackArrow from "../utils/icons/BackArrow.svg";
import Blog8 from "../utils/images/Blog8.webp";
import Gradient from "../utils/images/Gradient.jpg";
import Name from "../utils/icons/Name.svg";
import calender from "../utils/icons/calender.svg";
import clock from "../utils/icons/clock.svg";
import Common from "../utils/images/Common.jpg";
import {
  contentFontSize_16,
  contentFontSize_18,
  contentFontSize_20,
  contentFontsize_28,
  contentFontSize_48,
  contentFontSize_50,
} from "../Typography";
import Navbar from "../Navbar";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";
function AiBlog() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));
  const nav = useNavigate();
  const handleBackClick = () => {
    nav("/blogs");
  };
  const handleContactClick = () => {
    nav("./contact");
  };
  return (
    <div>
      <>
        <Navbar />
      </>
      <div className="back-btn" onClick={handleBackClick}>
        <img src={BackArrow}  style={{marginTop:mdmatch && "5px"}} />
        <span
          style={{
            marginLeft: "10px",
            fontSize: contentFontSize_18,
            fontFamily: "Roboto",
            marginBottom: "3px",
          }}
        >
          Back to Blogs
        </span>
      </div>
      <div
        className="responsive-container"
        style={{
          backgroundImage: xsmatch ? `url(${Gradient})` : `url(${Blog8})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "500px",
          position: "relative",
          color: "white",
          display: "flex",
          width: "100%",
          //justifyContent: "center",
          alignItems: "flex-end",
          marginTop: "30px",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="blog-text">
          <Typography
            fontSize={contentFontSize_48}
            fontWeight="700"
            marginLeft="0px"
          >
            How AI, Blockchain, and IoT are Transforming Industries
          </Typography>
        </div>
      </div>
      {/* <div className="blog-section-one">
        <div className="blog-section-sub">
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={Name} alt="Profile" style={{ marginRight: "5px" }} />
            <span
              style={{
                fontFamily: "Roboto",
                fontWeight: "500",
                fontSize: "14px",
              }}
            >
              Shyam Pushkar
            </span>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={calender} alt="calender" style={{ marginRight: "0px" }} />
            <span
              style={{
                marginLeft: "10px",
                fontFamily: "Roboto",
                fontWeight: "500",
                fontSize: "14px",
              }}
            >
              January 21 2024
            </span>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={clock} alt="clock" style={{ marginRight: "0px" }} />
            <span
              style={{
                marginLeft: "10px",
                fontFamily: "Roboto",
                fontWeight: "500",
                fontSize: "14px",
                textAlign: "center",
              }}
            >
              8:25
            </span>
          </div>
        </div>
      </div> */}
      <div className="main">
        <div>
          <Typography
            fontSize={contentFontsize_28}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Introduction
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            lineHeight="31px"
            sx={{
              textAlign: "justify",
              color: "#6C7282",
              marginTop: "10px",
              // letterSpacing: "1px",
            }}
          >
            The convergence of Artificial Intelligence (AI), Blockchain, and the
            Internet of Things (IoT) is revolutionizing industries by enabling
            smarter operations, enhanced security, and greater connectivity.
            Together, these technologies are reshaping how businesses operate,
            process data, and deliver value to their customers. This blog
            explores the transformative impact of AI, Blockchain, and IoT across
            various industries and how businesses can leverage these innovations
            for growth.
          </Typography>
        </div>
        {/* <div>
          <Typography
            fontSize={contentFontsize_28}
             fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px",  }}
          >
            What is Digital Transformation?
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
             fontFamily="Roboto"
            fontWeight="400"
            lineHeight="31px"
            sx={{
              textAlign: "justify",
              color: "#6C7282",
              marginTop: "10px",
              //letterSpacing: "1px",
            }}
          >
            Digital transformation involves integrating digital technologies
            into all areas of a business, fundamentally changing how it operates
            and delivers value to customers. It encompasses a shift in culture,
            processes, and strategies to leverage the power of technology for
            innovation and growth.
          </Typography>
        </div> */}
        <div>
          <Typography
            fontSize={contentFontsize_28}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            How AI, Blockchain, and IoT Work Together
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Artificial Intelligence (AI):
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            sx={{ textAlign: "justify", color: "#6C7282", marginTop: "10px" }}
          >
            AI enables machines to mimic human intelligence, providing
            capabilities like predictive analytics, natural language processing,
            and automation.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Blockchain:
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            sx={{ textAlign: "justify", color: "#6C7282", marginTop: "10px" }}
          >
            Blockchain offers a decentralized and tamper-proof ledger, ensuring
            data integrity, transparency, and security across transactions.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Internet of Things (IoT):
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            sx={{ textAlign: "justify", color: "#6C7282", marginTop: "10px" }}
          >
            IoT connects devices and systems, enabling real-time data collection
            and communication between physical and digital assets.
          </Typography>
        </div>
        {/* <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Improved Customer Experience:
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
             fontFamily="Roboto"
            fontWeight="400"
            sx={{ textAlign: "justify", color: "#6C7282", marginTop: "10px" }}
          >
            By utilizing data-driven insights, businesses can deliver
            personalized and seamless experiences across multiple touchpoints.
          </Typography>
        </div> */}
        <div>
          <Typography
            fontSize={contentFontsize_28}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Key Industry Transformations
          </Typography>
        </div>

        <div style={{ lineHeight: "1.8", margin: "20px" }}>
          <div style={{ marginBottom: "20px" }}>
            <Typography
              fontFamily="Roboto"
              fontWeight="700"
              fontSize={contentFontSize_20}
              sx={{ margin: "0 0 10px" }}
            >
              1. Healthcare{" "}
            </Typography>
            <ul style={{ margin: "0", paddingLeft: "20px" }}>
              <li style={{ color: "#6C7282", textAlign: "justify" }}>
                <Typography fontSize={contentFontSize_18}>
                  <span className="digital">AI Impact:</span>
                  <br />
                  <Typography
                    fontSize={contentFontSize_18}
                    color="#6C7282"
                    fontWeight="400"
                    sx={{ margin: "0 0 10px" }}
                  >
                    AI-powered tools assist in diagnostics, personalized
                    treatment plans, and predictive analytics for patient care.
                  </Typography>
                </Typography>
              </li>
              <li style={{ color: "#6C7282", textAlign: "justify" }}>
                <Typography fontSize={contentFontSize_18}>
                  <span className="digital">Blockchain Impact:</span>
                  <br />
                  <Typography
                    fontSize={contentFontSize_18}
                    color="#6C7282"
                    fontWeight="400"
                    sx={{ margin: "0 0 10px" }}
                  >
                    Blockchain secures patient records, ensuring privacy and
                    compliance with regulations like HIPAA.
                  </Typography>
                </Typography>
              </li>
              <li style={{ color: "#6C7282", textAlign: "justify" }}>
                <Typography fontSize={contentFontSize_18}>
                  <span className="digital">IoT Impact:</span>
                  <br />
                  <Typography
                    fontSize={contentFontSize_18}
                    color="#6C7282"
                    fontWeight="400"
                    sx={{ margin: "0 0 10px" }}
                  >
                    IoT devices like wearables monitor patient vitals in
                    real-time, enabling proactive healthcare.
                  </Typography>
                </Typography>
              </li>
              <li style={{ color: "#6C7282", textAlign: "justify" }}>
                <Typography fontSize={contentFontSize_18}>
                  <span className="digital">Example</span>
                  <br />
                  <Typography
                    fontSize={contentFontSize_18}
                    color="#6C7282"
                    fontWeight="400"
                    sx={{ margin: "0 0 10px" }}
                  >
                    Smart hospital systems integrate IoT and AI for automated
                    patient monitoring, while blockchain ensures secure data
                    sharing between providers.
                  </Typography>
                </Typography>
              </li>
            </ul>
          </div>

          <div style={{ marginBottom: "20px" }}>
            <Typography
              fontFamily="Roboto"
              fontWeight="700"
              fontSize={contentFontSize_20}
              sx={{ margin: "0 0 10px" }}
            >
              2. Supply Chain Management
            </Typography>
            <ul style={{ margin: "0", paddingLeft: "20px" }}>
              <li style={{ color: "#6C7282", textAlign: "justify" }}>
                <Typography fontSize={contentFontSize_18}>
                  <span className="digital">AI Impact:</span>
                  <br />
                  <Typography
                    fontSize={contentFontSize_18}
                    color="#6C7282"
                    fontWeight="400"
                    sx={{ margin: "0 0 10px" }}
                  >
                    AI predicts demand, optimizes inventory, and analyzes
                    logistics for faster delivery.
                  </Typography>
                </Typography>
              </li>
              <li style={{ color: "#6C7282", textAlign: "justify" }}>
                <Typography fontSize={contentFontSize_18}>
                  <span className="digital">Blockchain Impact:</span>
                  <br />
                  <Typography
                    fontSize={contentFontSize_18}
                    color="#6C7282"
                    fontWeight="400"
                    sx={{ margin: "0 0 10px" }}
                  >
                    Blockchain improves traceability, ensuring transparency
                    across the supply chain.
                  </Typography>
                </Typography>
              </li>
              <li style={{ color: "#6C7282", textAlign: "justify" }}>
                <Typography fontSize={contentFontSize_18}>
                  <span className="digital">IoT Impact:</span>
                  <br />
                  <Typography
                    fontSize={contentFontSize_18}
                    color="#6C7282"
                    fontWeight="400"
                    sx={{ margin: "0 0 10px" }}
                  >
                    IoT sensors track shipments in real time, reducing delays
                    and improving accountability.
                  </Typography>
                </Typography>
              </li>
              <li style={{ color: "#6C7282", textAlign: "justify" }}>
                <Typography fontSize={contentFontSize_18}>
                  <span className="digital">Example</span>
                  <br />
                  <Typography
                    fontSize={contentFontSize_18}
                    color="#6C7282"
                    fontWeight="400"
                    sx={{ margin: "0 0 10px" }}
                  >
                    A retail chain uses IoT-enabled tracking to monitor
                    shipments, AI for demand forecasting, and blockchain to
                    verify product authenticity.
                  </Typography>
                </Typography>
              </li>
            </ul>
          </div>

          <div>
            <Typography
              fontFamily="Roboto"
              fontWeight="700"
              fontSize={contentFontSize_20}
              sx={{ margin: "0 0 10px" }}
            >
              3. Finance and Banking
            </Typography>
            <ul style={{ margin: "0", paddingLeft: "20px" }}>
              <li style={{ color: "#6C7282", textAlign: "justify" }}>
                <Typography fontSize={contentFontSize_18}>
                  <span className="digital">AI Impact:</span>
                  <br />
                  <Typography
                    fontSize={contentFontSize_18}
                    color="#6C7282"
                    fontWeight="400"
                    sx={{ margin: "0 0 10px" }}
                  >
                    AI detects fraudulent transactions, improves customer
                    service through chatbots, and automates credit scoring.
                  </Typography>
                </Typography>
              </li>
              <li style={{ color: "#6C7282", textAlign: "justify" }}>
                <Typography fontSize={contentFontSize_18}>
                  <span className="digital">Blockchain Impact:</span>
                  <br />
                  <Typography
                    fontSize={contentFontSize_18}
                    color="#6C7282"
                    fontWeight="400"
                    sx={{ margin: "0 0 10px" }}
                  >
                    Blockchain streamlines cross-border payments and ensures
                    secure, tamper-proof transactions.
                  </Typography>
                </Typography>
              </li>
              <li style={{ color: "#6C7282", textAlign: "justify" }}>
                <Typography fontSize={contentFontSize_18}>
                  <span className="digital">IoT Impact:</span>
                  <br />
                  <Typography
                    fontSize={contentFontSize_18}
                    color="#6C7282"
                    fontWeight="400"
                    sx={{ margin: "0 0 10px" }}
                  >
                    IoT enhances digital payments through connected devices like
                    smart wallets and payment-enabled wearables.
                  </Typography>
                </Typography>
              </li>
              <li style={{ color: "#6C7282", textAlign: "justify" }}>
                <Typography fontSize={contentFontSize_18}>
                  <span className="digital">Example</span>
                  <br />
                  <Typography
                    fontSize={contentFontSize_18}
                    color="#6C7282"
                    fontWeight="400"
                    sx={{ margin: "0 0 10px" }}
                  >
                    Financial institutions use AI for fraud detection,
                    blockchain for secure record-keeping, and IoT for seamless
                    payment processing.
                  </Typography>
                </Typography>
              </li>
            </ul>
          </div>
          <div>
            <Typography
              fontFamily="Roboto"
              fontWeight="700"
              fontSize={contentFontSize_20}
              sx={{ margin: "0 0 10px" }}
            >
              4. Manufacturing
            </Typography>
            <ul style={{ margin: "0", paddingLeft: "20px" }}>
              <li style={{ color: "#6C7282", textAlign: "justify" }}>
                <Typography fontSize={contentFontSize_18}>
                  <span className="digital">AI Impact:</span>
                  <br />
                  <Typography
                    fontSize={contentFontSize_18}
                    color="#6C7282"
                    fontWeight="400"
                    sx={{ margin: "0 0 10px" }}
                  >
                    AI-powered systems optimize production lines, predict
                    equipment failures, and reduce downtime.
                  </Typography>
                </Typography>
              </li>
              <li style={{ color: "#6C7282", textAlign: "justify" }}>
                <Typography fontSize={contentFontSize_18}>
                  <span className="digital">Blockchain Impact:</span>
                  <br />
                  <Typography
                    fontSize={contentFontSize_18}
                    color="#6C7282"
                    fontWeight="400"
                    sx={{ margin: "0 0 10px" }}
                  >
                    Blockchain ensures authenticity and traceability in the
                    sourcing of raw materials.
                  </Typography>
                </Typography>
              </li>
              <li style={{ color: "#6C7282", textAlign: "justify" }}>
                <Typography fontSize={contentFontSize_18}>
                  <span className="digital">IoT Impact:</span>
                  <br />
                  <Typography
                    fontSize={contentFontSize_18}
                    color="#6C7282"
                    fontWeight="400"
                    sx={{ margin: "0 0 10px" }}
                  >
                    IoT devices monitor equipment health and automate quality
                    control processes.
                  </Typography>
                </Typography>
              </li>
              <li style={{ color: "#6C7282", textAlign: "justify" }}>
                <Typography fontSize={contentFontSize_18}>
                  <span className="digital">Example</span>
                  <br />
                  <Typography
                    fontSize={contentFontSize_18}
                    color="#6C7282"
                    fontWeight="400"
                    sx={{ margin: "0 0 10px" }}
                  >
                    Smart factories integrate IoT and AI for real-time
                    monitoring, with blockchain tracking material origins to
                    meet sustainability goals.
                  </Typography>
                </Typography>
              </li>
            </ul>
          </div>
          <div>
            <Typography
              fontFamily="Roboto"
              fontWeight="700"
              fontSize={contentFontSize_20}
              sx={{ margin: "0 0 10px" }}
            >
              5. Smart Cities
            </Typography>
            <ul style={{ margin: "0", paddingLeft: "20px" }}>
              <li style={{ color: "#6C7282", textAlign: "justify" }}>
                <Typography fontSize={contentFontSize_18}>
                  <span className="digital">AI Impact:</span>
                  <br />
                  <Typography
                    fontSize={contentFontSize_18}
                    color="#6C7282"
                    fontWeight="400"
                    sx={{ margin: "0 0 10px" }}
                  >
                    AI manages traffic flow, optimizes energy usage, and
                    improves waste management systems.
                  </Typography>
                </Typography>
              </li>
              <li style={{ color: "#6C7282", textAlign: "justify" }}>
                <Typography fontSize={contentFontSize_18}>
                  <span className="digital">Blockchain Impact:</span>
                  <br />
                  <Typography
                    fontSize={contentFontSize_18}
                    color="#6C7282"
                    fontWeight="400"
                    sx={{ margin: "0 0 10px" }}
                  >
                    Blockchain ensures secure and transparent data sharing among
                    city systems.
                  </Typography>
                </Typography>
              </li>
              <li style={{ color: "#6C7282", textAlign: "justify" }}>
                <Typography fontSize={contentFontSize_18}>
                  <span className="digital">IoT Impact:</span>
                  <br />
                  <Typography
                    fontSize={contentFontSize_18}
                    color="#6C7282"
                    fontWeight="400"
                    sx={{ margin: "0 0 10px" }}
                  >
                    IoT connects infrastructure, including streetlights, parking
                    systems, and waste bins, for smart city automation
                  </Typography>
                </Typography>
              </li>
              <li style={{ color: "#6C7282", textAlign: "justify" }}>
                <Typography fontSize={contentFontSize_18}>
                  <span className="digital">Example</span>
                  <br />
                  <Typography
                    fontSize={contentFontSize_18}
                    color="#6C7282"
                    fontWeight="400"
                    sx={{ margin: "0 0 10px" }}
                  >
                    A smart city uses IoT-enabled sensors to monitor energy
                    consumption, AI to optimize traffic lights, and blockchain
                    to securely manage citizen data.
                  </Typography>
                </Typography>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <Typography
            fontSize={contentFontsize_28}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            The Benefits of Integrating AI, Blockchain, and IoT
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Enhanced Efficiency:
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            sx={{ textAlign: "justify", color: "#6C7282", marginTop: "10px" }}
          >
            Automate complex processes and streamline operations.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Improved Security:
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            sx={{ textAlign: "justify", color: "#6C7282", marginTop: "10px" }}
          >
            Blockchain ensures data integrity, while AI detects and mitigates
            potential threats.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Real-Time Insights:
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            sx={{ textAlign: "justify", color: "#6C7282", marginTop: "10px" }}
          >
            IoT devices provide live data, enabling immediate analysis and
            action through AI.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Cost Savings:
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            sx={{ textAlign: "justify", color: "#6C7282", marginTop: "10px" }}
          >
            Reduce operational costs by optimizing resource allocation and
            automating tasks.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Greater Transparency:
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            sx={{ textAlign: "justify", color: "#6C7282", marginTop: "10px" }}
          >
            Blockchain ensures accountability and builds trust by offering
            tamper-proof records.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontsize_28}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Challenges in Adoption:
          </Typography>
        </div>
        <Typography
          fontFamily="Roboto"
          fontWeight="700"
          fontSize={contentFontSize_20}
          sx={{ margin: "0 0 10px" }}
        >
          High Initial Investment
        </Typography>
        <Typography fontSize={contentFontSize_18}>
          <span className="digital">Solution:</span>
          <br />
          <Typography
            fontSize={contentFontSize_18}
            color="#6C7282"
            fontWeight="400"
            sx={{ margin: "0 0 10px" }}
          >
            Start with scalable pilot projects and measure ROI before full-scale
            implementation.
          </Typography>
        </Typography>
        <Typography
          fontFamily="Roboto"
          fontWeight="700"
          fontSize={contentFontSize_20}
          sx={{ margin: "0 0 10px" }}
        >
          Data Privacy Concerns
        </Typography>
        <Typography fontSize={contentFontSize_18}>
          <span className="digital">Solution:</span>
          <br />
          <Typography
            fontSize={contentFontSize_18}
            color="#6C7282"
            fontWeight="400"
            sx={{ margin: "0 0 10px" }}
          >
            Use blockchain for secure data storage and ensure compliance with
            privacy regulations.
          </Typography>
        </Typography>
        <Typography
          fontFamily="Roboto"
          fontWeight="700"
          fontSize={contentFontSize_20}
          sx={{ margin: "0 0 10px" }}
        >
          Lack of Expertise
        </Typography>
        <Typography fontSize={contentFontSize_18}>
          <span className="digital">Solution:</span>
          <br />
          <Typography
            fontSize={contentFontSize_18}
            color="#6C7282"
            fontWeight="400"
            sx={{ margin: "0 0 10px" }}
          >
            Partner with technology providers like Axia Kairos for tailored
            solutions and ongoing support.
          </Typography>
        </Typography>

        <div>
          <Typography
            fontFamily="Roboto"
            fontWeight="700"
            fontSize={contentFontsize_28}
          >
            Conclusion
          </Typography>
        </div>
        <Typography
          fontSize={contentFontSize_18}
          color="#6C7282"
          fontWeight="400"
          textAlign="justify"
        >
          The integration of AI, Blockchain, and IoT is reshaping industries,
          driving innovation, and unlocking new opportunities for businesses
          worldwide. These technologies are not just trends—they’re the
          foundation of the future.
        </Typography>
      </div>
      <div
        className="bg-img"
        style={{
          backgroundImage: `url(${Common})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          // height: "500px",
          position: "relative",
          color: "white",
          padding: "20px", // Add spacing for responsiveness
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="blog-text1">
          {matches ? (
            <Typography
              fontSize={contentFontSize_50}
              fontWeight="700"
              sx={{
                marginBottom: "10px",
                color: "#FFD700",
                marginTop: smmatch ? "0px" : matches ? "50px" : "160px",
                padding: matches ? "10px 0px 10px 40px" : "0px",
                textAlign: matches ? "center" : null,
              }}
            >
              Begin Your Digital Transformation Journey with Axia Kairos
            </Typography>
          ) : (
            <Typography
              fontSize={contentFontSize_50}
              fontWeight="700"
              sx={{
                marginBottom: "10px",
                color: "#FFD700",
                marginTop: smmatch ? "0px" : matches ? "50px" : "160px",
                padding: matches ? "10px 0px 10px 40px" : "0px",
                textAlign: matches ? "center" : null,
              }}
            >
              Begin Your Digital Transformation Journey <br /> with Axia Kairos
            </Typography>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: matches && "center",
            padding: smmatch ? "0px" : "10px 40px",
            flexDirection: lgmatch ? "column" : "row",
          }}
        >
          <div style={{ width: "70%" }}>
            <Typography
              fontFamily="Roboto"
              fontWeight="400"
              fontSize={contentFontSize_20}
              style={{
                textAlign: matches ? "center" : "left",
                lineHeight: "1.6",
                marginBottom: "20px",
                color: "#FFFFFF", // White paragraph text
              }}
            >
              Ready to redefine your business with digital transformation? Axia
              Kairos delivers innovative strategies and cutting-edge
              technologies to help your organization adapt and thrive.
            </Typography>
          </div>
          <div style={{ marginTop: "10px" }}>
            <Button
              variant="contained"
              sx={{
                backgroundImage:
                  "linear-gradient(90deg, #DFAD17 0%, #795E0C 100%)",
                width: "300px",
                height: "40px",
                borderRadius: "15px",
                // padding: "10px 20px",
                // fontSize: "16px",
                // backgroundColor: "#FFC107",
                // color: "white",
                // border: "none",
                // borderRadius: "5px",
                // cursor: "pointer",
                // fontWeight: "500",
              }}
              onClick={handleContactClick}
            >
              <Typography
                fontFamily="Roboto"
                sx={{ textTransform: "capitalize", padding: "15px" }}
              >
                Start Your Transformation Today
              </Typography>
            </Button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AiBlog;
