import React from "react";
import Navbar from "../Navbar";
import "./index.css";
import { Typography } from "@mui/material";
import { contentFontSize_22, contentFontsize_64 } from "../Typography";
import About from "../utils/images/About.jpg";
import FirstPage from "./FirstPage/FirstPage";
import SecondPage from "./SecondPage/SecondPage";
import ThirdPage from "./ThirdPage/ThirdPage";
import FourthPage from "./FourthPage/FourthPage";
import FifthPage from "./FifthPage/FifthPage";
import Footer from "../Footer/Footer";

function Aboutus() {
  return (
    <>
      <Navbar />
      <FirstPage/>
      <SecondPage/>
      <ThirdPage/>
      <FourthPage/>
      <FifthPage/>
      <Footer/>
    </>
  );
}

export default Aboutus;
