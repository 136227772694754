import {
  Button,
  Divider,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect } from "react";
import {
  contentFontSize_16,
  contentFontSize_18,
  contentFontSize_20,
  contentFontsize_24,
  contentFontSize_50,
  contentFontsize_64,
} from "../../Typography";
import "./InnerSection.css";
import ServiceMain from "../../utils/images/ServiceMain.jpg";
import Rect from "../../utils/icons/Rect.svg";
import Services from "../../utils/images/Services.png";
import ArrowService from "../../utils/icons/ArrowService.svg";
import { useNavigate } from "react-router-dom";
import DigitalTrans from "../../utils/icons/DigitalTrans.svg";
import Business from "../../utils/icons/Business.svg";
import DataAnalytics from "../../utils/icons/DataAnalytics.svg";
import Customer from "../../utils/icons/Customer.svg";
import Devops from "../../utils/icons/Devops.svg";
import Mobility from "../../utils/icons/Mobility.svg";
import ECommerce from "../../utils/icons/ECommerce.svg";
import Ai from "../../utils/icons/Ai.svg";
import CyberSecurity from "../../utils/icons/CyberSecurity.svg";
import Application from "../../utils/icons/Application.svg";
function InnerSection() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const data = [
    {
      title: "Digital Transformation",
      subtitle: "Revolutionize Your Business with Digital Transformation",
      description:
        "Unlock the full potential of your business with Axia Kairos' cutting-edge digital transformation services. From process optimization to leveraging advanced technologies, we craft strategies that drive innovation, efficiency, and growth in the digital era.",
      buttonText: "Explore Digital Transformation ",
      img: DigitalTrans,
    },
    {
      title: "Business Process Automation",
      subtitle: "Streamline Operations with Business Process Automation",
      description:
        "Automate repetitive tasks and optimize workflows with Axia Kairos' business process automation solutions. Our services enhance productivity, reduce costs, and allow your team to focus on strategic growth opportunities.",
      buttonText: "Discover Business Process Automation ",
      img: Business,
    },
    {
      title: "Data Analytics & Insights",
      subtitle: "Unlock Business Intelligence with Data Analytics & Insights",
      description:
        "Make informed decisions with Axia Kairos' data analytics & insights services. By leveraging advanced analytics, we deliver actionable intelligence that improves operational efficiency, customer engagement, and profitability.",
      buttonText: "Uncover Insights ",
      img: DataAnalytics,
    },
  ];
  const data1 = [
    {
      title: "Custom Software Development",
      subtitle: "Bespoke Software Development Tailored to Your Business Needs",
      description:
        "Axia Kairos provides custom software development services to address your specific challenges. From internal systems to customer-facing applications, we design software that aligns with your strategic objectives and enhances operational performance.",
      buttonText: "Design Your Software",
      img: Customer,
    },
    {
      title: "DevOps & Cloud Engineering",
      subtitle:
        "Optimize Your Development Process with DevOps & Cloud Engineering",
      description:
        "Enhance your software lifecycle with Axia Kairos' DevOps & cloud engineering services. We enable seamless integration, continuous delivery, and improved efficiency, ensuring your business stays ahead in the fast-paced digital landscape.",
      buttonText: "Explore DevOps Solutions ",
      img: Devops,
    },
    {
      title: "Mobility & Web Development",
      subtitle:
        "Enhance Customer Engagement with Mobility & Web Development Solutions",
      description:
        "Create seamless, engaging user experiences across mobile and web platforms. Our mobility and web development services ensure your business stays connected to customers wherever they are, with responsive, intuitive designs.",
      buttonText: "Expand Your Digital Presence",
      img: Mobility,
    },
  ];

  const data2 = [
    {
      id: 1,
      title: "E-Commerce Solutions",
      subtitle: "Drive Sales with Advanced E-Commerce Solutions",
      description:
        "Build a powerful, user-friendly e-commerce platform that delivers seamless shopping experiences. From custom storefronts to integrated payment gateways, Axia Kairos provides all you need to succeed online.",
      buttonText: "Boost Your E-Commerce ",
      icon: ECommerce, // Replace with the actual path to the icon
    },
    {
      id: 2,
      title: "AI, Blockchain & IoT Integration",
      subtitle:
        "Revolutionize Your Business with AI, Blockchain & IoT Integration",
      description:
        "Stay ahead of the competition with innovative AI, blockchain, and IoT integration services. These technologies enable you to automate processes, improve decision-making, and unlock new revenue streams.",
      buttonText: "Explore Advanced Technologies ",
      icon: Ai, // Replace with the actual path to the icon
    },
    {
      id: 3,
      title: "Cybersecurity Services",
      subtitle: "Protect Your Business with Robust Cybersecurity Solutions",
      description:
        "Ensure your business is safeguarded against cyber threats with tailored cybersecurity services. We implement cutting-edge security protocols to protect your data, systems, and networks from evolving threats.",
      buttonText: "Secure Your Business ",
      icon: CyberSecurity, // Replace with the actual path to the icon
    },
  ];

  const data3 = [
    {
      id: 4,
      title: "Application Managed IT Services ",
      subtitle: "Maximize Application Performance with Managed IT Services",
      description:
        "Ensure uninterrupted performance and reliability of your applications with our application managed IT services. Axia Kairos provides ongoing support and monitoring, so you can focus on innovation and growth while we handle the technical complexities.",
      buttonText: "Optimize Application Performance ",
      img: Application,
    },
  ];
  const navigate = useNavigate();
  const handleExploreMore = (index) => () => {
    // Replace spaces with dashes for a cleaner URL
    // const slugifiedTitle = title.replace(/\s+/g, "-");
    // navigate(`/services/${slugifiedTitle}`);
    if (index === 0) {
      navigate("/services/Digital-Transfromation");
    } else if (index === 1) {
      navigate("/services/Business-Process-Automation");
    } else {
      navigate("/services/Data-Analytics-Insights");
    }
  };
  const handleExploreMore1 = (index) => () => {
    if (index === 0) {
      navigate("/services/E-Commerce-Solutions");
    } else if (index === 1) {
      navigate("/services/Ai-BlockChain-Iot-Solutions-for-Business-Innovation");
    } else {
      navigate("/services/CyberSecurity-Services");
    }
  };
  const handleExploreMore2 = (index) => () => {
    if (index === 0) {
      navigate("/services/Custom-Software-Development");
    } else if (index === 1) {
      navigate("/services/Devops-Cloud-Engineering");
    } else {
      navigate("/services/Mobility-Web-Development");
    }
  };
  const handleExploreMore3 = (index) => () => {
    if (index == 0) {
      navigate("/services/Application-Managed-IT-Services");
    }
  };
  const nav = useNavigate()
  function handleContact(){
    nav("/contact")
  }
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <>
      <div className="main-service">
        <div className="service-section">
          <Typography
            fontWeight="500"
            fontSize={contentFontsize_64}
            className="service-section-text"
          >
            Digital Transformation Services for Business Growth
          </Typography>
        </div>
        <div className="service-section">
          <Typography
            fontWeight="500"
            fontSize={contentFontSize_20}
            // className="service-section-sub"
          >
            Innovative Solutions to Revolutionize Your Business Operations
          </Typography>
        </div>
        <div className="service-section">
          <Typography
            fontWeight="400"
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            lineHeight="31px"
            sx={{ marginTop: "20px", color: " #6C7282", textAlign: "justify" }}
            className="service-section-sub"
          >
            At Axia Kairos, we deliver innovative digital transformation
            services designed to elevate your business. Our expertise includes
            cloud solutions, business process automation, data analytics &
            insights, and custom software development. Additionally, we
            specialize in DevOps & cloud engineering, mobility & web
            development, AI, blockchain & IoT integration, and cybersecurity
            services. Let us help you optimize processes, boost efficiency, and
            achieve sustainable growth with our cutting-edge solutions.
          </Typography>
        </div>
        <div className="service-img">
          <img src={ServiceMain} className="img-style" alt="loading" />
        </div>

        <div className="cards-container">
          {data.map((card, index) => (
            <div className="card-wrapper" key={index}>
              <div className="card-icon">
                <div>
                  <img src={card.img} />
                </div>
              </div>
              <div className="card-service">
                <Typography
                  sx={{ textAlign: "left" }}
                  fontSize={contentFontsize_24}
                  fontWeight="700"
                  fontFamily="Roboto"
                >
                  {card.title}
                </Typography>
                <Typography
                  sx={{
                    textAlign: "left",
                    // marginTop: "20px",

                    // marginBottom: "15px",
                  }}
                  fontSize={contentFontSize_20}
                  fontWeight="500"
                  fontFamily="Roboto"
                >
                  {card.subtitle}
                </Typography>
                <Typography
                  sx={{
                    color: "#6C7282",
                    textAlign: "left",
                    // marginTop: "20px",
                    // marginBottom: "20px",
                  }}
                  fontSize={contentFontSize_18}
                  fontWeight="400"
                  fontFamily="Roboto"
                >
                  {card.description}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      borderColor: "orange",
                      borderRadius: "15px",
                      width: "100%",
                      height: "40px",
                    }}
                    onClick={handleExploreMore(index)}
                  >
                    <Typography
                      fontSize={contentFontSize_16}
                      fontWeight="500"
                      fontFamily="Roboto"
                      sx={{ color: "black", textTransform: "capitalize" }}
                    >
                      {card.buttonText}
                    </Typography>
                    <span>
                      <img
                        src={ArrowService}
                        style={{ marginLeft: "5px", marginTop: "5px" }}
                      />
                    </span>
                  </Button>
                </div>
              </div>
              {/* Add a vertical line between cards except for the last card */}
              {index < data.length - 1 && <div className="vertical-line"></div>}
            </div>
          ))}
        </div>
        <div className="cards-container">
          {data1.map((card, index) => (
            <div className="card-wrapper" key={index}>
              <div className="card-icon">
                <div>
                  <img src={card.img} />
                </div>
              </div>
              <div className="card-service">
                <Typography
                  sx={{ textAlign: "left" }}
                  fontSize={contentFontsize_24}
                  fontWeight="700"
                  fontFamily="Roboto"
                >
                  {card.title}
                </Typography>
                <Typography
                  sx={{
                    textAlign: "left",
                    // marginTop: "20px",

                    // marginBottom: "15px",
                  }}
                  fontSize={contentFontSize_20}
                  fontWeight="500"
                  fontFamily="Roboto"
                >
                  {card.subtitle}
                </Typography>
                <Typography
                  sx={{
                    color: "#6C7282",
                    textAlign: "left",
                    // marginTop: "20px",
                    // marginBottom: "20px",
                  }}
                  fontSize={contentFontSize_18}
                  fontWeight="400"
                  fontFamily="Roboto"
                >
                  {card.description}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      borderColor: "orange",
                      borderRadius: "15px",
                      width: "100%",
                      height: "40px",
                    }}
                    onClick={handleExploreMore2(index)}
                  >
                    <Typography
                      fontSize={contentFontSize_16}
                      fontWeight="500"
                      fontFamily="Roboto"
                      sx={{ color: "black", textTransform: "capitalize" }}
                    >
                      {card.buttonText}
                    </Typography>
                    <span>
                      <img
                        src={ArrowService}
                        style={{ marginLeft: "5px", marginTop: "5px" }}
                      />
                    </span>
                  </Button>
                </div>
              </div>
              {/* Add a vertical line between cards except for the last card */}
              {index < data.length - 1 && <div className="vertical-line"></div>}
            </div>
          ))}
        </div>
        <div className="cards-container">
          {data2.map((card, index) => (
            <div className="card-wrapper" key={index}>
              <div className="card-service">
                <div className="card-icon">
                  <div>
                    <img src={card.icon} />
                  </div>
                </div>
                <Typography
                  sx={{ textAlign: "left" }}
                  fontSize={contentFontsize_24}
                  fontWeight="700"
                  fontFamily="Roboto"
                >
                  {card.title}
                </Typography>
                <Typography
                  sx={{
                    textAlign: "left",
                    // marginTop: "20px",

                    // marginBottom: "15px",
                  }}
                  fontSize={contentFontSize_20}
                  fontWeight="500"
                  fontFamily="Roboto"
                >
                  {card.subtitle}
                </Typography>
                <Typography
                  sx={{
                    color: "#6C7282",
                    textAlign: "left",
                    // marginTop: "20px",
                    // marginBottom: "20px",
                  }}
                  fontSize={contentFontSize_18}
                  fontWeight="400"
                  fontFamily="Roboto"
                >
                  {card.description}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      borderColor: "orange",
                      borderRadius: "15px",
                      width: "100%",
                      height: "40px",
                    }}
                    onClick={handleExploreMore1(index)}
                  >
                    <Typography
                      fontSize={contentFontSize_16}
                      fontWeight="500"
                      fontFamily="Roboto"
                      sx={{ color: "black", textTransform: "capitalize" }}
                    >
                      {card.buttonText}
                    </Typography>
                    <span>
                      <img
                        src={ArrowService}
                        style={{ marginLeft: "5px", marginTop: "5px" }}
                      />
                    </span>
                  </Button>
                </div>
              </div>
              {/* Add a vertical line between cards except for the last card */}
              {index < data.length - 1 && <div className="vertical-line"></div>}
            </div>
          ))}
        </div>
        <div className="cards-container">
          {data3.map((card, index) => (
            <div className="card-wrapper" key={index}>
              <div className="card-service">
                <div className="card-icon">
                  <div>
                    <img src={card.img} />
                  </div>
                </div>
                <Typography
                  sx={{ textAlign: "left" }}
                  fontSize={contentFontsize_24}
                  fontWeight="700"
                  fontFamily="Roboto"
                >
                  {card.title}
                </Typography>
                <Typography
                  sx={{
                    textAlign: "left",
                    // marginTop: "20px",

                    // marginBottom: "15px",
                  }}
                  fontSize={contentFontSize_20}
                  fontWeight="500"
                  fontFamily="Roboto"
                >
                  {card.subtitle}
                </Typography>
                <Typography
                  sx={{
                    color: "#6C7282",
                    textAlign: "left",
                    // marginTop: "20px",
                    // marginBottom: "20px",
                  }}
                  fontSize={contentFontSize_18}
                  fontWeight="400"
                  fontFamily="Roboto"
                >
                  {card.description}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      borderColor: "orange",
                      borderRadius: "15px",
                      width: "100%",
                      height: "40px",
                    }}
                    onClick={handleExploreMore3(index)}
                  >
                    <Typography
                      fontSize={contentFontSize_16}
                      fontWeight="500"
                      fontFamily="Roboto"
                      sx={{ color: "black", textTransform: "capitalize" }}
                    >
                      {card.buttonText}
                    </Typography>
                    <span>
                      <img
                        src={ArrowService}
                        style={{ marginLeft: "5px", marginTop: "5px" }}
                      />
                    </span>
                  </Button>
                </div>
              </div>
              {/* Add a vertical line between cards except for the last card */}
              {index < data.length - 1 && <div className="vertical-line"></div>}
            </div>
          ))}
        </div>
      </div>
      <div
        className="bg-img"
        style={{
          backgroundImage: `url(${Services})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          // height: "500px",
          position: "relative",
          color: "white",
          padding: "20px", // Add spacing for responsiveness
          backgroundRepeat: "no-repeat",
          marginTop: "60px",
        }}
      >
        <div className="blog-text1">
          {matches ? (
            <Typography
              fontSize={contentFontSize_50}
              fontWeight="700"
              sx={{
                marginBottom: "10px",
                color: "#FFD700",
                marginTop: smmatch ? "0px" : matches ? "50px" : "160px",
                padding: matches ? "10px 0px 10px 40px" : "0px",
                textAlign: matches ? "center" : null,
              }}
            >
              Ready to Transform Your Business?
            </Typography>
          ) : (
            <Typography
              fontSize={contentFontSize_50}
              fontWeight="700"
              sx={{
                marginBottom: "10px",
                color: "#FFD700",
                marginTop: smmatch ? "0px" : matches ? "50px" : "160px",
                padding: matches ? "10px 0px 10px 40px" : "0px",
                textAlign: matches ? "center" : null,
              }}
            >
              Ready to Transform Your Business?
            </Typography>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: matches && "center",
            padding: smmatch ? "0px" : "10px 40px",
            flexDirection: lgmatch ? "column" : "row",
          }}
        >
          <div style={{ width: "70%" }}>
            <Typography
              //fontFamily="Roboto"
              fontWeight="400"
              fontSize={contentFontSize_20}
              style={{
                textAlign: matches ? "center" : "left",
                lineHeight: "1.6",
                marginBottom: "20px",
                color: "#FFFFFF", // White paragraph text
              }}
            >
              "Axia Kairos is here to help you unlock the full potential of
              digital transformation. Whether you’re looking for cloud
              solutions, automation, AI, or cybersecurity, we offer tailored
              services designed for success.
            </Typography>
          </div>
          <div style={{ marginTop: "10px" }}>
            <Button
              variant="contained"
              onClick={handleContact}
              sx={{
                backgroundImage:
                  "linear-gradient(90deg, #DFAD17 0%, #795E0C 100%)",
                width: "300px",
                height: "40px",
                borderRadius: "15px",
                // padding: "10px 20px",
                // fontSize: "16px",
                // backgroundColor: "#FFC107",
                // color: "white",
                // border: "none",
                // borderRadius: "5px",
                // cursor: "pointer",
                // fontWeight: "500",
              }}
            >
              <Typography
                //fontFamily="Roboto"
                sx={{ textTransform: "capitalize", padding: "15px" }}
              >
                Contact Us Today
              </Typography>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default InnerSection;
