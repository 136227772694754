import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import Navbar from "../Navbar";
import Footer from "../Footer/Footer";
import "./Terms.css";

// Array containing the terms data
const termsData = [
  {
    // title: "Terms and Conditions",
    content: [
      `These Terms and Conditions ("Terms") govern your use of the website [www.axiakairos.com] (the "Site") and the services offered by Axia Kairos ("we", "us", "our"). By accessing or using this Site, you agree to comply with and be bound by these Terms. If you do not agree to these Terms, please do not use the Site.`,
    ],
  },
  {
    title: "1. Use of the Site",
    content: [
      "You agree to use the Site only for lawful purposes and in accordance with these Terms. You are responsible for ensuring that your use of the Site does not violate any applicable local, national, or international laws or regulations.",
      "Prohibited Uses:",
      "You may not use the Site in any way that:",
      "- Violates any applicable law or regulation.",
      "- Infringes on the intellectual property or privacy rights of others.",
      "- Transmits harmful or disruptive software (such as viruses or malware).",
      "- Engages in any activity that could damage, disable, or impair the Site.",
    ],
  },
  {
    title: "2. Intellectual Property",
    content: [
      "All content on the Site, including but not limited to text, graphics, logos, images, and software, is the property of Axia Kairos or its licensors and is protected by copyright and other intellectual property laws. You may not use any content from the Site for commercial purposes without our express permission.",
      "Trademarks:",
      '"Axia Kairos" and other trademarks, service marks, and logos are the trademarks of Axia Kairos. You are not permitted to use these trademarks without prior written consent from us.',
    ],
  },
  {
    title: "3. Privacy Policy",
    content: [
      "Your use of the Site is also governed by our Privacy Policy, which can be found at [link to Privacy Policy]. By using the Site, you consent to the collection and use of information as outlined in the Privacy Policy.",
    ],
  },
  {
    title: "4. Account Registration",
    content: [
      "Certain features of the Site may require you to create an account. You agree to provide accurate, current, and complete information during the registration process and to update your information as necessary. You are responsible for maintaining the confidentiality of your account and password and agree to notify us immediately of any unauthorized use of your account.",
    ],
  },
  {
    title: "5. Service Availability",
    content: [
      "Axia Kairos strives to provide a seamless user experience, but we cannot guarantee that the Site or services will be available at all times. We may suspend, withdraw, or limit access to the Site for business and operational reasons, including maintenance or upgrades.",
    ],
  },
  {
    title: "6. Third-Party Links",
    content: [
      "The Site may contain links to third-party websites for your convenience. These links are not under our control, and we are not responsible for the content, accuracy, or availability of these external sites. Inclusion of these links does not imply endorsement of the third-party sites or their content.",
    ],
  },
  {
    title: "7. Disclaimers and Limitation of Liability",
    content: [
      "No Warranty:",
      "The Site and all its content are provided 'as is' without any warranty, either express or implied. Axia Kairos does not warrant that the Site will be free from errors, interruptions, or that it will meet your requirements.",
      "Limitation of Liability:",
      "To the fullest extent permitted by law, Axia Kairos shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising from or related to your use of the Site or services, even if we have been advised of the possibility of such damages.",
    ],
  },
  {
    title: "8. Indemnification",
    content: [
      "You agree to indemnify, defend, and hold harmless Axia Kairos, its officers, directors, employees, agents, and affiliates from and against any claims, losses, damages, liabilities, and expenses (including legal fees) arising out of or related to:",
      "Your use or misuse of the Site.",
      "Any violation of these Terms.",
      "Any infringement of intellectual property rights or other rights of third parties.",
    ],
  },
  {
    title: "9. Termination",
    content: [
      "Axia Kairos reserves the right to suspend or terminate your access to the Site at any time, without notice, if you violate these Terms or engage in any conduct that we deem to be harmful to the Site or its users.",
    ],
  },
  {
    title: "10. Governing Law",
    content: [
      "These Terms and your use of the Site are governed by the laws of [Insert Jurisdiction], without regard to its conflict of law principles. You agree to submit to the exclusive jurisdiction of the courts in [Insert Jurisdiction] for the resolution of any disputes arising out of or related to these Terms.",
    ],
  },
  {
    title: "11. Changes to These Terms",
    content: [
      "Axia Kairos reserves the right to modify or update these Terms at any time. We will notify users of significant changes by posting the updated Terms on this page with a new effective date. Your continued use of the Site after such changes constitutes your acceptance of the updated Terms.",
    ],
  },
  {
    title: "12. Contact Us",
    content: [
      "If you have any questions or concerns about these Terms, please contact us at:",
      "Email: +1 437 702 2997",
      "Address: 34 Minowan Miikan Lane, Toronto, ON, M6J0G3, Canada",
    ],
  },
];

const TermsAndConditions = () => {
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <React.Fragment>
      <Navbar />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        <Grid item xs={11}>
          <h1 className="mainHeading">Terms and Conditions</h1>
        </Grid>
        <Grid item xs={10}>
          {termsData.map((section, index) => (
            <Box key={index} mb={4} sx={{ marginTop: "20px" }}>
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  fontSize: {
                    xs: "18px",
                    sm: "20px",
                    md: "22px",
                  },
                }}
                className="termsTitle"
              >
                {section?.title}
              </Typography>
              {section.content.map((paragraph, i) =>
                paragraph.startsWith("-") ? (
                  <Typography
                    key={i}
                    component="li"
                    className="description"
                    sx={{
                      fontSize: {
                        xs: "13px",
                        sm: "16px",
                        md: "20px",
                      },
                    }}
                  >
                    {paragraph.slice(1)}
                  </Typography>
                ) : (
                  <Typography
                    key={i}
                    paragraph
                    className="description"
                    sx={{
                      fontSize: {
                        xs: "13px",
                        sm: "16px",
                        md: "20px",
                      },
                    }}
                  >
                    {paragraph}
                  </Typography>
                )
              )}
            </Box>
          ))}
        </Grid>
      </Grid>
      <Footer />
    </React.Fragment>
  );
};

export default TermsAndConditions;
