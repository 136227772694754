import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  ButtonFontSize_16,
  contentFontSize_16,
  contentFontSize_18,
  contentFontSize_20,
  contentFontSize_20_xs_17,
  HeaderFontSize_24,
  HeaderFontSize_25,
  HeaderFontSize_42,
  HeaderFontSize_50,
} from "../../Typography";
import "./ContactUs.css";
import Submit from "../../utils/icons/submitarrow.svg";
import IconEmail from "../../utils/icons/Email.svg";
import Call from "../../utils/icons/Call.svg";
import Support from "../../utils/icons/Support.svg";
import { useTheme } from "@mui/material/styles";
import emailjs from "@emailjs/browser";
import { message } from "antd";
import { Snackbar, SnackbarContent } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

function ContactUs() {
  const [show, setShow] = useState(false);
  const [showSubmit, setShowSubmit] = useState(false);
  // Email Validation

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    phoneNumber: "",
    message: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    phoneNumber: "",
  });

  // Email Validation
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  // Phone Number Validation
  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^[0-9]{10}$/; // Assuming a 10-digit phone number
    return phoneRegex.test(phoneNumber);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });

    if (name === "email") {
      if (!validateEmail(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "Please enter a valid email address.",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "",
        }));
      }
    }

    if (name === "phoneNumber") {
      if (!validatePhoneNumber(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          phoneNumber:
            "Phone number must be 10 digits and contain only numbers.",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          phoneNumber: "",
        }));
      }
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
setShowSubmit(true)
    const templateParams = {
      name: formData.name,
      email: formData.email,
      phoneNumber: formData.phoneNumber,
      message: formData.message,
      subject: formData.subject,
    };

    emailjs
      .send(
        "service_1dy16sb",
        "template_q3cyhjh",
        templateParams,
        "hld0dDwOeyNu8DW41"
      )
      .then(
        (result) => {
          console.log(result.text);
          setShow(true);
          setFormData({
            name: "",
            email: "",
            subject: "",
            phoneNumber: "",
            message: "",
          });
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const theme = useTheme();
  const contactItems = [
    {
      icon: IconEmail,
      heading: "Email us",
      description:
        "Email us for general queries, including marketing and partnership opportunities.",
      contact: "reach@axiakairos.com",
    },
    {
      icon: Call,
      heading: "Call us",
      description:
        "Call us to speak to a member of our team. We are always happy to help.",
      contact: "+1 437 702 2997",
    },
    {
      icon: Support,
      heading: "Headquarters",
      description: "34 Minowan Miikan Lane, Toronto, ON, M6J0G3",
      contact: "Canada​",
    },
  ];
  return (
    <>
      <div className="main-contactus">
        <div className="contactus-form">
          <div className="contactus-heading">
            <div className="heading-section-contact">
              <Typography
                fontSize={HeaderFontSize_50}
                style={{ fontWeight: "600" }}
              >
                Connect with Our Experts
              </Typography>
              <Typography
                fontSize={contentFontSize_20}
                style={{ fontWeight: "600" }}
              >
                Ready to Discuss Your Digital Goals?
              </Typography>
            </div>
            <div className="next-contact">
              <Typography>
                <span style={{ color: "Red" }}>*</span>Fill out our convenient
                contact form below, and a member of our team will reach out to
                you shortly.{" "}
              </Typography>
              <TextField
                label="Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                variant="outlined"
                sx={{
                  borderRadius: "10px",
                  color: "#6C7282",
                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      borderColor: "#6C7282",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#6C7282",
                    },
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#6C7282",
                  },
                }}
                size="small"
                InputLabelProps={{
                  shrink: true, // Keeps the label at the top
                }}
              />
              <TextField
                label="Mobile Number"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                variant="outlined"
                sx={{
                  borderRadius: "10px",
                  color: "#6C7282",
                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      borderColor: "#6C7282",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#6C7282",
                    },
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#6C7282",
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                error={!!errors.phoneNumber} // Shows error if there's an error for the phone number
                helperText={errors.phoneNumber}
              />
              <TextField
                label="Email"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                variant="outlined"
                sx={{
                  borderRadius: "10px",
                  color: "#6C7282",
                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      borderColor: "#6C7282",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#6C7282",
                    },
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#6C7282",
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                error={!!errors.email}
                helperText={errors.email}
              />
              <TextField
                label="Subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                variant="outlined"
                sx={{
                  borderRadius: "10px",
                  color: "#6C7282",
                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      borderColor: "#6C7282",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#6C7282",
                    },
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#6C7282",
                  },
                }}
                InputLabelProps={{
                  shrink: true, // Keeps the label at the top
                }}
                size="small"
              />
              <TextField
                label="Message"
                multiline
                rows={4}
                name="message"
                value={formData.message}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                sx={{
                  borderRadius: "10px",
                  color: "#6C7282",
                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      borderColor: "#6C7282",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#6C7282",
                    },
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#6C7282",
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <div>
                <Button
                  type="submit"
                  onClick={handleSubmit}
                  variant="contained"
                  sx={{
                    height: "10%",
                    width: "30%",
                    borderRadius: "10px",
                    background: "#B97E12",
                    color: "#fff",
                    ":hover": {
                      background: "#B97E12",
                    },
                    margin: "0 auto",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "8px",
                    "@media (max-width: 768px)": {
                      width: "80%",
                      minWidth: "100px",
                    },
                    "@media (max-width: 375px)": {
                      width: "100%",
                    },
                    background:
                      "linear-gradient(90deg, #DFAD17 0%, #795E0C 100%)",
                    ":hover": {
                      background:
                        "linear-gradient(90deg, #795E0C 0%, #DFAD17 100%)",
                    },
                  }}
                >
                  <Typography fontSize={ButtonFontSize_16}>
                    {showSubmit ? "Submitting" : "Submit"}
                  </Typography>
                  <img src={Submit} alt="Submit Icon" />
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            flexDirection: "row", // Default layout
            width: "100%",
            margin: "0 auto",
            padding: "4rem 1rem",
            gap: "2rem",
            [theme.breakpoints.down("md")]: {
              flexDirection: "column", // Stack items on smaller screens
              alignItems: "center",
              padding: "4rem 0rem",
            },
          }}
        >
          {contactItems.map((item, index) => (
            <Box
              key={index}
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "1rem",
                flex: 1,
                padding: "1rem",
                [theme.breakpoints.up("sm")]: {
                  padding: "0rem",
                },
                width: "100%", // Ensures full width when stacked
                "&::before": {
                  content: '""',
                  position: "absolute",
                  left: 0,
                  top: "40%",
                  transform: "translateY(-50%)",
                  width: index === 1 ? "1px" : "0",
                  height: "60%",
                  backgroundColor: "#ccc",
                  [theme.breakpoints.down("md")]: {
                    display: "none", // Hides the dividers for small screens
                  },
                },
                "&::after": {
                  content: '""',
                  position: "absolute",
                  right: 0,
                  top: "40%",
                  transform: "translateY(-50%)",
                  width: index === 1 ? "1px" : "0",
                  height: "60%",
                  backgroundColor: "#ccc",
                  [theme.breakpoints.down("md")]: {
                    display: "none",
                  },
                },
              }}
            >
              <img
                src={item.icon}
                alt={`${item.heading} Icon`}
                // style={{ width: "40px", height: "40px" }}
              />
              <Box
                sx={{
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "10px",
                  minHeight: "180px",
                }}
              >
                <Typography
                  fontSize={HeaderFontSize_25}
                  fontWeight="600"
                  color="#183B56"
                  sx={{ minHeight: "24px" }}
                >
                  {item.heading}
                </Typography>
                <Typography
                  fontSize={contentFontSize_16}
                  color="#5A7184"
                  sx={{
                    minHeight: "60px",
                    [theme.breakpoints.up("sm")]: {
                      minHeight: "30px",
                    },
                    [theme.breakpoints.up("md")]: {
                      minHeight: "70px",
                    },
                  }}
                >
                  {item.description}
                </Typography>
                <Typography fontWeight="600" fontSize={contentFontSize_18}>
                  {item.contact}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </div>
      {show && (
        <>
          <Snackbar
            open={show}
            autoHideDuration={3000} 
            onClose={() => {
              setShow(false); 
              setShowSubmit(false);
            }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <SnackbarContent
              message={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CheckCircleIcon
                    style={{ color: "green", marginRight: "8px" }}
                  />
                  <span style={{ color: "black" }}>Message Sent</span>
                </div>
              }
              style={{
                backgroundColor: "white", // Green background color
                color: "white",
                borderRadius: "8px",
                padding: "16px",
              }}
            />
          </Snackbar>
        </>
      )}
    </>
  );
}

export default ContactUs;
