import { Typography, Link, linearProgressClasses } from "@mui/material";
import React from "react";
import "./Footer.css";
import { contentFontSize_18, HeaderFontSize_36_xs_25 } from "../Typography";
import IconEmail from "../utils/icons/mailfooter.svg";
import Call from "../utils/icons/phonefooter.svg";
import Support from "../utils/icons/locationfooter.svg";
import India from "../utils/images/india.png";
import America from "../utils/images/america.png";
import Canada from "../utils/images/canada.png";
import x from "../utils/icons/x-footer.svg";
import fb from "../utils/icons/fb-footer.svg";
import insta from "../utils/icons/insta-footer.svg";
import linkedin from "../utils/icons/linkedin-footer.svg";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleNavigation = (path) => {
    navigate(path);
  };
  const handlefbClick = () => {
    window.open("https://www.facebook.com/axiakairos", "_blank");
  };
  const handleXclick = () => {
    window.open("https://x.com/axia_kairos", "_blank");
  };
  const handleLinkedinClick = () => {
    window.open("https://www.linkedin.com/company/axiakairos", "_blank");
  };
  const handleInstaClick = () => {
    window.open("https://www.instagram.com/axiakairos", "_blank");
  };
  const nav = useNavigate();
  const handlePrivacyClick = () => {
    nav("/privacypolicy");
  };
  const handleTermsandConditionsClick = () => {
    nav("/terms&conditions");
  };
  return (
    <>
      <div className="footer-content">
        <div className="main-footer-div">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
            className="first-footer-seccontent"
          >
            <div className="logo-footer">
              <Typography
                fontSize={HeaderFontSize_36_xs_25}
                style={{ fontWeight: "700", lineHeight: 1 }}
              >
                <span>AXIA</span>
              </Typography>
              <Typography
                fontSize={HeaderFontSize_36_xs_25}
                style={{ fontWeight: "700", lineHeight: 1 }}
              >
                <span className="kairos-footer">
                  K<span className="color-ai">AI</span>ROS
                </span>
              </Typography>
            </div>
            <div className="contact-location">
              <div className="contact-section">
                <div className="contact-sec-768">
                  <div className="icons-section-footer">
                    <img src={IconEmail} />
                    <Typography fontSize={contentFontSize_18}>
                      reach@axiakairos.com
                    </Typography>
                  </div>
                  <div className="icons-section-footer">
                    <img src={Call} />
                    <Typography fontSize={contentFontSize_18}>
                      +1 437 702 2997
                    </Typography>
                  </div>
                </div>
                <div className="icons-section-footer">
                  <img src={Support} />
                  <Typography
                    style={{ textAlign: "left" }}
                    fontSize={contentFontSize_18}
                  >
                    34 Minowan Miikan Lane,
                    <br /> Toronto, ON, M6J0G3, Canada
                  </Typography>
                </div>
              </div>
              <div className="location-footer">
                <Typography
                  fontSize={contentFontSize_18}
                  style={{ fontWeight: "700" }}
                >
                  Locations Worldwide:
                </Typography>
                <div className="flags">
                  <img src={Canada} alt="Canada Flag" />
                  <img src={America} alt="US Flag" />
                  <img src={India} alt="India Flag" />
                </div>
              </div>
            </div>
          </div>

          {/* Contact Section */}

          {/* Quick Links Section */}
          <div className="quick-links">
            <Typography variant="h6">Quick Links</Typography>
            <ul>
              <li>
                <Link
                  href="#"
                  style={{
                    textDecoration: "none",
                    color: "black",
                    cursor: "pointer",
                  }}
                  onClick={handleScrollToTop}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  href="/#/about-us"
                  style={{
                    textDecoration: "none",
                    color: "black",
                    cursor: "pointer",
                  }}
                  onClick={handleScrollToTop}
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  href="/#/services"
                  style={{
                    textDecoration: "none",
                    color: "black",
                    cursor: "pointer",
                  }}
                  onClick={handleScrollToTop}
                >
                  Services
                </Link>
              </li>
              <li>
                <Link
                  href="/#/blogs"
                  style={{
                    textDecoration: "none",
                    color: "black",
                    cursor: "pointer",
                  }}
                  onClick={handleScrollToTop}
                >
                  Blog
                </Link>
              </li>
              <li>
                <Link
                  href="/#/careers"
                  style={{
                    textDecoration: "none",
                    color: "black",
                    cursor: "pointer",
                  }}
                  onClick={handleScrollToTop}
                >
                  Careers
                </Link>
              </li>
              <li>
                <Link
                  href="/#/industries"
                  style={{
                    textDecoration: "none",
                    color: "black",
                    cursor: "pointer",
                  }}
                  onClick={handleScrollToTop}
                >
                  Industries
                </Link>
              </li>
              <li>
                <Link
                  href="/#/contact"
                  style={{
                    textDecoration: "none",
                    color: "black",
                    cursor: "pointer",
                  }}
                  onClick={handleScrollToTop}
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>

          {/* Services Section */}
          <div className="services">
            <Typography variant="h6">Services</Typography>
            <ul>
              <li
                style={{ cursor: "pointer" }}
                onClick={() =>
                  handleNavigation("/services/Digital-Transfromation")
                }
              >
                Digital Transformation
              </li>
              <li
                style={{ cursor: "pointer" }}
                onClick={() =>
                  handleNavigation("/services/Business-Process-Automation")
                }
              >
                Business Process Automation
              </li>
              <li
                style={{ cursor: "pointer" }}
                onClick={() =>
                  handleNavigation("/services/Data-Analytics-Insights")
                }
              >
                Data Analytics & Insights
              </li>
              <li
                style={{ cursor: "pointer" }}
                onClick={() =>
                  handleNavigation("/services/Custom-Software-Development")
                }
              >
                Custom Software Development
              </li>
              <li
                style={{ cursor: "pointer" }}
                onClick={() =>
                  handleNavigation("/services/Devops-Cloud-Engineering")
                }
              >
                DevOps & Cloud Engineering
              </li>
              <li
                style={{ cursor: "pointer" }}
                onClick={() =>
                  handleNavigation("/services/Mobility-Web-Development")
                }
              >
                Mobility & Web Development
              </li>
              <li
                style={{ cursor: "pointer" }}
                onClick={() =>
                  handleNavigation("/services/E-Commerce-Solutions")
                }
              >
                E-Commerce Solutions
              </li>
              <li
                style={{ cursor: "pointer" }}
                onClick={() =>
                  handleNavigation(
                    "/services/Ai-BlockChain-Iot-Solutions-for-Business-Innovation"
                  )
                }
              >
                AI, Blockchain & IoT Integration
              </li>
              <li
                style={{ cursor: "pointer" }}
                onClick={() =>
                  handleNavigation("/services/CyberSecurity-Services")
                }
              >
                Cybersecurity Services
              </li>
              <li
                style={{ cursor: "pointer" }}
                onClick={() =>
                  handleNavigation("/services/Application-Managed-IT-Services")
                }
              >
                Application Managed IT
              </li>
            </ul>
          </div>

          {/* Legal Section */}
          <div className="legal">
            <Typography variant="h6">Legal</Typography>
            <ul>
              <li>
                <Link
                  onClick={handlePrivacyClick}
                  style={{
                    textDecoration: "none",
                    color: "black",
                    cursor: "pointer",
                  }}
                >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link
                  onClick={handleTermsandConditionsClick}
                  style={{
                    textDecoration: "none",
                    color: "black",
                    cursor: "pointer",
                  }}
                >
                  Terms & Conditions
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="social-media">
          <img src={fb} onClick={() => handlefbClick()} />
          <img src={linkedin} onClick={() => handleLinkedinClick()} />

          <img src={x} onClick={() => handleXclick()} />
          <img src={insta} onClick={() => handleInstaClick()} />
        </div>
      </div>
      <div className="copy-right">
        {/* Copyright Section */}
        <div className="copyright">
          <Typography fontSize={contentFontSize_18} align="center">
            Axia Kairos ©2025 All Rights Reserved.
          </Typography>
        </div>
      </div>
    </>
  );
}

export default Footer;
