import React, { useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import {
  contentFontSize_16,
  contentFontSize_18,
  contentFontSize_20,
  contentFontsize_24,
  contentFontsize_28,
  contentFontSize_50,
  contentFontsize_96,
  HeaderFontSize_50_xs_33,
} from "../../Typography";
import Common from "../../utils/images/Common.jpg";
import "./DigitalTransformation.css";
import Navbar from "../../Navbar";
import ApplicationM from "../../utils/images/ApplicationM.png"
import Footer from "../../Footer/Footer";
import WhiteArrow from "../../utils/icons/WhiteArrow.svg"
import { useNavigate } from "react-router-dom";
function ItServices() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
    const nav = useNavigate()
  function handleService(){
      nav("/services")
  }
  const cards = [
    {
      title: "Proactive Issue Resolution",
      description:
        "We monitor your applications continuously and address potential problems before they cause disruptions, ensuring your systems are always up and running. ",
    },
    {
      title: "Increased Operational Efficiency",
      description:
        "Our managed services improve application performance, streamline workflows, and reduce the time spent troubleshooting, allowing your teams to focus on strategic initiatives.",
    },
    {
      title: "Security & Compliance Assurance",
      description:
        "With robust security measures and regular compliance checks, we ensure that your applications remain secure and compliant with industry regulations.",
    },
    {
      title: "Scalability & Flexibility",
      description:
        "As your business grows, so do your application needs. We ensure that your applications are scalable and adaptable to changing business demands.",
    },
  ];
  const data = [
    {
      id: "01",
      title: "Pioneers in Transformation",
      content:
        "We are at the forefront of leveraging digital technologies to drive meaningful change. Whether it’s adopting cloud solutions, integrating AI, or enhancing user experiences, we help businesses turn ideas into reality as a trusted business IT solutions provider.",
    },
    {
      id: "02",
      title: "Personalized for You",
      content:
        "No two businesses are the same, and neither are our solutions. We tailor every strategy, platform, and process to your unique goals and challenges, ensuring maximum impact.",
    },
    {
      id: "03",
      title: "Built for Long-Term Success",
      content:
        "Our solutions aren’t just designed for today—they’re crafted to evolve with you, ensuring lasting value in an ever-changing landscape.",
    },
    {
      id: "04",
      title: "Driven by Expertise",
      content:
        "Our team of seasoned professionals brings together global experience, industry knowledge, and a passion for innovation to deliver results that matter.",
    },
  ];

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));
  const dataService = [
    {
      title: "Assessment & Consultation",

      description:
        "We begin by assessing your current application environment, understanding your specific needs, and determining the best approach to manage and optimize your software.",
    },
    {
      title: "Implementation & Customization",

      description:
        "Once we understand your requirements, we implement customized solutions that align with your business objectives. We ensure seamless integration with your existing systems.",
    },
    {
      title: "Ongoing Support & Monitoring",

      description:
        "Our team provides 24/7 support and continuous monitoring to ensure optimal performance. We address issues proactively and resolve them quickly to minimize disruptions.",
    },
  ];
  const dataService1 = [
    {
      title: "Continuous Improvement",

      description:
        "We continually optimize and enhance your applications to meet the evolving needs of your business, ensuring they remain agile, scalable, and secure.",
    },
    // {
    //   title: "Deployment & Support",

    //   description:
    //     "Once your software is ready, we deploy it to your environment, providing full integration and post-deployment support to ensure seamless operation.",
    // },
    // {
    //   title: "Maintenance & Support",

    //   description:
    //     "Our post-launch services include ongoing maintenance, security updates, and feature enhancements to keep your mobile app or website running smoothly and up to date with the latest trends. ",
    // },
  ];
  function handleContact(){
    nav("/contact")
  }
  return (
    <div>
      <Navbar />
      <div
        className="bg-img-1"
        style={{
          backgroundImage: `url(${ApplicationM})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          // height: "500px",
          position: "relative",
          color: "white",
          padding: smmatch ? "0px" : "20px", // Add spacing for responsiveness
          backgroundRepeat: "no-repeat",
        }}
      >
         <div className="back-btn" onClick={handleService}>
                                <img src={WhiteArrow} style={{marginTop:smmatch ? "18px" : mdmatch && "4px"}} />
                                <span
                                  style={{
                                    marginLeft: "10px",
                                    fontSize: contentFontSize_18,
                                    fontFamily: "Roboto",
                                    marginBottom: "3px",
                                     marginTop:smmatch && "15px"
                                  }}
                                >
                                  Back to Services
                                </span>
                              </div>
        <div className="blog-text1">
          {/* {matches ? ( */}
          <div>
            <Typography
              fontSize={contentFontSize_50}
              fontWeight="700"
              sx={{
                marginBottom: "10px",
                color: "w",
                marginTop: smmatch ? "0px" : matches ? "50px" : "300px",
                padding: matches ? "50px 0px 10px 40px" : "0px",
                textAlign: matches ? "center" : null,
              }}
            >
              Application Managed Services: Ensure Optimal Performance and
              Reliability
            </Typography>
            <div>
              <Typography
                fontSize={contentFontSize_20}
                fontWeight="400"
                fontFamily="Roboto"
                sx={{
                  padding: matches ? "10px 0px 10px 40px" : "0px",
                  textAlign: matches ? "center" : null,
                }}
              >
                Comprehensive Support and Maintenance to Keep Your Applications
                Running Smoothly
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div className="service-sub-content">
        <Typography
          fontFamily="Roboto"
          fontWeight="400"
          fontSize={contentFontSize_18}
          sx={{ textAlign: "justify", color: "#6C7282" }}
        >
          At Axia Kairos, we offer Application Managed Services (AMS) that
          provide continuous monitoring, support, and optimization for your
          business-critical applications. Our expert team ensures that your
          applications perform at their best, meet user expectations, and are
          always aligned with your business needs. With our managed services, we
          help reduce downtime, improve operational efficiency, and maximize the
          return on your technology investments.
        </Typography>
        <Typography
          fontFamily="Roboto"
          fontSize={contentFontsize_28}
          fontWeight="700"
          color="#B97E12"
          sx={{ marginTop: "15px" }}
        >
          What We Do and Offer
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          24/7 Application Monitoring
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          We continuously monitor your applications to ensure they are
          performing optimally. Our proactive monitoring systems detect
          potential issues before they escalate, enabling us to address problems
          swiftly and prevent any disruptions to your operations.
        </Typography>
        <ul style={{ paddingLeft: "20px", listStyleType: "disc" }}>
          <li>
            <Typography
              fontFamily="Roboto"
              fontWeight="600"
              fontSize={contentFontSize_20}
            >
              Real-Time Performance Monitoring
            </Typography>
            <Typography
              style={{ marginLeft: "20px", padding: "10px" }}
              fontWeight="400"
              fontSize={contentFontSize_18}
              color="#6C7282"
            >
              We use advanced tools to monitor your applications’ performance in
              real-time, ensuring they meet your service level agreements
              (SLAs).
            </Typography>
          </li>
          <li>
            <Typography
              fontFamily="Roboto"
              fontWeight="600"
              fontSize={contentFontSize_20}
            >
              Error Detection & Resolution
            </Typography>
            <Typography
              style={{ marginLeft: "20px", padding: "10px" }}
              fontWeight="400"
              fontSize={contentFontSize_18}
              color="#6C7282"
            >
              Our monitoring systems identify issues like slow performance,
              crashes, or errors, and our team takes immediate action to resolve
              them.
            </Typography>
          </li>
        </ul>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Application Maintenance & Support
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Keeping your applications updated and bug-free is essential for smooth
          operations. We offer ongoing maintenance and support to ensure that
          your software stays up-to-date, secure, and aligned with business
          goals.
        </Typography>
        <ul style={{ paddingLeft: "20px", listStyleType: "disc" }}>
          <li>
            <Typography
              fontFamily="Roboto"
              fontWeight="600"
              fontSize={contentFontSize_20}
            >
              Patch Management & Updates
            </Typography>
            <Typography
              style={{ marginLeft: "20px", padding: "10px" }}
              fontWeight="400"
              fontSize={contentFontSize_18}
              color="#6C7282"
            >
              We provide regular patches and updates to fix bugs, improve
              features, and enhance security.
            </Typography>
          </li>
          <li>
            <Typography
              fontFamily="Roboto"
              fontWeight="600"
              fontSize={contentFontSize_20}
            >
              Bug Fixes & Troubleshooting
            </Typography>
            <Typography
              style={{ marginLeft: "20px", padding: "10px" }}
              fontWeight="400"
              fontSize={contentFontSize_18}
              color="#6C7282"
            >
              Our experts quickly address issues and troubleshoot problems,
              ensuring minimal downtime and a smooth user experience.
            </Typography>
          </li>
        </ul>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Performance Optimization
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Application performance directly impacts user satisfaction and
          business productivity. We optimize your applications by identifying
          and addressing performance bottlenecks to ensure your systems run
          efficiently and at scale.
        </Typography>
        <ul style={{ paddingLeft: "20px", listStyleType: "disc" }}>
          <li>
            <Typography
              fontFamily="Roboto"
              fontWeight="600"
              fontSize={contentFontSize_20}
            >
              Speed & Responsiveness Enhancements
            </Typography>
            <Typography
              style={{ marginLeft: "20px", padding: "10px" }}
              fontWeight="400"
              fontSize={contentFontSize_18}
              color="#6C7282"
            >
              We enhance the speed and responsiveness of your applications,
              improving both end-user experience and operational efficiency.
            </Typography>
          </li>
          <li>
            <Typography
              fontFamily="Roboto"
              fontWeight="600"
              fontSize={contentFontSize_20}
            >
              Database Optimization
            </Typography>
            <Typography
              style={{ marginLeft: "20px", padding: "10px" }}
              fontWeight="400"
              fontSize={contentFontSize_18}
              color="#6C7282"
            >
              We optimize database performance to ensure fast data retrieval,
              improving overall application performance.
            </Typography>
          </li>
        </ul>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Security Management & Compliance
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Cybersecurity is a critical concern for any business. Our AMS services
          include regular security assessments, vulnerability scanning, and
          implementation of necessary patches and updates to safeguard your
          applications from threats.
        </Typography>
        <ul style={{ paddingLeft: "20px", listStyleType: "disc" }}>
          <li>
            <Typography
              fontFamily="Roboto"
              fontWeight="600"
              fontSize={contentFontSize_20}
            >
              Security Monitoring & Incident Response
            </Typography>
            <Typography
              style={{ marginLeft: "20px", padding: "10px" }}
              fontWeight="400"
              fontSize={contentFontSize_18}
              color="#6C7282"
            >
              We monitor your applications for security threats and
              vulnerabilities and respond quickly to any incidents.
            </Typography>
          </li>
          <li>
            <Typography
              fontFamily="Roboto"
              fontWeight="600"
              fontSize={contentFontSize_20}
            >
              Regulatory Compliance
            </Typography>
            <Typography
              style={{ marginLeft: "20px", padding: "10px" }}
              fontWeight="400"
              fontSize={contentFontSize_18}
              color="#6C7282"
            >
              We ensure your applications comply with industry standards and
              regulatory requirements such as GDPR, HIPAA, and PCI DSS.
            </Typography>
          </li>
        </ul>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Scalability & Capacity Management
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          As your business grows, so do your application needs. We help you
          scale your applications to accommodate growing traffic, users, and
          data, ensuring that your infrastructure can handle increased loads
          without compromising performance.
        </Typography>
        <ul style={{ paddingLeft: "20px", listStyleType: "disc" }}>
          <li>
            <Typography
              fontFamily="Roboto"
              fontWeight="600"
              fontSize={contentFontSize_20}
            >
              Elastic Scaling Solutions
            </Typography>
            <Typography
              style={{ marginLeft: "20px", padding: "10px" }}
              fontWeight="400"
              fontSize={contentFontSize_18}
              color="#6C7282"
            >
              We implement scaling strategies to ensure that your application
              can handle traffic spikes and growing demands without compromising
              performance.
            </Typography>
          </li>
          <li>
            <Typography
              fontFamily="Roboto"
              fontWeight="600"
              fontSize={contentFontSize_20}
            >
              Capacity Planning & Forecasting
            </Typography>
            <Typography
              style={{ marginLeft: "20px", padding: "10px" }}
              fontWeight="400"
              fontSize={contentFontSize_18}
              color="#6C7282"
            >
              We assess your infrastructure requirements and forecast future
              needs to ensure that your applications remain scalable and
              efficient.
            </Typography>
          </li>
        </ul>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Cloud Integration & Support
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Many businesses are moving their applications to the cloud to improve
          flexibility and reduce costs. We offer cloud-based application
          management, providing you with the infrastructure and support
          necessary to scale and optimize your cloud environment.
        </Typography>
        <ul style={{ paddingLeft: "20px", listStyleType: "disc" }}>
          <li>
            <Typography
              fontFamily="Roboto"
              fontWeight="600"
              fontSize={contentFontSize_20}
            >
              Cloud Hosting & Management
            </Typography>
            <Typography
              style={{ marginLeft: "20px", padding: "10px" }}
              fontWeight="400"
              fontSize={contentFontSize_18}
              color="#6C7282"
            >
              We manage your cloud applications to ensure high availability,
              performance, and security.
            </Typography>
          </li>
          <li>
            <Typography
              fontFamily="Roboto"
              fontWeight="600"
              fontSize={contentFontSize_20}
            >
              Cloud Migration & Optimization
            </Typography>
            <Typography
              style={{ marginLeft: "20px", padding: "10px" }}
              fontWeight="400"
              fontSize={contentFontSize_18}
              color="#6C7282"
            >
              We help migrate your applications to the cloud, ensuring they are
              optimized for cloud environments and fully integrated with your
              cloud infrastructure.
            </Typography>
          </li>
        </ul>
        <Typography
          fontFamily="Roboto"
          fontSize={contentFontsize_28}
          fontWeight="700"
          color="#B97E12"
          sx={{ marginTop: "15px" }}
        >
          Additional Services in Data Analytics
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Custom Application Enhancements
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Tailor your applications to meet evolving business requirements. We
          provide custom feature development, integration with third-party
          services, and the continuous addition of functionalities to ensure
          your software adapts to business needs.
        </Typography>
        <ul style={{ paddingLeft: "20px", listStyleType: "disc" }}>
          <li>
            <Typography
              fontFamily="Roboto"
              fontWeight="600"
              fontSize={contentFontSize_20}
            >
              Feature Development & Customization
            </Typography>
            <Typography
              style={{ marginLeft: "20px", padding: "10px" }}
              fontWeight="400"
              fontSize={contentFontSize_18}
              color="#6C7282"
            >
              We design and develop new features that enhance the functionality
              and user experience of your applications.
            </Typography>
          </li>
        </ul>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Application Lifecycle Management (ALM)
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          From inception to retirement, we provide end-to-end lifecycle
          management for your applications. Our approach ensures that your
          applications remain secure, up-to-date, and aligned with business
          objectives.
        </Typography>
        <ul style={{ paddingLeft: "20px", listStyleType: "disc" }}>
          <li>
            <Typography
              fontFamily="Roboto"
              fontWeight="600"
              fontSize={contentFontSize_20}
            >
              Version Control & Release Management
            </Typography>
            <Typography
              style={{ marginLeft: "20px", padding: "10px" }}
              fontWeight="400"
              fontSize={contentFontSize_18}
              color="#6C7282"
            >
              We manage application versions and releases, ensuring that updates
              and new features are rolled out smoothly and efficiently.
            </Typography>
          </li>
        </ul>

        {/* <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          IT Infrastructure Modernization
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          We upgrade your IT environment with cutting-edge tools and solutions.
          Whether it’s on-premise, hybrid, or cloud infrastructure, our services
          ensure your systems are resilient, secure, and future-ready.
        </Typography> */}
      </div>
      <div className="about-us-second">
        <div className="main-heading-about-us">
          <Typography
            sx={{ fontWeight: "700", fontFamily: "Roboto", marginTop: "50px",textAlign:smmatch && "center" }}
            fontSize={HeaderFontSize_50_xs_33}
          >
            Why Choose Axia Kairos?
          </Typography>
        </div>
        <Typography
          fontSize={contentFontSize_20}
          fontWeight="400"
          color="white"
          fontFamily="Roboto"
          sx={{
            paddingLeft: mdmatch ? "10px" : "150px",
            paddingRight: mdmatch ? "10px" : "150px",
            textAlign: "center",
            paddingBottom: "50px",
          }}
        >
          Axia Kairos offers a comprehensive suite of Application Managed
          Services designed to keep your business-critical applications running
          smoothly and efficiently. Our proactive approach ensures minimal
          downtime, improved application performance, and greater user
          satisfaction. By outsourcing your application management to us, you
          can focus on core business functions while we take care of your
          software’s health, security, and performance.
        </Typography>
        <div className="about-us-div">
          {data.map((item) => (
            <div className="about-us-card" key={item.id}>
              <Typography
                fontSize={contentFontsize_96}
                fontFamily="Roboto"
                fontWeight="100"
                sx={{ color: "#EBB81A", lineHeight: "98px" }}
              >
                {item.id}
              </Typography>
              <Typography
                sx={{
                  color: "#ECECEC",
                  textAlign: "left",
                  lineHeight: "31px",
                  marginBottom: "10px",
                }}
                fontSize={contentFontsize_24}
                fontFamily="Roboto"
              >
                {item.title}
              </Typography>
              <Typography
                sx={{
                  color: "#ECECEC",
                  textAlign: "left",
                  lineHeight: "31px",
                  fontWeight: "400",
                }}
                fontSize={contentFontSize_16}
                fontFamily="Roboto"
              >
                {item.content}
              </Typography>
              {/* <div className="imge-section-about-us">
                  <img src={Line}/>
                </div> */}
            </div>
          ))}
        </div>
      </div>
      <div className="third-page-service">
        <div style={{ marginTop: "50px", marginBottom: "50px" }}>
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              textAlign: "center",
            }}
            fontWeight="700"
            fontFamily="Roboto"
            fontSize={contentFontSize_50}
          >
          Our Proven Approach to Application Managed Services
          </Typography>
        </div>
        <div className="secnd-sec">
          <div className="flex-card-in">
            {dataService.map((card, index) => (
              <Card
                className="about-card-service"
                key={index}
                sx={{ borderRadius: "15px", background: "#9F6907" }}
              >
                <div className="about-content-card-service">
                  <Typography
                    fontWeight="700"
                    fontSize={contentFontsize_24}
                    fontFamily="Roboto"
                    className="about-card-title"
                  >
                    {card.title}
                  </Typography>
                  <Typography
                    fontWeight="400"
                    fontSize={contentFontSize_18}
                    fontFamily="Roboto"
                    color="white"
                    className="about-content-1-service"
                  >
                    {card.description}
                  </Typography>
                </div>
              </Card>
            ))}
          </div>
        </div>
        <div className="secnd-sec">
          <div className="flex-card-in-1">
            {dataService1.map((card, index) => (
              <Card
                className="about-card-service-1"
                key={index}
                sx={{
                  borderRadius: "15px",
                  background: "#9F6907",
                  marginTop: "20px",
                }}
              >
                <div className="about-content-card-service">
                  <Typography
                    fontWeight="700"
                    fontSize={contentFontsize_24}
                    fontFamily="Roboto"
                    className="about-card-title"
                  >
                    {card.title}
                  </Typography>
                  <Typography
                    fontWeight="400"
                    fontSize={contentFontSize_18}
                    fontFamily="Roboto"
                    color="white"
                    className="about-content-1-service"
                  >
                    {card.description}
                  </Typography>
                </div>
              </Card>
            ))}
          </div>
        </div>
      </div>
      <div className="main-third">
        <div className="about-us-text1">
          <Typography
            fontFamily="Roboto"
            fontWeight="500"
            fontSize={contentFontSize_50}
            sx={{
              paddingTop: mdmatch ? "50px" : "200px",
              textAlign: matches && "center",
            }}
          >
           How Our Application Managed Services Ensure Long-Term Success
          </Typography>
        </div>

        <div className="third-sub">
          {cards.map((card, index) => (
            <div
              key={index}
              //   ref={(el) => (cardRefs.current[index] = el)}
              data-index={index}
              //   className={`card-wrapper-about-us ${
              //     visibleCards.includes(index.toString()) ? "active" : ""
              //   }`}
              style={{
                display: "flex",
                flexDirection: mdmatch ? null : "row",
                justifyContent: mdmatch ? null : "space-between",
                width: mdmatch ? null : "100%",
              }}
            >
              <div
              // className={`side-item-about-us ${
              //   visibleCards.includes(index.toString()) ? "active" : ""
              // }`}
              ></div>

              <Card
                sx={{
                  borderRight: "5px solid #B97E12",
                  background: "#DFDFDF",
                  width: matches ? "100%" : "90%",
                  // color: "white",
                  transition: "all 0.5s ease",
                  height: mdmatch ? "100%" : "160px",
                  borderRadius: "10px",
                }}
                // className={`card-content ${
                //   visibleCards.includes(index.toString()) ? "active" : ""
                // }`}
              >
                <CardContent>
                  <div
                    className="card-title-about-us"
                    style={{ marginBottom: "10px" }}
                  >
                    <Typography
                      fontWeight="700"
                      fontSize={contentFontSize_20}
                      fontFamily="Roboto"
                      //color="black"
                    >
                      {card.title}
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      fontWeight="400"
                      fontSize={contentFontSize_18}
                      fontFamily="Roboto"
                      className="card-description-about-us"
                    >
                      {card.description}
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            </div>
          ))}
        </div>
      </div>
      <div
        className="bg-img"
        style={{
          backgroundImage: `url(${Common})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          // height: "500px",
          position: "relative",
          color: "white",
          padding: "20px", // Add spacing for responsiveness
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="blog-text1">
          {matches ? (
            <Typography
              fontSize={contentFontSize_50}
              fontWeight="700"
              sx={{
                marginBottom: "10px",
                color: "#FFD700",
                marginTop: smmatch ? "0px" : matches ? "50px" : "160px",
                padding: matches ? "10px 0px 10px 40px" : "0px",
                textAlign: matches ? "center" : null,
              }}
            >
              Ensure the Optimal Performance of Your Applications
            </Typography>
          ) : (
            <Typography
              fontSize={contentFontSize_50}
              fontWeight="700"
              sx={{
                marginBottom: "10px",
                color: "#FFD700",
                marginTop: smmatch ? "0px" : matches ? "50px" : "160px",
                padding: matches ? "10px 0px 10px 40px" : "0px",
                textAlign: matches ? "center" : null,
              }}
            >
              Ensure the Optimal Performance of Your Applications
            </Typography>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: matches && "center",
            padding: smmatch ? "0px" : "10px 40px",
            flexDirection: lgmatch ? "column" : "row",
          }}
        >
          <div style={{ width: "70%" }}>
            <Typography
              //fontFamily="Roboto"
              fontWeight="400"
              fontSize={contentFontSize_20}
              style={{
                textAlign: matches ? "center" : "left",
                lineHeight: "1.6",
                marginBottom: "20px",
                color: "#FFFFFF", // White paragraph text
              }}
            >
              Axia Kairos provides end-to-end Application Managed Services that ensure your business-critical applications perform optimally. Our expert team is here to manage, monitor, and enhance your software, so you can focus on growing your business.
            </Typography>
          </div>
          <div style={{ marginTop: "10px" }}>
            <Button
              variant="contained"
              onClick={handleContact}
              sx={{
                backgroundImage:
                  "linear-gradient(90deg, #DFAD17 0%, #795E0C 100%)",
                width: "300px",
                height: "40px",
                borderRadius: "15px",
                // padding: "10px 20px",
                // fontSize: "16px",
                // backgroundColor: "#FFC107",
                // color: "white",
                // border: "none",
                // borderRadius: "5px",
                // cursor: "pointer",
                // fontWeight: "500",
              }}
            >
              <Typography
                //fontFamily="Roboto"
                sx={{ textTransform: "capitalize", padding: "15px" }}
              >
                Get Started Today
              </Typography>
            </Button>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default ItServices;
