import React, { useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import {
  contentFontSize_16,
  contentFontSize_18,
  contentFontSize_20,
  contentFontsize_24,
  contentFontsize_28,
  contentFontSize_50,
  contentFontsize_96,
  HeaderFontSize_50_xs_33,
} from "../../Typography";
import Common from "../../utils/images/Common.jpg";
import "./DigitalTransformation.css";
import Navbar from "../../Navbar";
import DataA from "../../utils/images/DataA.png";
import Footer from "../../Footer/Footer";
import WhiteArrow from "../../utils/icons/WhiteArrow.svg";
import { useNavigate } from "react-router-dom";
function DataAnalytics() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const nav = useNavigate()
  function handleService(){
      nav("/services")
  }
  const cards = [
    {
      title: "Informed Decision Making",
      description:
        "Empower your business with real-time and predictive insights for smarter decisions. ",
    },
    {
      title: "Operational Efficiency",
      description:
        "Streamline operations with data analytics services, reducing waste and improving performance.  ",
    },
    {
      title: "Competitive Advantage",
      description:
        "Uncover new opportunities and stay ahead with advanced analytics consulting solutions.",
    },
    {
      title: "Customer Understanding",
      description:
        "Enhance customer experiences with insights from predictive analytics solutions. ",
    },
  ];
  const data = [
    {
      id: "01",
      title: "Pioneers in Transformation",
      content:
        "We are at the forefront of leveraging digital technologies to drive meaningful change. Whether it’s adopting cloud solutions, integrating AI, or enhancing user experiences, we help businesses turn ideas into reality as a trusted business IT solutions provider.",
    },
    {
      id: "02",
      title: "Personalized for You",
      content:
        "No two businesses are the same, and neither are our solutions. We tailor every strategy, platform, and process to your unique goals and challenges, ensuring maximum impact.",
    },
    {
      id: "03",
      title: "Built for Long-Term Success",
      content:
        "Our solutions aren’t just designed for today—they’re crafted to evolve with you, ensuring lasting value in an ever-changing landscape.",
    },
    {
      id: "04",
      title: "Driven by Expertise",
      content:
        "Our team of seasoned professionals brings together global experience, industry knowledge, and a passion for innovation to deliver results that matter.",
    },
  ];

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));
  const dataService = [
    {
      title: "Consultation & Data Assessment",

      description:
        "We evaluate your data landscape and identify the best strategies for implementing tailored data analytics services to align with your business goals. ",
    },
    {
      title: "Data Collection & Integration",

      description:
        "We gather data from diverse sources and integrate it into a centralized system, ensuring consistency, reliability, and scalability. Our seamless processes lay the groundwork for successful data analytics services, enabling accurate and comprehensive analysis. ",
    },
    {
      title: "Advanced Analytics & Modeling",

      description:
        "We leverage advanced analytics consulting methodologies to build predictive and prescriptive models. These models help uncover trends, optimize processes, and forecast outcomes, driving smarter decisions.  ",
    },
  ];
  const dataService1 = [
    {
      title: "Data Visualization & Reporting ",

      description:
        "Transform raw data into intuitive visualizations with our cutting-edge tools. Our interactive dashboards and reports provide real-time visibility into your KPIs, ensuring you make informed decisions quickly and confidently.  ",
    },
    {
      title: "Optimization & Continuous Monitoring",

      description:
        "We continuously monitor and refine your analytics strategies, ensuring they evolve with your business and leverage the latest innovations in big data analytics services. ",
    },
  ];
  function handleContact(){
    nav("/contact")
  }
  return (
    <div>
      <Navbar />
      <div
        className="bg-img-1"
        style={{
                 backgroundImage: `url(${DataA})`,
                 backgroundSize: "cover",
                 backgroundPosition: "center",
                 //height: "500px",
                 position: "relative",
                 color: "white",
                 padding: smmatch ? "0px" : "20px", // Add spacing for responsiveness
                 backgroundRepeat: "no-repeat",
                 display: "flex",
                 flexDirection: "column",
                 justifyContent: "center",
                 alignItems: smmatch ? "center" : "flex-start", // Center for small screens
               }}
      >
         <div className="back-btn" onClick={handleService}>
                                <img src={WhiteArrow} style={{marginTop:smmatch ? "18px" : mdmatch && "4px"}} />
                                <span
                                  style={{
                                    marginLeft: "10px",
                                    fontSize: contentFontSize_18,
                                    fontFamily: "Roboto",
                                    marginBottom: "3px",
                                     marginTop:smmatch && "15px"
                                  }}
                                >
                                  Back to Services
                                </span>
                              </div>
        <div className="blog-text1">
          {/* {matches ? ( */}
          <div>
            <Typography
              fontSize={contentFontSize_50}
              fontWeight="700"
              sx={{
                marginBottom: "10px",
                color: "w",
                marginTop: smmatch ? "0px" : matches ? "50px" : "300px",
                padding: matches ? "50px 0px 10px 40px" : "0px",
                textAlign: matches ? "center" : null,
              }}
            >
              Data Analytics & Insights: Unlock the Power of Your Business Data
            </Typography>
            <div>
              <Typography
                fontSize={contentFontSize_20}
                fontWeight="400"
                fontFamily="Roboto"
                sx={{
                  padding: matches ? "10px 0px 10px 40px" : "0px",
                  textAlign: matches ? "center" : null,
                }}
              >
                Transform Raw Data into Actionable Insights for Smarter
                Decisions
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div className="service-sub-content">
        <Typography
          fontFamily="Roboto"
          fontWeight="400"
          fontSize={contentFontSize_18}
          sx={{ textAlign: "justify", color: "#6C7282" }}
        >
          Data is the backbone of informed decision-making, and at Axia Kairos,
          we help you unlock its full potential. Our data analytics services
          provide the tools and strategies to turn raw data into actionable
          intelligence that drives business growth. Whether it’s real-time
          analytics, predictive analytics solutions, or custom dashboards, we
          help you leverage your data to optimize performance and achieve your
          business goals.
        </Typography>
        <Typography
          fontFamily="Roboto"
          fontSize={contentFontsize_28}
          fontWeight="700"
          color="#B97E12"
          sx={{ marginTop: "15px" }}
        >
          What We Do and Offer
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Data Collection & Integration
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          We collect and integrate data from multiple sources, creating a
          unified view of your business operations. Our team ensures seamless
          integration with your existing systems, providing a reliable
          foundation for advanced data analytics services and actionable
          insights.
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Advanced Data Analytics
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Using the latest analytics tools, we analyze large volumes of data to
          uncover patterns, trends, and insights. Our advanced analytics
          consulting services provide deep visibility into business performance,
          enabling better decision-making.
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Predictive Analytics
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Leverage our predictive analytics solutions to forecast future trends
          and outcomes based on historical data. Anticipate market shifts,
          customer behavior, and operational challenges to stay ahead of the
          competition.
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Business Intelligence (BI) & Reporting
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Our business intelligence services deliver real-time, customized
          dashboards and reports. With big data analytics services, you gain
          access to valuable insights through intuitive, visual representations
          of your KPIs.
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Data Visualization
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Turn complex datasets into interactive visualizations with our
          advanced tools. From graphs to maps, our solutions help identify
          patterns and trends, ensuring smarter, data-driven decisions.
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Data Governance & Quality Assurance
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          We ensure the accuracy and consistency of your data through robust
          quality assurance practices. Our approach guarantees your data meets
          industry standards, enabling reliable data analytics services.
        </Typography>
        <Typography
          fontFamily="Roboto"
          fontSize={contentFontsize_28}
          fontWeight="700"
          color="#B97E12"
          sx={{ marginTop: "15px" }}
        >
          Additional Services in Data Analytics
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Real-Time Data Analytics
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Gain immediate insights with real-time analytics. Our tools allow you
          to monitor live data, enabling faster decisions and agility in
          fast-paced environments.
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Big Data Solutions
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Axia Kairos specializes in big data analytics services, using
          state-of-the-art technologies to process and analyze massive datasets.
          Our solutions drive innovation and uncover new opportunities for
          growth.
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Customer Analytics
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Understand customer behavior with advanced analytics. Our predictive
          analytics solutions enhance customer experiences by providing
          actionable insights tailored to improve satisfaction and retention.
        </Typography>
        {/* <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          IT Infrastructure Modernization
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          We upgrade your IT environment with cutting-edge tools and solutions.
          Whether it’s on-premise, hybrid, or cloud infrastructure, our services
          ensure your systems are resilient, secure, and future-ready.
        </Typography> */}
      </div>
      <div className="about-us-second">
        <div className="main-heading-about-us">
          <Typography
            sx={{ fontWeight: "700", fontFamily: "Roboto", marginTop: "50px",textAlign:smmatch && "center" }}
            fontSize={HeaderFontSize_50_xs_33}
          >
            Why Choose Axia Kairos?
          </Typography>
        </div>
        <Typography
          fontSize={contentFontSize_20}
          fontWeight="400"
          color="white"
          fontFamily="Roboto"
          sx={{
            paddingLeft: mdmatch ? "10px" : "150px",
            paddingRight: mdmatch ? "10px" : "150px",
            textAlign: "center",
            paddingBottom: "50px",
          }}
        >
          Axia Kairos combines industry-leading tools with deep expertise in
          data analytics services to deliver insights that drive success. From
          advanced analytics consulting to implementation, our tailored
          solutions provide measurable results and a competitive edge in your
          industry.
        </Typography>
        <div className="about-us-div">
          {data.map((item) => (
            <div className="about-us-card" key={item.id}>
              <Typography
                fontSize={contentFontsize_96}
                fontFamily="Roboto"
                fontWeight="100"
                //className="thin-font"
                sx={{ color: "#EBB81A", lineHeight: "98px" }}
              >
                {item.id}
              </Typography>
              <Typography
                sx={{
                  color: "#ECECEC",
                  textAlign: "left",
                  lineHeight: "31px",
                  marginBottom: "10px",
                }}
                fontSize={contentFontsize_24}
                fontFamily="Roboto"
              >
                {item.title}
              </Typography>
              <Typography
                sx={{
                  color: "#ECECEC",
                  textAlign: "left",
                  lineHeight: "31px",
                  fontWeight: "400",
                }}
                fontSize={contentFontSize_16}
                fontFamily="Roboto"
              >
                {item.content}
              </Typography>
              {/* <div className="imge-section-about-us">
                  <img src={Line}/>
                </div> */}
            </div>
          ))}
        </div>
      </div>
      <div className="third-page-service">
        <div style={{ marginTop: "50px", marginBottom: "50px" }}>
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              textAlign: "center",
            }}
            fontWeight="700"
            fontFamily="Roboto"
            fontSize={contentFontSize_50}
          >
            Our Proven Approach to Data Analytics
          </Typography>
        </div>
        <div className="secnd-sec">
          <div className="flex-card-in">
            {dataService.map((card, index) => (
              <Card
                className="about-card-service"
                key={index}
                sx={{ borderRadius: "15px", background: "#9F6907" }}
              >
                <div className="about-content-card-service">
                  <Typography
                    fontWeight="700"
                    fontSize={contentFontsize_24}
                    fontFamily="Roboto"
                    className="about-card-title"
                  >
                    {card.title}
                  </Typography>
                  <Typography
                    fontWeight="400"
                    fontSize={contentFontSize_18}
                    fontFamily="Roboto"
                    color="white"
                    className="about-content-1-service"
                  >
                    {card.description}
                  </Typography>
                </div>
              </Card>
            ))}
          </div>
        </div>
        <div className="secnd-sec">
          <div className="flex-card-in-1">
            {dataService1.map((card, index) => (
              <Card
                className="about-card-service-1"
                key={index}
                sx={{
                  borderRadius: "15px",
                  background: "#9F6907",
                  marginTop: "20px",
                }}
              >
                <div className="about-content-card-service">
                  <Typography
                    fontWeight="700"
                    fontSize={contentFontsize_24}
                    fontFamily="Roboto"
                    className="about-card-title"
                  >
                    {card.title}
                  </Typography>
                  <Typography
                    fontWeight="400"
                    fontSize={contentFontSize_18}
                    fontFamily="Roboto"
                    color="white"
                    className="about-content-1-service"
                  >
                    {card.description}
                  </Typography>
                </div>
              </Card>
            ))}
          </div>
        </div>
      </div>
      <div className="main-third">
        <div className="about-us-text1">
          <Typography
            fontFamily="Roboto"
            fontWeight="500"
            fontSize={contentFontSize_50}
            sx={{
              paddingTop: mdmatch ? "50px" : "200px",
              textAlign: matches && "center",
            }}
          >
            How Our Data Analytics & Insights Services Ensure Long-Term Success
          </Typography>
        </div>

        <div className="third-sub">
          {cards.map((card, index) => (
            <div
              key={index}
              //   ref={(el) => (cardRefs.current[index] = el)}
              data-index={index}
              //   className={`card-wrapper-about-us ${
              //     visibleCards.includes(index.toString()) ? "active" : ""
              //   }`}
              style={{
                display: "flex",
                flexDirection: mdmatch ? null : "row",
                justifyContent: mdmatch ? null : "space-between",
                width: mdmatch ? null : "100%",
              }}
            >
              <div
              // className={`side-item-about-us ${
              //   visibleCards.includes(index.toString()) ? "active" : ""
              // }`}
              ></div>

              <Card
                sx={{
                  borderRight: "5px solid #B97E12",
                  background: "#DFDFDF",
                  width: matches ? "100%" : "90%",
                  // color: "white",
                  transition: "all 0.5s ease",
                  height: mdmatch ? "100%" : "160px",
                  borderRadius: "10px",
                }}
                // className={`card-content ${
                //   visibleCards.includes(index.toString()) ? "active" : ""
                // }`}
              >
                <CardContent>
                  <div
                    className="card-title-about-us"
                    style={{ marginBottom: "10px" }}
                  >
                    <Typography
                      fontWeight="700"
                      fontSize={contentFontSize_20}
                      fontFamily="Roboto"
                      //color="black"
                    >
                      {card.title}
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      fontWeight="400"
                      fontSize={contentFontSize_18}
                      fontFamily="Roboto"
                      className="card-description-about-us"
                    >
                      {card.description}
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            </div>
          ))}
        </div>
      </div>
      <div
        className="bg-img"
        style={{
          backgroundImage: `url(${Common})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          // height: "500px",
          position: "relative",
          color: "white",
          padding: "20px", // Add spacing for responsiveness
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="blog-text1">
          {matches ? (
            <Typography
              fontSize={contentFontSize_50}
              fontWeight="700"
              sx={{
                marginBottom: "10px",
                color: "#FFD700",
                marginTop: smmatch ? "0px" : matches ? "50px" : "160px",
                padding: matches ? "10px 0px 10px 40px" : "0px",
                textAlign: matches ? "center" : null,
              }}
            >
              Unlock the Power of Your Data to Drive Business Growth
            </Typography>
          ) : (
            <Typography
              fontSize={contentFontSize_50}
              fontWeight="700"
              sx={{
                marginBottom: "10px",
                color: "#FFD700",
                marginTop: smmatch ? "0px" : matches ? "50px" : "160px",
                padding: matches ? "10px 0px 10px 40px" : "0px",
                textAlign: matches ? "center" : null,
              }}
            >
              Unlock the Power of Your Data to Drive Business Growth
            </Typography>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: matches && "center",
            padding: smmatch ? "0px" : "10px 40px",
            flexDirection: lgmatch ? "column" : "row",
          }}
        >
          <div style={{ width: "70%" }}>
            <Typography
              //fontFamily="Roboto"
              fontWeight="400"
              fontSize={contentFontSize_20}
              style={{
                textAlign: matches ? "center" : "left",
                lineHeight: "1.6",
                marginBottom: "20px",
                color: "#FFFFFF", // White paragraph text
              }}
            >
              Axia Kairos offers comprehensive data analytics services,
              including big data analytics services and predictive analytics
              solutions, tailored to help your business succeed in today’s
              competitive landscape.
            </Typography>
          </div>
          <div style={{ marginTop: "10px" }}>
            <Button
              variant="contained"
              onClick={handleContact}
              sx={{
                backgroundImage:
                  "linear-gradient(90deg, #DFAD17 0%, #795E0C 100%)",
                width: "300px",
                height: "40px",
                borderRadius: "15px",
                // padding: "10px 20px",
                // fontSize: "16px",
                // backgroundColor: "#FFC107",
                // color: "white",
                // border: "none",
                // borderRadius: "5px",
                // cursor: "pointer",
                // fontWeight: "500",
              }}
            >
              <Typography
                //fontFamily="Roboto"
                sx={{ textTransform: "capitalize", padding: "15px" }}
              >
                Get Started Today
              </Typography>
            </Button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default DataAnalytics;
