import React, { useEffect } from "react";
import "./Blog.css";
import {
  contentFontsize_14,
  contentFontSize_15,
  contentFontSize_16,
  HeaderFontSize_25,
  HeaderfontSize_30,
  HeaderFontsize_36,
  HeaderFontSize_50,
  HeaderFontSize_52,
} from "../../Typography";
import { Typography } from "@mui/material";
import Blog1D from "../../utils/images/Blog1D.webp";
import Blogdrone from "../../utils/images/Blogdrone.png";
import Readmore from "../../utils/icons/readmore.svg";
import dataAnalytics from "../../utils/images/dataAnalytics.png";
import Devops from "../../utils/images/Devops.png";
import Customsoftware from "../../utils/images/Customsoftware.png";
import { useNavigate } from "react-router-dom";
import Blog2B from "../../utils/images/Blog2B.webp";
import Blog3Data from "../../utils/images/Blog3Data.webp";
import Blog4C from "../../utils/images/Blog4C.webp"
import Blog5Devops from "../../utils/images/Blog5Devops.webp"
import Blog6M from "../../utils/images/Blog6M.webp"
import Blog7E from "../../utils/images/Blog7E.webp"
import Blog8Ai from "../../utils/images/Blog8Ai.webp"
import Blog9Cyber from "../../utils/images/Blog9Cyber.webp"
import Blog10A from "../../utils/images/Blog10A.webp"
function BlogFirst() {
  const posts = [
    {
      id: 1,
      imgSrc: Blog2B,
      altText: "Business Processes",
      postedDate: "24-05-2024",
      title: "Top 5 Business Processes Every Company Should Automate",
      description:
        "In an increasingly competitive marketplace, businesses must find ways to enhance productivity, reduce costs, and improve accuracy.Business Process Automation .....",
    },
    {
      id: 2,
      imgSrc: Blog3Data,
      altText: "DataAnalytics",
      postedDate: "24-05-2024",
      title: " 5 Ways Data Analytics Can Transform Your Business Decisions",
      description:
        "In the age of digital transformation, data has become the foundation of informed decision-making. However, raw data alone.....",
    },
    {
      id: 3,
      imgSrc: Blog4C,
      altText: "CustomSoftware",
      postedDate: "24-05-2024",
      title: " Top 5 Benefits of Investing in Custom Software for Your Business",
      description:
        "Off-the-shelf software might seem like a quick solution for business needs,but it often falls short in addressing unique challenges.Custom software development .....",
    },
    {
      id: 4,
      imgSrc: Blog5Devops,
      altText: "Devops",
      postedDate: "24-05-2024",
      title: " Why DevOps is the Key to Faster, Reliable Software Delivery",
      description:
        "In today’s fast-paced tech-driven world, businesses are under constant pressure to deliver software faster, more efficiently,with minimal errors .....",
    },
    {
      id: 5,
      imgSrc: Blog6M,
      altText: "Mobility & Web",
      postedDate: "24-05-2024",
      title: "Why Responsive Design is Essential for Modern Web Development",
      description:
        "In an era where mobile devices account for more than half of all web traffic, having a responsive website is no longer optional—it’s essential.....",
    },
    {
      id: 6,
      imgSrc: Blog7E,
      altText: "ECommerce",
      postedDate: "24-05-2024",
      title: " Top Features Your E-Commerce Website Needs to Succeed",
      description:
        "As the e-commerce industry continues to grow, businesses must build online stores that stand out in a crowded digital marketplace. An effective e-commerce website goes  .....",
    },
    {
      id: 7,
      imgSrc: Blog8Ai,
      altText: "Ai BlockChain",
      postedDate: "24-05-2024",
      title: "How AI, Blockchain, and IoT are Transforming Industries",
      description:
        "The convergence of Artificial Intelligence (AI), Blockchain, and the Internet of Things (IoT) is revolutionizing industries by enabling smarter operations, enhanced security, and greater  .....",
    },
    {
      id: 8,
      imgSrc: Blog9Cyber,
      altText: "CyberSecurity",
      postedDate: "24-05-2024",
      title: " Why Cyber Security Should Be Your Top Priority in 2024",
      description:
        "In today’s hyper-connected world, cyber threats are becoming more sophisticated and frequent, making cybersecurity a top priority for businesses of all sizes .....",
    },
    {
      id: 9,
      imgSrc: Blog10A,
      altText: "Application",
      postedDate: "24-05-2024",
      title: " Why Application Managed Services Are Essential for Your Business Success",
      description:
        " In today’s fast-paced digital world, businesses rely heavily on applications to drive efficiency, enhance customer experiences, and support growth .....",
    },
    
  ];
  const nav = useNavigate();

  const handleDigitalClick = () => {
    nav("/blogs/Digital-Transformation");
  };
  const handleBlog = (index) => {
     console.log("index",index)
     if(index === 0){
      nav("/blogs/Business-Process-Automation")
     }
     else if(index === 1){
       nav("/blogs/Data-Analytics")
     }
     else if(index === 2){
      nav("/blogs/Custom-Software")
     }
     else if(index === 3){
      nav("/blogs/Devops")
     }
     else if(index === 4){
         nav("/blogs/Mobility-Web-Blog")
     }
     else if(index === 5){
      nav("/blogs/ECommerce-Solutions-Blog")
     }
     else if(index === 6){
      nav("/blogs/Ai-BlockChain-IoT-Blog")
     }
     else if(index === 7){
      nav("/blogs/CyberSecurity-Blog")
     }
     else{
      nav("/blogs/Application-Managed-Services-Blog")
     }
  }
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
  return (
    <>
      <div className="Blog-main-blog">
        <Typography
          fontSize={HeaderFontSize_52}
          className="font-blog"
          style={{ fontWeight: 600 }}
          sx={{
            "@media (max-width: 480px)": {
              lineHeight: 1.3,
            },
          }}
        >
          Explore Our Latest Posts
        </Typography>
        <div className="blog-first-section">
          <div className="first-blog-section-main">
            <div className="col-blog-content">
              <Typography fontSize={contentFontSize_16} className="newest-font">
                Newest
              </Typography>
              <div className="col-blog-content">
                <Typography
                  fontSize={contentFontsize_14}
                  className="posted-text"
                >
                  Posted on : 24-05-2024
                </Typography>
                <Typography
                  fontSize={HeaderfontSize_30}
                  style={{ fontWeight: 600 }}
                >
                  Digital Transformation: A roadmap to innovate, adapt, and
                  thrive in the modern era
                </Typography>
                <Typography
                  className="blog-description"
                  fontSize={contentFontSize_16}
                >
                  In an era where technology drives every aspect of business,
                  digital transformation has become a necessity rather than a
                  luxury. Companies that embrace digital transformation gain a
                  competitive edge by streamlining operations, enhancing...
                </Typography>

                <div className="readmore-blog" onClick={handleDigitalClick}>
                  <Typography fontSize={contentFontSize_16}>
                    Read More
                  </Typography>
                  <img src={Readmore} alt="Read More Icon" />
                </div>
              </div>
            </div>
            <div className="posted-img">
              <img src={Blog1D} alt="Digital Transformation" />
            </div>
          </div>
        </div>
        <div className="blog-section-container-blog">
          {posts.map((post,index) => (
            <div key={index} className="blog-card">
              <div className="blog-card-image">
                <img src={post.imgSrc} alt={post.altText}  style={{height:"300px",borderRadius:"20px"}}/>
                <Typography
                  fontSize={contentFontsize_14}
                  className="blog-post-date"
                >
                  Posted on: {post.postedDate}
                </Typography>
              </div>
              <div className="blog-card-content-new">
                <Typography
                  fontSize={HeaderfontSize_30}
                  style={{ fontWeight: 600, lineHeight: 1.4 }}
                >
                  {post.title}
                </Typography>
                <Typography
                  className="blog-description"
                  fontSize={contentFontSize_16}
                >
                  {post.description}
                </Typography>
                <div className="blog-read-more" onClick={() => handleBlog(index)}>
                  <Typography fontSize={contentFontSize_16}>
                    Read More
                  </Typography>
                  <img src={Readmore} alt="Read More Icon" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default BlogFirst;
