import {
  Button,
  Card,
  CardContent,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import {
  contentFontSize_16,
  contentFontSize_20,
  contentFontSize_20_xs_18,
  contentFontsize_30,
  contentFontSize_50,
} from "../../Typography";
import Common from "../../utils/images/ContactFaq.png";
import "./ContactFaq.css";

function ContactFaq() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <div
      className="bg-img-02"
      style={{
        backgroundImage: `url(${Common})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        // height: "500px",
        position: "relative",
        color: "white",
        padding: "20px", // Add spacing for responsiveness
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="blog-text1">
        {matches ? (
          <Typography
            fontSize={contentFontSize_50}
            fontWeight="700"
            sx={{
              marginBottom: "10px",
              color: "#FFD700",
              marginTop: smmatch ? "0px" : matches ? "50px" : "90px",
              padding: matches ? "10px 0px 10px 40px" : "0px",
              textAlign: matches ? "left" : null,
            }}
          >
            Let's Partner on
            <br /> Your Digital Transformation Journey!
          </Typography>
        ) : (
          <Typography
            fontSize={contentFontSize_50}
            fontWeight="700"
            sx={{
              marginBottom: "10px",
              color: "#FFD700",
              marginTop: smmatch ? "0px" : matches ? "50px" : "90px",
              padding: matches ? "10px 0px 10px 20px" : "0px",
              textAlign: matches ? "left" : null,
            }}
          >
            Let's Partner on
            <br /> Your Digital Transformation Journey!
          </Typography>
        )}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          //   alignItems: matches && "center",
          padding: smmatch ? "0px 25px" : "10px 40px",
          flexDirection: "column",
        }}
      >
        <div style={{ width: "70%" }}>
          <Typography
            //fontFamily="Roboto"
            fontWeight="400"
            fontSize={contentFontSize_20}
            style={{
              textAlign: matches ? "left" : "left",
              lineHeight: "1.6",
              marginBottom: "20px",
              color: "#FFFFFF", // White paragraph text
            }}
          >
            Axia Kairos is your one-stop shop for all your digital
            transformation needs. Whether you require expert consultation,
            comprehensive services, or simply have a question, we're here to
            help
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_16}
            style={{ fontWeight: "600" }}
          >
            Ready to take the next step?
            <span style={{ fontWeight: "500" }}>
              Here are a few ways to get started:
            </span>
          </Typography>
        </div>
        <div className="card-contact">
          <Card
            sx={{
              background: "transparent",
              color: "white",
              width: matches ? "100%" : "50%",
              height: "130px",
              display: "flex",
              padding: "10px",
              borderRadius: "15px",
              borderLeft: "5px solid #EBB81A",
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                padding: "15px 20px",
                justifyContent: "flex-start",
                gap: 1,
                alignItems: "center",
              }}
            >
              <Typography
                fontSize={contentFontSize_20_xs_18}
                fontWeight="600"
                textAlign="left"
              >
                Contact Us Today!{" "}
                <span style={{ fontWeight: "500" }}>
                  Fill out the form above or reach out directly at
                  reach@axiakairos.com
                </span>
              </Typography>
            </CardContent>
          </Card>
          <Card
            sx={{
              background: "transparent",
              color: "white",
              width: matches ? "100%" : "50%",
              height: "130px",
              display: "flex",
              padding: "10px",
              borderRadius: "15px",
              borderRight: "5px solid #EBB81A",
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                padding: "15px 20px",
                justifyContent: "flex-start",
                gap: 1,
                alignItems: "center",
              }}
            >
              <Typography
                fontSize={contentFontSize_20_xs_18}
                fontWeight="600"
                textAlign="left"
              >
                Explore Our Services!{" "}
                <span style={{ fontWeight: "500" }}>
                  Discover how Axia Kairos can help you achieve your digital
                  goals on our dedicated services page.
                </span>
              </Typography>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default ContactFaq;
