import React, { useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import {
  contentFontSize_16,
  contentFontSize_18,
  contentFontSize_20,
  contentFontsize_24,
  contentFontsize_28,
  contentFontSize_50,
  contentFontsize_96,
  HeaderFontSize_50_xs_33,
} from "../../Typography";
import Common from "../../utils/images/Common.jpg";
import "./DigitalTransformation.css";
import Navbar from "../../Navbar";
import DevopsC from "../../utils/images/DevopsC.jpg";
import Footer from "../../Footer/Footer";
import WhiteArrow from "../../utils/icons/WhiteArrow.svg"
import { useNavigate } from "react-router-dom";
function Devops() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const nav = useNavigate()
  function handleService(){
      nav("/services")
  }
  const cards = [
    {
      title: "Faster Time-to-Market",
      description:
        "Our CI/CD pipelines and automated workflows enable faster development cycles, allowing you to release new features and updates more frequently, keeping you ahead of the competition. ",
    },
    {
      title: "Scalable Solutions",
      description:
        "By leveraging cloud-native development technologies, we create scalable solutions that can grow with your business, ensuring your infrastructure can handle increased traffic and workloads seamlessly. ",
    },
    {
      title: "Improved Collaboration & Efficiency",
      description:
        "DevOps services break down the barriers between development, testing, and operations teams, fostering a culture of collaboration and continuous improvement that accelerates software delivery and quality. ",
    },
    {
      title: "Enhanced Security & Compliance",
      description:
        "With our DevSecOps solutions, we ensure that security is integrated into every stage of development, protecting your applications and data from vulnerabilities and ensuring compliance with industry standards. ",
    },
  ];
  const data = [
    {
      id: "01",
      title: "Pioneers in Transformation",
      content:
        "We are at the forefront of leveraging digital technologies to drive meaningful change. Whether it’s adopting cloud solutions, integrating AI, or enhancing user experiences, we help businesses turn ideas into reality as a trusted business IT solutions provider.",
    },
    {
      id: "02",
      title: "Personalized for You",
      content:
        "No two businesses are the same, and neither are our solutions. We tailor every strategy, platform, and process to your unique goals and challenges, ensuring maximum impact.",
    },
    {
      id: "03",
      title: "Built for Long-Term Success",
      content:
        "Our solutions aren’t just designed for today—they’re crafted to evolve with you, ensuring lasting value in an ever-changing landscape.",
    },
    {
      id: "04",
      title: "Driven by Expertise",
      content:
        "Our team of seasoned professionals brings together global experience, industry knowledge, and a passion for innovation to deliver results that matter.",
    },
  ];

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));
  const dataService = [
    {
      title: "Consultation & Assessment",

      description:
        "We begin by understanding your current development and cloud environments, identifying bottlenecks, and defining your goals. Our experts assess your existing workflows to design a roadmap for optimization. ",
    },
    {
      title: "Cloud Architecture Design",

      description:
        "Our architects design cloud-native development architectures tailored to your business needs, ensuring scalability, resilience, and high performance. We select the best technologies and platforms that align with your business objectives. ",
    },
    {
      title: "DevOps Implementation",

      description:
        "We implement DevOps services that bring agility and automation to your development cycle. From CI/CD pipelines to automated testing and deployment, we ensure that your development process is streamlined and efficient. ",
    },
  ];
  const dataService1 = [
    {
      title: "Cloud Infrastructure Setup & Management",

      description:
        "Our cloud engineers build and manage your cloud infrastructure, ensuring it is optimized for performance, security, and cost efficiency. We utilize Infrastructure as Code (IaC) to automate infrastructure provisioning and management. ",
    },
    // {
    //   title: "Deployment & Support",

    //   description:
    //     "Once your software is ready, we deploy it to your environment, providing full integration and post-deployment support to ensure seamless operation.",
    // },
    {
      title: "Continuous Monitoring & Optimization",

      description:
        "Once your systems are deployed, we monitor and optimize them continuously. Using real-time analytics, we identify performance bottlenecks, enhance scalability, and ensure that your cloud infrastructure evolves with your business. ",
    },
  ];
  function handleContact(){
    nav("/contact")
  }
  return (
    <div>
      <Navbar />
      <div
        className="bg-img-1"
        style={{
          backgroundImage: `url(${DevopsC})`,
          backgroundSize: "cover",
          // backgroundPosition: "center",
          //height: "500px",
          position: "relative",
          color: "white",
          padding: smmatch ? "0px" : "20px", // Add spacing for responsiveness
          backgroundRepeat: "no-repeat",
        }}
      >
         <div className="back-btn" onClick={handleService}>
                                <img src={WhiteArrow} style={{ marginTop:smmatch ? "18px" : mdmatch && "4px"}} />
                                <span
                                  style={{
                                    marginLeft: "10px",
                                    fontSize: contentFontSize_18,
                                    fontFamily: "Roboto",
                                    marginBottom: "3px",
                                     marginTop:smmatch && "15px"
                                  }}
                                >
                                  Back to Services
                                </span>
                              </div>
        <div className="blog-text1">
          {/* {matches ? ( */}
          <div>
            <Typography
              fontSize={contentFontSize_50}
              fontWeight="700"
              sx={{
                marginBottom: "10px",
                color: "w",
                marginTop: smmatch ? "0px" : matches ? "50px" : "300px",
                padding: matches ? "50px 0px 10px 40px" : "0px",
                textAlign: matches ? "center" : null,
              }}
            >
              DevOps & Cloud Engineering: Accelerate Development and Delivery
            </Typography>
            <div>
              <Typography
                fontSize={contentFontSize_20}
                fontWeight="400"
                fontFamily="Roboto"
                sx={{
                  padding: matches ? "10px 0px 10px 40px" : "0px",
                  textAlign: matches ? "center" : null,
                }}
              >
                Optimizing Your Development Processes with Cloud-Native
                Solutions
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div className="service-sub-content">
        <Typography
          fontFamily="Roboto"
          fontWeight="400"
          fontSize={contentFontSize_18}
          sx={{ textAlign: "justify", color: "#6C7282" }}
        >
          At Axia Kairos, we combine DevOps services with cloud engineering
          solutions to help you streamline your software development and
          deployment processes. Our solutions enable faster delivery, greater
          collaboration, and more efficient management of cloud infrastructure.
          Whether you're building applications from scratch or looking to
          improve your existing systems, our cloud engineering services ensure
          scalable, reliable, and high-performing solutions. We specialize in
          cloud-native development, ensuring your applications are optimized for
          the cloud.
        </Typography>
        <Typography
          fontFamily="Roboto"
          fontSize={contentFontsize_28}
          fontWeight="700"
          color="#B97E12"
          sx={{ marginTop: "15px" }}
        >
          What We Do and Offer
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Continuous Integration & Continuous Delivery (CI/CD)
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Accelerate your software delivery with automated CI/CD pipelines. Our
          DevOps services enable faster and more efficient development cycles,
          ensuring that your software is always ready for deployment. We
          streamline code integration, automate testing, and manage deployments,
          reducing errors and speeding up time-to-market.
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Cloud-Native Development
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Develop scalable, reliable, and resilient applications using
          cloud-native development principles. By leveraging microservices,
          containerization (Docker, Kubernetes), and serverless computing, we
          create applications that are optimized for the cloud, enabling
          improved performance, scalability, and flexibility.
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Infrastructure as Code (IaC)
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Automate the management and provisioning of cloud infrastructure with
          Infrastructure as Code (IaC). We use tools like Terraform and
          CloudFormation to manage your infrastructure through code, ensuring
          repeatable and scalable deployments, minimizing human error, and
          improving consistency across environments.
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          DevOps Automation
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Streamline your development process with end-to-end automation. Our
          DevOps services automate testing, monitoring, and deployment tasks,
          allowing your teams to focus on more strategic initiatives while
          improving collaboration and efficiency across development, testing,
          and operations.
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Cloud Infrastructure Management & Optimization
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          We ensure that your cloud infrastructure management is highly
          available, optimized for performance, and cost-effective. Through
          continuous monitoring and optimization, we ensure that your cloud
          environment scales with your business while maintaining the security
          and performance standards you need.
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Monitoring & Logging
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Get real-time visibility into your applications and infrastructure
          with robust monitoring and logging solutions. We use tools like
          Prometheus, Grafana, and ELK Stack to provide actionable insights into
          your systems, enabling you to proactively address performance issues
          and optimize your operations.
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Security in DevOps (DevSecOps)
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Security is embedded at every stage of the development lifecycle with
          our DevSecOps solutions. We integrate automated security testing and
          compliance checks into your CI/CD pipeline to ensure that security
          vulnerabilities are identified and mitigated early in the development
          process.
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Automated Testing & Quality Assurance
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Ensure the quality and performance of your applications with automated
          testing solutions that integrate seamlessly into your CI/CD pipeline.
          We automate functional, performance, and security testing, ensuring
          that your software is reliable and secure at every stage of
          development.
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Cloud Migration & Cloud Strategy
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Seamlessly migrate your on-premise applications and infrastructure to
          the cloud with minimal disruption. Our cloud strategy and migration
          services ensure that your systems are optimized for cloud
          environments, improving scalability and reducing operational costs.
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Hybrid & Multi-Cloud Solutions
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Maximize your cloud strategy with hybrid and multi-cloud environments.
          We help you leverage the best features of multiple cloud providers,
          ensuring flexibility, performance, and reduced risk while optimizing
          costs.
        </Typography>

        {/* <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          IT Infrastructure Modernization
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          We upgrade your IT environment with cutting-edge tools and solutions.
          Whether it’s on-premise, hybrid, or cloud infrastructure, our services
          ensure your systems are resilient, secure, and future-ready.
        </Typography> */}
      </div>
      <div className="about-us-second">
        <div className="main-heading-about-us">
          <Typography
            sx={{ fontWeight: "700", fontFamily: "Roboto", marginTop: "50px",textAlign:smmatch && "center" }}
            fontSize={HeaderFontSize_50_xs_33}
          >
            Why Choose Axia Kairos?
          </Typography>
        </div>
        <Typography
          fontSize={contentFontSize_20}
          fontWeight="400"
          color="white"
          fontFamily="Roboto"
          sx={{
            paddingLeft: mdmatch ? "10px" : "150px",
            paddingRight: mdmatch ? "10px" : "150px",
            textAlign: "center",
            paddingBottom: "50px",
          }}
        >
          Axia Kairos combines best practices in DevOps services and cloud
          engineering solutions to deliver high-performance, secure, and
          scalable solutions. Our team brings deep expertise in cloud platforms
          like AWS, Azure, and Google Cloud, ensuring that your applications are
          optimized for the cloud while implementing DevSecOps solutions that
          enable faster time-to-market. We help your teams collaborate
          seamlessly and focus on delivering value to your business, while we
          handle the infrastructure and deployment.
        </Typography>
        <div className="about-us-div">
          {data.map((item) => (
            <div className="about-us-card" key={item.id}>
              <Typography
                fontSize={contentFontsize_96}
                fontFamily="Roboto"
                fontWeight="100"
                sx={{ color: "#EBB81A", lineHeight: "98px" }}
              >
                {item.id}
              </Typography>
              <Typography
                sx={{
                  color: "#ECECEC",
                  textAlign: "left",
                  lineHeight: "31px",
                  marginBottom: "10px",
                }}
                fontSize={contentFontsize_24}
                fontFamily="Roboto"
              >
                {item.title}
              </Typography>
              <Typography
                sx={{
                  color: "#ECECEC",
                  textAlign: "left",
                  lineHeight: "31px",
                  fontWeight: "400",
                }}
                fontSize={contentFontSize_16}
                fontFamily="Roboto"
              >
                {item.content}
              </Typography>
              {/* <div className="imge-section-about-us">
                  <img src={Line}/>
                </div> */}
            </div>
          ))}
        </div>
      </div>
      <div className="third-page-service">
        <div style={{ marginTop: "50px", marginBottom: "50px" }}>
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              textAlign: "center",
            }}
            fontWeight="700"
            fontFamily="Roboto"
            fontSize={contentFontSize_50}
          >
            Our Proven Approach to DevOps & Cloud Engineering
          </Typography>
        </div>
        <div className="secnd-sec">
          <div className="flex-card-in">
            {dataService.map((card, index) => (
              <Card
                className="about-card-service"
                key={index}
                sx={{ borderRadius: "15px", background: "#9F6907" }}
              >
                <div className="about-content-card-service">
                  <Typography
                    fontWeight="700"
                    fontSize={contentFontsize_24}
                    fontFamily="Roboto"
                    className="about-card-title"
                  >
                    {card.title}
                  </Typography>
                  <Typography
                    fontWeight="400"
                    fontSize={contentFontSize_18}
                    fontFamily="Roboto"
                    color="white"
                    className="about-content-1-service"
                  >
                    {card.description}
                  </Typography>
                </div>
              </Card>
            ))}
          </div>
        </div>
        <div className="secnd-sec">
          <div className="flex-card-in-1">
            {dataService1.map((card, index) => (
              <Card
                className="about-card-service-1"
                key={index}
                sx={{
                  borderRadius: "15px",
                  background: "#9F6907",
                  marginTop: "20px",
                }}
              >
                <div className="about-content-card-service">
                  <Typography
                    fontWeight="700"
                    fontSize={contentFontsize_24}
                    fontFamily="Roboto"
                    className="about-card-title"
                  >
                    {card.title}
                  </Typography>
                  <Typography
                    fontWeight="400"
                    fontSize={contentFontSize_18}
                    fontFamily="Roboto"
                    color="white"
                    className="about-content-1-service"
                  >
                    {card.description}
                  </Typography>
                </div>
              </Card>
            ))}
          </div>
        </div>
      </div>
      <div className="main-third">
        <div className="about-us-text1">
          <Typography
            fontFamily="Roboto"
            fontWeight="500"
            fontSize={contentFontSize_50}
            sx={{
              paddingTop: mdmatch ? "50px" : "200px",
              textAlign: matches && "center",
            }}
          >
            How Our DevOps & Cloud Engineering Services Ensure Long-Term Success
          </Typography>
        </div>

        <div className="third-sub">
          {cards.map((card, index) => (
            <div
              key={index}
              //   ref={(el) => (cardRefs.current[index] = el)}
              data-index={index}
              //   className={`card-wrapper-about-us ${
              //     visibleCards.includes(index.toString()) ? "active" : ""
              //   }`}
              style={{
                display: "flex",
                flexDirection: mdmatch ? null : "row",
                justifyContent: mdmatch ? null : "space-between",
                width: mdmatch ? null : "100%",
              }}
            >
              <div
              // className={`side-item-about-us ${
              //   visibleCards.includes(index.toString()) ? "active" : ""
              // }`}
              ></div>

              <Card
                sx={{
                  borderRight: "5px solid #B97E12",
                  background: "#DFDFDF",
                  width: matches ? "100%" : "90%",
                  // color: "white",
                  transition: "all 0.5s ease",
                  height: mdmatch ? "100%" : "160px",
                  borderRadius: "10px",
                }}
                // className={`card-content ${
                //   visibleCards.includes(index.toString()) ? "active" : ""
                // }`}
              >
                <CardContent>
                  <div
                    className="card-title-about-us"
                    style={{ marginBottom: "10px" }}
                  >
                    <Typography
                      fontWeight="700"
                      fontSize={contentFontSize_20}
                      fontFamily="Roboto"
                      //color="black"
                    >
                      {card.title}
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      fontWeight="400"
                      fontSize={contentFontSize_18}
                      fontFamily="Roboto"
                      className="card-description-about-us"
                    >
                      {card.description}
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            </div>
          ))}
        </div>
      </div>
      <div
        className="bg-img"
        style={{
          backgroundImage: `url(${Common})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          // height: "500px",
          position: "relative",
          color: "white",
          padding: "20px", // Add spacing for responsiveness
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="blog-text1">
          {matches ? (
            <Typography
              fontSize={contentFontSize_50}
              fontWeight="700"
              sx={{
                marginBottom: "10px",
                color: "#FFD700",
                marginTop: smmatch ? "0px" : matches ? "50px" : "160px",
                padding: matches ? "10px 0px 10px 40px" : "0px",
                textAlign: matches ? "center" : null,
              }}
            >
              Unlock the Full Potential of Your Cloud & DevOps Strategy
            </Typography>
          ) : (
            <Typography
              fontSize={contentFontSize_50}
              fontWeight="700"
              sx={{
                marginBottom: "10px",
                color: "#FFD700",
                marginTop: smmatch ? "0px" : matches ? "50px" : "160px",
                padding: matches ? "10px 0px 10px 40px" : "0px",
                textAlign: matches ? "center" : null,
              }}
            >
              Unlock the Full Potential of Your Cloud & DevOps Strategy
            </Typography>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: matches && "center",
            padding: smmatch ? "0px" : "10px 40px",
            flexDirection: lgmatch ? "column" : "row",
          }}
        >
          <div style={{ width: "70%" }}>
            <Typography
              //fontFamily="Roboto"
              fontWeight="400"
              fontSize={contentFontSize_20}
              style={{
                textAlign: matches ? "center" : "left",
                lineHeight: "1.6",
                marginBottom: "20px",
                color: "#FFFFFF", // White paragraph text
              }}
            >
              Axia Kairos helps businesses adopt cloud-native solutions and
              DevOps services to accelerate software development, improve
              efficiency, and deliver secure, high-performance applications.
              Ready to transform your development process?
            </Typography>
          </div>
          <div style={{ marginTop: "10px" }}>
            <Button
              variant="contained"
              onClick={handleContact}
              sx={{
                backgroundImage:
                  "linear-gradient(90deg, #DFAD17 0%, #795E0C 100%)",
                width: "300px",
                height: "40px",
                borderRadius: "15px",
                // padding: "10px 20px",
                // fontSize: "16px",
                // backgroundColor: "#FFC107",
                // color: "white",
                // border: "none",
                // borderRadius: "5px",
                // cursor: "pointer",
                // fontWeight: "500",
              }}
            >
              <Typography
                //fontFamily="Roboto"
                sx={{ textTransform: "capitalize", padding: "15px" }}
              >
                Get Started Today
              </Typography>
            </Button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Devops;
