import React, { useEffect, useRef, useState } from "react";
import "./ThirdPage.css";
import {
  Card,
  CardContent,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  contentFontSize_18,
  contentFontSize_20,
  contentFontSize_24,
  contentFontSize_50,
} from "../../Typography";

function ThirdPage() {
  const cardRefs = useRef([]);
  const [visibleCards, setVisibleCards] = useState([]);

  // Function to observe cards
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const index = entry.target.dataset.index;
          if (entry.isIntersecting) {
            setVisibleCards((prev) => [...new Set([...prev, index])]);
          } else {
            setVisibleCards((prev) => prev.filter((id) => id !== index));
          }
        });
      },
      { threshold: 0.5 } // Trigger when 50% of the card is visible
    );

    // Attach observer to each card
    cardRefs.current.forEach((card) => {
      if (card) observer.observe(card);
    });

    return () => observer.disconnect();
  }, []);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));
  const cards = [
    {
      title: "Insightful Discovery",
      description:
        "We begin by immersing ourselves in your business environment, understanding your challenges and objectives to gain a comprehensive perspective of what matters most.",
    },
    {
      title: "Strategic Allignment",
      description:
        "With a deep understanding of your vision, we develop a tailored strategy that harmonizes technology with your business goals, ensuring alignment at every stage of the journey.",
    },
    {
      title: "Efficient Implementation",
      description:
        " Execution is at the heart of what we do. We ensure the seamless deployment of each solution, with a focus on delivering measurable results, high quality, and minimal disruption. ",
    },
    {
      title: "Sustained Growth",
      description:
        " Our commitment doesn’t end at implementation. We partner with you to continuously enhance and scale our solutions, ensuring they evolve in line with your business and market demands.",
    },
  ];

  return (
    <div>
      <div className="main-third">
        <div className="about-us-text1">
          <Typography
            fontFamily="Roboto"
            fontWeight="500"
            fontSize={contentFontSize_50}
            sx={{ paddingTop: mdmatch ? "50px" : "370px" }}
          >
            Our Process
          </Typography>
        </div>

        <div className="third-sub">
          {cards.map((card, index) => (
            <div
              key={index}
              ref={(el) => (cardRefs.current[index] = el)}
              data-index={index}
              className={`card-wrapper-about-us ${
                visibleCards.includes(index.toString()) ? "active" : ""
              }`}
              style={{
                display: "flex",
                flexDirection: mdmatch ? null : "row",
                justifyContent: mdmatch ? null : "space-between",
                width: mdmatch ? null : "100%",
              }}
            >
              <div
                className={`side-item-about-us ${
                  visibleCards.includes(index.toString()) ? "active" : ""
                }`}
              ></div>

              <Card
                sx={{
                  border: "5px solid #DFDFDF",
                  background: "#DFDFDF",
                  width: "90%",
                  // color: "white",
                  transition: "all 0.5s ease",
                  height: mdmatch ? "100%" : "160px",
                }}
                className={`card-content ${
                  visibleCards.includes(index.toString()) ? "active" : ""
                }`}
              >
                <CardContent sx={{ padding: "10px" }}>
                  <div
                    className="card-title-about-us"
                    style={{ marginBottom: "10px" }}
                  >
                    <Typography
                      fontWeight="700"
                      fontSize={contentFontSize_20}
                      fontFamily="Roboto"
                      //color="black"
                    >
                      {card.title}
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      fontWeight="400"
                      fontSize={contentFontSize_18}
                      fontFamily="Roboto"
                      lineHeight="31px"
                      className="card-description-about-us"
                    >
                      {card.description}
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ThirdPage;
