import { Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { contentFontSize_20, contentFontSize_50 } from "../../Typography";
import Common from "../../utils/images/CareerHero.png";
import './Careers.css'

function CareerHero() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <div
      className="bg-img-1"
      style={{
        backgroundImage: `url(${Common})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        // height: "500px",
        position: "relative",
        color: "white",
        padding: smmatch ? "0px" : "20px",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="blog-text1">
        {matches ? (
          <div>
            <Typography
              fontSize={contentFontSize_50}
              fontWeight="700"
              sx={{
                marginBottom: "10px",
                marginTop: smmatch ? "0px" : matches ? "50px" : "200px",
                padding: matches ? "10px 0px 10px 40px" : "0px",
                textAlign: matches ? "center" : undefined,
              }}
              className="main-careerfont"
            >
              Join the Axia Kairos Team: <br />
              Your Future in Digital Innovation
            </Typography>
            <div>
              <Typography
                fontSize={contentFontSize_20}
                style={{ fontWeight: "600", color: "black" }}
                sx={{
                  padding: matches ? "10px 0px 10px 40px" : "0px",
                  textAlign: matches ? "left" : null,
                }}
              >
                Why Join Axia Kairos?
              </Typography>
            </div>
          </div>
        ) : (
          <div>
            <Typography
              fontSize={contentFontSize_50}
              fontWeight="700"
              sx={{
                marginBottom: "10px",
                marginTop: smmatch ? "0px" : matches ? "50px" : "200px",
                padding: matches ? "10px 0px 10px 40px" : "0px",
                textAlign: matches ? "left" : null,
              }}
              className="main-careerfont"
            >
              Join the Axia Kairos Team: <br />
              Your Future in Digital Innovation
            </Typography>
            <div>
              <Typography
                fontSize={contentFontSize_20}
                style={{ fontWeight: "600", color: "black" }}
              >
                Why Join Axia Kairos?
              </Typography>
            </div>
          </div>
        )}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: matches && "center",
          padding: smmatch ? "0px" : "10px 40px",
          flexDirection: lgmatch ? "column" : "row",
        }}
      >
        <div style={{ width: "70%" }}>
          <Typography
            //fontFamily="Roboto"
            fontWeight="400"
            fontSize={contentFontSize_20}
            style={{
              textAlign: matches ? "center" : "left",
              lineHeight: "1.6",
              marginBottom: "20px",
              color: "black", // White paragraph text
            }}
          >
            At Axia Kairos, fostering open communication is at the core of our
            commitment to empowering businesses. Whether you’re exploring
            digital transformation services, seeking a technology partner, or
            looking for insights into IT solutions, our team of experts is here
            to assist. We prioritize your goals and ensure prompt responses to
            all inquiries.
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default CareerHero;
