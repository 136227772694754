import React from "react";
import Navbar from "../Navbar";
import InnerSection from "./InnerSection/InnerSection";
import Footer from "../Footer/Footer";

function Services() {
  return (
    <>
      <Navbar />
      <InnerSection />
      <Footer />
    </>
  );
}

export default Services;
