import React, { useEffect, useState, useRef } from "react";
import "./CareersInner.css";
import Base from "../../utils/images/Base1.png";
import {
  ButtonFontSize_16,
  contentFontSize_18,
  contentFontSize_20,
  contentFontsize_28,
  HeaderFontSize_40,
} from "../../Typography";
import {
  Button,
  FormControl,
  Grid,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Check from "../../utils/icons/Checkcareer.svg";
import Envelope from "../../utils/icons/envelope.svg";
import emailjs from "@emailjs/browser";
import { message } from "antd";
import BackArrow from "../../utils/icons/backbutton.svg";
import Navbar from "../../Navbar";
import share from "../../utils/icons/share-alt.svg";
import Footer from "../../Footer/Footer";

function CareersInner() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const nav = useNavigate();
  const location = useLocation();
  const job = location.state?.job;
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const [errors, setErrors] = useState({
    email: "",
  });

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const formRef = useRef(null);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [e.target.name]: e.target.value });

    if (name === "email") {
      if (!validateEmail(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "Please enter a valid email address.",
        }));
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      name: formData.name,
      email: formData.email,
      message: formData.message,
      subject: formData.subject,
    };

    emailjs
      .send(
        "service_heh5e3i",
        "template_8kpwnmd",
        templateParams,
        "hld0dDwOeyNu8DW41"
      )
      .then(
        (result) => {
          console.log(result.text);
          message.success({
            content: "Message sent successfully",
            className: "custom-class",
            style: {
              marginTop: "20vh",
            },
          });
          setFormData({
            name: "",
            email: "",
            subject: "",
            message: "",
          });
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  const handleBackClick = () => {
    nav("/careers");
  };
  const handleApplyNowClick = () => {
    const secondPage = document.getElementById("aboutus");
    if (secondPage) {
      secondPage.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Navbar />
      <div className="main-inner-careers">
        <div className="img-section-career">
          <div className="back-btn-careers" onClick={handleBackClick}>
            <img src={BackArrow} />
            <span
              style={{
                marginLeft: "10px",
                fontSize: contentFontSize_18,
                // fontFamily: "Roboto",
                marginBottom: "3px",
              }}
            >
              Back to Careers
            </span>
          </div>
          <div className="job-details-position">
            <Typography
              fontSize={HeaderFontSize_40}
              style={{ fontWeight: "700" }}
            >
              {job.title}
            </Typography>
            <div>
              <Typography fontSize={contentFontSize_20}>{job.title}</Typography>
            </div>
          </div>
        </div>
        <div className="apply-box" style={{ backgroundColor: "white" }}>
          <Button
            onClick={handleApplyNowClick}
            className="button-careers"
            variant="contained"
            sx={{
              width: "100%",
              background: "#B97E12",
              color: "#fff",
              ":hover": {
                background: "#B97E12",
              },
              display: "block",
              marginLeft: "0",
              marginRight: "auto",
              "@media (max-width: 768px)": {
                width: "100%",
                minWidth: "100px",
              },
              "@media (max-width: 375px)": {
                width: "100%",
              },
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                textTransform: "capitalize",
              }}
            >
              <img src={Envelope} />
              <Typography fontSize={ButtonFontSize_16}>Apply Now</Typography>
            </div>
          </Button>
          <Button
            className="button-careers"
            variant="contained"
            sx={{
              width: "100%",
              background: "white",
              color: "#B97E12",
              ":hover": {
                background: "white",
              },
              boxShadow: "none",
              border: "2px solid #B97E12",
              display: "block",
              marginLeft: "0",
              marginRight: "auto",
              "@media (max-width: 768px)": {
                width: "100%",
                minWidth: "100px",
              },
              "@media (max-width: 375px)": {
                width: "100%",
              },
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "20px",
                textTransform: "capitalize",
              }}
            >
              <Typography fontSize={ButtonFontSize_16}>Share Now</Typography>
              <img src={share} />
            </div>
          </Button>
        </div>
        <div className="inner-main">
          <div className="inner-content-careers">
            <div>
              <Typography
                fontSize={contentFontSize_18}
                // fontFamily="Roboto"
                fontWeight="400"
                sx={{
                  textAlign: "justify",
                  color: "#6C7282",
                  marginTop: "10px",
                  letterSpacing: "1px",
                }}
              >
                {job.description}
              </Typography>
            </div>

            <div>
              <Typography
                fontSize={contentFontsize_28}
                // fontFamily="Roboto"
                fontWeight="700"
                sx={{ marginTop: "10px", letterSpacing: "1px" }}
              >
                Who We Are
              </Typography>
            </div>
            <div>
              <Typography
                fontSize={contentFontSize_18}
                // fontFamily="Roboto"
                fontWeight="400"
                sx={{
                  textAlign: "justify",
                  color: "#6C7282",
                  marginTop: "10px",
                  letterSpacing: "1px",
                }}
              >
                We’re a team of lifelong learners. We’re equal parts left and
                right brained. And we’re generally likeable. We won’t bore you
                with more adjectives. See for yourself.
              </Typography>
            </div>
            <div>
              <Typography
                fontSize={contentFontsize_28}
                // fontFamily="Roboto"
                fontWeight="700"
                sx={{ marginTop: "10px", letterSpacing: "1px" }}
              >
                Working with us
              </Typography>
            </div>
            <div>
              <Typography
                fontSize={contentFontSize_18}
                // fontFamily="Roboto"
                fontWeight="400"
                sx={{
                  textAlign: "justify",
                  color: "#6C7282",
                  marginTop: "10px",
                  letterSpacing: "1px",
                }}
              >
                We’re a global team with a philosophy of encouraging passionate
                people to be fun and creative. We value the unique experiences
                and perspectives of individuals from diverse backgrounds,
                believing that inclusivity fosters innovation and a vibrant
                workplace culture.
              </Typography>
            </div>
            <div>
              <Typography
                fontSize={contentFontsize_28}
                // fontFamily="Roboto"
                fontWeight="700"
                sx={{ marginTop: "10px", letterSpacing: "1px" }}
              >
                Key Responsibilities:
              </Typography>
            </div>
            <ul style={{ paddingLeft: "20px", listStyle: "none" }}>
              {job?.responsibilities?.map((point, index) => (
                <li
                  key={index}
                  style={{
                    marginBottom: "10px",
                    textAlign: "justify",
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                >
                  <img
                    src={Check}
                    alt="check"
                    style={{
                      width: "16px",
                      height: "16px",
                      marginRight: "10px",
                      marginTop: "4px",
                    }}
                  />
                  <Typography
                    fontSize={contentFontSize_18}
                    fontWeight="400"
                    sx={{
                      color: "#6C7282",
                      letterSpacing: "1px",
                    }}
                  >
                    {point}
                  </Typography>
                </li>
              ))}
            </ul>
            <div>
              <Typography
                fontSize={contentFontsize_28}
                // fontFamily="Roboto"
                fontWeight="700"
                sx={{ marginTop: "10px", letterSpacing: "1px" }}
              >
                Preferred Skills:
              </Typography>
            </div>
            <ul style={{ paddingLeft: "20px", listStyle: "none" }}>
              {job?.preferredSkills?.map((point, index) => (
                <li
                  key={index}
                  style={{
                    marginBottom: "10px",
                    textAlign: "justify",
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                >
                  <img
                    src={Check}
                    alt="check"
                    style={{
                      width: "16px",
                      height: "16px",
                      marginRight: "10px",
                      marginTop: "4px",
                    }}
                  />
                  <Typography
                    fontSize={contentFontSize_18}
                    fontWeight="400"
                    sx={{
                      color: "#6C7282",
                      letterSpacing: "1px",
                    }}
                  >
                    {point}
                  </Typography>
                </li>
              ))}
            </ul>
          </div>
          <div className="form" id="aboutus">
            <form className="form-container" onSubmit={handleSubmit}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required variant="standard">
                    <label>
                      <p className="Contactformtext">
                        Full Name <span>*</span>
                      </p>
                    </label>
                    <TextField
                      size="small"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                          "& fieldset": {
                            borderColor: "#BDBABA",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#BDBABA",
                          },
                        },
                        "& .MuiInputBase-input": {
                          borderRadius: "20px",
                        },
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required variant="standard">
                    <label>
                      <p className="Contactformtext">
                        Contact Mail <span>*</span>
                      </p>
                    </label>
                    <TextField
                      size="small"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                          "& fieldset": {
                            borderColor: "#BDBABA",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#BDBABA",
                          },
                        },
                        "& .MuiInputBase-input": {
                          borderRadius: "20px",
                        },
                      }}
                      error={!!errors.email}
                      helperText={errors.email}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl fullWidth required variant="standard">
                    <label>
                      <p className="Contactformtext">Subject</p>
                    </label>
                    <TextField
                      size="small"
                      // type="subject"
                      name="subject"
                      value={formData.subject}
                      onChange={handleChange}
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                          "& fieldset": {
                            borderColor: "#BDBABA",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#BDBABA",
                          },
                        },
                        "& .MuiInputBase-input": {
                          borderRadius: "20px",
                        },
                      }}
                    />
                  </FormControl>
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required variant="standard">
                    <label>
                      <p className="Contactformtext">Phone Number</p>
                    </label>
                    <TextField
                      size="small"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                          "& fieldset": {
                            borderColor: "#BDBABA",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#BDBABA",
                          },
                        },
                        "& .MuiInputBase-input": {
                          borderRadius: "20px",
                        },
                      }}
                    />
                  </FormControl>
                </Grid> */}
                <Grid item xs={12}>
                  <FormControl fullWidth required variant="standard">
                    <label>
                      <p className="Contactformtext">
                        Tell us a bit about you <span>*</span>
                      </p>
                    </label>
                    <TextField
                      name="message"
                      multiline={true}
                      rows="2"
                      placeholder="Let us know"
                      value={formData.message}
                      onChange={handleChange}
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px",
                          "& fieldset": {
                            borderColor: "#BDBABA",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#BDBABA",
                          },
                        },
                        "& .MuiInputBase-input": {
                          // borderRadius: "20px",
                        },
                      }}
                    />
                  </FormControl>
                  <div>
                    <Typography
                      fontSize={contentFontSize_18}
                      // fontFamily="Roboto"
                      fontWeight="400"
                      sx={{
                        textAlign: "justify",
                        color: "#6C7282",
                        marginTop: "10px",
                        letterSpacing: "1px",
                      }}
                    >
                      By submitting this form you agree to our terms and
                      conditions and our Privacy Policy which explains how we
                      may collect, use and disclose your personal information
                      including to third parties.
                    </Typography>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth required variant="standard">
                    <Button
                      type="submit"
                      className="button-careers"
                      variant="contained"
                      sx={{
                        width: "20%",
                        background: "#B97E12",
                        color: "#fff",
                        ":hover": {
                          background: "#B97E12",
                        },
                        display: "block",
                        marginLeft: "0",
                        marginRight: "auto",
                        "@media (max-width: 768px)": {
                          width: "100%",
                          minWidth: "100px",
                        },
                        "@media (max-width: 375px)": {
                          width: "100%",
                        },
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "5px",
                          textTransform: "capitalize",
                        }}
                      >
                        <img src={Envelope} />
                        <Typography fontSize={ButtonFontSize_16}>
                          Apply Now
                        </Typography>
                      </div>
                    </Button>
                  </FormControl>
                </Grid>
              </Grid>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default CareersInner;
