import React, { useState } from "react";
import "./Industries.css";
import {
  Button,
  Card,
  CardContent,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Oil from "../../utils/images/Oil.png";
import ArrowService from "../../utils/icons/ArrowService.svg";
import {
  contentFontSize_18,
  HeaderFontSize_25,
  HeaderFontSize_36_xs_25,
} from "../../Typography";
import IndustriesDialog from "./IndustriesDialog";
import Automotive from "../../utils/images/Automotive.png";
import Banking from "../../utils/images/Banking.png";
import Ecommerce from "../../utils/images/ECommerce.png";
import Education from "../../utils/images/Education.png";
import Egovernance from "../../utils/images/Egovernance.png";
import Energy from "../../utils/images/Energy.png";
import Healthcare from "../../utils/images/Healthcare.png";
import Lending from "../../utils/images/Lending.png";
import Pharmaceuticals from "../../utils/images/Pharmaceuticals.png";
import Telecommunications from "../../utils/images/Telecommunication.png";
import Aviation from "../../utils/images/Aviation.png";

function IndustriesCard() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [dialogOpen, setDialogOpen] = useState(false);
  const [industriesSection, setIndustriesSection] = useState(true);
  const [selectedIndustry, setSelectedIndustry] = useState(null);

  const industriesData = [
    {
      img: Oil,
      title: "Oil & Gas",
      description:
        "Optimize exploration and production, enhance safety, and minimize environmental impact. Leverage advanced technologies like AI, IoT, and cloud computing to improve operational efficiency, reduce costs, and ensure a sustainable energy future. We help energy companies navigate the complexities of the energy transition and unlock new opportunities in renewable energy.",
      bulletPoints: [
        {
          highlighted: "Digital Oilfield:",
          content:
            "Optimize production and maintenance processes, enhance performance, and reduce operational costs.",
        },
        {
          highlighted: "Supply Chain Optimization:",
          content:
            "Streamline logistics, improve inventory management, and reduce supply chain disruptions.",
        },
        {
          highlighted: "Data Analytics:",
          content:
            "Gain valuable insights from operational data to make informed decisions, optimize asset performance, and predict maintenance needs.",
        },
        {
          highlighted: "Sustainability Solutions:",
          content:
            "Develop sustainable practices, reduce carbon footprint, and comply with environmental regulations.",
        },
      ],
    },
    {
      img: Ecommerce,
      title: "E-commerce & Retail",
      description:
        "Drive sales growth, build lasting brand loyalty, and optimize operations. Empower retailers with data-driven insights, personalized marketing campaigns, and robust e-commerce platforms. We help businesses thrive in the digital age by creating seamless omnichannel experiences, enhancing customer engagement, and optimizing the entire customer journey.",
      bulletPoints: [
        {
          highlighted: "Personalized Shopping Experiences:",
          content:
            "Use AI-powered recommendations and customer analytics to deliver tailored experiences.",
        },
        {
          highlighted: "Supply Chain Management:",
          content:
            "Optimize inventory management, improve order fulfillment, and enhance supply chain visibility.",
        },
        {
          highlighted: "Omnichannel Commerce:",
          content:
            "Create seamless shopping experiences across various channels, including online, mobile, and in-store.",
        },
        {
          highlighted: "Fraud Prevention:",
          content:
            "Implement robust fraud detection and prevention measures to protect customer data and revenue.",
        },
      ],
    },
    {
      img: Energy,
      title: "Energy & Utilities",
      description:
        "Modernize grids, accelerate renewable energy adoption, and enhance grid reliability. Leverage advanced analytics, AI, and IoT to optimize grid operations, improve energy efficiency, and ensure a reliable and sustainable energy supply. We help utilities navigate the challenges of a changing energy landscape and build a more sustainable future for all.",
      bulletPoints: [
        {
          highlighted: "Smart Grid Solutions:",
          content:
            "Improve grid reliability, enhance energy efficiency, and enable the integration of renewable energy sources.",
        },
        {
          highlighted: "Customer Relationship Management:",
          content:
            "Provide exceptional customer service, streamline billing processes, and offer personalized energy solutions.",
        },
        {
          highlighted: "Asset Performance Management:",
          content:
            "Optimize asset lifecycle, reduce maintenance costs, and improve overall operational efficiency.",
        },
        {
          highlighted: "Cybersecurity:",
          content:
            "Protect critical infrastructure from cyber threats and ensure data privacy.",
        },
      ],
    },
    {
      img: Pharmaceuticals,
      title: "Pharmaceuticals & Biotechnology",
      description:
        "Accelerate drug discovery & development, improve patient outcomes, and bring life-saving therapies to market faster. Leverage AI, machine learning, and advanced analytics to accelerate drug discovery, personalize medicine, and improve clinical trial outcomes. We help the life sciences industry address critical healthcare challenges and improve human health. ",
      bulletPoints: [
        {
          highlighted: "Clinical Trial Management:",
          content:
            "Streamline clinical trial processes, accelerate drug development, and improve patient outcomes.",
        },
        {
          highlighted: "Supply Chain Management:",
          content:
            "Ensure the safety and quality of pharmaceutical products throughout the supply chain.",
        },
        {
          highlighted: "Regulatory Compliance:",
          content:
            "Adhere to strict regulatory standards and minimize compliance risks.",
        },
        {
          highlighted: "Data Analytics:",
          content:
            "Leverage data to accelerate drug discovery, optimize clinical trials, and improve patient care.",
        },
      ],
    },
    {
      img: Egovernance,
      title: "E-Governance & Public Sector",
      description:
        "Enhance service delivery, increase transparency, and optimize resource allocation. Leverage technology to improve citizen services, enhance government efficiency, and improve public safety. We help government agencies better serve their citizens, improve operational efficiency, and build a more responsive and effective government.  ",
      bulletPoints: [
        {
          highlighted: "Citizen Services:",
          content:
            "Improve citizen engagement, streamline government services, and enhance transparency.",
        },
        {
          highlighted: "Data Analytics:",
          content:
            "Use data to make informed decisions, optimize resource allocation, and improve service delivery.",
        },
        {
          highlighted: "Cybersecurity:",
          content:
            "Protect sensitive government data and infrastructure from cyber threats.",
        },
        {
          highlighted: "Digital Transformation:",
          content:
            "Modernize government operations, improve efficiency, and reduce costs.",
        },
      ],
    },
    {
      img: Healthcare,
      title: "Healthcare & Life Sciences",
      description:
        "Empower healthcare providers with innovative solutions for better patient care, improved efficiency, and enhanced medical research. Leverage technology to improve patient outcomes, reduce healthcare costs, and enhance the overall quality of care. We help healthcare organizations deliver high-quality, cost-effective care and improve the overall health and well-being of individuals and communities. ",
      bulletPoints: [
        {
          highlighted: "Electronic Health Records (EHR):",
          content:
            "Digitize patient records, improve interoperability, and enhance patient care.",
        },
        {
          highlighted: "Telehealth Solutions:",
          content:
            "Expand access to healthcare services, especially in remote areas.",
        },
        {
          highlighted: "Medical Device Development:",
          content:
            "Accelerate product development, ensure regulatory compliance, and improve patient outcomes.",
        },
        {
          highlighted: "Precision Medicine:",
          content:
            "Leverage genomics and data analytics to personalize treatment plans and improve patient outcomes.",
        },
      ],
    },
    {
      img: Automotive,
      title: "Automotive & Manufacturing",
      description:
        "Optimize production, improve quality, and enhance supply chain resilience. Leverage automation, AI, and IoT to improve operational efficiency, enhance product quality, and drive innovation. We help manufacturers achieve operational excellence, increase competitiveness, and build a more sustainable and resilient manufacturing sector. ",
      bulletPoints: [
        {
          highlighted: "Connected Vehicles:",
          content:
            "Develop innovative connected car solutions, enhance safety, and improve driving experiences.",
        },
        {
          highlighted: "Manufacturing Automation:",
          content:
            "Optimize production processes, improve efficiency, and reduce costs.",
        },
        {
          highlighted: "Supply Chain Management:",
          content:
            "Enhance supply chain visibility, reduce lead times, and improve inventory management.",
        },
        {
          highlighted: "Industry 4.0:",
          content:
            "Embrace Industry 4.0 technologies to create smart factories and drive innovation.",
        },
      ],
    },
    {
      img: Aviation,
      title: "Aviation & Aerospace",
      description:
        "Enhance safety, efficiency, and sustainability across the industries. Leverage advanced technologies to improve flight safety, optimize aircraft maintenance, and enhance passenger experience. We help the aviation and aerospace industry navigate the challenges of the future and ensure safe, efficient, and sustainable air travel.",
      bulletPoints: [
        {
          highlighted: "Aircraft Maintenance:",
          content:
            "Streamline maintenance operations, reduce downtime, and improve aircraft reliability.",
        },
        {
          highlighted: "Air Traffic Management:",
          content:
            "Improve air traffic control efficiency, safety, and capacity.",
        },
        {
          highlighted: "Aerospace Engineering:",
          content:
            "Design and develop advanced aerospace systems, including drones and autonomous vehicles.",
        },
        {
          highlighted: "Data Analytics:",
          content:
            "Use data to optimize flight operations, improve fuel efficiency, and enhance passenger experiences.",
        },
      ],
    },
    {
      img: Telecommunications,
      title: "Telecommunications & Media",
      description:
        "Deliver exceptional customer experiences, optimize networks, and empower businesses in the digital age. Leverage data analytics and AI to personalize customer experiences, optimize network performance, and deliver innovative content. We help telecommunications and media companies navigate the digital transformation and deliver exceptional value to their customers. ",
      bulletPoints: [
        {
          highlighted: "5G Network Deployment:",
          content:
            "Deploy next-generation networks for high-speed connectivity and low latency.",
        },
        {
          highlighted: "Digital Media Solutions:",
          content:
            "Enhance content delivery, improve customer experiences, and monetize digital content.",
        },
        {
          highlighted: "Cybersecurity:",
          content:
            "Protect critical telecommunications infrastructure from cyber threats.",
        },
        {
          highlighted: "Internet of Things (IoT):",
          content:
            "Leverage IoT to create innovative solutions, such as smart cities and connected devices.",
        },
      ],
    },
    {
      img: Banking,
      title: "Banking, Financial Services, & Insurance (BFSI)",
      description:
        "Enhance customer experience, mitigate risk, and drive financial inclusion. Leverage data analytics, AI, and cybersecurity to enhance fraud prevention, improve risk management, and deliver personalized financial advice. We help financial institutions build stronger customer relationships, mitigate risk, and drive innovation in the financial services industry",
      bulletPoints: [
        {
          highlighted: "Digital Banking:",
          content:
            "Offer innovative digital banking services, such as mobile banking and online lending.",
        },
        {
          highlighted: "Risk Management:",
          content:
            "Mitigate risks, improve decision-making, and comply with regulatory requirements.",
        },
        {
          highlighted: "Fraud Detection:",
          content:
            "Implement advanced fraud detection and prevention measures to protect customer assets.",
        },
        {
          highlighted: "Data Analytics:",
          content:
            "Use data to improve customer insights, optimize marketing campaigns, and enhance risk assessment.",
        },
      ],
    },
    {
      img: Lending,
      title: "Lending & FinTech",
      description:
        "Simplify lending processes, improve access to credit, and drive financial empowerment. Leverage AI and machine learning to improve credit risk assessment, automate lending processes, and expand access to financial services. We help lending and fintech companies make informed decisions, reduce risk, and empower individuals and businesses. ",
      bulletPoints: [
        {
          highlighted: "Digital Lending:",
          content:
            "Streamline lending processes, reduce costs, and improve customer experiences.",
        },
        {
          highlighted: "Fintech Innovations:",
          content:
            "Embrace emerging technologies like AI, machine learning, and blockchain to disrupt the financial industry.",
        },
        {
          highlighted: "Regulatory Compliance:",
          content:
            "Adhere to strict regulatory standards and mitigate compliance risks.",
        },
        {
          highlighted: "Customer Experience:",
          content:
            "Enhance customer experiences through personalized services and innovative solutions.",
        },
      ],
    },
    {
      img: Education,
      title: "Education & Learning",
      description:
        "Personalize learning experiences, enhance teaching effectiveness, and improve educational outcomes. Leverage technology to personalize learning, enhance teaching effectiveness, and improve access to education. We help educational institutions prepare students for success in the 21st century and create a more equitable and inclusive education system. ",
      bulletPoints: [
        {
          highlighted: "E-learning Platforms:",
          content:
            "Deliver high-quality online learning experiences and personalize learning paths.",
        },
        {
          highlighted: "Student Information Systems (SIS):",
          content:
            "Manage student data, improve administrative efficiency, and enhance student engagement.",
        },
        {
          highlighted: "Learning Analytics:",
          content:
            "Use data to track student progress, identify areas for improvement, and personalize learning experiences.",
        },
        {
          highlighted: "EdTech Solutions:",
          content:
            "Develop innovative educational technologies, such as virtual reality and augmented reality.",
        },
      ],
    },
  ];

  const handleExploreClick = (industry) => {
    setSelectedIndustry(industry);
    setDialogOpen(true);
  };

  return (
    <>
      {industriesData?.map((industry, index) => (
        <div className="main-industries">
          <div className="main-industries-card">
            <Card
              className="card-industries"
              sx={{
                padding: "0px",
                paddingBottom: "0px",
                width: "100%",
                boxShadow: "0px 2px 8px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <CardContent
                className="card-main"
                sx={{
                  "&:last-child": { paddingBottom: 0 },
                  padding: "0px",
                }}
              >
                <div style={{ boxShadow: "none" }} className="imge-container">
                  <img
                    className="card-image"
                    src={industry.img}
                    alt="Oil Industry"
                  />
                </div>

                <div className="card-content-container">
                  <Typography
                    className="heading-oil"
                    fontSize={HeaderFontSize_36_xs_25}
                    sx={{ fontWeight: "700" }}
                  >
                    {industry?.title}
                  </Typography>
                  <Typography
                    className="content-sub"
                    fontSize={contentFontSize_18}
                  >
                    {industry?.description}
                  </Typography>
                  <div className="button-container">
                    <Button
                      variant="outlined"
                      sx={{
                        borderColor: "orange",
                        borderRadius: "15px",
                        width: "200px",
                        height: "40px",
                      }}
                      onClick={() => handleExploreClick(industry)}
                    >
                      <Typography
                        sx={{ color: "black", textTransform: "capitalize" }}
                      >
                        Explore More
                      </Typography>
                      <span>
                        <img
                          src={ArrowService}
                          alt="Arrow Icon"
                          style={{ marginLeft: "5px", marginTop: "5px" }}
                        />
                      </span>
                    </Button>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      ))}
      {dialogOpen && (
        <IndustriesDialog
          dialogOpen={dialogOpen}
          setDialogOpen={setDialogOpen}
          title={selectedIndustry.title}
          bulletPoints={selectedIndustry.bulletPoints}
        />
      )}
    </>
  );
}

export default IndustriesCard;
