import { Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import "./InnerPage.css";
import React, { useEffect } from "react";
import BackArrow from "../utils/icons/BackArrow.svg";
import BlogMain1 from "../utils/images/BlogMain1.png";
import Gradient from "../utils/images/Gradient.jpg";
import Name from "../utils/icons/Name.svg";
import calender from "../utils/icons/calender.svg";
import clock from "../utils/icons/clock.svg";
import Common from "../utils/images/Common.jpg";
import {
  contentFontSize_16,
  contentFontSize_18,
  contentFontSize_20,
  contentFontsize_28,
  contentFontSize_48,
  contentFontSize_50,
} from "../Typography";
import Navbar from "../Navbar";
import BusinessBlog2 from "../utils/images/BusinessBlog2.png";
import { useNavigate } from "react-router-dom";
function BusinessBlog() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));
  const nav = useNavigate();
  const handleBackClick = () => {
    nav("/blogs");
  };
  const handleContactClick = () => {
    nav("./contact");
  };
  return (
    <div>
      <>
        <Navbar />
      </>
      <div className="back-btn" onClick={handleBackClick}>
        <img src={BackArrow} style={{marginTop:mdmatch && "6px"}}  />
        <span
          style={{
            marginLeft: "10px",
            fontSize: contentFontSize_18,
            // fontFamily: "Roboto",
            marginBottom: "3px",
          }}
        >
          Back to Blogs
        </span>
      </div>
      <div
        className="responsive-container"
        style={{
          backgroundImage: xsmatch
            ? `url(${Gradient})`
            : `url(${BusinessBlog2})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "500px",
          position: "relative",
          color: "white",
          display: "flex",
          width: "100%",
          //justifyContent: "center",
          alignItems: "flex-end",
          marginTop: "30px",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="blog-text">
          <Typography
            fontSize={contentFontSize_48}
            fontWeight="700"
            marginLeft="0px"
          >
            Top 5 Business Processes Every Company Should Automate
          </Typography>
        </div>
      </div>
      {/* <div className="blog-section-one">
        <div className="blog-section-sub">
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={Name} alt="Profile" style={{ marginRight: "5px" }} />
            <span
              style={{
                // fontFamily: "Roboto",
                fontWeight: "500",
                fontSize: "14px",
              }}
            >
              Shyam Pushkar
            </span>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={calender} alt="calender" style={{ marginRight: "0px" }} />
            <span
              style={{
                marginLeft: "10px",
                // fontFamily: "Roboto",
                fontWeight: "500",
                fontSize: "14px",
              }}
            >
              January 21 2024
            </span>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={clock} alt="clock" style={{ marginRight: "0px" }} />
            <span
              style={{
                marginLeft: "10px",
                // fontFamily: "Roboto",
                fontWeight: "500",
                fontSize: "14px",
                textAlign: "center",
              }}
            >
              8:25
            </span>
          </div>
        </div>
      </div> */}
      <div className="main">
        <div>
          <Typography
            fontSize={contentFontsize_28}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Introduction
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            lineHeight="31px"
            sx={{
              textAlign: "justify",
              color: "#6C7282",
              marginTop: "10px",
            }}
          >
            In an increasingly competitive marketplace, businesses must find
            ways to enhance productivity, reduce costs, and improve accuracy.
            Business Process Automation (BPA) achieves these goals by automating
            repetitive and time-consuming tasks, allowing organizations to focus
            on high-value activities. But where should you start? This blog
            explores the top five business processes that every company should
            automate to drive efficiency and growth.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontsize_28}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            What is Business Process Automation (BPA)?
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            lineHeight="31px"
            sx={{
              textAlign: "justify",
              color: "#6C7282",
              marginTop: "10px",
              //letterSpacing: "1px",
            }}
          >
            BPA refers to the use of technology to streamline and automate
            repetitive tasks and workflows, reducing the need for manual
            intervention. It minimizes errors, accelerates processes, and
            ensures consistency across operations.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontsize_28}
            // fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px", letterSpacing: "1px" }}
          >
            Benefits of Automating Business Processes
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            // fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Increased Efficiency:
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            // fontFamily="Roboto"
            fontWeight="400"
            sx={{ textAlign: "justify", color: "#6C7282", marginTop: "10px" }}
          >
            Automation reduces the time spent on routine tasks, enabling
            employees to focus on strategic initiatives.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            // fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Cost Savings:
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            // fontFamily="Roboto"
            fontWeight="400"
            sx={{ textAlign: "justify", color: "#6C7282", marginTop: "10px" }}
          >
            Automating manual processes eliminates inefficiencies and reduces
            labor costs.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            // fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Error Reduction:
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            // fontFamily="Roboto"
            fontWeight="400"
            sx={{ textAlign: "justify", color: "#6C7282", marginTop: "10px" }}
          >
            Automation ensures consistency, minimizing the risk of human errors
            in critical processes.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            // fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Scalability:
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            // fontFamily="Roboto"
            fontWeight="400"
            sx={{ textAlign: "justify", color: "#6C7282", marginTop: "10px" }}
          >
            Automated processes can handle growing business demands without
            additional resources.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontsize_28}
            // fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Top 5 Processes to Automate
          </Typography>
        </div>
        <Typography
          fontFamily="Roboto"
          fontWeight="700"
          fontSize={contentFontSize_20}
          sx={{ margin: "20px 0 10px" }}
        >
          Invoice Processing
        </Typography>
        <Typography fontSize={contentFontSize_18}>
          <span className="digital">Challenges:</span>

          <Typography
            fontSize={contentFontSize_18}
            color="#6C7282"
            fontWeight="400"
            sx={{ margin: "0 0 10px" }}
          >
            {" "}
            Manual invoice processing is time-consuming and prone to errors.
          </Typography>
        </Typography>
        <Typography fontSize={contentFontSize_18}>
          <span className="digital">Solution:</span>

          <Typography
            fontSize={contentFontSize_18}
            color="#6C7282"
            fontWeight="400"
            sx={{ margin: "0 0 10px" }}
          >
            {" "}
            Automate the entire invoicing lifecycle, from receiving invoices to
            processing payments, ensuring faster approvals and improved cash
            flow.
          </Typography>
        </Typography>
        <Typography
          fontFamily="Roboto"
          fontWeight="700"
          fontSize={contentFontSize_20}
          sx={{ margin: "0 0 10px" }}
        >
          Employee Onboarding
        </Typography>
        <Typography fontSize={contentFontSize_18}>
          <span className="digital">Challenges:</span>
          <br />
          <Typography
            fontSize={contentFontSize_18}
            color="#6C7282"
            fontWeight="400"
            sx={{ margin: "0 0 10px" }}
          >
            Onboarding new employees involves multiple steps, including
            documentation, training, and system access provisioning.
          </Typography>
        </Typography>
        <Typography fontSize={contentFontSize_18}>
          <span className="digital">Solution:</span>
          <br />
          <Typography
            fontSize={contentFontSize_18}
            color="#6C7282"
            fontWeight="400"
            sx={{ margin: "0 0 10px" }}
          >
            Use BPA tools to streamline onboarding workflows, ensuring new hires
            are set up quickly and consistently.
          </Typography>
        </Typography>
        <Typography
          fontFamily="Roboto"
          fontWeight="700"
          fontSize={contentFontSize_20}
          sx={{ margin: "0 0 10px" }}
        >
          Customer Support and Help Desk
        </Typography>
        <Typography fontSize={contentFontSize_18}>
          <span className="digital">Challenges:</span>
          <br />
          <Typography
            fontSize={contentFontSize_18}
            color="#6C7282"
            fontWeight="400"
            sx={{ margin: "0 0 10px" }}
          >
            Responding to repetitive customer inquiries can overwhelm support
            teams.
          </Typography>
        </Typography>
        <Typography fontSize={contentFontSize_18}>
          <span className="digital">Solution:</span>
          <br />
          <Typography
            fontSize={contentFontSize_18}
            color="#6C7282"
            fontWeight="400"
            sx={{ margin: "0 0 10px" }}
          >
            Implement AI-powered chatbots and automated ticketing systems to
            handle FAQs and route complex queries to the appropriate teams.
          </Typography>
        </Typography>
        <Typography
          fontFamily="Roboto"
          fontWeight="700"
          fontSize={contentFontSize_20}
          sx={{ margin: "0 0 10px" }}
        >
          Marketing Campaign Management
        </Typography>
        <Typography fontSize={contentFontSize_18}>
          <span className="digital">Challenges:</span>
          <br />
          <Typography
            fontSize={contentFontSize_18}
            color="#6C7282"
            fontWeight="400"
            sx={{ margin: "0 0 10px" }}
          >
            Coordinating campaigns across multiple platforms can be
            labor-intensive.
          </Typography>
        </Typography>
        <Typography fontSize={contentFontSize_18}>
          <span className="digital">Solution:</span>
          <br />
          <Typography
            fontSize={contentFontSize_18}
            color="#6C7282"
            fontWeight="400"
            sx={{ margin: "0 0 10px" }}
          >
            Automate email marketing, social media posts, and lead nurturing to
            ensure timely and consistent communication with your audience.
          </Typography>
        </Typography>
        <Typography
          fontFamily="Roboto"
          fontWeight="700"
          fontSize={contentFontSize_20}
          sx={{ margin: "0 0 10px" }}
        >
          Inventory Management
        </Typography>
        <Typography fontSize={contentFontSize_18}>
          <span className="digital">Challenges:</span>
          <br />
          <Typography
            fontSize={contentFontSize_18}
            color="#6C7282"
            fontWeight="400"
            sx={{ margin: "0 0 10px" }}
          >
            Manual inventory tracking can lead to stock discrepancies and missed
            sales opportunities.
          </Typography>
        </Typography>
        <Typography fontSize={contentFontSize_18}>
          <span className="digital">Solution:</span>
          <br />
          <Typography
            fontSize={contentFontSize_18}
            color="#6C7282"
            fontWeight="400"
            sx={{ margin: "0 0 10px" }}
          >
            Use automation to monitor inventory levels, generate restocking
            alerts, and sync with e-commerce platforms for real-time updates.
          </Typography>
        </Typography>
        <div>
          <Typography
            fontSize={contentFontsize_28}
            // fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            How to Get Started with Business Process Automation
          </Typography>
        </div>

        <div style={{ lineHeight: "1.8", marginTop: "20px" }}>
          <div style={{ marginBottom: "20px", marginTop: "20px" }}>
            <Typography
              fontFamily="Roboto"
              fontWeight="700"
              fontSize={contentFontSize_20}
              //   sx={{ margin: "0 0 10px" }}
            >
              1. Identify Repetitive Tasks
            </Typography>
            {/* <ul style={{ margin: "0", paddingLeft: "20px" }}>
              <li style={{ color: "#6C7282", textAlign: "justify" }}> */}
            <Typography
              fontSize={contentFontSize_18}
              color="#6C7282"
              fontWeight="400"
              sx={{ margin: "0 0 10px" }}
            >
              Begin by listing tasks that are time-consuming, repetitive, and
              prone to errors.
            </Typography>
            {/* </li>
              <li style={{ color: "#6C7282", textAlign: "justify" }}> */}

            {/* </li>
            </ul> */}
          </div>

          <div style={{ marginBottom: "20px" }}>
            <Typography
              fontFamily="Roboto"
              fontWeight="700"
              fontSize={contentFontSize_20}
              //   sx={{ margin: "0 0 10px" }}
            >
              2. Select the Right Tools
            </Typography>
            {/* <ul style={{ margin: "0", paddingLeft: "20px" }}>
              <li style={{ color: "#6C7282", textAlign: "justify" }}> */}
            <Typography
              fontSize={contentFontSize_18}
              color="#6C7282"
              fontWeight="400"
              sx={{ margin: "0 0 10px" }}
            >
              Choose BPA tools that integrate seamlessly with your existing
              systems. Popular options include Robotic Process Automation (RPA)
              tools, workflow management platforms, and AI-based solutions.
            </Typography>
            {/* </li>
              <li style={{ color: "#6C7282", textAlign: "justify" }}>
                <Typography
                  fontWeight="400"
                  fontFamily="Roboto"
                  fontSize={contentFontSize_16}
                >
                  Align objectives with overall business strategy to ensure
                  measurable impact.
                </Typography>
              </li>
            </ul> */}
          </div>

          <div>
            <Typography
              fontFamily="Roboto"
              fontWeight="700"
              fontSize={contentFontSize_20}
              //   sx={{ margin: "0 0 10px" }}
            >
              3. Start Small
            </Typography>

            <Typography
              fontSize={contentFontSize_18}
              color="#6C7282"
              fontWeight="400"
              sx={{ margin: "0 0 10px" }}
            >
              Automate one or two processes first, measure the results, and
              gradually expand automation to other areas.
            </Typography>
          </div>
          <div>
            <Typography
              fontFamily="Roboto"
              fontWeight="700"
              fontSize={contentFontSize_20}
              //   sx={{ margin: "0 0 10px" }}
            >
              4. Monitor and Optimize:
            </Typography>

            <Typography
              fontSize={contentFontSize_18}
              color="#6C7282"
              fontWeight="400"
              sx={{ margin: "0 0 10px" }}
            >
              Continuously evaluate the performance of automated processes and
              make necessary adjustments to improve efficiency.
            </Typography>
          </div>
        </div>
        <div>
          <Typography
            fontSize={contentFontsize_28}
            // fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Common Mistakes to Avoid in BPA Implementation
          </Typography>
        </div>
        <div style={{ marginTop: "20px" }}>
          <Typography
            fontFamily="Roboto"
            fontWeight="700"
            fontSize={contentFontSize_20}
            sx={{ margin: "0 0 10px" }}
          >
            Automating Broken Processes
          </Typography>

          <Typography
            fontSize={contentFontSize_18}
            color="#6C7282"
            fontWeight="400"
            sx={{ margin: "0 0 10px" }}
          >
            Fix inefficiencies in the workflow before automating to avoid
            scaling flawed processes.
          </Typography>

          <Typography
            fontFamily="Roboto"
            fontWeight="700"
            fontSize={contentFontSize_20}
            sx={{ margin: "0 0 10px" }}
          >
            Overlooking Employee Training
          </Typography>

          <Typography
            fontSize={contentFontSize_18}
            color="#6C7282"
            fontWeight="400"
            sx={{ margin: "0 0 10px" }}
          >
            Ensure your team understands how to use automation tools
            effectively.
          </Typography>

          <Typography
            fontFamily="Roboto"
            fontWeight="700"
            fontSize={contentFontSize_20}
            sx={{ margin: "0 0 10px" }}
          >
            Ignoring Data Security
          </Typography>

          <Typography
            fontSize={contentFontSize_18}
            color="#6C7282"
            fontWeight="400"
            sx={{ margin: "0 0 10px" }}
          >
            Choose tools that adhere to industry security standards and protect
            sensitive business information.
          </Typography>
        </div>
        <div>
          <Typography
            fontFamily="Roboto"
            fontWeight="700"
            fontSize={contentFontsize_28}
          >
            Conclusion
          </Typography>
        </div>
        <Typography
          fontSize={contentFontSize_18}
          color="#6C7282"
          fontWeight="400"
          textAlign="justify"
        >
          Automating key business processes is essential for companies aiming to
          stay competitive and agile in today’s fast-paced market. From invoice
          processing to customer support, automation drives efficiency, reduces
          costs, and enhances overall productivity. At Axia Kairos, we
          specialize in delivering customized business process automation
          solutions tailored to your specific needs. Let us help you identify
          opportunities for automation and implement tools that unlock your
          business’s full potential.
        </Typography>
      </div>
      <div
        className="bg-img"
        style={{
          backgroundImage: `url(${Common})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          // height: "500px",
          position: "relative",
          color: "white",
          padding: "20px", // Add spacing for responsiveness
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="blog-text1">
          {matches ? (
            <Typography
              fontSize={contentFontSize_50}
              fontWeight="700"
              sx={{
                marginBottom: "10px",
                color: "#FFD700",
                marginTop: smmatch ? "0px" : matches ? "50px" : "160px",
                padding: matches ? "10px 0px 10px 40px" : "0px",
                textAlign: matches ? "center" : null,
              }}
            >
              Begin Your Digital Transformation Journey with Axia Kairos
            </Typography>
          ) : (
            <Typography
              fontSize={contentFontSize_50}
              fontWeight="700"
              sx={{
                marginBottom: "10px",
                color: "#FFD700",
                marginTop: smmatch ? "0px" : matches ? "50px" : "160px",
                padding: matches ? "10px 0px 10px 40px" : "0px",
                textAlign: matches ? "center" : null,
              }}
            >
              Begin Your Digital Transformation Journey <br /> with Axia Kairos
            </Typography>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: matches && "center",
            padding: smmatch ? "0px" : "10px 40px",
            flexDirection: lgmatch ? "column" : "row",
          }}
        >
          <div style={{ width: "70%" }}>
            <Typography
              //fontFamily="Roboto"
              fontWeight="400"
              fontSize={contentFontSize_20}
              style={{
                textAlign: matches ? "center" : "left",
                lineHeight: "1.6",
                marginBottom: "20px",
                color: "#FFFFFF", // White paragraph text
              }}
            >
              Ready to redefine your business with digital transformation? Axia
              Kairos delivers innovative strategies and cutting-edge
              technologies to help your organization adapt and thrive.
            </Typography>
          </div>
          <div style={{ marginTop: "10px" }}>
            <Button
              variant="contained"
              sx={{
                backgroundImage:
                  "linear-gradient(90deg, #DFAD17 0%, #795E0C 100%)",
                width: "300px",
                height: "40px",
                borderRadius: "15px",
                // padding: "10px 20px",
                // fontSize: "16px",
                // backgroundColor: "#FFC107",
                // color: "white",
                // border: "none",
                // borderRadius: "5px",
                // cursor: "pointer",
                // fontWeight: "500",
              }}
              onClick={handleContactClick}
            >
              <Typography
                //fontFamily="Roboto"
                sx={{ textTransform: "capitalize", padding: "15px" }}
              >
                Start Your Transformation Today
              </Typography>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BusinessBlog;
