import { Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import "./InnerPage.css";
import React, { useEffect } from "react";
import BackArrow from "../utils/icons/BackArrow.svg";
import BlogMain1 from "../utils/images/BlogMain1.png";
import Gradient from "../utils/images/Gradient.jpg";
import Name from "../utils/icons/Name.svg";
import calender from "../utils/icons/calender.svg";
import clock from "../utils/icons/clock.svg";
import Common from "../utils/images/Common.jpg";
import {
  contentFontSize_16,
  contentFontSize_18,
  contentFontSize_20,
  contentFontsize_28,
  contentFontSize_48,
  contentFontSize_50,
} from "../Typography";
import Navbar from "../Navbar";
import ApplicationBlogS from "../utils/images/ApplicationBlogS.png"
import { useNavigate } from "react-router-dom";
function ApplicationBlog() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));
  const nav = useNavigate();
  const handleBackClick = () => {
    nav("/blogs");
  };
  const handleContactClick = () => {
    nav("./contact");
  };
  return (
    <div>
      <>
        <Navbar />
      </>
      <div className="back-btn" onClick={handleBackClick}>
        <img src={BackArrow}  style={{marginTop:mdmatch && "5px"}} />
        <span
          style={{
            marginLeft: "10px",
            fontSize: contentFontSize_18,
            fontFamily: "Roboto",
            marginBottom: "3px",
          }}
        >
          Back to Blogs
        </span>
      </div>
      <div
        className="responsive-container"
        style={{
          backgroundImage: xsmatch ? `url(${Gradient})` : `url(${ApplicationBlogS})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "500px", 
          position: "relative",
          color: "white",
          display: "flex",
          width: "100%",
          //justifyContent: "center",
          alignItems: "flex-end",
          marginTop: "30px",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="blog-text">
          <Typography
            fontSize={contentFontSize_48}
            fontWeight="700"
            marginLeft="0px"
          >
            Why Application Managed Services Are Essential for Your Business
            Success
          </Typography>
        </div>
      </div>
      {/* <div className="blog-section-one">
        <div className="blog-section-sub">
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={Name} alt="Profile" style={{ marginRight: "5px" }} />
            <span
              style={{
                fontFamily: "Roboto",
                fontWeight: "500",
                fontSize: "14px",
              }}
            >
              Shyam Pushkar
            </span>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={calender} alt="calender" style={{ marginRight: "0px" }} />
            <span
              style={{
                marginLeft: "10px",
                fontFamily: "Roboto",
                fontWeight: "500",
                fontSize: "14px",
              }}
            >
              January 21 2024
            </span>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={clock} alt="clock" style={{ marginRight: "0px" }} />
            <span
              style={{
                marginLeft: "10px",
                fontFamily: "Roboto",
                fontWeight: "500",
                fontSize: "14px",
                textAlign: "center",
              }}
            >
              8:25
            </span>
          </div>
        </div>
      </div> */}
      <div className="main">
        <div>
          <Typography
            fontSize={contentFontsize_28}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Introduction
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            lineHeight="31px"
            sx={{
              textAlign: "justify",
              color: "#6C7282",
              marginTop: "10px",
              // letterSpacing: "1px",
            }}
          >
            In today’s fast-paced digital world, businesses rely heavily on
            applications to drive efficiency, enhance customer experiences, and
            support growth. However, maintaining these applications, ensuring
            they run smoothly, and keeping them secure can be a challenge for
            in-house IT teams. Application Managed Services (AMS) offer a
            solution by providing ongoing monitoring, support, and optimization
            to ensure your applications perform at their best. In this blog,
            we’ll explore the importance of AMS and how it can contribute to
            your business’s long-term success.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontsize_28}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            What Are Application Managed Services?
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            lineHeight="31px"
            sx={{
              textAlign: "justify",
              color: "#6C7282",
              marginTop: "10px",
              // letterSpacing: "1px",
            }}
          >
            Application Managed Services refer to the ongoing management,
            monitoring, and support of business-critical applications by an
            external service provider. These services can include everything
            from application performance monitoring and troubleshooting to
            software upgrades, security management, and integration support. By
            outsourcing AMS, businesses can focus on their core operations while
            ensuring their applications remain secure, efficient, and reliable.
          </Typography>
        </div>
        {/* <div>
          <Typography
            fontSize={contentFontsize_28}
             fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px",  }}
          >
            What is Digital Transformation?
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
             fontFamily="Roboto"
            fontWeight="400"
            lineHeight="31px"
            sx={{
              textAlign: "justify",
              color: "#6C7282",
              marginTop: "10px",
              //letterSpacing: "1px",
            }}
          >
            Digital transformation involves integrating digital technologies
            into all areas of a business, fundamentally changing how it operates
            and delivers value to customers. It encompasses a shift in culture,
            processes, and strategies to leverage the power of technology for
            innovation and growth.
          </Typography>
        </div> */}
        {/* <div>
          <Typography
            fontSize={contentFontsize_28}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            How AI, Blockchain, and IoT Work Together
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Artificial Intelligence (AI):
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            sx={{ textAlign: "justify", color: "#6C7282", marginTop: "10px" }}
          >
            AI enables machines to mimic human intelligence, providing
            capabilities like predictive analytics, natural language processing,
            and automation.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Blockchain:
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            sx={{ textAlign: "justify", color: "#6C7282", marginTop: "10px" }}
          >
            Blockchain offers a decentralized and tamper-proof ledger, ensuring
            data integrity, transparency, and security across transactions.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Internet of Things (IoT):
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            sx={{ textAlign: "justify", color: "#6C7282", marginTop: "10px" }}
          >
            IoT connects devices and systems, enabling real-time data collection
            and communication between physical and digital assets.
          </Typography>
        </div> */}
        {/* <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Improved Customer Experience:
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
             fontFamily="Roboto"
            fontWeight="400"
            sx={{ textAlign: "justify", color: "#6C7282", marginTop: "10px" }}
          >
            By utilizing data-driven insights, businesses can deliver
            personalized and seamless experiences across multiple touchpoints.
          </Typography>
        </div> */}
        {/* <div>
          <Typography
            fontSize={contentFontsize_28}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Key Industry Transformations
          </Typography>
        </div> */}

       
        <div>
          <Typography
            fontSize={contentFontsize_28}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Why Your Business Needs Application Managed Services
          </Typography>
        </div>
        <Typography
          fontFamily="Roboto"
          fontWeight="700"
          fontSize={contentFontSize_20}
          sx={{ margin: "20px 0 10px" }}
        >
          Proactive Monitoring and Support
        </Typography>
        <ul>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Benefit:</span>

              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                {" "}
                Continuous monitoring ensures potential issues are identified
                and resolved before they impact business operations.
              </Typography>
            </Typography>
          </li>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Example:</span>

              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                {" "}
                With AMS, your applications are constantly being monitored for
                performance, security vulnerabilities, and downtime. This
                proactive approach allows businesses to address issues swiftly,
                minimizing disruptions and downtime.
              </Typography>
            </Typography>
          </li>
        </ul>

        <Typography
          fontFamily="Roboto"
          fontWeight="700"
          fontSize={contentFontSize_20}
          sx={{ margin: "0 0 10px" }}
        >
          Enhanced Security and Compliance
        </Typography>
        <ul>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Benefit:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                AMS providers implement security best practices, ensuring that
                applications are protected from cyber threats and compliant with
                regulations.
              </Typography>
            </Typography>
          </li>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Example:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                A healthcare provider can rely on AMS to ensure its patient data
                management software complies with HIPAA regulations while
                remaining secure from potential data breaches
              </Typography>
            </Typography>
          </li>
        </ul>
        <Typography
          fontFamily="Roboto"
          fontWeight="700"
          fontSize={contentFontSize_20}
          sx={{ margin: "0 0 10px" }}
        >
          Cost Savings
        </Typography>
        <ul>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Benefit:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                Outsourcing application management helps reduce operational
                costs by eliminating the need for in-house teams to handle
                support and maintenance tasks.
              </Typography>
            </Typography>
          </li>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Example:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                Small businesses can reduce overhead costs associated with
                hiring and training an internal IT team by leveraging AMS to
                manage their applications.
              </Typography>
            </Typography>
          </li>
        </ul>
        <Typography
          fontFamily="Roboto"
          fontWeight="700"
          fontSize={contentFontSize_20}
          sx={{ margin: "0 0 10px" }}
        >
          Increased Application Efficiency
        </Typography>
        <ul>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Benefit:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                Regular updates, bug fixes, and performance optimization enhance
                application functionality and ensure they meet evolving business
                needs.
              </Typography>
            </Typography>
          </li>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Example:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                An e-commerce platform can rely on AMS to continuously optimize
                its website’s performance, ensuring quick load times, a smooth
                checkout process, and high availability during peak shopping
                seasons.
              </Typography>
            </Typography>
          </li>
        </ul>
        <Typography
          fontFamily="Roboto"
          fontWeight="700"
          fontSize={contentFontSize_20}
          sx={{ margin: "0 0 10px" }}
        >
          Scalability for Growth
        </Typography>
        <ul>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Benefit:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                AMS provides businesses with the flexibility to scale their
                applications as needed without the complexity of managing the
                infrastructure in-house.
              </Typography>
            </Typography>
          </li>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Example:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                A SaaS company can scale its applications to handle increasing
                customer data and traffic, ensuring that growth doesn’t
                negatively impact performance or service delivery.
              </Typography>
            </Typography>
          </li>
        </ul>
        <div>
          <Typography
            fontSize={contentFontsize_28}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Key Benefits of Outsourcing Application Management
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Focus on Core Business Functions
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            sx={{ textAlign: "justify", color: "#6C7282", marginTop: "10px" }}
          >
            Outsourcing the management of non-core IT tasks allows businesses to
            focus on strategic goals like innovation, marketing, and customer
            acquisition.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Access to Expertise and Technology
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            sx={{ textAlign: "justify", color: "#6C7282", marginTop: "10px" }}
          >
            AMS providers offer specialized knowledge and advanced tools that
            ensure your applications are optimized, secure, and aligned with
            industry standards.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Improved Incident Management
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            sx={{ textAlign: "justify", color: "#6C7282", marginTop: "10px" }}
          >
            With AMS, your applications are in expert hands, ensuring that any
            issues are quickly detected, diagnosed, and resolved. This leads to
            faster incident response times and reduced downtime.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            24/7 Availability and Support
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            sx={{ textAlign: "justify", color: "#6C7282", marginTop: "10px" }}
          >
            AMS ensures that your applications are always available and
            monitored, even outside of business hours. This is especially
            important for businesses with global operations or customers in
            different time zones.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Streamlined Upgrades and Maintenance
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            sx={{ textAlign: "justify", color: "#6C7282", marginTop: "10px" }}
          >
            Continuous updates and maintenance ensure your applications stay
            current with the latest features and security patches, minimizing
            the risk of technical debt.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontsize_28}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            How Application Managed Services Improve Business Performance
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Optimized Application Performance
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            sx={{ textAlign: "justify", color: "#6C7282", marginTop: "10px" }}
          >
            By monitoring key performance indicators (KPIs) and optimizing
            system configurations, AMS providers ensure your applications
            perform at their peak. This translates into faster load times,
            better user experiences, and higher customer satisfaction.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Reduced Risk of Downtime
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            sx={{ textAlign: "justify", color: "#6C7282", marginTop: "10px" }}
          >
            Proactive monitoring and regular maintenance reduce the likelihood
            of application downtime, ensuring that your business can run
            smoothly without interruptions.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Enhanced Customer Experience
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            sx={{ textAlign: "justify", color: "#6C7282", marginTop: "10px" }}
          >
            Well-managed applications improve reliability, speed, and user
            interface, resulting in better customer experiences and increased
            retention.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Efficient Use of Resources
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            sx={{ textAlign: "justify", color: "#6C7282", marginTop: "10px" }}
          >
            With AMS, businesses can maximize the efficiency of their IT
            resources by leveraging the expertise of a dedicated team, reducing
            the need for in-house staff.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontsize_28}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Best Practices for Implementing Application Managed Services
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Define Clear Service Level Agreements (SLAs)
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            sx={{ textAlign: "justify", color: "#6C7282", marginTop: "10px" }}
          >
            Ensure that your AMS provider understands your business needs by
            setting clear SLAs, outlining expectations regarding uptime,
            response times, and issue resolution.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Conduct Regular Performance Reviews
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            sx={{ textAlign: "justify", color: "#6C7282", marginTop: "10px" }}
          >
            Regularly assess the performance of your managed applications to
            ensure they meet business objectives and identify areas for
            improvement.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Ensure Security and Compliance
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            sx={{ textAlign: "justify", color: "#6C7282", marginTop: "10px" }}
          >
            Make sure your AMS provider implements security measures to protect
            sensitive data and complies with industry regulations.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Foster Collaboration Between Internal and External Teams
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            sx={{ textAlign: "justify", color: "#6C7282", marginTop: "10px" }}
          >
            Ensure that your internal IT team works closely with the AMS
            provider to ensure a smooth integration and to address any issues
            effectively.
          </Typography>
        </div>

        <div>
          <Typography
            fontFamily="Roboto"
            fontWeight="700"
            fontSize={contentFontsize_28}
          >
            Conclusion
          </Typography>
        </div>
        <Typography
          fontSize={contentFontSize_18}
          color="#6C7282"
          fontWeight="400"
          textAlign="justify"
        >
          Application Managed Services provide businesses with a reliable,
          cost-effective solution for managing their mission-critical
          applications. By outsourcing the management of these applications to
          experts, businesses can optimize performance, enhance security, and
          scale with ease—all while focusing on core business activities. At
          Axia Kairos, we specialize in providing tailored AMS solutions that
          help businesses maintain optimal application performance and support
          growth. Let us handle your application management needs so you can
          focus on what truly matters—growing your business.
        </Typography>
      </div>
      <div
        className="bg-img"
        style={{
          backgroundImage: `url(${Common})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          // height: "500px",
          position: "relative",
          color: "white",
          padding: "20px", // Add spacing for responsiveness
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="blog-text1">
          {matches ? (
            <Typography
              fontSize={contentFontSize_50}
              fontWeight="700"
              sx={{
                marginBottom: "10px",
                color: "#FFD700",
                marginTop: smmatch ? "0px" : matches ? "50px" : "160px",
                padding: matches ? "10px 0px 10px 40px" : "0px",
                textAlign: matches ? "center" : null,
              }}
            >
              Begin Your Digital Transformation Journey with Axia Kairos
            </Typography>
          ) : (
            <Typography
              fontSize={contentFontSize_50}
              fontWeight="700"
              sx={{
                marginBottom: "10px",
                color: "#FFD700",
                marginTop: smmatch ? "0px" : matches ? "50px" : "160px",
                padding: matches ? "10px 0px 10px 40px" : "0px",
                textAlign: matches ? "center" : null,
              }}
            >
              Begin Your Digital Transformation Journey <br /> with Axia Kairos
            </Typography>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: matches && "center",
            padding: smmatch ? "0px" : "10px 40px",
            flexDirection: lgmatch ? "column" : "row",
          }}
        >
          <div style={{ width: "70%" }}>
            <Typography
              fontFamily="Roboto"
              fontWeight="400"
              fontSize={contentFontSize_20}
              style={{
                textAlign: matches ? "center" : "left",
                lineHeight: "1.6",
                marginBottom: "20px",
                color: "#FFFFFF", // White paragraph text
              }}
            >
              Ready to redefine your business with digital transformation? Axia
              Kairos delivers innovative strategies and cutting-edge
              technologies to help your organization adapt and thrive.
            </Typography>
          </div>
          <div style={{ marginTop: "10px" }}>
            <Button
              variant="contained"
              sx={{
                backgroundImage:
                  "linear-gradient(90deg, #DFAD17 0%, #795E0C 100%)",
                width: "300px",
                height: "40px",
                borderRadius: "15px",
                // padding: "10px 20px",
                // fontSize: "16px",
                // backgroundColor: "#FFC107",
                // color: "white",
                // border: "none",
                // borderRadius: "5px",
                // cursor: "pointer",
                // fontWeight: "500",
              }}
              onClick={handleContactClick}
            >
              <Typography
                fontFamily="Roboto"
                sx={{ textTransform: "capitalize", padding: "15px" }}
              >
                Start Your Transformation Today
              </Typography>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApplicationBlog;
