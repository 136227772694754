import React, { useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import {
  contentFontSize_16,
  contentFontSize_18,
  contentFontSize_20,
  contentFontsize_24,
  contentFontsize_28,
  contentFontSize_50,
  contentFontsize_96,
  HeaderFontSize_50_xs_33,
} from "../../Typography";
import Common from "../../utils/images/Common.jpg";
import CustomS from "../../utils/images/CustomS.png";
import "./DigitalTransformation.css";
import Navbar from "../../Navbar";
import Footer from "../../Footer/Footer";
import WhiteArrow from "../../utils/icons/WhiteArrow.svg"
import { useNavigate } from "react-router-dom";
function CustomSoftware() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
    const nav = useNavigate()
  function handleService(){
      nav("/services")
  }
  const cards = [
    {
      title: "Tailored to Your Business Needs",
      description:
        "Our custom software is built to address your unique challenges, ensuring that the solution fits seamlessly into your business operations and goals.",
    },
    {
      title: "Scalability & Flexibility",
      description:
        "As your business grows, so does your software. Our solutions are scalable, flexible, and designed to evolve with your changing needs.",
    },
    {
      title: "Enhanced Security & Compliance",
      description:
        "We prioritize security in every phase of development, ensuring that your custom software is safe from threats and compliant with industry regulations.",
    },
    {
      title: "Improved Operational Efficiency",
      description:
        "Our custom software streamlines your processes, automates tasks, and enhances productivity, leading to greater operational efficiency and reduced costs. ",
    },
  ];
  const data = [
    {
      id: "01",
      title: "Pioneers in Transformation",
      content:
        "We are at the forefront of leveraging digital technologies to drive meaningful change. Whether it’s adopting cloud solutions, integrating AI, or enhancing user experiences, we help businesses turn ideas into reality as a trusted business IT solutions provider.",
    },
    {
      id: "02",
      title: "Personalized for You",
      content:
        "No two businesses are the same, and neither are our solutions. We tailor every strategy, platform, and process to your unique goals and challenges, ensuring maximum impact.",
    },
    {
      id: "03",
      title: "Built for Long-Term Success",
      content:
        "Our solutions aren’t just designed for today—they’re crafted to evolve with you, ensuring lasting value in an ever-changing landscape.",
    },
    {
      id: "04",
      title: "Driven by Expertise",
      content:
        "Our team of seasoned professionals brings together global experience, industry knowledge, and a passion for innovation to deliver results that matter.",
    },
  ];

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));
  const dataService = [
    {
      title: "Consultation & Requirements Gathering",

      description:
        "We begin by understanding your business goals, challenges, and software needs. Our team works with you to define the project scope and gather detailed requirements.",
    },
    {
      title: "Design & Architecture",

      description:
        "Our design team creates intuitive, user-centric designs while our architects ensure that the software is scalable, reliable, and secure.",
    },
    {
      title: "Development & Customization",

      description:
        "We use agile development practices to build your custom software, ensuring that every feature is tailored to your specific needs and that we can quickly adapt to any changes during the development process.",
    },
  ];
  const dataService1 = [
    {
      title: "Testing & Quality Assurance",

      description:
        "Before deployment, we rigorously test your software to ensure that it performs optimally and meets your expectations. Our QA team ensures that your software is secure, scalable, and bug-free.",
    },
    {
      title: "Deployment & Support",

      description:
        "Once your software is ready, we deploy it to your environment, providing full integration and post-deployment support to ensure seamless operation.",
    },
    {
      title: "Ongoing Maintenance & Updates",

      description:
        "We offer continuous support and regular software updates to keep your system optimized and up-to-date with the latest technologies.",
    },
  ];
  function handleContact(){
    nav("/contact")
  }
  return (
    <div>
      <Navbar />
      <div
        className="bg-img-1"
        style={{
          backgroundImage: `url(${CustomS})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
         // height: "500px",
          position: "relative",
          color: "white",
          padding: smmatch ? "0px" : "20px", // Add spacing for responsiveness
          backgroundRepeat: "no-repeat",
        }}
      >
         <div className="back-btn" onClick={handleService}>
                                <img src={WhiteArrow} style={{ marginTop:smmatch ? "18px" : mdmatch && "4px"}} />
                                <span
                                  style={{
                                    marginLeft: "10px",
                                    fontSize: contentFontSize_18,
                                    fontFamily: "Roboto",
                                    marginBottom: "3px",
                                     marginTop:smmatch && "15px"
                                  }}
                                >
                                  Back to Services
                                </span>
                              </div>
        <div className="blog-text1">
          {/* {matches ? ( */}
          <div>
            <Typography
              fontSize={contentFontSize_50}
              fontWeight="700"
              sx={{
                marginBottom: "10px",
                color: "w",
                marginTop: smmatch ? "0px" : matches ? "50px" : "300px",
                padding: matches ? "50px 0px 10px 40px" : "0px",
                textAlign: matches ? "center" : null,
              }}
            >
              Custom Software Development: Tailored Solutions for Your Unique
              Business Needs
            </Typography>
            <div>
              <Typography
                fontSize={contentFontSize_20}
                fontWeight="400"
                fontFamily="Roboto"
                sx={{
                  padding: matches ? "10px 0px 10px 40px" : "0px",
                  textAlign: matches ? "center" : null,
                }}
              >
                Develop Bespoke Applications that Drive Business Innovation
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div className="service-sub-content">
        <Typography
          fontFamily="Roboto"
          fontWeight="400"
          fontSize={contentFontSize_18}
          sx={{ textAlign: "justify", color: "#6C7282" }}
        >
          At Axia Kairos, we specialize in custom software development designed
          to meet the specific challenges and goals of your business. Whether
          you need a powerful enterprise application, a customer-facing
          platform, or a mobile solution, our team of experts delivers scalable,
          secure, and high-performance software that aligns with your unique
          requirements. We work with you through every step, from ideation to
          deployment, ensuring that your custom solutions drive real value for
          your business.
        </Typography>
        <Typography
          fontFamily="Roboto"
          fontSize={contentFontsize_28}
          fontWeight="700"
          color="#B97E12"
          sx={{ marginTop: "15px" }}
        >
          What We Do and Offer
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Bespoke Software Development
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Axia Kairos creates custom software solutions that are perfectly
          aligned with your business objectives. We design, develop, and deploy
          tailored applications that address specific needs, enabling you to
          stay ahead of the competition and improve operational efficiency.
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Enterprise Software Development
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          We develop enterprise-level solutions that optimize your business
          processes, enhance internal workflows, and improve communication
          within your organization. Our enterprise applications are scalable,
          secure, and tailored to meet the needs of large organizations.
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Web Application Development
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Create responsive and user-friendly web applications that are
          optimized for performance. From simple websites to complex web
          applications, we design solutions that provide seamless user
          experiences across all devices.
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Mobile App Development
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Axia Kairos builds high-performance mobile applications for iOS and
          Android. Our mobile solutions offer rich user experiences, seamless
          functionality, and security, helping you engage customers and
          streamline business operations.
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Cloud-based Software Solutions
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Leverage the power of the cloud to build scalable and flexible
          applications. Our cloud-based solutions ensure high availability,
          security, and performance, enabling you to meet the growing demands of
          your business.
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          API Integration & Development
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Seamlessly integrate your custom software with existing systems and
          third-party applications through robust APIs. Our API development and
          integration services enhance your software’s functionality and
          streamline business operations.
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Software Maintenance & Support
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Axia Kairos provides ongoing maintenance and support for your custom
          software to ensure its continued performance, security, and
          scalability. We monitor and optimize your applications, providing
          updates and improvements as needed.
        </Typography>
        <Typography
          fontFamily="Roboto"
          fontSize={contentFontsize_28}
          fontWeight="700"
          color="#B97E12"
          sx={{ marginTop: "15px" }}
        >
          Additional Services in Data Analytics
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          UI/UX Design
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Deliver an outstanding user experience with our UI/UX design services.
          We focus on creating intuitive, visually appealing interfaces that
          enhance usability and user satisfaction.
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Software Testing & QA
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Ensure your custom software is bug-free and performs optimally. We
          provide comprehensive testing and quality assurance services to
          deliver secure, reliable, and fully functional software.
        </Typography>
        <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          Legacy System Modernization
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          Upgrade your outdated software systems to modern, scalable, and
          efficient solutions. We specialize in modernizing legacy systems,
          ensuring they integrate seamlessly with new technologies.
        </Typography>
        {/* <Typography
          fontWeight="700"
          fontFamily="Roboto"
          fontSize={contentFontSize_20}
          sx={{ marginTop: "15px" }}
        >
          IT Infrastructure Modernization
        </Typography>
        <Typography
          fontWeight="400"
          fontSize={contentFontSize_18}
          color="#6C7282"
          sx={{ marginTop: "15px" }}
        >
          We upgrade your IT environment with cutting-edge tools and solutions.
          Whether it’s on-premise, hybrid, or cloud infrastructure, our services
          ensure your systems are resilient, secure, and future-ready.
        </Typography> */}
      </div>
      <div className="about-us-second">
        <div className="main-heading-about-us">
          <Typography
            sx={{ fontWeight: "700", fontFamily: "Roboto", marginTop: "50px",textAlign:smmatch && "center" }}
            fontSize={HeaderFontSize_50_xs_33}
          >
            Why Choose Axia Kairos?
          </Typography>
        </div>
        <Typography
          fontSize={contentFontSize_20}
          fontWeight="400"
          color="white"
          fontFamily="Roboto"
          sx={{
            paddingLeft: mdmatch ? "10px" : "150px",
            paddingRight: mdmatch ? "10px" : "150px",
            textAlign: "center",
            paddingBottom: "50px",
          }}
        >
          Axia Kairos combines technical expertise, deep industry knowledge, and
          a customer-first approach to deliver high-quality, custom software
          solutions. We work closely with you to understand your business
          objectives and create software that not only solves problems but also
          drives long-term success. Our team ensures that your custom software
          is secure, scalable, and designed to grow with your business.
        </Typography>
        <div className="about-us-div">
          {data.map((item) => (
            <div className="about-us-card" key={item.id}>
              <Typography
                fontSize={contentFontsize_96}
                fontFamily="Roboto"
                fontWeight="100"
                sx={{ color: "#EBB81A", lineHeight: "98px" }}
              >
                {item.id}
              </Typography>
              <Typography
                sx={{
                  color: "#ECECEC",
                  textAlign: "left",
                  lineHeight: "31px",
                  marginBottom: "10px",
                }}
                fontSize={contentFontsize_24}
                fontFamily="Roboto"
              >
                {item.title}
              </Typography>
              <Typography
                sx={{
                  color: "#ECECEC",
                  textAlign: "left",
                  lineHeight: "31px",
                  fontWeight: "400",
                }}
                fontSize={contentFontSize_16}
                fontFamily="Roboto"
              >
                {item.content}
              </Typography>
              {/* <div className="imge-section-about-us">
                  <img src={Line}/>
                </div> */}
            </div>
          ))}
        </div>
      </div>
      <div className="third-page-service">
        <div style={{ marginTop: "50px", marginBottom: "50px" }}>
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              textAlign: "center",
            }}
            fontWeight="700"
            fontFamily="Roboto"
            fontSize={contentFontSize_50}
          >
            Our Proven Approach to Custom Software Development
          </Typography>
        </div>
        <div className="secnd-sec">
          <div className="flex-card-in">
            {dataService.map((card, index) => (
              <Card
                className="about-card-service"
                key={index}
                sx={{ borderRadius: "15px", background: "#9F6907" }}
              >
                <div className="about-content-card-service">
                  <Typography
                    fontWeight="700"
                    fontSize={contentFontsize_24}
                    fontFamily="Roboto"
                    className="about-card-title"
                  >
                    {card.title}
                  </Typography>
                  <Typography
                    fontWeight="400"
                    fontSize={contentFontSize_18}
                    fontFamily="Roboto"
                    color="white"
                    className="about-content-1-service"
                  >
                    {card.description}
                  </Typography>
                </div>
              </Card>
            ))}
          </div>
        </div>
        <div className="secnd-sec">
          <div className="flex-card-in-1">
            {dataService1.map((card, index) => (
              <Card
                className="about-card-service-1"
                key={index}
                sx={{
                  borderRadius: "15px",
                  background: "#9F6907",
                  marginTop: "20px",
                }}
              >
                <div className="about-content-card-service">
                  <Typography
                    fontWeight="700"
                    fontSize={contentFontsize_24}
                    fontFamily="Roboto"
                    className="about-card-title"
                  >
                    {card.title}
                  </Typography>
                  <Typography
                    fontWeight="400"
                    fontSize={contentFontSize_18}
                    fontFamily="Roboto"
                    color="white"
                    className="about-content-1-service"
                  >
                    {card.description}
                  </Typography>
                </div>
              </Card>
            ))}
          </div>
        </div>
      </div>
      <div className="main-third">
        <div className="about-us-text1">
          <Typography
            fontFamily="Roboto"
            fontWeight="500"
            fontSize={contentFontSize_50}
            sx={{
              paddingTop: mdmatch ? "50px" : "200px",
              textAlign: matches && "center",
            }}
          >
            How Our Custom Software Development Services Ensure Long-Term
            Success
          </Typography>
        </div>

        <div className="third-sub">
          {cards.map((card, index) => (
            <div
              key={index}
              //   ref={(el) => (cardRefs.current[index] = el)}
              data-index={index}
              //   className={`card-wrapper-about-us ${
              //     visibleCards.includes(index.toString()) ? "active" : ""
              //   }`}
              style={{
                display: "flex",
                flexDirection: mdmatch ? null : "row",
                justifyContent: mdmatch ? null : "space-between",
                width: mdmatch ? null : "100%",
              }}
            >
              <div
              // className={`side-item-about-us ${
              //   visibleCards.includes(index.toString()) ? "active" : ""
              // }`}
              ></div>

              <Card
                sx={{
                  borderRight: "5px solid #B97E12",
                  background: "#DFDFDF",
                  width: matches ? "100%" : "90%",
                  // color: "white",
                  transition: "all 0.5s ease",
                  height: mdmatch ? "100%" : "160px",
                  borderRadius: "10px",
                }}
                // className={`card-content ${
                //   visibleCards.includes(index.toString()) ? "active" : ""
                // }`}
              >
                <CardContent>
                  <div
                    className="card-title-about-us"
                    style={{ marginBottom: "10px" }}
                  >
                    <Typography
                      fontWeight="700"
                      fontSize={contentFontSize_20}
                      fontFamily="Roboto"
                      //color="black"
                    >
                      {card.title}
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      fontWeight="400"
                      fontSize={contentFontSize_18}
                      fontFamily="Roboto"
                      className="card-description-about-us"
                    >
                      {card.description}
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            </div>
          ))}
        </div>
      </div>
      <div
        className="bg-img"
        style={{
          backgroundImage: `url(${Common})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          // height: "500px",
          position: "relative",
          color: "white",
          padding: "20px", // Add spacing for responsiveness
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="blog-text1">
          {matches ? (
            <Typography
              fontSize={contentFontSize_50}
              fontWeight="700"
              sx={{
                marginBottom: "10px",
                color: "#FFD700",
                marginTop: smmatch ? "0px" : matches ? "50px" : "160px",
                padding: matches ? "10px 0px 10px 40px" : "0px",
                textAlign: matches ? "center" : null,
              }}
            >
              Ready to Build Custom Software That Drives Business Success?
            </Typography>
          ) : (
            <Typography
              fontSize={contentFontSize_50}
              fontWeight="700"
              sx={{
                marginBottom: "10px",
                color: "#FFD700",
                marginTop: smmatch ? "0px" : matches ? "50px" : "160px",
                padding: matches ? "10px 0px 10px 40px" : "0px",
                textAlign: matches ? "center" : null,
              }}
            >
              Ready to Build Custom Software That Drives Business Success?
            </Typography>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: matches && "center",
            padding: smmatch ? "0px" : "10px 40px",
            flexDirection: lgmatch ? "column" : "row",
          }}
        >
          <div style={{ width: "70%" }}>
            <Typography
              //fontFamily="Roboto"
              fontWeight="400"
              fontSize={contentFontSize_20}
              style={{
                textAlign: matches ? "center" : "left",
                lineHeight: "1.6",
                marginBottom: "20px",
                color: "#FFFFFF", // White paragraph text
              }}
            >
              Axia Kairos delivers end-to-end custom software development
              services tailored to meet your unique business requirements. From
              enterprise applications to mobile solutions, we create software
              that empowers your business to thrive.
            </Typography>
          </div>
          <div style={{ marginTop: "10px" }}>
            <Button
              variant="contained"
              onClick={handleContact}
              sx={{
                backgroundImage:
                  "linear-gradient(90deg, #DFAD17 0%, #795E0C 100%)",
                width: "300px",
                height: "40px",
                borderRadius: "15px",
                // padding: "10px 20px",
                // fontSize: "16px",
                // backgroundColor: "#FFC107",
                // color: "white",
                // border: "none",
                // borderRadius: "5px",
                // cursor: "pointer",
                // fontWeight: "500",
              }}
            >
              <Typography
                //fontFamily="Roboto"
                sx={{ textTransform: "capitalize", padding: "15px" }}
              >
                Get Started Today
              </Typography>
            </Button>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default CustomSoftware;
