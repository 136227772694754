import { Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import {
  contentFontSize_18,
  contentFontSize_20,
  contentFontsize_24,
  contentFontSize_50,
} from "../../Typography";
import Common from "../../utils/images/Common.jpg";
import ArrowAboutus from "../../utils/icons/ArrowAboutus.svg";
import "./FifthPage.css";
import { useNavigate } from "react-router-dom";
function FifthPage() {
  const nav = useNavigate()
  function handleService(){
    nav("/services")
  }
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));

  const data1 = [
    {
      title: "Partnerships and Alliances",

      description:
        "We believe in the power of collaboration. At Axia Kairos, we actively seek strategic partnerships to drive mutual growth and innovation. Whether you're looking to integrate cutting-edge solutions or expand your market reach, we’re here to partner with you for success. ",
    },
    {
      title: "Get In Touch",

      description:
        "Ready to transform your business? Reach out to our team for a consultation or to ask any questions about how we can help. We’re here to guide you every step of the way. ",
    },
  ];
  function handleContact(index){
    if(index === 0){
    nav("/careers")
  }
  else{
    nav("/contact")
  }
  }
  return (
    <div>
      <div
        className="bg-img"
        style={{
          backgroundImage: `url(${Common})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          // height: "500px",
          position: "relative",
          color: "white",
          padding: "20px", // Add spacing for responsiveness
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="blog-text1">
          {matches ? (
            <Typography
              fontSize={contentFontSize_50}
              fontWeight="700"
              sx={{
                marginBottom: "10px",
                color: "#FFD700",
                marginTop: smmatch ? "0px" : matches ? "50px" : "160px",
                padding: matches ? "10px 0px 10px 40px" : "0px",
                textAlign: matches ? "center" : null,
              }}
            >
              Explore Our Services
            </Typography>
          ) : (
            <Typography
              fontSize={contentFontSize_50}
              fontWeight="700"
              sx={{
                marginBottom: "10px",
                color: "#FFD700",
                marginTop: smmatch ? "0px" : matches ? "50px" : "160px",
                padding: matches ? "10px 0px 10px 40px" : "0px",
                textAlign: matches ? "center" : null,
              }}
            >
              Explore <br/> Our Services
            </Typography>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: matches && "center",
            padding: smmatch ? "0px" : "0px 40px",
            flexDirection: lgmatch ? "column" : "row",
          }}
        >
          <div style={{ width: "70%" }}>
            <Typography
              //fontFamily="Roboto"
              fontWeight="400"
              fontSize={contentFontSize_20}
              style={{
                textAlign: matches ? "center" : "left",
                lineHeight: "1.6",
                marginBottom: "20px",
                color: "#FFFFFF", // White paragraph text
              }}
            >
              Unlock the full potential of your business with Axia Kairos'
              comprehensive suite of digital transformation solutions. From
              cloud services to AI-driven innovations, we offer customized
              strategies that align with your unique business needs.
            </Typography>
          </div>
          <div style={{ marginTop: "10px" }}>
            <Button
              variant="contained"
              onClick={handleService}
              sx={{
                backgroundImage:
                  "linear-gradient(90deg, #DFAD17 0%, #795E0C 100%)",
                width: "300px",
                height: "40px",
                borderRadius: "15px",
                
                // padding: "10px 20px",
                // fontSize: "16px",
                // backgroundColor: "#FFC107",
                // color: "white",
                // border: "none",
                // borderRadius: "5px",
                // cursor: "pointer",
                // fontWeight: "500",
              }}
            >
              <Typography
                //fontFamily="Roboto"
                sx={{ textTransform: "capitalize", padding: "15px" }}
              >
                View Our Services
              </Typography>
            </Button>
          </div>
        </div>
      </div>
      <div className="cards-container-1">
        {data1.map((card, index) => (
          <div className="card-wrapper-about-us-5" key={index}>
            <div className="card-service-about-us">
              {/* <div className="card-icon">
                        <div>
                          <img src={Rect} />
                        </div>
                      </div> */}
              <Typography
                sx={{ textAlign: "left", marginTop: "20px" }}
                fontSize={contentFontsize_24}
                fontWeight="700"
                fontFamily="Roboto"
              >
                {card.title}
              </Typography>
              {/* <Typography
                        sx={{
                          textAlign: "left",
                          marginTop: "20px",
      
                          marginBottom: "15px",
                        }}
                        fontSize={contentFontSize_20}
                        fontWeight="500"
                        fontFamily="Roboto"
                      >
                        {card.subtitle}
                      </Typography> */}
              <Typography
                sx={{
                  color: "#6C7282",
                  textAlign: "left",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
                fontSize={contentFontSize_18}
                fontWeight="400"
                fontFamily="Roboto"
              >
                {card.description}
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginTop:"auto"
                }}
              >
                <Button
                  variant="outlined"
                  onClick={()=>handleContact(index)}
                  sx={{
                    //orderColor: "orange",
                    borderRadius: "15px",
                    width: "100%",
                    height: "40px",
                    background:
                      "linear-gradient(90deg, #DFAD17 0%, #795E0C 100%)",
                  }}
                >
                  <Typography
                    sx={{ color: "white", textTransform: "capitalize" }}
                  >
                    {index === 0
                      ? "Learn More About Our Partnerships"
                      : "Contact Us Today"}
                  </Typography>
                  <span>
                    <img
                      src={ArrowAboutus}
                      style={{ marginLeft: "5px", marginTop: "8px" }}
                    />
                  </span>
                </Button>
              </div>
            </div>

            {/* Add a vertical line between cards except for the last card */}
            {index < data1.length - 1 && (
              <div className="vertical-line-1"></div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default FifthPage;
