import React from "react";
import Navbar from "../Navbar";
import CareersMain from "./CareersMain/Careers";
import CareerImage from "./CareersMain/CareerImage";
import CareerHero from "./CareersMain/CareerHero";
import Footer from "../Footer/Footer";

function Careers() {
  return (
    <>
    
      <Navbar />
      <CareerHero/>
      <CareersMain />
      <CareerImage />
      <Footer/>
    </>
  );
}

export default Careers;
