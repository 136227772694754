import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import "./Industries.css";
import {
  ButtonFontSize_16,
  contentFontSize_18,
  HeaderFontSize_24,
} from "../../Typography";

function IndustriesDialog({ dialogOpen, setDialogOpen, title, bulletPoints }) {
  const handleClose = () => {
    setDialogOpen(false);
  };

  console.log("hi",title,bulletPoints)

  return (
    <Dialog
      open={dialogOpen}
      onClose={handleClose}
      aria-labelledby="dialog-title"
      sx={{
        "& .MuiDialog-paper": {
          padding: "30px",
          borderRadius: "20px",
        },
      }}
    >
      <DialogTitle id="dialog-title">
        <Typography fontSize={HeaderFontSize_24} sx={{ fontWeight: "700" }}>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <List sx={{ listStyleType: "disc", paddingLeft: "20px" }}>
          {bulletPoints.map((point, index) => (
            <ListItem
              key={index} // Always provide a key for each list item
              sx={{ display: "list-item", padding: 0, marginBottom: "10px" }}
            >
              <Typography fontSize={contentFontSize_18}>
                <span className="digital">{point.highlighted}</span>
                {point.content}
              </Typography>
            </ListItem>
          ))}
        </List>
        <div>
          <Button
            onClick={handleClose}
            variant="contained"
            sx={{
              height: "10%",
              width: "30%",
              borderRadius: "10px",
              background: "#B97E12",
              color: "#fff",
              ":hover": {
                background: "#B97E12",
              },
              margin: "0 auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
              "@media (max-width: 768px)": {
                width: "80%",
                minWidth: "100px",
              },
              "@media (max-width: 375px)": {
                width: "100%",
              },
              background: "linear-gradient(90deg, #DFAD17 0%, #795E0C 100%)",
              ":hover": {
                background: "linear-gradient(90deg, #795E0C 0%, #DFAD17 100%)",
              },
            }}
          >
            <Typography fontSize={ButtonFontSize_16}>Close</Typography>
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default IndustriesDialog;
