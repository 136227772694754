import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { styled } from "@mui/system";
import "./Careers.css";
import { Typography } from "@mui/material";
import {
  ButtonFontSize_16,
  ButtonFontSize_18,
  contentFontSize_16,
  contentFontSize_18,
} from "../../Typography";
import location from "../../utils/icons/Locationimg.svg";
import clock from "../../utils/icons/clock.svg";
import { useNavigate } from "react-router-dom";

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
});

const CareersMain = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  const [filters, setFilters] = useState({
    department: "",
    country: "",
    state: "",
  });

  const [filteredJobs, setFilteredJobs] = useState([]);

  const jobs = [
    {
      title: "SAP Consultant - CPI/BTP",
      experience: "6+ Years Experience",
      location: "India (Remote/On-site as needed)",
      type: "Full-Time",
      department: "SAP Solutions",
      country: "India",
      state: "",
      description:
        "We are looking for an experienced SAP Consultant specializing in CPI (Cloud Platform Integration) and BTP (Business Technology Platform). The ideal candidate will be responsible for designing, implementing, and supporting cutting-edge SAP integrations and applications that streamline business processes.",
      responsibilities: [
        "Develop and deploy CPI/BTP solutions tailored to business needs.",
        "Integrate SAP applications with third-party systems and platforms.",
        "Troubleshoot and resolve technical issues to ensure uninterrupted operations.",
        "Collaborate with cross-functional teams to understand business requirements.",
        "Stay updated on the latest developments in SAP CPI/BTP and recommend improvements.",
      ],
      preferredSkills: [
        "Hands-on experience in CPI integration flows and BTP services.",
        "Expertise in SAP connectivity technologies such as IDOC, OData, REST, and SOAP.",
        "Strong problem-solving and analytical skills.",
      ],
    },
    {
      title: "SAP Consultant - BYD (Business ByDesign)",
      experience: "6+ Years Experience",
      location: "India (Remote/On-site as needed)",
      type: "Full-Time",
      department: "SAP Solutions",
      country: "India",
      state: "",
      description:
        "Manage the implementation, customization, and ongoing support of SAP Business ByDesign solutions.",
      responsibilities: [
        "Lead the end-to-end implementation of SAP BYD projects.",
        "Configure and customize BYD solutions to meet client requirements.",
        "Monitor system performance and resolve issues promptly.",
        "Conduct training sessions for end-users and provide documentation.",
        "Continuously assess the BYD system for optimization opportunities.",
      ],
      preferredSkills: [
        "In-depth knowledge of SAP BYD modules, including Financials, Supply Chain, and CRM.",
        "Proven ability to lead successful SAP BYD projects.",
        "Strong communication and stakeholder management skills.",
      ],
    },
    {
      title: "SAP Consultant - TPM (Trade Promotion Management)",
      experience: "6+ Years Experience",
      location: "India (Remote/On-site as needed)",
      type: "Full-Time",
      department: "SAP Solutions",
      country: "India",
      state: "",
      description:
        "Lead the implementation and optimization of Trade Promotion Management systems.",
      responsibilities: [
        "Analyze business requirements and design SAP TPM solutions.",
        "Implement and configure TPM functionalities, including budget planning and claims management.",
        "Integrate TPM with other SAP modules such as CRM and BI.",
        "Provide training and support to end-users.",
        "Perform regular system audits to identify areas for improvement.",
      ],
      preferredSkills: [
        "Deep understanding of SAP TPM processes and best practices.",
        "Proficiency in integrating SAP TPM with analytics tools for performance tracking.",
        "Strong project management and team collaboration skills.",
      ],
    },
    {
      title: "Business Development Executive - SAP Solutions",
      experience: "5+ Years in SAP Sales",
      location: "Canada (Remote/Hybrid)",
      type: "Full-Time",
      department: "Business Development",
      country: "Canada",
      state: "",
      description:
        "Drive sales of SAP solutions and build client relationships.",
      responsibilities: [
        "Identify and engage potential clients in need of SAP solutions.",
        "Develop customized proposals and presentations to address client challenges.",
        "Negotiate contracts and close deals to meet or exceed sales targets.",
        "Maintain an active pipeline of opportunities.",
        "Collaborate with technical teams to ensure seamless solution delivery.",
      ],
      preferredSkills: [
        "Extensive network in the SAP ecosystem.",
        "Strong understanding of SAP solutions, including CPI, BYD, and TPM.",
        "Exceptional negotiation and presentation skills.",
      ],
    },
  ];

  const departments = ["Design", "Development", "Marketing", "HR"];
  const countries = ["Canada", "USA"];
  const states = ["Ontario", "New York", "California"];

  const handleSearch = () => {
    const result = jobs.filter((job) => {
      return (
        (filters.department === "" || job.department === filters.department) &&
        (filters.country === "" || job.country === filters.country) &&
        (filters.state === "" || job.state === filters.state)
      );
    });
    setFilteredJobs(result);
  };

  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };
  const nav = useNavigate();

  const handleApplyClick = (job) => {
    nav("/careers/Job-Apply", { state: { job } });
  };

  return (
    <div className="careers-container">
      {/* <Box className="filter-section" display="flex" gap={2} mb={3}>
        <div className="flex-border">
          <CustomTextField
            select
            label="Department"
            variant="outlined"
            size="small"
            fullWidth
            onChange={(e) => handleFilterChange("department", e.target.value)}
          >
            <MenuItem value="">All Departments</MenuItem>
            {jobs.map((dept, index) => (
              <MenuItem key={index} value={dept.department}>
                {dept.department}
              </MenuItem>
            ))}
          </CustomTextField>
        </div>
        <div className="flex-border">
          <CustomTextField
            select
            label="Country"
            variant="outlined"
            size="small"
            fullWidth
            onChange={(e) => handleFilterChange("country", e.target.value)}
          >
            <MenuItem value="">All Countries</MenuItem>
            {jobs.map((job, index) => (
              <MenuItem key={index} value={job.country}>
                {job.country}
              </MenuItem>
            ))}
          </CustomTextField>
        </div>
        <div className="flex-border-last">
          <CustomTextField
            select
            label="State"
            variant="outlined"
            size="small"
            fullWidth
            onChange={(e) => handleFilterChange("state", e.target.value)}
          >
            <MenuItem value="">All States</MenuItem>
            {jobs.map((job, index) => (
              <MenuItem key={index} value={job.state}>
                {job.state}
              </MenuItem>
            ))}
          </CustomTextField>
        </div>
        <Button
          variant="contained"
          sx={{
            width: "40%",
            borderRadius: "10px",
            background: "#B97E12",
            color: "#fff",
            ":hover": {
              background: "#B97E12",
            },
            margin: "0 auto", // Center the button horizontally
            display: "block",
            "@media (max-width: 768px)": {
              width: "80%",
              minWidth: "100px",
            },
            "@media (max-width: 375px)": {
              width: "100%",
            },
          }}
          onClick={handleSearch}
        >
          <Typography fontSize={ButtonFontSize_16}>Search</Typography>
        </Button>
      </Box> */}
      <div className="job-listings">
        {(filteredJobs.length > 0 ? filteredJobs : jobs).map((job, index) => (
          <div className="job-card" key={index}>
            <div className="job-details">
              <div className="job-name">
                <Typography
                  fontSize={contentFontSize_18}
                  className="job-title"
                  style={{ fontWeight: "600" }}
                >
                  {job.title}
                </Typography>
                <Typography
                  fontSize={contentFontSize_18}
                  className="job-experience"
                >
                  {job.experience}
                </Typography>
              </div>
              <div className="job-location">
                <div className="location-flex">
                  <img src={location} alt="location" />
                  <Typography
                    fontSize={contentFontSize_16}
                    className="location-text"
                    style={{ fontWeight: "600" }}
                  >
                    {job.location}
                  </Typography>
                </div>
              </div>
              <div className="job-location">
                <div className="location-flex">
                  <img src={clock} alt="location" />
                  <Typography
                    fontSize={contentFontSize_16}
                    className="location-text"
                    style={{ fontWeight: "600" }}
                  >
                    {job.type}
                  </Typography>
                </div>
              </div>
            </div>
            <Button
              className="button-careers"
              onClick={() => handleApplyClick(job)}
              variant="contained"
              sx={{
                width: "15%",
                background: "#B97E12",
                color: "#fff",
                ":hover": {
                  background: "#B97E12",
                },
                margin: "0 auto",
                display: "block",
                "@media (max-width: 768px)": {
                  width: "100%",
                  minWidth: "100px",
                },
                "@media (max-width: 375px)": {
                  width: "100%",
                },
              }}
            >
              <Typography fontSize={ButtonFontSize_16}>Apply</Typography>
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CareersMain;
