import { Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import "./InnerPage.css";
import React, { useEffect } from "react";
import BackArrow from "../utils/icons/BackArrow.svg";
import BlogMain1 from "../utils/images/BlogMain1.png";
import Gradient from "../utils/images/Gradient.jpg";
import Name from "../utils/icons/Name.svg";
import calender from "../utils/icons/calender.svg";
import clock from "../utils/icons/clock.svg";
import Common from "../utils/images/Common.jpg";
import DigitalBlog from "../utils/images/DigitalBlog.png";
import {
  contentFontSize_16,
  contentFontSize_18,
  contentFontSize_20,
  contentFontsize_28,
  contentFontSize_48,
  contentFontSize_50,
} from "../Typography";
import Navbar from "../Navbar";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer/Footer";
function Digital() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));

  const nav = useNavigate();

  const handleBackClick = () => {
    nav("/blogs");
  };
  const handleContactClick = () => {
    nav("./contact");
  };

  return (
    <div>
      <>
        <Navbar />
      </>
      <div className="back-btn" onClick={handleBackClick}>
        <img src={BackArrow}  style={{marginTop:mdmatch && "5px"}} />
        <span
          style={{
            marginLeft: "10px",
            fontSize: contentFontSize_18,
            fontFamily: "Roboto",
            marginBottom: "3px",
          }}
        >
          Back to Blogs
        </span>
      </div>
      <div
        className="responsive-container"
        style={{
          backgroundImage: xsmatch ? `url(${Gradient})` : `url(${DigitalBlog})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "500px",
          position: "relative",
          color: "white",
          display: "flex",
          width: "100%",
          //justifyContent: "center",
          alignItems: "flex-end",
          marginTop: "30px",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="blog-text">
          <Typography fontSize={contentFontSize_48} fontWeight="700">
            Digital Transformation: A roadmap to innovate, adapt, and thrive in
            the modern Era
          </Typography>
        </div>
      </div>
      {/* <div className="blog-section-one">
        <div className="blog-section-sub">
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={Name} alt="Profile" style={{ marginRight: "5px" }} />
            <span
              style={{
                fontFamily: "Roboto",
                fontWeight: "500",
                fontSize: "14px",
              }}
            >
              Shyam Pushkar
            </span>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={calender} alt="calender" style={{ marginRight: "0px" }} />
            <span
              style={{
                marginLeft: "10px",
                fontFamily: "Roboto",
                fontWeight: "500",
                fontSize: "14px",
              }}
            >
              January 21 2024
            </span>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={clock} alt="clock" style={{ marginRight: "0px" }} />
            <span
              style={{
                marginLeft: "10px",
                fontFamily: "Roboto",
                fontWeight: "500",
                fontSize: "14px",
                textAlign: "center",
              }}
            >
              8:25
            </span>
          </div>
        </div>
      </div> */}
      <div className="main">
        <div>
          <Typography
            fontSize={contentFontsize_28}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Introduction
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            lineHeight="31px"
            sx={{
              textAlign: "justify",
              color: "#6C7282",
              marginTop: "10px",
              // letterSpacing: "1px",
            }}
          >
            In an era where technology drives every aspect of business, digital
            transformation has become a necessity rather than a luxury.
            Companies that embrace digital transformation gain a competitive
            edge by streamlining operations, enhancing customer experiences, and
            unlocking new opportunities for growth. However, achieving
            successful transformation requires a structured approach and
            strategic implementation. In this blog, we will explore the concept
            of digital transformation, its benefits, and how businesses can
            develop a roadmap to navigate their transformation journey
            effectively.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontsize_28}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            What is Digital Transformation?
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            lineHeight="31px"
            sx={{
              textAlign: "justify",
              color: "#6C7282",
              marginTop: "10px",
              //letterSpacing: "1px",
            }}
          >
            Digital transformation involves integrating digital technologies
            into all areas of a business, fundamentally changing how it operates
            and delivers value to customers. It encompasses a shift in culture,
            processes, and strategies to leverage the power of technology for
            innovation and growth.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontsize_28}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Key Benefits of Digital Transformation
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Enhanced Agility:
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            sx={{ textAlign: "justify", color: "#6C7282", marginTop: "10px" }}
          >
            Digital tools enable businesses to adapt quickly to changing market
            demands and stay ahead of competitors.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Operational Efficiency:
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            sx={{ textAlign: "justify", color: "#6C7282", marginTop: "10px" }}
          >
            Automating repetitive tasks and optimizing workflows reduces costs
            and increases productivity.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Data-Driven Decision Making:
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            sx={{ textAlign: "justify", color: "#6C7282", marginTop: "10px" }}
          >
            With advanced analytics, businesses can gather actionable insights
            to make smarter and faster decisions.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Improved Customer Experience:
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            sx={{ textAlign: "justify", color: "#6C7282", marginTop: "10px" }}
          >
            By utilizing data-driven insights, businesses can deliver
            personalized and seamless experiences across multiple touchpoints.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontsize_28}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Steps to Build a Digital Transformation Roadmap
          </Typography>
        </div>

        <div style={{ lineHeight: "1.8", margin: "20px" }}>
          <div style={{ marginBottom: "20px" }}>
            <Typography
              fontFamily="Roboto"
              fontWeight="700"
              fontSize={contentFontSize_20}
              sx={{ margin: "0 0 10px" }}
            >
              1. Assess Your Current State
            </Typography>
            <ul style={{ margin: "0", paddingLeft: "20px" }}>
              <li style={{ color: "#6C7282", textAlign: "justify" }}>
                <Typography
                  fontWeight="400"
                  fontFamily="Roboto"
                  fontSize={contentFontSize_18}
                >
                  Conduct a comprehensive audit of your current processes,
                  tools, and technologies.
                </Typography>
              </li>
              <li style={{ color: "#6C7282", textAlign: "justify" }}>
                <Typography
                  fontWeight="400"
                  fontFamily="Roboto"
                  fontSize={contentFontSize_18}
                >
                  Identify pain points and areas that need improvement or
                  innovation.
                </Typography>
              </li>
            </ul>
          </div>

          <div style={{ marginBottom: "20px" }}>
            <Typography
              fontFamily="Roboto"
              fontWeight="700"
              fontSize={contentFontSize_20}
              sx={{ margin: "0 0 10px" }}
            >
              2. Define Clear Objectives
            </Typography>
            <ul style={{ margin: "0", paddingLeft: "20px" }}>
              <li style={{ color: "#6C7282", textAlign: "justify" }}>
                <Typography
                  fontWeight="400"
                  fontFamily="Roboto"
                  fontSize={contentFontSize_18}
                >
                  Establish specific goals for digital transformation, such as
                  improving customer engagement or increasing operational
                  efficiency.
                </Typography>
              </li>
              <li style={{ color: "#6C7282", textAlign: "justify" }}>
                <Typography
                  fontWeight="400"
                  fontFamily="Roboto"
                  fontSize={contentFontSize_18}
                >
                  Align objectives with overall business strategy to ensure
                  measurable impact.
                </Typography>
              </li>
            </ul>
          </div>

          <div>
            <Typography
              fontFamily="Roboto"
              fontWeight="700"
              fontSize={contentFontSize_20}
              sx={{ margin: "0 0 10px" }}
            >
              3. Choose the Right Technologies
            </Typography>
            <ul style={{ margin: "0", paddingLeft: "20px" }}>
              <li style={{ color: "#6C7282", textAlign: "justify" }}>
                <Typography
                  fontWeight="400"
                  fontFamily="Roboto"
                  fontSize={contentFontSize_18}
                >
                  Evaluate emerging technologies like AI, IoT, cloud computing,
                  and blockchain.
                </Typography>
              </li>
              <li style={{ color: "#6C7282", textAlign: "justify" }}>
                <Typography
                  fontWeight="400"
                  fontFamily="Roboto"
                  fontSize={contentFontSize_18}
                >
                  Select tools and platforms that best address your business
                  needs and challenges.
                </Typography>
              </li>
            </ul>
          </div>
          <div>
            <Typography
              fontFamily="Roboto"
              fontWeight="700"
              fontSize={contentFontSize_20}
              sx={{ margin: "0 0 10px" }}
            >
              4. Empower Your Workforce
            </Typography>
            <ul style={{ margin: "0", paddingLeft: "20px" }}>
              <li style={{ color: "#6C7282", textAlign: "justify" }}>
                <Typography
                  fontWeight="400"
                  fontFamily="Roboto"
                  fontSize={contentFontSize_18}
                >
                  Provide training and resources to help employees adapt to new
                  technologies.
                </Typography>
              </li>
              <li style={{ color: "#6C7282", textAlign: "justify" }}>
                <Typography
                  fontWeight="400"
                  fontFamily="Roboto"
                  fontSize={contentFontSize_18}
                >
                  Foster a culture of innovation by encouraging collaboration
                  and experimentation.
                </Typography>
              </li>
            </ul>
          </div>
          <div>
            <Typography
              fontFamily="Roboto"
              fontWeight="700"
              fontSize={contentFontSize_20}
              sx={{ margin: "0 0 10px" }}
            >
              5. Integrate Digital Solutions
            </Typography>
            <ul style={{ margin: "0", paddingLeft: "20px" }}>
              <li
                style={{
                  color: "#6C7282",
                  textAlign: "justify",
                  fontFamily: "Roboto",
                }}
              >
                <Typography
                  fontWeight="400"
                  fontFamily="Roboto"
                  fontSize={contentFontSize_18}
                >
                  Implement technologies that seamlessly integrate with your
                  existing systems.
                </Typography>
              </li>
              <li style={{ color: "#6C7282", textAlign: "justify" }}>
                <Typography
                  fontWeight="400"
                  fontFamily="Roboto"
                  fontSize={contentFontSize_18}
                >
                  Focus on creating unified experiences across departments and
                  customer touchpoints.
                </Typography>
              </li>
            </ul>
          </div>
          <div>
            <Typography
              fontFamily="Roboto"
              fontWeight="700"
              fontSize={contentFontSize_20}
              sx={{ margin: "0 0 10px" }}
            >
              6. Monitor and Optimize
            </Typography>
            <ul style={{ margin: "0", paddingLeft: "20px" }}>
              <li style={{ color: "#6C7282", textAlign: "justify" }}>
                <Typography
                  fontWeight="400"
                  fontFamily="Roboto"
                  fontSize={contentFontSize_18}
                >
                  Regularly track the performance of implemented solutions.
                </Typography>
              </li>
              <li style={{ color: "#6C7282", textAlign: "justify" }}>
                <Typography
                  fontWeight="400"
                  fontFamily="Roboto"
                  fontSize={contentFontSize_18}
                >
                  Refine strategies and technologies based on insights and
                  evolving business needs.
                </Typography>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <Typography
            fontSize={contentFontsize_28}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Common Challenges in Digital Transformation (and How to Overcome
            Them):
          </Typography>
        </div>
        <Typography
          fontFamily="Roboto"
          fontWeight="700"
          fontSize={contentFontSize_20}
          sx={{ margin: "0 0 10px" }}
        >
          Resistance to Change
        </Typography>
        <Typography fontSize={contentFontSize_18}>
          <span className="digital">Solution:</span>
          <br />
          <Typography
            fontSize={contentFontSize_18}
            color="#6C7282"
            fontWeight="400"
            sx={{ margin: "0 0 10px" }}
          >
            Promote transparency and involve employees in the transformation
            process to gain their support.
          </Typography>
        </Typography>
        <Typography
          fontFamily="Roboto"
          fontWeight="700"
          fontSize={contentFontSize_20}
          sx={{ margin: "0 0 10px" }}
        >
          Integration Complexities
        </Typography>
        <Typography fontSize={contentFontSize_18}>
          <span className="digital">Solution:</span>
          <br />
          <Typography
            fontSize={contentFontSize_18}
            color="#6C7282"
            fontWeight="400"
            sx={{ margin: "0 0 10px" }}
          >
            Work with experienced partners to ensure smooth integration of new
            technologies with legacy systems.
          </Typography>
        </Typography>
        <Typography
          fontFamily="Roboto"
          fontWeight="700"
          fontSize={contentFontSize_20}
          sx={{ margin: "0 0 10px" }}
        >
          IData Privacy Concerns
        </Typography>
        <Typography fontSize={contentFontSize_18}>
          <span className="digital">Solution:</span>
          <br />
          <Typography
            fontSize={contentFontSize_18}
            color="#6C7282"
            fontWeight="400"
            sx={{ margin: "0 0 10px" }}
          >
            Implement robust security protocols and comply with industry
            regulations like GDPR or HIPAA.
          </Typography>
        </Typography>
        <Typography
          fontFamily="Roboto"
          fontWeight="700"
          fontSize={contentFontSize_20}
          sx={{ margin: "0 0 10px" }}
        >
          Budget Constraints
        </Typography>
        <Typography fontSize={contentFontSize_18}>
          <span className="digital">Solution:</span>
          <br />
          <Typography
            fontSize={contentFontSize_18}
            color="#6C7282"
            fontWeight="400"
            sx={{ margin: "0 0 10px" }}
          >
            Start with high-impact initiatives and scale gradually based on ROI
            and business needs.
          </Typography>
        </Typography>
        <div>
          <Typography
            fontFamily="Roboto"
            fontWeight="700"
            fontSize={contentFontsize_28}
          >
            Conclusion
          </Typography>
        </div>
        <Typography
          fontSize={contentFontSize_18}
          color="#6C7282"
          fontWeight="400"
          textAlign="justify"
        >
          Digital transformation is a journey that empowers businesses to
          innovate, adapt, and thrive in an increasingly competitive
          environment. By following a structured roadmap, selecting the right
          technologies, and fostering a culture of adaptability, businesses can
          unlock significant value and achieve sustainable growth. At Axia
          Kairos, we specialize in driving digital transformation with tailored
          solutions that align with your business goals. Partner with us to
          embrace innovation, enhance efficiency, and stay ahead in a rapidly
          changing digital world.
        </Typography>
      </div>
      <div
        className="bg-img"
        style={{
          backgroundImage: `url(${Common})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          // height: "500px",
          position: "relative",
          color: "white",
          padding: "20px", // Add spacing for responsiveness
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="blog-text1">
          {matches ? (
            <Typography
              fontSize={contentFontSize_50}
              fontWeight="700"
              sx={{
                marginBottom: "10px",
                color: "#FFD700",
                marginTop: smmatch ? "0px" : matches ? "50px" : "160px",
                padding: matches ? "10px 0px 10px 40px" : "0px",
                textAlign: matches ? "center" : null,
              }}
            >
              Begin Your Digital Transformation Journey with Axia Kairos
            </Typography>
          ) : (
            <Typography
              fontSize={contentFontSize_50}
              fontWeight="700"
              sx={{
                marginBottom: "10px",
                color: "#FFD700",
                marginTop: smmatch ? "0px" : matches ? "50px" : "160px",
                padding: matches ? "10px 0px 10px 40px" : "0px",
                textAlign: matches ? "center" : null,
              }}
            >
              Begin Your Digital Transformation Journey <br /> with Axia Kairos
            </Typography>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: matches && "center",
            padding: smmatch ? "0px" : "10px 40px",
            flexDirection: lgmatch ? "column" : "row",
          }}
        >
          <div style={{ width: "70%" }}>
            <Typography
              fontFamily="Roboto"
              fontWeight="400"
              fontSize={contentFontSize_20}
              style={{
                textAlign: matches ? "center" : "left",
                lineHeight: "1.6",
                marginBottom: "20px",
                color: "#FFFFFF", // White paragraph text
              }}
            >
              Ready to redefine your business with digital transformation? Axia
              Kairos delivers innovative strategies and cutting-edge
              technologies to help your organization adapt and thrive.
            </Typography>
          </div>
          <div style={{ marginTop: "10px" }}>
            <Button
              variant="contained"
              sx={{
                backgroundImage:
                  "linear-gradient(90deg, #DFAD17 0%, #795E0C 100%)",
                width: "300px",
                height: "40px",
                borderRadius: "15px",
                // padding: "10px 20px",
                // fontSize: "16px",
                // backgroundColor: "#FFC107",
                // color: "white",
                // border: "none",
                // borderRadius: "5px",
                // cursor: "pointer",
                // fontWeight: "500",
              }}
              onClick={handleContactClick}
            >
              <Typography
                fontFamily="Roboto"
                sx={{ textTransform: "capitalize", padding: "15px" }}
              >
                Start Your Transformation Today
              </Typography>
            </Button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Digital;
