import logo from "./logo.svg";
import Home from "./Home";
import Navbar from "./Navbar";
import {
  HashRouter as Router,
  Routes,
  Route,
  useLocation,
  useParams,
} from "react-router-dom";
import Aboutus from "./Aboutus";
import Services from "./Services";
import Industries from "./Industries";
import Blogs from "./Blogs";
import Careers from "./Careers";
import Contact from "./Contact";
//import InnerPage from "./Blogs/InnerPage";
import ServiceInnerPage from "./Services/ServiceInnerPage/ServiceInnerPage";
import DigitalTransformation from "./Services/ServiceInnerPage/DigitalTransformation";
import BusinessProcess from "./Services/ServiceInnerPage/BusinessProcess";
import DataAnalytics from "./Services/ServiceInnerPage/DataAnalytics";
import ECommerce from "./Services/ServiceInnerPage/ECommerce";
import BlockChain from "./Services/ServiceInnerPage/BlockChain";
import CyberSecurity from "./Services/ServiceInnerPage/CyberSecurity";
import CustomSoftware from "./Services/ServiceInnerPage/CustomSoftware";
import Devops from "./Services/ServiceInnerPage/Devops";
import Mobility from "./Services/ServiceInnerPage/Mobility";
import ItServices from "./Services/ServiceInnerPage/ItServices";
import Digital from "./Blogs/Digital";
import BusinessBlog from "./Blogs/BusinessBlog";
import DataAnalyticsBlog from "./Blogs/DataAnalyticsBlog";
import CustomSoftwareBlog from "./Blogs/CustomSoftwareBlog";
import DevopsBlog from "./Blogs/DevopsBlog";
//import InnerPage from "./Blogs/InnerPage";
import CareersInner from "./Careers/CareersMain/CareersInner";
import MobilityBlog from "./Blogs/MobilityBlog";
import ECommerceBlog from "./Blogs/ECommerceBlog";
import AiBlog from "./Blogs/AiBlog";
import CyberSecurityBlog from "./Blogs/CyberSecurityBlog";
import ApplicationBlog from "./Blogs/ApplicationBlog";
import Privacypolicy from "./PrivacyPolicy/Privacypolicy";
import TermsAndConditions from "./Terms&Condition/TermsAndConditions";

function App() {
  const { title } = useParams();
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<Aboutus />} />
        <Route path="/services" element={<Services />} />
        <Route path="/industries" element={<Industries />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blogs/Digital-Transformation" element={<Digital />} />
        <Route
          path="/blogs/Business-Process-Automation"
          element={<BusinessBlog />}
        />
        <Route path="/blogs/Data-Analytics" element={<DataAnalyticsBlog />} />
        <Route path="/blogs/Custom-Software" element={<CustomSoftwareBlog />} />
        <Route path="/blogs/Devops" element={<DevopsBlog />} />
        <Route path="/blogs/Mobility-Web-Blog" element={<MobilityBlog />} />
        <Route path="/privacypolicy" element={<Privacypolicy/>}/>
        <Route path="/terms&conditions" element={<TermsAndConditions/>}/>
        <Route
          path="/blogs/ECommerce-Solutions-Blog"
          element={<ECommerceBlog />}
        />
        <Route path="/blogs/Ai-BlockChain-IoT-Blog" element={<AiBlog />} />
        <Route
          path="/blogs/CyberSecurity-Blog"
          element={<CyberSecurityBlog />}
        />
        <Route
          path="/blogs/Application-Managed-Services-Blog"
          element={<ApplicationBlog />}
        />
        <Route
          path="/services/Digital-Transfromation"
          element={<DigitalTransformation />}
        />
        <Route
          path="/services/Business-Process-Automation"
          element={<BusinessProcess />}
        />
        <Route
          path="/services/Data-Analytics-Insights"
          element={<DataAnalytics />}
        />
        <Route path="/services/E-Commerce-Solutions" element={<ECommerce />} />
        <Route
          path="/services/Ai-BlockChain-Iot-Solutions-for-Business-Innovation"
          element={<BlockChain />}
        />
        <Route
          path="/services/CyberSecurity-Services"
          element={<CyberSecurity />}
        />
        <Route
          path="/services/Custom-Software-Development"
          element={<CustomSoftware />}
        />
        <Route path="/services/Devops-Cloud-Engineering" element={<Devops />} />
        <Route
          path="/services/Mobility-Web-Development"
          element={<Mobility />}
        />
        <Route
          path="/services/Application-Managed-IT-Services"
          element={<ItServices />}
        />

        {/* <Route path="/blogs/Digital-Transformation" element={<InnerPage />} /> */}
        <Route path="/careers/Job-Apply" element={<CareersInner />} />
      </Routes>
    </Router>
  );
}

export default App;
