import { Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import "./InnerPage.css";
import React, { useEffect } from "react";
import BackArrow from "../utils/icons/BackArrow.svg";
import Blog7 from "../utils/images/Blog7.webp";
import Gradient from "../utils/images/Gradient.jpg";
import Name from "../utils/icons/Name.svg";
import calender from "../utils/icons/calender.svg";
import clock from "../utils/icons/clock.svg";
import Common from "../utils/images/Common.jpg";
import {
  contentFontSize_16,
  contentFontSize_18,
  contentFontSize_20,
  contentFontsize_28,
  contentFontSize_48,
  contentFontSize_50,
} from "../Typography";
import Navbar from "../Navbar";
import { useNavigate } from "react-router-dom";
function ECommerceBlog() {
  useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));
  const nav = useNavigate();

  const handleBackClick = () => {
    nav("/blogs");
  };
  const handleContactClick = () => {
    nav("./contact");
  };

  return (
    <div>
      <>
        <Navbar />
      </>
      <div className="back-btn" onClick={handleBackClick}>
        <img src={BackArrow} style={{marginTop:mdmatch && "5px"}}  />
        <span
          style={{
            marginLeft: "10px",
            fontSize: contentFontSize_18,
            fontFamily: "Roboto",
            marginBottom: "3px",
          }}
        >
          Back to Blogs
        </span>
      </div>
      <div
        className="responsive-container"
        style={{
          backgroundImage: xsmatch ? `url(${Gradient})` : `url(${Blog7})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "500px",
          position: "relative",
          color: "white",
          display: "flex",
          width: "100%",
          //justifyContent: "center",
          alignItems: "flex-end",
          marginTop: "30px",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="blog-text">
          <Typography
            fontSize={contentFontSize_48}
            fontWeight="700"
            marginLeft="0px"
          >
            Top Features Your E-Commerce Website Needs to Succeed
          </Typography>
        </div>
      </div>
      {/* <div className="blog-section-one">
        <div className="blog-section-sub">
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={Name} alt="Profile" style={{ marginRight: "5px" }} />
            <span
              style={{
                fontFamily: "Roboto",
                fontWeight: "500",
                fontSize: "14px",
              }}
            >
              Shyam Pushkar
            </span>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={calender} alt="calender" style={{ marginRight: "0px" }} />
            <span
              style={{
                marginLeft: "10px",
                fontFamily: "Roboto",
                fontWeight: "500",
                fontSize: "14px",
              }}
            >
              January 21 2024
            </span>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={clock} alt="clock" style={{ marginRight: "0px" }} />
            <span
              style={{
                marginLeft: "10px",
                fontFamily: "Roboto",
                fontWeight: "500",
                fontSize: "14px",
                textAlign: "center",
              }}
            >
              8:25
            </span>
          </div>
        </div>
      </div> */}
      <div className="main">
        <div>
          <Typography
            fontSize={contentFontsize_28}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Introduction
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            sx={{
              textAlign: "justify",
              color: "#6C7282",
              marginTop: "10px",
              //letterSpacing: "1px",
            }}
          >
            As the e-commerce industry continues to grow, businesses must build
            online stores that stand out in a crowded digital marketplace. An
            effective e-commerce website goes beyond a sleek design—it requires
            features that enhance user experience, streamline operations, and
            drive conversions. This blog highlights the must-have features your
            e-commerce website needs to succeed and how they can transform your
            online business.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontsize_28}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Why E-Commerce Websites Need Advanced Features
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            lineHeight="31px"
            sx={{
              textAlign: "justify",
              color: "#6C7282",
              marginTop: "10px",
              //letterSpacing: "1px",
            }}
          >
            While aesthetics attract customers, functionality keeps them engaged
            and drives conversions. Advanced e-commerce features enable seamless
            navigation, secure transactions, and personalized experiences,
            ensuring your online store remains competitive.
          </Typography>
        </div>

        <div>
          <Typography
            fontSize={contentFontsize_28}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Top Features for a Successful E-Commerce Website
          </Typography>
        </div>
        <Typography
          fontFamily="Roboto"
          fontWeight="700"
          fontSize={contentFontSize_20}
          sx={{ margin: "20px 0 10px" }}
        >
          User-Friendly Navigation
        </Typography>
        <ul>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Why It’s Important:</span>

              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                {" "}
                Clear and intuitive navigation helps users find products easily,
                improving their shopping experience.
              </Typography>
            </Typography>
          </li>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">How to Implement:</span>

              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                {" "}
                Use a well-structured menu, include search functionality with
                filters, and organize products into categories.
              </Typography>
            </Typography>
          </li>
        </ul>

        <Typography
          fontFamily="Roboto"
          fontWeight="700"
          fontSize={contentFontSize_20}
          sx={{ margin: "0 0 10px" }}
        >
          Mobile Responsiveness
        </Typography>
        <ul>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Why It’s Important:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                With over 60% of e-commerce traffic coming from mobile devices,
                a responsive design ensures a seamless shopping experience
                across all screen sizes.
              </Typography>
            </Typography>
          </li>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">How to Implement:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                Optimize your website for mobile users with adaptive layouts,
                quick-loading pages, and touch-friendly elements.
              </Typography>
            </Typography>
          </li>
        </ul>
        <Typography
          fontFamily="Roboto"
          fontWeight="700"
          fontSize={contentFontSize_20}
          sx={{ margin: "0 0 10px" }}
        >
          Secure Payment Gateways
        </Typography>
        <ul>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Why It’s Important:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                Customers need assurance that their payment details are safe.
              </Typography>
            </Typography>
          </li>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">How to Implement:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                Integrate trusted payment gateways like PayPal, Stripe, or
                Razorpay, and implement SSL encryption to secure transactions.
              </Typography>
            </Typography>
          </li>
        </ul>
        <Typography
          fontFamily="Roboto"
          fontWeight="700"
          fontSize={contentFontSize_20}
          sx={{ margin: "0 0 10px" }}
        >
          Product Reviews and Ratings
        </Typography>
        <ul>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Why It’s Important:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                Social proof influences purchasing decisions and builds trust.
              </Typography>
            </Typography>
          </li>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">How to Implement:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                Enable customers to leave reviews and ratings on product pages,
                and display them prominently.
              </Typography>
            </Typography>
          </li>
        </ul>
        <Typography
          fontFamily="Roboto"
          fontWeight="700"
          fontSize={contentFontSize_20}
          sx={{ margin: "0 0 10px" }}
        >
          Advanced Search and Filters
        </Typography>
        <ul>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Why It’s Important:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                Customers want to find products quickly without scrolling
                through endless pages.
              </Typography>
            </Typography>
          </li>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">How to Implement:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                Include features like predictive search, category-specific
                filters, and sorting options for price, ratings, and popularity.
              </Typography>
            </Typography>
          </li>
        </ul>
        <Typography
          fontFamily="Roboto"
          fontWeight="700"
          fontSize={contentFontSize_20}
          sx={{ margin: "0 0 10px" }}
        >
          Personalized Recommendations
        </Typography>
        <ul>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Why It’s Important:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                Personalization improves engagement and increases the likelihood
                of repeat purchases.
              </Typography>
            </Typography>
          </li>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">How to Implement:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                Use AI-driven algorithms to recommend products based on browsing
                history, purchase behavior, and preferences.
              </Typography>
            </Typography>
          </li>
        </ul>

        <Typography
          fontFamily="Roboto"
          fontWeight="700"
          fontSize={contentFontSize_20}
          sx={{ margin: "0 0 10px" }}
        >
          Multiple Delivery Options
        </Typography>
        <ul>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Why It’s Important:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                Flexible delivery options improve customer satisfaction and
                loyalty.
              </Typography>
            </Typography>
          </li>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">How to Implement:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                Offer choices like standard, express, or same-day delivery, and
                allow customers to track their orders in real-time.
              </Typography>
            </Typography>
          </li>
        </ul>
        <Typography
          fontFamily="Roboto"
          fontWeight="700"
          fontSize={contentFontSize_20}
          sx={{ margin: "0 0 10px" }}
        >
          Guest Checkout
        </Typography>
        <ul>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Why It’s Important:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                Requiring account creation can deter potential buyers.
              </Typography>
            </Typography>
          </li>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">How to Implement:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                Provide a guest checkout option while encouraging account
                creation with perks like discounts or loyalty points.
              </Typography>
            </Typography>
          </li>
        </ul>
        <Typography
          fontFamily="Roboto"
          fontWeight="700"
          fontSize={contentFontSize_20}
          sx={{ margin: "0 0 10px" }}
        >
          Comprehensive Analytics Dashboard
        </Typography>
        <ul>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Why It’s Important:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                Analytics provide insights into sales performance, customer
                behavior, and product trends.
              </Typography>
            </Typography>
          </li>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">How to Implement:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                Use analytics tools to monitor KPIs like conversion rates, cart
                abandonment, and top-performing products
              </Typography>
            </Typography>
          </li>
        </ul>
        <Typography
          fontFamily="Roboto"
          fontWeight="700"
          fontSize={contentFontSize_20}
          sx={{ margin: "0 0 10px" }}
        >
          Loyalty Programs
        </Typography>
        <ul>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Why It’s Important:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                Loyalty programs incentivize repeat purchases and foster
                long-term relationships with customers.
              </Typography>
            </Typography>
          </li>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">How to Implement:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                Offer rewards for every purchase, referrals, or social media
                engagement, redeemable as discounts or free products.
              </Typography>
            </Typography>
          </li>
        </ul>
        <div>
          <Typography
            fontSize={contentFontsize_28}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px", letterSpacing: "1px" }}
          >
            Best Practices for Building a High-Performance E-Commerce Website
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Optimize Website Speed
          </Typography>
        </div>
        <div>
          <ul>
            <li>
              <Typography
                fontSize={contentFontSize_18}
                fontFamily="Roboto"
                fontWeight="400"
                sx={{
                  textAlign: "justify",
                  color: "#6C7282",
                  marginTop: "10px",
                }}
              >
                Ensure pages load quickly by compressing images and minimizing
                scripts.
              </Typography>
            </li>
          </ul>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Focus on SEO
          </Typography>
        </div>
        <div>
          <ul>
            <li>
              <Typography
                fontSize={contentFontSize_18}
                fontFamily="Roboto"
                fontWeight="400"
                sx={{
                  textAlign: "justify",
                  color: "#6C7282",
                  marginTop: "10px",
                }}
              >
                Optimize product descriptions, use relevant keywords, and
                implement schema markup to improve search rankings.
              </Typography>
            </li>
          </ul>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Test for User Experience (UX)
          </Typography>
        </div>
        <div>
          <ul>
            <li>
              <Typography
                fontSize={contentFontSize_18}
                fontFamily="Roboto"
                fontWeight="400"
                sx={{
                  textAlign: "justify",
                  color: "#6C7282",
                  marginTop: "10px",
                }}
              >
                Regularly test your website for functionality, usability, and
                responsiveness to ensure a smooth shopping experience.
              </Typography>
            </li>
          </ul>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Prioritize Data Security
          </Typography>
        </div>
        <div>
          <ul>
            <li>
              <Typography
                fontSize={contentFontSize_18}
                fontFamily="Roboto"
                fontWeight="400"
                sx={{
                  textAlign: "justify",
                  color: "#6C7282",
                  marginTop: "10px",
                }}
              >
                Protect customer data with robust security measures, including
                firewalls and regular vulnerability assessments.
              </Typography>
            </li>
          </ul>
        </div>
        <div>
          <Typography
            fontSize={contentFontsize_28}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Common Pitfalls to Avoid
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Overcomplicating the Design:
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            sx={{ textAlign: "justify", color: "#6C7282", marginTop: "10px" }}
          >
            Simplicity is key. Avoid cluttering pages with unnecessary elements
            that distract customers.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Ignoring Mobile Optimization:
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            sx={{ textAlign: "justify", color: "#6C7282", marginTop: "10px" }}
          >
            Many businesses lose customers due to poor mobile experiences.
            Always prioritize mobile usability.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Neglecting Customer Support:
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            sx={{ textAlign: "justify", color: "#6C7282", marginTop: "10px" }}
          >
            Provide multiple support channels like live chat, email, and FAQs to
            address customer concerns promptly.
          </Typography>
        </div>

        <div>
          <Typography
            fontFamily="Roboto"
            fontWeight="700"
            fontSize={contentFontsize_28}
          >
            Conclusion
          </Typography>
        </div>
        <Typography
          fontSize={contentFontSize_18}
          color="#6C7282"
          fontWeight="400"
          textAlign="justify"
        >
          A successful e-commerce website is more than just a platform to sell
          products—it’s a dynamic tool that drives engagement, builds trust, and
          enhances the customer experience. By incorporating the right features
          and following best practices, you can create an online store that not
          only attracts customers but also keeps them coming back.
        </Typography>
      </div>
      <div
        className="bg-img"
        style={{
          backgroundImage: `url(${Common})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          // height: "500px",
          position: "relative",
          color: "white",
          padding: "20px", // Add spacing for responsiveness
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="blog-text1">
          {matches ? (
            <Typography
              fontSize={contentFontSize_50}
              fontWeight="700"
              sx={{
                marginBottom: "10px",
                color: "#FFD700",
                marginTop: smmatch ? "0px" : matches ? "50px" : "160px",
                padding: matches ? "10px 0px 10px 40px" : "0px",
                textAlign: matches ? "center" : null,
              }}
            >
              Begin Your Digital Transformation Journey with Axia Kairos
            </Typography>
          ) : (
            <Typography
              fontSize={contentFontSize_50}
              fontWeight="700"
              sx={{
                marginBottom: "10px",
                color: "#FFD700",
                marginTop: smmatch ? "0px" : matches ? "50px" : "160px",
                padding: matches ? "10px 0px 10px 40px" : "0px",
                textAlign: matches ? "center" : null,
              }}
            >
              Begin Your Digital Transformation Journey <br /> with Axia Kairos
            </Typography>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: matches && "center",
            padding: smmatch ? "0px" : "10px 40px",
            flexDirection: lgmatch ? "column" : "row",
          }}
        >
          <div style={{ width: "70%" }}>
            <Typography
              fontFamily="Roboto"
              fontWeight="400"
              fontSize={contentFontSize_20}
              style={{
                textAlign: matches ? "center" : "left",
                lineHeight: "1.6",
                marginBottom: "20px",
                color: "#FFFFFF", // White paragraph text
              }}
            >
              Ready to redefine your business with digital transformation? Axia
              Kairos delivers innovative strategies and cutting-edge
              technologies to help your organization adapt and thrive.
            </Typography>
          </div>
          <div style={{ marginTop: "10px" }}>
            <Button
              variant="contained"
              sx={{
                backgroundImage:
                  "linear-gradient(90deg, #DFAD17 0%, #795E0C 100%)",
                width: "300px",
                height: "40px",
                borderRadius: "15px",
                // padding: "10px 20px",
                // fontSize: "16px",
                // backgroundColor: "#FFC107",
                // color: "white",
                // border: "none",
                // borderRadius: "5px",
                // cursor: "pointer",
                // fontWeight: "500",
              }}
              onClick={handleContactClick}
            >
              <Typography
                //fontFamily="Roboto"
                sx={{ textTransform: "capitalize", padding: "15px" }}
              >
                Start Your Transformation Today
              </Typography>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ECommerceBlog;
