import { Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import "./InnerPage.css";
import React, { useEffect } from "react";
import BackArrow from "../utils/icons/BackArrow.svg";
import Blog4Custom from "../utils/images/Blog4Custom.webp";
import Gradient from "../utils/images/Gradient.jpg";
import Name from "../utils/icons/Name.svg";
import calender from "../utils/icons/calender.svg";
import clock from "../utils/icons/clock.svg";
import Common from "../utils/images/Common.jpg";
import {
  contentFontSize_16,
  contentFontSize_18,
  contentFontSize_20,
  contentFontsize_28,
  contentFontSize_48,
  contentFontSize_50,
} from "../Typography";
import Navbar from "../Navbar";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer/Footer";
function CustomSoftwareBlog() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const upXlmatch = useMediaQuery(theme.breakpoints.up("xl"));
  const xlmatch = useMediaQuery(theme.breakpoints.down("xl"));
  const lgmatch = useMediaQuery(theme.breakpoints.down("lg"));
  const mdmatch = useMediaQuery(theme.breakpoints.down("md"));
  const smmatch = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmatch = useMediaQuery(theme.breakpoints.down("xs"));

  const nav = useNavigate();

  const handleBackClick = () => {
    nav("/blogs");
  };
  const handleContactClick = () => {
    nav("./contact");
  };

  return (
    <div>
      <>
        <Navbar />
      </>
      <div className="back-btn" onClick={handleBackClick}>
        <img src={BackArrow} style={{marginTop:mdmatch && "4px"}}  />
        <span
          style={{
            marginLeft: "10px",
            fontSize: contentFontSize_18,
            fontFamily: "Roboto",
            marginBottom: "3px",
          }}
        >
          Back to Blogs
        </span>
      </div>
      <div
        className="responsive-container"
        style={{
          backgroundImage: xsmatch ? `url(${Gradient})` : `url(${Blog4Custom})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "500px",
          position: "relative",
          color: "white",
          display: "flex",
          width: "100%",
          //justifyContent: "center",
          alignItems: "flex-end",
          marginTop: "30px",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="blog-text">
          <Typography
            fontSize={contentFontSize_48}
            fontWeight="700"
            marginLeft="0px"
          >
            Top 5 Benefits of Investing in Custom Software for Your Business
          </Typography>
        </div>
      </div>
      {/* <div className="blog-section-one">
        <div className="blog-section-sub">
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={Name} alt="Profile" style={{ marginRight: "5px" }} />
            <span
              style={{
                fontFamily: "Roboto",
                fontWeight: "500",
                fontSize: "14px",
              }}
            >
              Shyam Pushkar
            </span>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={calender} alt="calender" style={{ marginRight: "0px" }} />
            <span
              style={{
                marginLeft: "10px",
                fontFamily: "Roboto",
                fontWeight: "500",
                fontSize: "14px",
              }}
            >
              January 21 2024
            </span>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={clock} alt="clock" style={{ marginRight: "0px" }} />
            <span
              style={{
                marginLeft: "10px",
                fontFamily: "Roboto",
                fontWeight: "500",
                fontSize: "14px",
                textAlign: "center",
              }}
            >
              8:25
            </span>
          </div>
        </div>
      </div> */}
      <div className="main">
        <div>
          <Typography
            fontSize={contentFontsize_28}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Introduction
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            sx={{
              textAlign: "justify",
              color: "#6C7282",
              marginTop: "10px",
              //letterSpacing: "1px",
            }}
          >
            Off-the-shelf software might seem like a quick solution for business
            needs, but it often falls short in addressing unique challenges.
            Custom software development, on the other hand, provides tailor-made
            solutions that align perfectly with your specific business processes
            and goals. This blog explores the top five benefits of investing in
            custom software and how it can propel your business to new heights.
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontsize_28}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Why Choose Custom Software Over Off-the-Shelf Solutions?
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_18}
            fontFamily="Roboto"
            fontWeight="400"
            lineHeight="31px"
            sx={{
              textAlign: "justify",
              color: "#6C7282",
              marginTop: "10px",
              //letterSpacing: "1px",
            }}
          >
            Off-the-shelf software is designed for general use, which means it
            often includes unnecessary features and lacks the flexibility to
            adapt to your specific needs. Custom software is built specifically
            for your business, ensuring it integrates seamlessly with your
            operations while providing scalability and competitive advantages.
          </Typography>
        </div>

        <div>
          <Typography
            fontSize={contentFontsize_28}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Top 5 Benefits of Custom Software Development
          </Typography>
        </div>
        <Typography
          fontFamily="Roboto"
          fontWeight="700"
          fontSize={contentFontSize_20}
          sx={{ margin: "20px 0 10px" }}
        >
          Tailored to Your Business Needs
        </Typography>
        <ul>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Benefit:</span>

              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                {" "}
                Custom software is designed to address your unique business
                challenges and objectives.
              </Typography>
            </Typography>
          </li>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Example:</span>

              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                {" "}
                A logistics company might require software with real-time
                tracking and route optimization, features that may not be
                available in off-the-shelf solutions.
              </Typography>
            </Typography>
          </li>
        </ul>

        <Typography
          fontFamily="Roboto"
          fontWeight="700"
          fontSize={contentFontSize_20}
          sx={{ margin: "0 0 10px" }}
        >
          Scalability for Future Growth
        </Typography>
        <ul>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Benefit:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                Custom software evolves with your business, allowing you to add
                new features or expand capabilities as your needs grow.
              </Typography>
            </Typography>
          </li>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Example:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                An e-commerce platform can integrate additional payment gateways
                or inventory management systems as the business scales.
              </Typography>
            </Typography>
          </li>
        </ul>
        <Typography
          fontFamily="Roboto"
          fontWeight="700"
          fontSize={contentFontSize_20}
          sx={{ margin: "0 0 10px" }}
        >
          Seamless Integration with Existing Systems
        </Typography>
        <ul>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Benefit:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                Custom software integrates effortlessly with your current tools,
                ensuring a smooth workflow and data consistency.
              </Typography>
            </Typography>
          </li>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Example:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                A custom CRM can sync with your ERP system, providing a unified
                view of customer interactions and inventory.
              </Typography>
            </Typography>
          </li>
        </ul>
        <Typography
          fontFamily="Roboto"
          fontWeight="700"
          fontSize={contentFontSize_20}
          sx={{ margin: "0 0 10px" }}
        >
          Enhanced Security
        </Typography>
        <ul>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Benefit:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                Custom software incorporates advanced security measures tailored
                to your industry’s specific requirements, reducing
                vulnerabilities.
              </Typography>
            </Typography>
          </li>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Example:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                A healthcare application can comply with HIPAA regulations,
                ensuring sensitive patient data is protected.
              </Typography>
            </Typography>
          </li>
        </ul>
        <Typography
          fontFamily="Roboto"
          fontWeight="700"
          fontSize={contentFontSize_20}
          sx={{ margin: "0 0 10px" }}
        >
          Competitive Advantage
        </Typography>
        <ul>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Benefit:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                By offering unique functionalities and superior performance,
                custom software gives your business an edge over competitors.
              </Typography>
            </Typography>
          </li>
          <li>
            <Typography fontSize={contentFontSize_18}>
              <span className="digital">Example:</span>
              <br />
              <Typography
                fontSize={contentFontSize_18}
                color="#6C7282"
                fontWeight="400"
                sx={{ margin: "0 0 10px" }}
              >
                A retail company can use custom analytics software to track
                customer behavior and personalize shopping experiences.
              </Typography>
            </Typography>
          </li>
        </ul>
        <div>
          <Typography
            fontSize={contentFontsize_28}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px", letterSpacing: "1px" }}
          >
            How to Approach Custom Software Development
          </Typography>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Identify Business Goals
          </Typography>
        </div>
        <div>
          <ul>
            <li>
              <Typography
                fontSize={contentFontSize_18}
                fontFamily="Roboto"
                fontWeight="400"
                sx={{
                  textAlign: "justify",
                  color: "#6C7282",
                  marginTop: "10px",
                }}
              >
                Define the problems you want the software to solve and the goals
                it should achieve.
              </Typography>
            </li>
          </ul>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Partner with a Trusted Development Team
          </Typography>
        </div>
        <div>
          <ul>
            <li>
              <Typography
                fontSize={contentFontSize_18}
                fontFamily="Roboto"
                fontWeight="400"
                sx={{
                  textAlign: "justify",
                  color: "#6C7282",
                  marginTop: "10px",
                }}
              >
                Choose a software development partner, like Axia Kairos, with
                expertise in creating solutions tailored to your industry.
              </Typography>
            </li>
          </ul>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Define Requirements
          </Typography>
        </div>
        <div>
          <ul>
            <li>
              <Typography
                fontSize={contentFontSize_18}
                fontFamily="Roboto"
                fontWeight="400"
                sx={{
                  textAlign: "justify",
                  color: "#6C7282",
                  marginTop: "10px",
                }}
              >
                Work with the development team to outline features,
                integrations, and technical specifications.
              </Typography>
            </li>
          </ul>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Develop and Test
          </Typography>
        </div>
        <div>
          <ul>
            <li>
              <Typography
                fontSize={contentFontSize_18}
                fontFamily="Roboto"
                fontWeight="400"
                sx={{
                  textAlign: "justify",
                  color: "#6C7282",
                  marginTop: "10px",
                }}
              >
                Use agile development practices to ensure the software is built
                iteratively and tested thoroughly.
              </Typography>
            </li>
          </ul>
        </div>
        <div>
          <Typography
            fontSize={contentFontSize_20}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
            Deploy and Maintain
          </Typography>
        </div>
        <div>
          <ul>
            <li>
              <Typography
                fontSize={contentFontSize_18}
                fontFamily="Roboto"
                fontWeight="400"
                sx={{
                  textAlign: "justify",
                  color: "#6C7282",
                  marginTop: "10px",
                }}
              >
                Once deployed, continuously monitor the software’s performance
                and implement updates as needed.
              </Typography>
            </li>
          </ul>
        </div>
        <div>
          <Typography
            fontSize={contentFontsize_28}
            fontFamily="Roboto"
            fontWeight="700"
            sx={{ marginTop: "10px" }}
          >
           Common Myths About Custom Software Development
          </Typography>
        </div>

        <div style={{ lineHeight: "1.8", marginTop: "20px" }}>
          <div style={{ marginBottom: "20px", marginTop: "20px" }}>
            <Typography
              fontFamily="Roboto"
              fontWeight="700"
              fontSize={contentFontSize_20}
              //   sx={{ margin: "0 0 10px" }}
            >
              Myth 1: It’s Too Expensive 
            </Typography>
            {/* <ul style={{ margin: "0", paddingLeft: "20px" }}>
              <li style={{ color: "#6C7282", textAlign: "justify" }}> */}
            <ul>
              <li>
                <Typography fontSize={contentFontSize_18}>
                  <span className="digital">Reality:</span>

                  <Typography
                    fontSize={contentFontSize_18}
                    color="#6C7282"
                    fontWeight="400"
                    sx={{ margin: "0 0 10px" }}
                  >
                    {" "}
                    While the upfront cost may be higher than off-the-shelf solutions, the long-term ROI often outweighs the initial investment. 
                  </Typography>
                </Typography>
              </li>
            </ul>
            {/* </li>
              <li style={{ color: "#6C7282", textAlign: "justify" }}> */}

            {/* </li>
            </ul> */}
          </div>

          <div style={{ marginBottom: "20px" }}>
            <Typography
              fontFamily="Roboto"
              fontWeight="700"
              fontSize={contentFontSize_20}
              //   sx={{ margin: "0 0 10px" }}
            >
             Myth 2: It Takes Too Long to Develop 
            </Typography>
            {/* <ul style={{ margin: "0", paddingLeft: "20px" }}>
              <li style={{ color: "#6C7282", textAlign: "justify" }}> */}
            <ul>
              <li>
                <Typography fontSize={contentFontSize_18}>
                  <span className="digital">Reality:</span>

                  <Typography
                    fontSize={contentFontSize_18}
                    color="#6C7282"
                    fontWeight="400"
                    sx={{ margin: "0 0 10px" }}
                  >
                    {" "}
                    With agile methodologies, custom software can be developed faster and more efficiently than ever before.
                  </Typography>
                </Typography>
              </li>
            </ul>
            {/* </li>
              <li style={{ color: "#6C7282", textAlign: "justify" }}>
                <Typography
                  fontWeight="400"
                  fontFamily="Roboto"
                  fontSize={contentFontSize_16}
                >
                  Align objectives with overall business strategy to ensure
                  measurable impact.
                </Typography>
              </li>
            </ul> */}
          </div>

          <div>
            <Typography
              fontFamily="Roboto"
              fontWeight="700"
              fontSize={contentFontSize_20}
              //   sx={{ margin: "0 0 10px" }}
            >
             Myth 3: Off-the-Shelf Software is Just as Good 
            </Typography>
            <ul>
              <li>
                <Typography fontSize={contentFontSize_18}>
                  <span className="digital">Reality:</span>

                  <Typography
                    fontSize={contentFontSize_18}
                    color="#6C7282"
                    fontWeight="400"
                    sx={{ margin: "0 0 10px" }}
                  >
                    {" "}
                    Generic solutions rarely address all your business needs and may lack the flexibility to grow with your business. 
                  </Typography>
                </Typography>
              </li>
            </ul>
          </div>
        </div>

        <div>
          <Typography
            fontFamily="Roboto"
            fontWeight="700"
            fontSize={contentFontsize_28}
          >
            Conclusion
          </Typography>
        </div>
        <Typography
          fontSize={contentFontSize_18}
          color="#6C7282"
          fontWeight="400"
          textAlign="justify"
        >
          Custom software development is a smart investment for businesses seeking scalability, security, and a competitive edge. By tailoring software to your unique needs, you can streamline operations, improve customer experiences, and drive growth. 

At Axia Kairos, we specialize in developing custom software that solves your business challenges and aligns with your goals. Let us help you create a solution that drives efficiency and innovation. 
        </Typography>
      </div>
      <div
        className="bg-img"
        style={{
          backgroundImage: `url(${Common})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          // height: "500px",
          position: "relative",
          color: "white",
          padding: "20px", // Add spacing for responsiveness
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="blog-text1">
          {matches ? (
            <Typography
              fontSize={contentFontSize_50}
              fontWeight="700"
              sx={{
                marginBottom: "10px",
                color: "#FFD700",
                marginTop: smmatch ? "0px" : matches ? "50px" : "160px",
                padding: matches ? "10px 0px 10px 40px" : "0px",
                textAlign: matches ? "center" : null,
              }}
            >
              Begin Your Digital Transformation Journey with Axia Kairos
            </Typography>
          ) : (
            <Typography
              fontSize={contentFontSize_50}
              fontWeight="700"
              sx={{
                marginBottom: "10px",
                color: "#FFD700",
                marginTop: smmatch ? "0px" : matches ? "50px" : "160px",
                padding: matches ? "10px 0px 10px 40px" : "0px",
                textAlign: matches ? "center" : null,
              }}
            >
              Begin Your Digital Transformation Journey <br /> with Axia Kairos
            </Typography>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: matches && "center",
            padding: smmatch ? "0px" : "10px 40px",
            flexDirection: lgmatch ? "column" : "row",
          }}
        >
          <div style={{ width: "70%" }}>
            <Typography
              fontFamily="Roboto"
              fontWeight="400"
              fontSize={contentFontSize_20}
              style={{
                textAlign: matches ? "center" : "left",
                lineHeight: "1.6",
                marginBottom: "20px",
                color: "#FFFFFF", // White paragraph text
              }}
            >
              Ready to redefine your business with digital transformation? Axia
              Kairos delivers innovative strategies and cutting-edge
              technologies to help your organization adapt and thrive.
            </Typography>
          </div>
          <div style={{ marginTop: "10px" }}>
            <Button
              variant="contained"
              sx={{
                backgroundImage:
                  "linear-gradient(90deg, #DFAD17 0%, #795E0C 100%)",
                width: "300px",
                height: "40px",
                borderRadius: "15px",
                // padding: "10px 20px",
                // fontSize: "16px",
                // backgroundColor: "#FFC107",
                // color: "white",
                // border: "none",
                // borderRadius: "5px",
                // cursor: "pointer",
                // fontWeight: "500",
              }}
          onClick={handleContactClick}
            >
              <Typography
                //fontFamily="Roboto"
                sx={{ textTransform: "capitalize", padding: "15px" }}
              >
                Start Your Transformation Today
              </Typography>
            </Button>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default CustomSoftwareBlog;
